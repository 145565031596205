import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  otp_code: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  cursorPointer: any;
  loading: boolean;
  otperror: boolean;
  otperrorText: string;
  password: string;
  confirmPassword: string;
  pswderror: boolean;
  pswderrorText: string;
  cpswderror: boolean;
  cpswderrorText: string;
  email: string;
  emailerror: boolean;
  emailerrorText: string;
  openMenu: any;
  selectedCountryCode: string;
  selectedCountry: any;
  countryList: any;
  forgetType: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  showResendButton: boolean;
  oneMinTimer: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgetEmailCallId: string = '';
  emailOtpCallId: string = '';
  newPasswordCallId: string = '';
  countryListApiId: string = '';
  interval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      otp_code: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      cursorPointer: 0,
      loading: false,
      otperror: false,
      otperrorText: '',
      password: '',
      confirmPassword: '',
      pswderror: false,
      pswderrorText: '',
      cpswderror: false,
      cpswderrorText: '',
      email: '',
      emailerror: false,
      emailerrorText: '',
      openMenu: null,
      selectedCountryCode: "+966",
      selectedCountry: 'SA',
      countryList: [],
      forgetType: 'email',
      showPassword: false,
      showConfirmPassword: false,
      showResendButton: false,
      oneMinTimer: 59

    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getCountryList();
    if (window.location.href.toLowerCase().includes('otpverification') && this.state.oneMinTimer > 0) {
      this.interval = setInterval(
        () => this.setState((prevState) => ({ oneMinTimer: prevState.oneMinTimer - 1 })),
        1000
      );
    }
  }

  componentDidUpdate() {
    if (window.location.href.toLowerCase().includes('otpverification') && this.state.oneMinTimer === 0) {
      clearInterval(this.interval);
    }
  }

  // Customizable Area Start

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = () => this.setState({ showPassword: !this.state.showPassword });

  handleClickShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  handleMouseDownConfrimPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

  onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const numberReg = /^\d*(\.\d+)?$/;
    const value = event.target.value.trim();
    if (value.length >= 2 && numberReg.test(value)) {
      this.setState({ forgetType: "phone" });
    } else {
      this.setState({
        forgetType: "email",
        openMenu: null
      });
    }
    this.setState({
      email: event.target.value.trim(), emailerror: false,
      emailerrorText: ""
    });
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.forgetEmailCallId:
          this.handleForgetEmailResponse(responseJson)
          break;
        case this.emailOtpCallId:
          this.handleEmailOtpResponse(responseJson)
          break;
        case this.newPasswordCallId:
          this.handleNewPasswordResponse(responseJson)
          break;
        case this.countryListApiId:
          this.handleCountryListResponse(responseJson)
          break;
        default:
          break
      }
    }
    // Customizable Area End
  }

  handleForgetEmailResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ loading: false })
        localStorage.setItem("otp_id", responseJson?.otp_id)

        if (!window.location.href.toLowerCase().includes('otpverification')) {
          this.props.navigation.navigate("OtpVerification")
        }
      } else {
        this.setState({ loading: false, emailerror: true, emailerrorText: responseJson?.errors[0]?.otp })
      }
      if (window.location.href.toLowerCase().includes('otpverification')) {
        if (responseJson.errors) {
          this.setState({ loading: false, otperror: true, otperrorText: responseJson?.errors[0]?.otp })
        }
        this.setState(({
          otp1: '',
          otp2: '',
          otp3: '',
          otp4: ''
        }));

        this.setState({ oneMinTimer: 59 }, () => {
          this.interval = setInterval(
            () => this.setState((prevState) => ({ oneMinTimer: prevState.oneMinTimer - 1 })),
            1000
          );
        });
      }
    }
  }

  handleEmailOtpResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ loading: false })
        localStorage.setItem("otp_id", responseJson?.messages[0]?.otp_id)
        this.props.navigation.navigate("CreatePassword")
      }
      else if(responseJson?.errors[0]?.pin){
        let errorMes = responseJson?.errors[0]?.pin
        this.setState({ loading: false, otperror: true, otperrorText: errorMes })
        toast.error(errorMes)
      }
      else {
        let errorMes = responseJson?.errors[0]?.otp
        this.setState({ loading: false, otperror: true, otperrorText: errorMes })
        toast.error(errorMes)
      }
    }
  }

  handleNewPasswordResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ loading: false })
        toast.success(this.props.t("forgotpassword.NPCS"))
        this.props.navigation.navigate("EmailAccountLoginBlockWeb")
      }
      else {
        this.setState({ loading: false, emailerror: true, emailerrorText: responseJson?.errors[0]?.otp ? responseJson?.errors[0]?.otp : responseJson?.errors })
        toast.error(responseJson?.errors)
      }
    }
  }

  handleCountryListResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ loading: false, countryList: responseJson?.country_codes })
      }
    }
  }

  navToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  };

  navToforget = () => {
    this.props.navigation.navigate("ForgotPasswordWeb")
  };

  getCountryList() {
    this.setState({ loading: true });

    const headers = {
      language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListApiId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isValidate() {
    const { t }: any = this.props
    let isValid: boolean = true;
    const emailReg = /^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/;
    const numberReg = /^\d*(\.\d+)?$/
    const { email, selectedCountryCode } = this.state

    if (email !== "" || email.length !== 0) {
      if (this.state.forgetType == "phone") {
        if (!numberReg.test(email) || email.length < 7 || email.length > 15) {
          this.setState({ emailerror: true, emailerrorText: t("loginScreen.EVP") })
          isValid = false
        } else if (selectedCountryCode == "" || selectedCountryCode.length == 0) {
          this.setState({ emailerror: true, emailerrorText: t("loginScreen.SCC") })
          isValid = false
        } else {
          this.setState({ emailerror: false, emailerrorText: '' })
        }
      } else if (!emailReg.test(email)) {
        this.setState({ emailerror: true, emailerrorText: t("loginScreen.EVE") })
        isValid = false
      } else {
        this.setState({ emailerror: false, emailerrorText: '' })
      }
    } else {
      this.setState({ emailerror: true, emailerrorText: t("loginScreen.F_Valid") })
      isValid = false
    }

    return isValid
  }

  onChangeCreatePassword = (event: any) => {
    this.setState({
      password: event.target.value,
      pswderror: false, pswderrorText: ""
    });
  }

  onChangeConfrimPassword = (event: any) => {
    this.setState({
      confirmPassword: event.target.value,
      cpswderror: false,
      cpswderrorText: "",
    });
  }

  onChangeOtp1 = (event: any) => {
    this.setState({ otp1: event.target.value })
    if (event.target.value.length === 1) {
      const nextfield = document.querySelector(
        `input[name=otp2]`
      );
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }
  }

  onChangeOtp2 = (event: any) => {
    this.setState({ otp2: event.target.value })
    if (event.target.value.length === 1) {
      const nextfield = document.querySelector(
        `input[name=otp3]`
      );
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }
  }

  onChangeOtp3 = (event: any) => {
    this.setState({ otp3: event.target.value })
    if (event.target.value.length === 1) {
      const nextfield = document.querySelector(
        `input[name=otp4]`
      );
      if (nextfield !== null) {
        (nextfield as HTMLElement).focus();
      }
    }
  }
  
  onKeyDown=(event:any, otpState:any , queryName:string) =>{
    let prevfield = document.querySelector(
      queryName
    );
    if (event.key === 'Backspace'){
      if(otpState?.length ==0 && prevfield !==null){
        (prevfield as HTMLElement).focus();
      }
    }
  }

  ForgetPasswordOtp(event: any) {
    event.preventDefault();
    let isValid = this.isValidate()
    if (isValid) {
      this.setState({ loading: true })
      let formData = new FormData();
      localStorage.setItem("otp_type", this.state.forgetType)
      if (this.state.forgetType == 'phone') {
        let number: any = this.state.selectedCountryCode + this.state.email
        formData.append("full_phone_number", number)
        localStorage.setItem("forgetpasswordemail", number)
      } else {
        formData.append("email", this.state.email)
        localStorage.setItem("forgetpasswordemail", this.state.email)
      }

      const headers = {
        language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
        token: localStorage.getItem("Token")
      };

      const getOtpValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.forgetEmailCallId = getOtpValidationsMsg.messageId;

      getOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordOtpEndPoint
      );

      getOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      getOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      
      runEngine.sendMessage(getOtpValidationsMsg.id, getOtpValidationsMsg);
    }
  }

  ResendOtp(event: any) {
    event.preventDefault();
    let forgetType: any = localStorage.getItem("otp_type")
    let email: any = localStorage.getItem("forgetpasswordemail")
    if (forgetType && email) {
      this.setState({ loading: true })
      let formData = new FormData();

      if (forgetType == 'phone') {
        formData.append("full_phone_number", email)
      } else {
        formData.append("email", email)
      }

      const headers = {
        token: localStorage.getItem("Token"),
        language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
      };

      const getResendOtpValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.forgetEmailCallId = getResendOtpValidationsMsg.messageId;

      getResendOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordOtpEndPoint
      );

      getResendOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      getResendOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      
      getResendOtpValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(getResendOtpValidationsMsg.id, getResendOtpValidationsMsg);
    } else {
      this.props.navigation.navigate("ForgotPasswordWeb")
    }
  }

  emailOTPhandleSubmit = (e: React.SyntheticEvent) => {
    const { t }: any = this.props
    e.preventDefault();
    const { otp1, otp2, otp3, otp4, otp_code } = this.state
    if ((otp1 + otp2 + otp3 + otp4).length == 0) {
      this.setState({ otperror: true, otperrorText: t("loginScreen.OR") });
    }
    else if ((otp1 + otp2 + otp3 + otp4).length < 4) {
      this.setState({ otperror: true, otperrorText: t("loginScreen.VO") });
    }
    else {
      this.setState({ otperror: false });
    }
    if (otp1.length === 0 || otp2.length === 0 || otp3.length === 0 || otp4.length === 0) {
      this.setState({ otperror: true, otperrorText: t("loginScreen.OR") })
      if (otp_code.concat(otp1, otp2, otp3, otp4).length < 4 && otp_code.concat(otp1, otp2, otp3, otp4).length > 0) {
        this.setState({ otperror: true, otperrorText: t("loginScreen.VO") });
      }
      return false;
    }
    this.setState({ loading: true })
    let otp_id: any = localStorage.getItem("otp_id")
    let otp_type: any = localStorage.getItem("otp_type")
    let formData = new FormData();
    formData.append("otp_id", otp_id)
    formData.append("otp_code", otp_code.concat(otp1, otp2, otp3, otp4))
    formData.append("otp_type", otp_type)

    const headers = {
      language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
      token: localStorage.getItem("Token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailOtpCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitOtpEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    const passwordReg = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    const { t }: any = this.props
    let isValid: boolean = true

    if (!pswdspaceReg.test(this.state.password)) {
      this.setState({
        pswderror: true,
        pswderrorText: t("loginScreen.NSWS"),
      });
      isValid = false
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({
        pswderror: true,
        pswderrorText: t("loginScreen.PR"),
      });
      isValid = false
    }
    if (!pswdspaceReg.test(this.state.confirmPassword)) {
      this.setState({
        cpswderror: true,
        cpswderrorText:
          t("loginScreen.NSWS"),
      });
      isValid = false
    }

    if (
      this.state.confirmPassword === null ||
      this.state.confirmPassword.length === 0
    ) {
      this.setState({
        cpswderror: true,
        cpswderrorText: t("loginScreen.CR"),
      });
      isValid = false
    }

    if (this.state.password != this.state.confirmPassword) {
      this.setState({
        cpswderror: true,
        cpswderrorText: t("loginScreen.PCPM"),
      });
      isValid = false
    }

    if (!passwordReg.test(this.state.password)) {
      this.setState({
        pswderror: true,
        pswderrorText:
          t("loginScreen.MINP"),
      });
      isValid = false
    }

    if (isValid) {
      this.setState({ loading: true })
      let otp_id: any = localStorage.getItem("otp_id")
      let otp_type: any = localStorage.getItem("otp_type")
      let formData = new FormData();
      formData.append("otp_id", otp_id)
      formData.append("new_password", this.state.password)
      formData.append("otp_type", otp_type)

      const headers = {
        language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
        token: localStorage.getItem("Token")
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.newPasswordCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.newPasswordEndPoint
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  selectCountryCode = (item: any) => {
    this.setState({
      selectedCountryCode: '+' + item,
    })
  }
  // Customizable Area End
}
