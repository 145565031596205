import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utility";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  plansDetails: any;
  coupon: any;
  applyCouponModal: any;
  couponApplied: boolean;
  loading: boolean;
  selectedPlanId: any;
  coupounError: boolean;
  coupounErrorMessage: string;
  seletedCoupounID: any;
  selectedPlanDetail: any;
  appliedCouponSuccessMes: string;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class ViewSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPlansApiCallId: string = "";
  checkoutApiCallId: string = "";
  applyCouponApiCallId: string = "";
  apiPaymentStatusId: string = "";
  basicPlanAPICallId:string = "";
  getBasicStatusApiCallId:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      plansDetails: [],
      selectedPlanId: "",
      coupon: "",
      coupounError: false,
      coupounErrorMessage: "",
      seletedCoupounID: "",
      applyCouponModal: null,
      couponApplied: false,
      loading: false,
      selectedPlanDetail: {},
      appliedCouponSuccessMes: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getSubscriptionPlans();
    sessionStorage.clear()
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let loading = false;

    switch (apiRequestCallId) {
      case this.getPlansApiCallId:
        this.handleGetPlansApiResponse(responseJson);
        break;
      case this.basicPlanAPICallId:
        this.handleBasicPlanResponse(responseJson);
        break;
      case this.getBasicStatusApiCallId:
        this.handleBasicStatusResponse(responseJson);
        break;

    }
  }

  handleGetPlansApiResponse(responseJson: any) {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ plansDetails: responseJson?.packages?.data, loading: false });
      } else {
        this.setState({ loading: false });
        let key = Object.keys(responseJson?.errors[0])[0];
        toast.error(responseJson?.errors[0][key]);
      }
    }
  }

  // Customizable Area Start
  getSubscriptionPlans = () => {
    this.setState({ loading: true })
    this.getPlansApiCallId = sendAPIRequest(
      configJSON.getPlansEndPoint,
      {
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  buttonClickView = (_data: any) => {
    this.setState({
      selectedPlanId: Number(_data.id),
      selectedPlanDetail: _data,
    })
  }
  getBasicPaymentStatus = async (_request_id :string) =>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("Token")
    };
  
    const getBasicStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBasicStatusApiCallId = getBasicStatusMsg.messageId;
  
    getBasicStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentStatusEndPoint}?requests_id=${_request_id}`
    );
  
    getBasicStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBasicStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getBasicStatusMsg.id, getBasicStatusMsg);
  }

  handleBasicStatusResponse = async (_responseJson : any) =>{
    let profileData = await getStorageData("user_data")
  let profileDataParsed = JSON.parse(profileData)
  let selectedProfie = profileDataParsed.account_profile.data.attributes
  setStorageData("selectedUser", selectedProfie.id)
  setStorageData("selected_user_data", JSON.stringify(selectedProfie))
    const basicPlanMes: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    basicPlanMes.addData(
      getName(MessageEnum.NavigationTargetMessage), "DashboardWeb")
    basicPlanMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(basicPlanMes);
  this.setState({loading:false})
  }
  handleBasicPlanResponse = (_responseJson : {
    errors:string,
    transaction : {
      hyperpay_request_id : string | null,
      requests_id: string
    }
  }) =>{
    if (!_responseJson?.errors) {
      let hyperpay_checkout_id = _responseJson?.transaction?.hyperpay_request_id;
      let requests_id = _responseJson?.transaction?.requests_id;
      if (hyperpay_checkout_id === null || hyperpay_checkout_id === "") {
        this.getBasicPaymentStatus(requests_id)
      }
      }else{
        this.setState({loading: false})
      }
  }

  handleAddPlan =async  () =>{
    let {selectedPlanDetail , selectedPlanId} = this.state
    let planPrice = selectedPlanDetail.attributes.price   
    if(planPrice == 0){
      this.setState({loading: true})
      let  httpBody = {
        plan_id: selectedPlanId,
        coupon_code: null,
        applied: null,
        prev_bal: 0,
        hyperpay_amount: planPrice,
      };
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: await getStorageData("Token")
      };
    
      const addBasicPlanAPICall = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.basicPlanAPICallId = addBasicPlanAPICall.messageId;
    
      addBasicPlanAPICall.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getCheckoutIdEndPoint
      );
    
      addBasicPlanAPICall.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      addBasicPlanAPICall.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      addBasicPlanAPICall.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
      runEngine.sendMessage(addBasicPlanAPICall.id, addBasicPlanAPICall);
    }else{
      this.props.navigation.navigate('PlanSubscription', {
        id: this.state.selectedPlanId
      })
    }
  }

  // Customizable Area End
}
