/* eslint-disable no-undef */
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  contentDetails?: any;
  similarMovies?: any;
  isGuest?: boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  selectedTab: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class RowController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      imgIndex: null,
      selectedTab: 'starCast'      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // handers

  previewHandler = (ref: any) => {
    ref.slickPrev();
  };
  nextHandler = (ref: any) => {
    ref.slickNext();
  };
  navHandler = (contentId: any) => {
    localStorage.removeItem("continueWatch");
    const { navigation } = this.props;
    navigation.navigate(`MovieDetails`, { id: contentId });
  };

  onChangeTab = (tabName: string) =>{
    this.setState({selectedTab: tabName})
  }

  // Customizable Area End
}
