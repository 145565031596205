Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostMethod = "POST";
exports.DeleteMethod = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SubscriptionBilling";
exports.labelBodyText = "SubscriptionBilling Body";

exports.btnExampleTitle = "CLICK ME";
exports.favouritesListUrl = "bx_block_favourites/favourites";
exports.audiofavouritesListUrl = "bx_block_favourites/favourites";
exports.watchHistoty = "bx_block_content_management/contents/get_watch_histoty";
exports.deleteHistory = "bx_block_content_management/contents/delete_history";
exports.getAllDevicesUrl = "bx_block_account_device/account_devices";
exports.logoutDeviceUrl = "bx_block_account_device/account_devices/account_devices";
exports.getPerksEndPoint ="bx_block_plan/plans/get_plan";
exports.deleteFirebaseTokenEndPoint = "bx_block_account_device/account_devices/delete_account_device";
// Customizable Area End