import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { withTranslation } from "react-i18next";

export const themeCreatStyle = (theme: any) => ({
    perkContainer : {
        justifyContent: "space-around" , 
        display: "flex" , 
        minHeight: '100vh',
        flexDirection : "column" as const , 
        padding: '10px 30px'
      },
      logo: {
        maxWidth: "120px",
        aspectRation : "1/1",
        marginBottom: 20,
        width: "auto",
        backgroundColor: "transparent"
      },
      perkGrid: {
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "center",
        margin: "40px 0",        
        [theme.breakpoints.down("sm")]: {
          margin : '20px 0'
        },
        [theme.breakpoints.down("md")]: {
            margin : '30px 0'
        }
      },
      perkHeading: {
          fontFamily: "Roboto",
          fontWeight: 700,
        width: "100%",
        textAlign: "center" as const,
        marginBottom: 20,
        lineHeight: '27px',
        fontSize: 20,
        color:"#F6F6F6",
        [theme.breakpoints.down("sm")]: {
          fontSize: '16px',
          marginBottom: 15     
        }
      },
      perkMainContainer: {
          maxWidth: '600px',
          margin: "auto"      
      },
      perksImage: {
        maxWidth: "90px",
        width: "auto",
        marginBottom: 20,
        aspectRation : "1/1",
      },

      navigationButton: {
          alignSelf: "center",
          width: "100%",
        height: "48px",
        margin: "20px 0",
        justifyContent: "center",
        fontWeight: 600,
        fontSize: 18,
        color: theme.palette.primary.main,
        fontFamily: "Roboto",
        [theme.breakpoints.only("xs")]: {
          fontSize: 15
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 16
        }
      },

      perks_details: {
          fontFamily: "Roboto",
          fontWeight: 600,
          color: theme.palette.text.primary,
        alignItems: "center",
        fontStyle: "normal",
        alignContent: "center",
        display: "flex",
        letterSpacing: 0,
        fontSize: 16,
        margin: "5px 0",        
        [theme.breakpoints.only("sm")]: {
            fontSize: 14.5
        },[theme.breakpoints.only("xs")]: {
            fontSize: 14
        }
    },
    tickIcon: {
        marginRight: theme.direction == "rtl" ? 0 : 15,
        width: 15,
        height: 15,
        marginLeft: theme.direction == "rtl" ? 15 : 0,
    },
      perkOuterContainer:{
        [theme.breakpoints.only("xs")]: {
          padding: "25px 20px",
        },
        background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
        padding: "40px 45px",
        borderRadius: "8px"    
      },
      perkDetailsOuter: {
        height: "100%",
        borderRadius: 8,
        padding: 1,
        margin: '10px 0',
        background:
            "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    },
    perkInnerContainer: {
        height: "100%",
        background: "#222222",
        width: "100%",
        borderRadius:8,
        [theme.breakpoints.only("xs")]: {
            padding: "20px"                
        },
        [theme.breakpoints.only("sm")]: {
            padding: "23px"
        },
        padding: "27px"
    },
    willDoIt: {
        fontFamily: "Roboto",
        fontWeight: 600,
        color: "#A1A1A1",
        cursor:"pointer",
        fontSize: 16,
        [theme.breakpoints.only("xs")]: {
          fontSize: 14
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 15
        }
    }
});

import DowngradePerksController, { Props } from "./DowngradePerksController.web";
import { defaultFavImage, perksImage, tickIcon } from "./assets";

export class DowngradePerks extends DowngradePerksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    // Customizable Area Start
    return (
      <Grid container>
         {this.state.isLoading && <Spinner spinnerModal={this.state.isLoading} />}
       <Grid item xs={12}  className={classes.perkContainer} >
          <Grid container className={classes.perkGrid}>
              <Grid item xs={12} className={classes.perkMainContainer}>
                <Grid container justifyContent="center">
            <img className={classes.logo} src={defaultFavImage} />
            </Grid>
            <Grid container className={classes.perkOuterContainer}>
            <Grid container justifyContent="center">
            <img className={classes.perksImage} src={perksImage} />
            </Grid>
            <Typography className={classes.perkHeading}>
            {t("perksDowngrade.planHeading")}
          </Typography>
           {t('perksDowngrade.DowngradationPerksDetails', { returnObjects: true }).length !==0 && <Grid xs={12} className={classes.perkDetailsOuter}>
                            <Grid container className={classes.perkInnerContainer}>
                                {
                                    t('perksDowngrade.DowngradationPerksDetails', { returnObjects: true }).map((_data:string)=>{
                                    return(
                                      <Typography className={classes.perks_details}>   <img
                                          className={classes.tickIcon}
                                          src={tickIcon}
                                          alt="user"
                                      />  {_data}</Typography>
                                
                                    )
                                    })
                                }
                                </Grid>
            </Grid>}

            <Grid item xs={12}>
                    <Button
                        variant="contained"
                        className={classes.navigationButton}
                        data-test-id="perkButton"
                        onClick={()=>this.navigatetoScreen()}
                        type="submit"
                        fullWidth

                      >
                        {t("perksDowngrade.plaButton")}
                      </Button>                    
                  </Grid>
                           
              </Grid>              
            </Grid>            
          </Grid>
         
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(
  withTranslation()(DowngradePerks)
);
// Customizable Area End
