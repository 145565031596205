import React from "react";
import { Box, withStyles } from "@material-ui/core";

import { backgroundNew } from "./assets";

interface CommanLayoutProps {
  children: React.ReactNode;
  navigation: any;
  token?: string;
  classes: any;
}

export const CommanLayout = ({ children, classes }: CommanLayoutProps) => {
  return <Box className={classes.box_css}>{children}</Box>;
};

export const ThemeCreateStyle = (theme: any) => {
  return {
    box_css: {
      backgroundImage: `url(${backgroundNew})`,
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      minHeight: "100vh"
    }
  };
};

export default withStyles(ThemeCreateStyle)(CommanLayout);
