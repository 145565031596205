Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.getPerferenceEndPoint = "bx_block_categories/genre";
exports.postPereferenceEndPoint="bx_block_profile/profiles/set_preferences"
exports.getUserProfileEndPoint = "bx_block_profile/profiles/user_profiles";
exports.getRefershTokenEndPoint = "account_block/accounts/refresh_token";
exports.logoutEndPoint="bx_block_account_device/account_devices/delete_account_device"
exports.postUserDeviceEndPoint="bx_block_account_device/account_devices/add_profile_name"
exports.getUserPasswordEndPoint = "account_block/accounts/password_check";
exports.deleteUserProfileEndPoint = "bx_block_profile/profiles";
exports.placeHolderEmail = "Email";
exports.methodTypeApiDelete = "DELETE";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.logoutDeviceUrl = "bx_block_account_device/account_devices/account_devices";
exports.errorEmailNotValid = "Email not valid.";
exports.addUserEndPoint = "bx_block_profile/profiles";
exports.deleteFirebaseTokenEndPoint = "bx_block_devices/devices/delete_device";
exports.passwordRegx=/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
exports.getUserProfile = "bx_block_profile/profiles/user_profiles";
exports.getUserProfileDetails = "bx_block_profile/profiles/";
exports.deleteAccountApiEndPoint = "account_block/accounts";
exports.getPlansApiEndPoint ="bx_block_plan/plans/get_plan";
exports.deleteFirebaseTokenEndPoint = "bx_block_account_device/account_devices/delete_account_device";
exports.logoutEndPoint="bx_block_account_device/account_devices/delete_account_device";
 exports.getUserPasswordEndPoint = "account_block/accounts/password_check";
// Customizable Area End

