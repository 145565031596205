import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData, getStorageData, removeStorageData} from "framework/src/Utilities";
// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import DeviceDetector from "device-detector-js";
import moment from "moment";
const deviceDetector = new DeviceDetector();
const userAgent = navigator.userAgent;
const device = deviceDetector.parse(userAgent);
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?: any;
  i18n?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: any;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  formData: {
    email: any;
    password: string;
  };
  isInvalidPassword: boolean;
  isInvalidRepeat: boolean;
  showPassword: boolean;
  isTrue: boolean;
  countryArr: any;
  openModal: boolean;
  anchorE1: any;
  selectionCode: string;
  selectedCountry: any;
  isInvalidEmail: any;
  isInvalidPass: any;
  loading: boolean;
  emailErrMsg: string;
  emailErr: boolean;
  passErrMsg: string;
  passErr: boolean;
  isGuest: boolean;
  guestUserID: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiEmailLoginCallId1: string = "";
  validationApiCallId: string = "";
  apiLoginAsGuest: string = "";
  reg: RegExp;
  passwordReg: RegExp;
  regMobile: RegExp;
  pswdspaceReg: RegExp;
  regPassword: RegExp;
  labelTitle: string = "";
  countryDataCallId: any = "";
  emailLoginApiCallId: any = "";
  deviceTokenAPICallId: any = "";
  getGuestPrefrenceAPI : string = "";
  setGuestPrefrenceAPI : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      formData: {
        email: "",
        password: "",
      },
      isTrue: false,
      countryArr: [],
      openModal: false,
      anchorE1: null,
      selectionCode: "+966",
      selectedCountry: "SA",
      isInvalidEmail: null,
      isInvalidPass: null,
      isInvalidPassword: false,
      isInvalidRepeat: false,
      loading: false,
      emailErrMsg: "",
      emailErr: false,
      passErrMsg: "",
      passErr: false,
      isGuest: false,
      guestUserID: ''
    };

    this.reg = new RegExp(/^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i);
    this.regMobile = new RegExp(/^\d*$/);
    this.passwordReg = new RegExp(
      /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
    );
    this.regPassword = new RegExp(
      /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
    );
    this.pswdspaceReg = new RegExp(/^\S$|^\S[\s\S]*\S$/);

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCountrylist();
    this.createDeviceUniqueId();
    let isGuestUser = await getStorageData("isGuestUser");
    localStorage.getItem("Token") &&
      isGuestUser !== "true" &&
      this.goToUserProfile("UserProfile");
    // Customizable Area End
  }

  // Customizable Area Start
  goToUserProfile = (path: string) => {
    this.props.navigation.navigate(path);
  };
  navigateToForgotPassword = () => {
    this.props.navigation.navigate("ForgotPasswordWeb");
  };
  navigateToRegistration = () => {
    this.props.navigation.navigate("EmailAccountRegistrationWeb");
  };
  goToViewSubscription = () => {
    this.props.navigation.navigate("ViewSubscription");
  };

  selectCountryCode = (item: any, cName: any) => {
    this.setState(
      {
        selectionCode: "+" + item,
        openModal: false,
      },
      () => {
        this.setState({ anchorE1: null });
      }
    );
  };

  navToLanding = () => {
    this.props.navigation.navigate("LandingPage");
  };

  handleClick = (e: any) => {
    this.setState({ anchorE1: e.currentTarget });
  };

  handleAnchorClose = () => {
    this.setState({ anchorE1: null });
  };

  handleChangeForm = (e: any) => {
    if ([e.target.name][0] == "email") {
      this.setState({ emailErr: false, emailErrMsg: "" });     
    }

    if ([e.target.name][0] == "password") {
      this.setState({ passErr: false, passErrMsg: "" });
    }
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };

  handleOpen = () => this.setState({ openModal: true });
  handleClose = () => this.setState({ openModal: false });
  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

    checkForEmailValidation = () =>{
      let { t } = this.props;
      if (!this.state.formData.email) {
        this.setState({ isInvalidEmail: true });
        this.setState({
          isInvalidPass: true,
          emailErr: true,
          emailErrMsg: t("loginScreen.PECEAOM"),
        });
        return false;
      } else if (
        this.state.formData.email.includes("@") &&
        !this.reg.test(this.state.formData.email)
      ) {
        this.setState({ emailErr: true, emailErrMsg: t("loginScreen.PECEA") });
        return false;
      } else if (
        !this.state.formData.email.includes("@") &&
        (!this.regMobile.test(this.state.formData.email) ||
          this.state.formData.email.length < 7 ||
          this.state.formData.email.length > 15)
      ) {
        this.setState({ emailErr: true, emailErrMsg: t("loginScreen.PECEAOM") });
        return false;
      }
      return true
    }

    checkForPasswordValidation = () =>{
      let { t } = this.props;
      let passwordErrorMsg = t("loginScreen.MINP");
      if (!this.state.formData.password) {        
        this.setState({
          isInvalidPassword: true,
          isInvalidRepeat: false,
          passErr: true,
          passErrMsg: t("loginScreen.PEP"),
        });
        return false;
      }
      else if (!this.regPassword.test(this.state.formData.password)) {        
        this.setState({
          isInvalidPassword: true,
          isInvalidRepeat: false,
          passErr: true,
          passErrMsg: passwordErrorMsg,
        });
        return false;
      }
      return true
    }

  handleFormSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();   
    let isValid = true;
    isValid = this.checkForEmailValidation()
    isValid = this.checkForPasswordValidation()
    if (isValid) {
      let type = "";
      if (this.state.formData.email.includes("@")) {
        type = "email";
      } else {
        type = "phone";
      }
      this.loginApi(e, type);
    }
  };
  loginApi = (e: any, type: any) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (type == "email") {
      const header = {
        "Content-Type": configJSON.loginApiContentType,
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
      };
      const attrs = {
        email: this.state.formData.email,
        password: this.state.formData.password,
        device_name: device?.os?.name,
        device_unique_id: localStorage.getItem("deviceId"),
      };
      const data = {
        attributes: attrs,
      };
      const httpBody = {
        data: data,
      };
      this.setState({ loading: true });
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiEmailLoginCallId1 = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_login/logins"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      const header = {
        "Content-Type": "application/json",
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
      };
      const attrs = {
        full_phone_number: this.state.selectionCode + this.state.formData.email,
        password: this.state.formData.password,
        device_name: device?.os?.name,
        device_unique_id: localStorage.getItem("deviceId"),
      };
      const data = {
        attributes: attrs,
      };
      const httpBody = {
        data: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiEmailLoginCallId1 = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_login/logins"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getCountrylist = () => {
    this.setState({ loading: true });
    const getCountryCodeData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryDataCallId = getCountryCodeData.messageId;

    getCountryCodeData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_country_codes"
    );
    getCountryCodeData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getCountryCodeData.id, getCountryCodeData);
  };

  createDeviceUniqueId() {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      localStorage.setItem("deviceId", deviceId);
    } else {
      localStorage.setItem("deviceId", uuidv4());
    }
  }

  // Send device token
  sendDeviceToken = () => {
    let headers = {
      token: localStorage.getItem("Token"),
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        attributes: {
          token: localStorage.getItem("firebasetoken"),
        },
      },
    };

    const deviceTokenApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deviceTokenAPICallId = deviceTokenApiMsg.messageId;

    deviceTokenApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deviceTokenEndPoints
    );

    deviceTokenApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deviceTokenApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    deviceTokenApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(deviceTokenApiMsg.id, deviceTokenApiMsg);
  };
  getGuestPreference = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await getStorageData("Token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGuestPrefrenceAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPerferencePoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyGuestPreference = async (prefernceId : [] ) => {
    this.setState({ loading: true })
    const data = {
      profile_id: this.state.guestUserID,
      preferences: prefernceId
    }
    const httpBody = data;
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await getStorageData("Token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setGuestPrefrenceAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPereferencePoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.goToUserProfile("DashboardWeb");
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.countryDataCallId === apiRequestCallId) {
        this.handleCountryData(responseJson);
      } else if (apiRequestCallId === this.apiEmailLoginCallId1) {
        this.handleEmailLogin(responseJson);
      }
      if(this.apiLoginAsGuest === apiRequestCallId){
        this.handleGuestLogin(responseJson)
      }
      if(this.getGuestPrefrenceAPI === apiRequestCallId){
        // let prefernceId = responseJson?.data?.map((_data: any) => parseInt(_data.id))
        // this.applyGuestPreference(prefernceId)
      }
    }
    // Customizable Area End
  }

  handleCountryData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        countryArr: responseJson?.country_codes,
        loading: false,
      });
    } else {
      this.setState({
        countryArr: [],
        loading: false,
      });
    }
  };

  handleGuestLogin = (responseJson : any)=>{
    if (!responseJson.errors) {
      this.handleEmailLogin(responseJson)
    }
    else{
      this.createGuestUser()
    }
  }


  handleEmailLogin = (responseJson: any) => {
    this.setState({ loading: false });
    if (!responseJson.errors) {
      if (responseJson?.meta?.token) {
        localStorage.setItem("Token", responseJson?.meta?.token);
        localStorage.setItem(
          "refresh_token",
          responseJson?.meta?.refresh_token
        );
        setStorageData("device_unique_id",responseJson.account_device?.id)
        localStorage.setItem("User_Id", responseJson?.account?.data?.id);
        let exp_time: any = moment()
          .add(23, "hours")
          .format("DD/MM/YYYY hh:mm a");
        localStorage.setItem("exp_time", exp_time);
        localStorage.setItem(
          "user_data",
          JSON.stringify(responseJson?.account?.data?.attributes)
        );
        this.handleData(responseJson);
      }
    } else {
      this.setState({ loading: false });
      let key = Object.keys(responseJson?.errors[0])[0];
      toast.error(responseJson?.errors[0][key]);
    }
  };

  createGuestUser = () => {
    this.setState({ loading: true });
    let deviceToken = localStorage.getItem("deviceId")
    const header = {
      "Content-Type": "application/json",
      language:  this.props.i18n.language,
    };
    const attrs = {
      first_name: configJSON.guestType,
      last_name: "",
      is_guest: true,
      email: deviceToken + configJSON.almanshaEmail,
      password: deviceToken + configJSON.dummyPassword,
      password_confirmation: deviceToken + configJSON.dummyPassword,
      guest_email: deviceToken + configJSON.almanshaEmail
    };

    const data = {
      type: "email_account",
      attributes: attrs,
      device_name: device?.os?.name,
      device_unique_id: deviceToken,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountInfoAPIEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  loginAsGuest = ()=>{
    this.setState({ loading: true, isGuest: true });
    let deviceToken = localStorage.getItem("deviceId")
    const header = {
        "Content-Type": configJSON.loginApiContentType,
        language: this.props.i18n.language,
      };
      const httpBody = {
        data: {
          attributes: {
            email: deviceToken + configJSON.almanshaEmail,
            password: deviceToken + configJSON.dummyPassword,
            device_name: device?.os?.name,
            device_unique_id: deviceToken,
          }
        },
      };
     const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiLoginAsGuest = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userLoginAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod 
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleData = (responseJson: any) => {
    let isGuest =
      responseJson.account.data.attributes?.is_guest || this.state.isGuest;
    !isGuest && removeStorageData("isGuestUser")
    if (
      localStorage
        .getItem("urlData")
        ?.toLowerCase()
        .includes("?share") || isGuest
    ) {
       let arr = localStorage.getItem("urlData") ?? "";
       let selectedUserID = responseJson.account.data.attributes?.account_profile?.data?.id
        localStorage.setItem(
          "selectedUser",
          selectedUserID
        );
        localStorage.setItem(
          "selected_user_data",
          JSON.stringify(responseJson?.account?.data?.attributes?.account_profile?.data
            ?.attributes)
        );              
        if(isGuest){
          this.setState({guestUserID: selectedUserID})
          // this.getGuestPreference()
          this.goToUserProfile("DashboardWeb");
          setStorageData("isGuestUser", "true")          
          return
        }
      localStorage.removeItem("urlData");
      window.location.href = arr
    } else if (responseJson?.account?.data?.attributes?.active_subscription) {
      this.goToUserProfile("UserProfile");
    } else {
      this.goToViewSubscription();
    }
  };
}
