"use strict";

// Customizable Area Start
const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// const baseURL = "https://localvodsaudi-118668-ruby.b118668.uat.uaenorth.az.svc.builder.ai";
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
