import React ,{Fragment, lazy , Suspense} from "react";
// Customizable Area Start
import { Box, Container } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {
  withStyles,
} from "@material-ui/core/styles";
//@ts-ignore
import "./Dashboard.css"
// Customizable Area End
import DashboardController from "./DashboardController.web";

import { LazyMoviesSlider, LazySwiper } from "./LazyImporter.web";
const MovieSwiper = lazy(LazySwiper)
const MoviesSlider = lazy(LazyMoviesSlider)

import { MovieSwiperSkelton } from "./Swiperskelton.web";
import 'react-loading-skeleton/dist/skeleton.css'
export class DashboardWeb extends DashboardController {
  constructor(props: any) {
    super(props)
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  removeChildBullets = () => {
    let bulletd: any = document.getElementsByClassName('swiper-pagination')[0];
    bulletd.childNodes[0].style.display = 'none';
  }
  navigate =(id: any) => { 
    sessionStorage.setItem("moviesData", id)
    this.props.navigation.navigate('Dashboard');
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;

    return (
      <Fragment>
        <Container maxWidth={false} className={`${classes.dashboardContainer} dashBoard_slider`} disableGutters>
          {this.state.moviesLoading ? <MovieSwiperSkelton /> : this.state.moviesData.length !== 0 && <Suspense fallback><MovieSwiper data-test-id="movieswiper" moviesData={this.state.moviesData} navHandler={this.navHandler} imgIndex={this.state?.imgIndex} /></Suspense>}

        </Container>
        <Suspense fallback>
        {this.state.continueWatchData?.length > 0 && <Box data-test-id="movie-slider" className={classes.SliderWrapper}>
          <MoviesSlider
            navHandler={this.navHandler}
            slidesToShow={10}
            slidesToScroll={5}
            sliderTitle={t("movieDetails.continueWatching")}
            isContinueWatching={true}
            similarMovies={this.state.continueWatchData}
          />
        </Box>}
        </Suspense>
        {this.state.bandData?.map((item) => {
          return (
            <Box key={item?.id} className={classes.SliderWrapper}>
              {item?.bend_contents?.data?.length > 0 && (
                <Suspense fallback>
                <MoviesSlider
                  navHandler={this.navHandler}
                  slidesToShow={10}
                  slidesToScroll={5}
                  sliderTitle={item?.name}
                  similarMovies={item?.bend_contents?.data}
                />
                </Suspense>)
                }
            </Box>
          )
        })
        }
      </Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const themeCreatStyle:any = (theme: any) => ({
  sliderContainer: {
    background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
    position: 'relative',
    top: 0,
  },
  SliderWrapper: {
    margin: theme.direction === "rtl" ? '0 5% 0 0' : '0 0 0 5%'
  },
  Slider: {
    margin: 'auto',
    width: '100%',
    height: '75vh',
  },
  movieNames: {
    color: '#fff',
    position: 'absolute',
    top: 170,
    textAlign: 'center',
    fontSize: 20,
  },
  dashboardContainer: {
    margin: '0px',
    padding: '0px',
    overflowX: 'hidden',
    boxSizing: 'border-box',
  },
  arabicButtons: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    margin: '10px 5vw',
    background: 'transparent',
    boxShadow: 'none'
  },
  leftHeader: {
    display: 'flex',
  },
  rightHeader: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  play: {
    width: '70px',
    height: '70px',
    position: 'relative',
    top: -120,
    right: -80,
    [theme.breakpoints.only("xs")]: {
      width: '50px',
      height: '50px',
      top: -105,
      right: -90,
    },
  },
  backgroundImage_div3: {
    maxWidth: "100%",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
    [theme.breakpoints.only("xs")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "32px 10px",
    },
  },

  typo: {
    color: '#fff',
    [theme.breakpoints.only("xs")]: {
      fontSize: '17px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '27px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },

  arrowLeft: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrowRight: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrows: {
    [theme.breakpoints.only("xs")]: {
      fontSize: '15px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },
  arrowsGrid: {
    width: '5%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    right: 50,
    [theme.breakpoints.only("xs")]: {
      position: 'relative',
      right: 0,
      width: '15%',
    },
    [theme.breakpoints.only("sm")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("md")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("lg")]: {

    },
    [theme.breakpoints.up("lg")]: {

    },
  },

})
export default withStyles(themeCreatStyle)(withTranslation()(DashboardWeb))
// Customizable Area End
