import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import CONTENT_TYPES from "../../../components/src/Enums";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    index: any
    // Customizable Area Start
    episode: any,
    onPlayVideo?: any
    updateMovieDetailsState?:any,
    t?:any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    imgIndex: any;
    loading: boolean;
    percentageValue:number;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class EpisodeComponentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start

        this.state = {
            errorMsg: "",
            token: "",
            loading: false,
            imgIndex: null,
            percentageValue:0,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start      
        this.calculatePercentageValue();
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Props) {
        // Update percentage value if episode prop changes
        if (prevProps.episode !== this.props.episode) {
            this.calculatePercentageValue();
        }
    }

    // Customizable Area Start

    onClickCard = (contentDetails: any, episode: any, index: any) => {
        const { navigation, onPlayVideo,updateMovieDetailsState } = this.props;
        const { content_type } = contentDetails;
        updateMovieDetailsState({updatePodcastParts:true});
        if(content_type == CONTENT_TYPES.WEBSERIES || content_type == CONTENT_TYPES.TVSHOWS){
            (document as any).pictureInPictureElement && (document as any).exitPictureInPicture();
        }
        const data = { contentType: content_type,title:episode?.title , assetId: episode?.asset_id, episode_watch_history: this.checkWatchHistory() ? episode.watch_history : null , isGuestSeries : episode?.is_guest_content}
        if(contentDetails.content_type == CONTENT_TYPES.MOVIES){
            navigation.navigate('MovieDetails', { id: episode?.id })
        }else if(content_type == CONTENT_TYPES.AUDIOPODCAST){
            navigation.navigate('PodcastDetails', { id: episode?.id })
        }else {
            onPlayVideo(data)
        }
    }

    watchingTime = (duration:any, watching_duration:any) => {
        const time = (watching_duration / duration) * 100
        return time;
      }

    removeZeroFromFront = (time: any) => {
        let spit_time = time.split("");
        if (spit_time[0] === "0") {
            return spit_time[1];
        }
        else {
            return spit_time.join("");
        }
    }

    checkWatchHistory = () =>{
        let {episode} = this.props
        let isHistory = episode?.watch_history && episode?.watch_history?.watching_dusration != 0 
        return isHistory

    }

    handleTimeFormat = (data: any) => {
        const {t} = this.props
        if (data?.includes(":")) {
            let split_time = data.split(":");
            let hours = split_time[0] !== "00" ? (this.removeZeroFromFront(split_time[0])) : "00";
            let miniutes = split_time[1] !== "00" ? (this.removeZeroFromFront(split_time[1])) : "00";
            if (hours === "00" && miniutes === "00") {
                return ""
            } else if (hours !== "00" && miniutes === "00") {
                return hours + t("dashboard.hours")
            } else if (hours === "00" && miniutes !== "00") {
                return miniutes + t("dashboard.min")
            } else {
                return hours + t("dashboard.hours") + " " + miniutes + t("dashboard.minutes")
            }
        } else {
            return data;
        }
    }

    calculatePercentageValue = () => {
        const { episode } = this.props;
        if (episode?.watch_history) {
            const { duration, watching_duration } = episode.watch_history;
            const percentageValue = Math.round(this.watchingTime(parseInt(duration), parseInt(watching_duration)));
            this.setState({ percentageValue });
        } else {
            this.setState({ percentageValue: 0 });
        }
    }
    
    // Customizable Area End

}
