import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    data: any;
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    currentLang: any;
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class TransactionInvoiceController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start 
            currentLang: localStorage.getItem("i18nextLng")
            // Customizable Area End
        };
    }

    async componentDidMount() {
        super.componentDidMount()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    dateFormat = (_date: string) => {
        return moment(_date, "YYYY-MM-DD").format("DD MMM, YYYY")
    }

    invoiceDateFormat = (_date: string) => {
        return moment(_date, "YYYY-MM-DD").format("DD/MM/YYYY")
    }

    getBillMonth = (_date: any) => {
        return moment(_date, "YYYY-MM-DD").format("MMMM YYYY")
    }

    checkForLang = (inEnglish: string , inArabic: string) =>{
        return this.state.currentLang === 'en' ? inEnglish : inArabic
    }
    // Customizable Area End
}
