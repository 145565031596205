import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/Utility";
import React from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  contentDetails?: any;
  isPodcast?: any;
  isSubscribed?: boolean;
  updateMovieDetailsState?: any;
  isShareDialog?: boolean;
  handleCloseShare?: any;
  isComment? : boolean;
  isLikeModal ? : boolean;
  isGuest?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean;
  drawerShareOpen: boolean;
  drawerCommentOpen: boolean;
  isLike: boolean,
  comments: any,
  commentText: any,
  shareShow: boolean,
  movieUrl: string,
  tootipOpen: boolean,
  disableButton: boolean,
  favId: string,
  isCommentCalled: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class LikeCommentShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiLikeUnlikeMovieCallId: any;
  apiGetCommentsCallId: any;
  apiCreateCommentCallId: any;
  commentEnd: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.commentEnd = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      drawerShareOpen: false,
      drawerCommentOpen: false,
      isLike: false,
      comments: [],
      commentText: '',
      shareShow: false,
      movieUrl: "",
      tootipOpen: false,
      disableButton: false,
      favId: '',
      isCommentCalled: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener('willFocus', () => {
    //     this.getToken();
    //   });
    // }
    // Customizable Area Start
    this.onInitialized();
    this.handleMovieUrl();
    this.setState({
      isLike: this.props?.contentDetails?.like,
    })
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any) {

    if (prevProps?.contentDetails?.id !== this.props?.contentDetails?.id) {
      this.onInitialized();
      this.handleMovieUrl();
      this.setState({
        isLike: this.props?.contentDetails?.like,
      })
    }
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson?.errors) {
        if (responseJson?.data && responseJson?.data?.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({ errorMsg: "", loading: false });
          switch (apiRequestCallId) {
            case this.apiGetCommentsCallId:
              this.handleGetCommentsResponce(responseJson);
              break;
            case this.apiCreateCommentCallId:
              this.handleCreateCommentResponce(responseJson);
              break;
            case this.apiLikeUnlikeMovieCallId:
              this.handleLikeUnLikeResponce(responseJson);
              break;
            default:
              break;
          }
        }
      } else {
        this.handleErrorResponse(apiRequestCallId, errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // handers

  onInitialized = () => {
    this.props.isComment && this.getCommentsApi();
  }

  likeUnlikeHandler = () => {
    const { isSubscribed } = this.props;
    if (!isSubscribed) {
      this.goToPerks()
      return;
    }
    if (!this.state.disableButton) {
      this.setState({
        disableButton: true
      })
      const { contentDetails } = this.props;
      let body = {
        favourite: {
          favouriteable_id: contentDetails?.id,
          favouriteable_type: "BxBlockContentManagement::Content",
          profile_id: JSON.parse(localStorage.getItem("selectedUser") || '')
        }
      }
      const reqBody = this.state.isLike ? {} : body;
      
      let favEndPoint = this.state.favId !== '' ? this.state.favId : contentDetails?.favourite_id
      const endPoint = this.state.isLike ? `${configJSON.createFavourites}/${favEndPoint}` : configJSON.createFavourites;

      const method = this.state.isLike ? "DELETE" : "POST";

      this.apiLikeUnlikeMovieCallId =
        sendAPIRequest(
          endPoint,
          {
            method,
            body: { ...reqBody },
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem('Token'),
              language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
            }
          },
        );
    }
  }

  createCommentsApi = () => {
    if (!this.props.isSubscribed) {
      this.goToPerks()
      return;
    }
    if(this.state.isCommentCalled || this.state.commentText === ""){
      return false
    }
    this.setState({ isCommentCalled: true});
    const { contentDetails } = this.props;
    let reqBody = {
      comment: {
        comment: this.state.commentText,
        // commentable_id: contentDetails?.id,
        content_id: contentDetails?.id,
        // commentable_type: this.props.isPodcast ? "BxBlockContentManagement::Content" : "BxBlockContentManagement::Content",
        profile_id: JSON.parse(localStorage.getItem("Profile_Id") || '')
      }
    }

    this.apiCreateCommentCallId = sendAPIRequest(`${configJSON.createComments}`,
      {
        method: "POST", body: { ...reqBody }, headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      },
    );
  }

  getCommentsApi = () => {
    const { contentDetails } = this.props;
    const url = this.props.isPodcast ? `${configJSON.getPodcastComments}?content_id=${contentDetails?.id}` : `${configJSON.getComments}?content_id=${contentDetails?.id}`
    this.apiGetCommentsCallId = sendAPIRequest(url,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      },
    );
  }

  goToPerks = () => {
    const navigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     navigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
            "Perks")
      navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigationMes);
}

getData(guestMessage: string , upgradeMessage: string){
  return this.props.isGuest ? guestMessage : upgradeMessage;
}

  handleGetCommentsResponce = (json: any) => {
   let comments = json?.data?.sort((a: {
      attributes: {
        id: number;
        content_id: number;
        comment: string;
        created_at: string;
        profile_name: string;
      }
    }, b: {
      attributes: {
        id: number;
        content_id: number;
        comment: string;
        created_at: string;
        profile_name: string
      }
    }) => new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime());
    this.setState({ comments });
  }
  handleLikeUnLikeResponce = (json: any) => {
    if (!json?.errors) {
      this.setState((prevState) => ({ isLike: prevState.isLike ? false : true, favId: json?.data?.id, disableButton: false }));
      const { updateMovieDetailsState } = this.props;
      updateMovieDetailsState({ like: this.state.isLike });
    } else {
      this.setState({ disableButton: false })
    }
  }

  handleCreateCommentResponce = (json: any) => {
    if (json && json.data) {
      this.setState({ commentText: '', isCommentCalled: false , comments : [json.data, ...this.state.comments] });
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
  }
  scrollToBottom = () => {
    this.scrollToMyRef(this.commentEnd);
  }
  scrollToMyRef = (ref: any) => ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
  handleCopyEvent = () => {
    navigator.clipboard.writeText(this.state.movieUrl);
    this.setState({
      tootipOpen: true
    })
    setTimeout(this.handleTooltipClose, 2000)
  }
  handleTooltipClose = () => {
    this.setState({
      tootipOpen: false
    })
  };
  handleMovieUrl = () => {
    const tempUrl = window.location.href.toLowerCase().includes("://localhost") ? "https://localvodsaudi-118668-react-native.b118668.dev.eastus.az.svc.builder.cafe" + window.location.pathname : window.location.href;
    this.setState({
      movieUrl: `${tempUrl}?share`
    })
  }
  handleErrorResponse = (apiRequestCallId:any, errorReponse:any) => {
    if (apiRequestCallId == this.apiGetCommentsCallId) {
      this.setState({
        comments: []
      })
    }
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false
      });
    }
  }
  // Customizable Area End

}
