// Customizable Area Start
import { v4 as uuidv4 } from "uuid";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import BulkUploadingController from "../../BulkUploading/src/BulkUploadingController";
// Customizable Area End
export default class BulkUploadMediaAdapter {
  send: (message: Message) => void;

  constructor() {
    // Customizable Area Start
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.BulkUploadMessage),
    ]);
    const uploadMediaController = new BulkUploadingController({
      navigation: null,
      id: "",
    });
    // Customizable Area End
  }

  // Customizable Area Start
  convert = (from: Message): Message => {
    const to = new Message(getName(MessageEnum.BulkUploaBlockMessage));

    to.addData(
      getName(MessageEnum.BulkUploadMessageData),
      from.getData(getName(MessageEnum.BulkUploadMessageData))
    );

    return to;
  };

  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
  // Customizable Area End
}