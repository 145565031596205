import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import moment from "moment";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  plan_id: string;
  t: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  token: string;
  success: boolean;
  error: boolean;
  successMessage: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ConfirmationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiPaymentStatusId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      loading: false,
      token: "",
      success: false,
      error: false,
      successMessage: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiPaymentStatusId) {
        if (responseJson && !responseJson.errors) {
          this.setState({ loading: false });
          switch (responseJson?.transaction?.status) {
            case "success":
              return this.handleSuccessResponse(responseJson);
            case "failed": {
              return this.setState({ error: true });
            }              
            default:
              return this.setState({ success: false, error: false });
          }
        } else {
          this.setState({ loading: false });
          let key = Object.keys(responseJson?.errors[0])[0];
          toast.error(responseJson?.errors[0][key]);
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.handleNavigateToMobile()
    this.getPaymentStatus();
  }

  // Customizable Area Start
  
  handleSuccessResponse = (responseJson:any) =>{
    let successmessage = sessionStorage.getItem("update_plan_page") === "true" ? this.props.t("view_subscription.successCPMI") : this.props.t("view_subscription.successAddedCPMI")
    let message = responseJson?.future_date === null ? successmessage : this.props.t("view_subscription.successCPM") +
    moment(responseJson?.future_date).format("DD-MMM-YYYY");
    this.setState({successMessage:message},()=>{
      this.setState({success:true})
    })
  }

  getPaymentStatus = () => {
    this.setState({ loading: true });
    const temp_payment_id = sessionStorage.getItem("payment_requests_id") || "";
    const payment_request_id = decodeURIComponent(window.atob(temp_payment_id));
    let mobileToken = sessionStorage.getItem("mobileToken")
    const header = {
      token: mobileToken ? mobileToken : localStorage.getItem("Token"),
    };

    const requestPaymentStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPaymentStatusId = requestPaymentStatusMessage.messageId;

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkoutid}?requests_id=${payment_request_id}`
    );

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestPaymentStatusMessage.id, requestPaymentStatusMessage);
    // Customizable Area End
    return true;
  };

  handleNavigateToMobile = () =>{
    let mobileToken = sessionStorage.getItem("mobileToken")
    if(mobileToken){ 
      try{  
      sessionStorage.removeItem("mobileToken")
      window.location.href = `almanassah://thankYou`;        
      }
      catch{}
      finally{
        this.props.navigation.navigate("LandingPage")
      }
      return false
    }
  }

  handleSuccessNavigate = () => {
    let upgradeFromUser =  sessionStorage.getItem("upgradeFromUser")
    const isUpdatePage = sessionStorage.getItem("update_plan_page");
    const navigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     navigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
        (upgradeFromUser === "false" || isUpdatePage ==="true") ? "GeneralSetting" : "UserProfile")
      navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const propsMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  propsMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: "switch_profile" });
  navigationMes.addData(getName(MessageEnum.NavigationRaiseMessage), propsMessage);
      this.send(navigationMes);
    sessionStorage.clear();
  };

  handleFailedNavigate = () => {
    const isUpdatePage = sessionStorage.getItem("update_plan_page");
    const podcastNavigationMes: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
   podcastNavigationMes.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isUpdatePage === "true" ? "GeneralSetting" : "ViewSubscription")
    podcastNavigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const propsMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    propsMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: "plan_details" });
    podcastNavigationMes.addData(getName(MessageEnum.NavigationRaiseMessage), propsMessage);
    this.send(podcastNavigationMes);
    sessionStorage.clear();
  };
  // Customizable Area End
}
