// App.js - WEB
import React, { Component } from 'react';

import { View } from 'react-native';
import { CometChat } from '@cometchat-pro/chat';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Favourites from '../../blocks/Settings5/src/Favourites.web';
import GeneralSetting from '../../blocks/Settings5/src/GeneralSetting.web';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import NotificationsWeb from '../../blocks/notifications/src/NotificationsWeb.web';
import AddAdvertisements from '../../blocks/CustomAdvertisements/src/AddAdvertisements';
import SubscriptionPerks from '../../blocks/SubscriptionBilling/src/SubscriptionPerks.web'
import DowngradePerks from '../../blocks/SubscriptionBilling/src/DowngradePerks.web'
import AboutUS from '../../blocks/TermsAndConditions/src/AboutUS.web';
import PrivacyPolicy from '../../blocks/TermsAndConditions/src/PrivacyPolicy.web';
import Condition from '../../blocks/TermsAndConditions/src/Condition.web';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import MovieDetails from '../../blocks/dashboard/src/MovieDetails.web';
import SearchWeb from '../../blocks/search/src/SearchWeb.web';
import ViewSubscription from '../../blocks/email-account-registration/src/ViewSubscription.web';
import PaymentWeb from '../../blocks/Payments/src/PaymentWeb.web';
import Confirmation from '../../blocks/Payments/src/Confirmation.web';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import UpgradePlan from '../../blocks/email-account-registration/src/UpgradePlan.web';

import ChoosePreference from '../../blocks/user-profile-basic/src/ChoosePreference.web';
import EmailAccountLoginBlockWeb from '../../blocks/email-account-login/src/EmailAccountLoginBlockWeb.web';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistrationWeb.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPasswordWeb.web';
import OtpVerification from '../../blocks/forgot-password/src/OtpVerification.web';
import CreatePassword from '../../blocks/forgot-password/src/CreatePassword.web';
import LandingPage from '../../blocks/dashboard/src/LandingPage.web';
import DashboardWeb from '../../blocks/dashboard/src/DashboardWeb.web';
import StarCastMovies from '../../blocks/dashboard/src/StarCastMovies.web';
import PlanSubscription from '../../blocks/email-account-registration/src/PlanSubscription.web';
import Chat from '../../blocks/LiveChat2/src/Chat.web';
import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  createMuiTheme,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import '../../framework/src/languageSupport/i18n.web';
import WebSeries from '../../blocks/dashboard/src/WebSeries.web';
import Movies from '../../blocks/dashboard/src/Movies.web';
import PodcastDetails from '../../blocks/dashboard/src/PodcastDetails.web';
import 'react-datetime/css/react-datetime.css';
import PodcastsWeb from '../../blocks/dashboard/src/Podcast.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import AcDeleteDialog from '../../blocks/user-profile-basic/src/AcDeleteDialog.web'
// import AcDeleteDialo
import axios from 'axios';
import './App.css'
export const configJSON = require('./config');


const routeMap = {
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
    // nonuse26
  },
  GeneralSetting: {
    component: GeneralSetting,
    path: '/GeneralSetting'
  },
  TvShows: {
    component: WebSeries,
    path: '/TvShows'
  },
  Movies: {
    component: Movies,
    path: '/Movies'
  },
  ViewSubscription: {
    component: ViewSubscription,
    path: '/ViewSubscription',
    type: 'guest'
  },
  UpgradePlan: {
    component: UpgradePlan,
    path: '/UpgradePlan/:id'
  },
  Payment: {
    component: PaymentWeb,
    path: '/Payment',
    type: 'guest'
  },
  Confirmation: {
    component: Confirmation,
    path: '/Confirmation'
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true,
    type: 'guest'
  },
  ChoosePreference: {
    component: ChoosePreference,
    path: '/ChoosePreference',
    type: 'guest'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile',
    type: 'guest'
  },
  EmailAccountLoginBlockWeb: {
    component: EmailAccountLoginBlockWeb,
    path: '/EmailAccountLoginBlockWeb',
    type: 'guest'
  },
  Perks: {
    component: SubscriptionPerks,
    path: '/Perks',
    type: 'guest'
  },
  DowngradePerks: {
    component: DowngradePerks,
    path: '/DowngradePerks',
    type: 'guest'
  },
  DashboardWeb: {
    component: DashboardWeb,
    path: '/DashboardWeb/:id?'
  },

  StarCast: {
    component: StarCastMovies,
    path: '/StarCast/:id'
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb',
    type: 'guest'
  },
  CreatePassword: {
    component: CreatePassword,
    path: '/CreatePassword',
    type: 'guest'
  },
  OtpVerification: {
    component: OtpVerification,
    path: '/OtpVerification',
    type: 'guest'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPasswordWeb',
    type: 'guest'
  },
  Notifications: {
    component: NotificationsWeb,
    path: '/Notifications'
  },
  AddAdvertisements: {
    component: AddAdvertisements,
    path: '/AddAdvertisements',
    type: 'modal'
  },
  AboutUS:{
    component: AboutUS,
    path: "/AboutUS"
  },
  PrivacyPolicy:{
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  TermsAndConditions:{
    component: Condition,
    path: "/TermsAndConditions"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  MovieDetails: {
    component: MovieDetails,
    path: '/MovieDetails/:id'
  },
  Search: {
    component: SearchWeb,
    path: '/Search'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    type: 'guest',
    modal: true
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  Podcasts: {
    component: PodcastsWeb,
    path: '/Podcasts'
  },
  PodcastDetails: {
    component: PodcastDetails,
    path: '/PodcastDetails/:id'
  },
  PlanSubscription: {
    component: PlanSubscription,
    path: '/PlanSubscription/:id',
    type: 'guest'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyChBcnjObuwZF4Oh1p5_cKkvM0YqQ_z0Qo",
  authDomain: "localvodsaudi-a6d03.firebaseapp.com",
  projectId: "localvodsaudi-a6d03",
  storageBucket: "localvodsaudi-a6d03.appspot.com",
  messagingSenderId: "91311451870",
  appId: "1:91311451870:web:cf2003dd3ba6a12c551c4f"
});

const appID = '25693110f8bf7c26';
const region = 'In';
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .autoEstablishSocketConnection(true)
  .build();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const messaging = firebase.messaging();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: true
    };
    this.initilizationCometChat()
    
  }

  initilizationCometChat= ()=> {
    CometChat.init(appID, appSetting).then(
      () => {
        console.log('Initialization completed successfully');
      },
      error => {
        console.log('Initialization failed with error:', error);
      }
    );
  }

  componentDidMount() {
    let darkMode = localStorage.getItem('darkMode');
    console.log("appComponentDidMount")
    if (darkMode !== undefined) {
      this.setState({ darkMode: darkMode == 'true' ? true : false });
    }
    if (darkMode === null || darkMode === undefined) {
      this.setState({
        darkMode: true
      });
      localStorage.setItem('darkMode', true);
    }
    window.onstorage = () => {
      let darkModeChange = window.localStorage.getItem('darkMode') || 'true';
      let mode = darkModeChange == 'true' ? true : false;
      if (this.state.darkMode !== mode) {
        this.setState({ darkMode: !this.state.darkMode });
      }
    };
    this.handleNotification()
    let url = window.location.href.toLowerCase()
    let isMobilePayment = url.includes('payment')  && url.includes('paymentid')
    if (
      window.location.pathname == "/" ||
      url.includes('emailaccountloginblockweb') ||
      url.includes('emailaccountregistrationweb') ||
      url.includes('forgotpasswordweb') ||
      url.includes('otpverification') ||
      url.includes('createpassword') ||
      url.includes('alertweb') ||
      url.includes('switchaccountslogin') ||
      url.includes('customadvertisementslogin') ||
      url.includes('helpandfaqweb') ||
      url.includes('privacypolicy') ||
      url.includes('termsandconditions') ||
      url.includes('aboutus') ||
      isMobilePayment ||
      url.includes('confirmation') ||
      localStorage.getItem('Token')
    ) {
      console.log(window.location.pathname)
    } else {
      if (url.includes('?share')) {
        const url = `${window.location.pathname}?share`;
        localStorage.setItem('urlData', url);
      }
      window.location.href = '/EmailAccountLoginBlockWeb'
    }
    window.onbeforeunload = () => {
      localStorage.removeItem('urlData');
    }
  }

  handleNotification = () => {
    if (localStorage.getItem("Token")) {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          let msg = localStorage.getItem('i18nextLng') === 'ar' ? "مطلوب إذن الإخطار" : "Notification permission is required"
          toast.error(msg)
          Notification.requestPermission();
        }
        this.saveFireBase();
        this.getNotificationMsg();
      });
    }
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
    localStorage.getItem("Token") && this.saveFireBase()
    !CometChat.isInitialized() && this.initilizationCometChat()
  }

  saveFireBase = async () => {
    let firebaseToken = localStorage.getItem("firebasetoken")
    messaging
      .getToken({
        vapidKey:
          "BNs6M67T3XHuRELrHdsrwehnVDVDzDNk7duWYrKTP3XZQk4Or2C-xGiNqzm5vlI3qKw6VFnAjacSxo1FuGqovMA"
      })
      .then(currentToken => {
        if (currentToken) {
          if (firebaseToken !== currentToken) {
            localStorage.setItem('firebasetoken', currentToken);
            this.deleteFirebaseToken(firebaseToken)
            this.saveFirebaseToken(currentToken)
          }
        } else {
          console.log('firebase token error');
        }
      });
  };

  deleteFirebaseToken = deviceId => {
    const header = {
      token: localStorage.getItem('Token'),
    };
    const httpBody = {
      "token": deviceId

    }
    axios({
      method: 'delete',
      url: configJSON.baseURL + `bx_block_devices/devices/delete_device`,
      headers: header,
      data: JSON.stringify(httpBody)
    })
      .then(response => {
        console.log(response);
      })
      .catch(s3error => {
        console.log(s3error);
      });
  };

  saveFirebaseToken = token => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('Token'),
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
    };

    const httpBody = {
      "data": {
        "attributes": {
          "token": token,
          "account_device_id": localStorage.getItem("device_unique_id")
        }
      }
    }
    axios({
      method: 'post',
      url: configJSON.baseURL + configJSON.addDeviceToken,
      headers: header,
      data: JSON.stringify(httpBody)
    })
      .then(response => {
        console.log(response)
        localStorage.setItem('device_id', response?.data?.data?.id);
      })
      .catch(s3error => {
        console.log(s3error);
      });
  };

  getNotificationMsg = () =>
    new Promise(resolve => {
      messaging.onMessage(payload => {
        let notifcationType = payload.data.notify_type === "New Release"
        let notificationInformation = JSON.parse(payload.data.information)
        let notificationImage = notifcationType ? notificationInformation?.image_url : ""
        const notificationTitle = localStorage.getItem("i18nextLng") === "ar" ? payload.data.title_in_arabic : payload.data.title;
        const notificationOptions = {
          body: localStorage.getItem("i18nextLng") === "ar" ? payload.data.message_in_arabic : payload.data.message,
          data: payload.data,
          icon: notificationImage
        };
        console.log('firebase_msg', payload)
        let notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        // notification.show()
        notification.addEventListener('click', event => {
          event.preventDefault();
        });
        resolve(payload);
      });
    });

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    let gradientBorder =
      'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)';
    const darkTheme = createMuiTheme({
      overrides: {
        MuiOutlinedInput: {
          root: {
            backgroundColor: "#222222",
            '& fieldset': {
              borderWidth: '0.09rem',
              borderStyle: 'solid',
              borderImage: gradientBorder,
              borderImageSlice: 1, // Apply gradient border to the fieldset
              borderRadius: '8px', // Adjust border radius as needed
            },
            '&:hover fieldset': {
              borderColor: `${gradientBorder}`, // Change border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: `${gradientBorder}`, // Change border color when focused
            },
            '& input::placeholder': {
              opacity: 0.7,
            },
            '& textarea::placeholder': {
              opacity: 0.7,
            },
          },
        },
       MuiButton: {
        contained: {
            background: gradientBorder, // Apply gradient background to the button
            // Adjust border radius as needed
                '&:hover': {
                  background: gradientBorder, // Change gradient background on hover
                },
                '&:disabled': {
                  background: gradientBorder, // Change gradient background on hover
                },
          },          
        }
      },
      typography: {
        button: {
          textTransform: 'none',
        },
      },
      direction: localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr',
      palette: {
        type: 'dark',
        primary: { main: '#FFFFFF' },
        secondary: { main: '#F6F6F6' },
        third: { main: '#8187A4' },
        background: {
          default: '#f5f5f5',
          gunmeta: '#212330',
          eerieBlack: '#1A1B22',
          blackDark: '#1A1B22'
        },
        text: {
          primary: '#DCDCDC',
          secondary: '#757A8C'
        }
      }
    });

    const lightTheme = createMuiTheme({
      typography: {
        button: {
          textTransform: 'none',
        },
      },
      direction: localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr',
      palette: {
        type: 'light',
        primary: { main: '#000000' },
        secondary: { main: '#1b2025' },
        third: { main: '#8187A4' },
        background: {
          default: '#f5f5f5',
          gunmeta: 'transparent',
          eerieBlack: 'transparent',
          blackDark: '#D3D3D3'
        },
        text: {
          primary: '#0b0c0d',
          secondary: '#757A8C'
        }
      }
    });

    const direction = createTheme({
      languageType: localStorage.getItem('i18nextLng')
    });

    return (
      <div dir={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
        <MuiThemeProvider theme={direction}>
          <MuiThemeProvider
            theme={this.state.darkMode ? darkTheme : lightTheme}
          >
            <View style={{ height: 'auto' }}>
              {WebRoutesGenerator({ routeMap })}
              <ModalContainer />
              <ToastContainer
                style={{
                  zIndex: '999999'
                }}
                autoClose={6000}
                position="top-right"
                className="toast-container"
                toastClassName="Toastify__toast-theme--dark dark-toast"
              />
            </View>
          </MuiThemeProvider>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;