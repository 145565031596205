import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {closeModal, dilaogStar, logoutDialog } from "../assets";


export const themeCreatStyle = (theme: any) => ({
   deleteMes1: {
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "normal",        
        color: "#F6F6F6",
        margin: '21px 0px 30px',
    },
    deleteMes2: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center" as const,
        color: "#F6F6F6",
        margin: '5px 0px 20px',
    },
    deleteText: {
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "normal",      
        [theme.breakpoints.down("xs")]: {
            fontSize: 15,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 16,    
          }

    },
    cancelButton: {
        margin: theme.direction == "rtl" ? '0 20px 0 0' : '0 0 0 20px',
        padding: '14px 22px',
        background: 'none',
        color: "#888888",
        textTransform: "initial" as const,
        borderRadius: 8,
        minWidth: '170px',
        borderWidth: "1px",
        borderStyle: "solid",
        borderImage: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        borderImageSlice: 1       

    },
    deleteButton: {
        textTransform: "initial" as const,
        margin: theme.direction == "rtl" ? '0 0px 0 20px' : "0 20px 0 0",
        padding: '14px 22px',
        borderRadius: '3px',
        backgroundColor: '#EA435D',
        color:"#F6F6F6",
        minWidth: '170px',
        '&:hover':{
            backgroundColor: '#EA435D',
        }

    },
    logoutIcon:{
        width: 80,
        height: 80,
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            width: 60,
        height: 60        
          },
          [theme.breakpoints.down("sm")]: {
            width: 70,
        height: 70        
          }
    },
    backgroundImage_div: {
        outline: 'none',
        justifyContent: "center" as const,
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)' as const,
        width: "600px",
        maxWidth: '90%',
        borderRadius:8,
        background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)"
    
},
backgroundModal:{
    padding: "32px 18px",
    position: "relative" as const,
    width: '100%'
},
closeIconStyle:{
    position: 'absolute' as const,
    width: 24,
    height: 24,
    top: 15,
    left: theme.direction === 'rtl' ? '20' : 'auto',
    right: theme.direction === 'ltr' ? '20' : 'auto',
    cursor: "pointer"
},
popoverDesign: {
    borderRadius: 8, 
    backdropFilter: "blur(2px)",
    direction: theme.direction
}
});

interface Props {
    title?: string;
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    confirmMes: any;
    classes: any;
    cancelButtonText: any;
    okButtonText: any;
    isDescription? : boolean;
    description? : string;
    isCancelFunction? : boolean;
    onCancelClick? : any;
    isLogout?: boolean;
}

export const MesDialog = ({
    deleteModal,
    onCloseModal,
    deleteClick,
    confirmMes,
    classes,
    cancelButtonText,
    okButtonText,
    isDescription,
    description,
    isCancelFunction,
    onCancelClick,
    isLogout
}: Props) => {
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.popoverDesign}
            >
                <Grid container className={classes.backgroundImage_div}>
                <Grid item className={classes.backgroundModal}            
                >
                    <img src={closeModal} className={classes.closeIconStyle} onClick={onCloseModal}/>
                   <Grid container justifyContent="center">
                    <img src={isLogout ? logoutDialog : dilaogStar} className={classes.logoutIcon}/>
                   </Grid>
                    <div className={classes.deleteMes1} style={{
                        textAlign: "center",
                        marginBottom: isDescription ? '0px': '30px'
                    }}>{confirmMes}</div>
                    {isDescription && <div className={classes.deleteMes2}>{description}</div>}
                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" className={classes.deleteButton}
                            onClick={deleteClick}><span className={classes.deleteText}>{okButtonText}</span></Button>
                        <Button className={classes.cancelButton} 
                        onClick={isCancelFunction ? onCancelClick :onCloseModal}>
                            <span className={classes.deleteText}>{cancelButtonText}</span></Button>
                        
                    </Grid>
                </Grid>
                </Grid>
            </Modal>
        </>
    );
}
export default withStyles(themeCreatStyle)(MesDialog);
