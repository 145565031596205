import i18next from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const en = require('./enWeb.json');
const ar = require('./arWeb.json');


i18next
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: {
					...en
				}
			}, ar: {
				translation: {
					...ar
				}
			}
		},
		supportedLngs: ['en', 'ar'],
		fallbackLng: 'en',
		debug: false,
		// Options for language detector
		detection: {
			order: ['localStorage', 'cookie', 'htmlTag', 'path',],
			caches: ['localStorage', 'cookie'],
		},
		react: { useSuspense: false },
		backend: {
			loadPath: './{{lng}}.json',
		},
	})

export default i18n;
