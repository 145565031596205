import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    headerText: any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    imgIndex: any;
    loading: boolean;
    castData: any;
    castName: string;
    castImage : string
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class StarCastMoviesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getCastInfoAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            errorMsg: "",
            token: "",
            loading: false,
            imgIndex: null,
            castData: null,
            castName: "",
            castImage : ''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getCastDetails();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        let errorMsg = "";
        let loading = false;

        if (responseJson && !responseJson?.errors) {
            if (responseJson?.data && responseJson?.data?.length === 0) {
                errorMsg = "Data Not Found";
            }
            if (apiRequestCallId === this.getCastInfoAPICallId) {
                this.handleCastInfoResponce(responseJson);
            }
        } else {
            errorMsg = errorResponse ?? "Something went wrong";
        }

        this.setState({ errorMsg, loading });
    }

    // Customizable Area Start
    getCastDetails = () => {
        const castId = this.props?.navigation?.getParam('id');
        this.getCastInfoAPICallId = sendAPIRequest(`${configJSON.castDataAPIEndPoint}/${castId}?profile_id=${localStorage.getItem("selectedUser")}`,
            { headers: { language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en", token: localStorage.getItem('Token') } },
        );
    }

    handleCastInfoResponce = (json: any) => {
        const castData = json?.data?.attributes?.contents;
        const castName = json?.data?.attributes?.name;
        let castImage = json?.data?.attributes?.image;
        this.setState({ castData, castName, castImage });
    }

    historyBack() {
        this.props.navigation.goBack()
    }
    // Customizable Area End

}
