import React from 'react';
import { Popover, MenuItem, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomCountryCodePickerController, {Props} from './customCountryCodePickerController.web';


export class CustomCountryCodePicker extends CustomCountryCodePickerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    
      // Customizable Area Start
      // Customizable Area End
    render() {
        const { countryList, selectedCountryCode, selectCountryCode, classes } = this.props;
        const { anchorE1, filterList, countrySerch }: any = this.state;

        return (
            <>
                <span
                    data-test-id="target"
                    onClick={(event: any) =>
                        this.setAnchorE1(event.currentTarget)
                    }
                    className={classes.spanText}
                >
                    {selectedCountryCode}
                </span>
                {countryList != null &&
                    <Popover
                        data-test-id="close"
                        anchorEl={anchorE1}
                        open={Boolean(anchorE1)}
                        onClose={() => { this.setAnchorE1(null) }}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        style={{
                            backgroundColor: "rgba(16,16,20, 0.60108)",
                            opacity: 1,
                        }}
                        PaperProps={{
                            className: classes.apply_coupon_modal,
                            style: {
                                maxHeight: '50%',
                                minHeight: '50%',
                                maxWidth: '350px'
                            },
                        }}
                    >
                        <Grid
                            container
                            style={{
                                display: 'block',
                                borderRadius: 10,
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <MenuItem className={classes.InputBox}>
                                <input type="text" placeholder="Search country name here" value={countrySerch} data-test-id="search_country" onChange={(e) => this.searchCountry(e)} className={classes.countrySearchInput} />
                            </MenuItem>
                            {Array.isArray(filterList) && filterList?.filter((_country: any) => {
                                if (countrySerch == "") return _country
                                if (_country.country_name.toLowerCase().includes(countrySerch.toLowerCase()) || _country.country_code.includes(countrySerch)) return _country

                            }).map((_data: any) => {
                                return (
                                    <>
                                        <MenuItem className={classes.MenuItems} data-test-id="select_code"
                                            onClick={() => {
                                                selectCountryCode(_data.country_code, _data.country_name);
                                                this.setAnchorE1(null); this.setFilterList(countryList)
                                            }
                                            }>{`${_data?.country_name}  +${_data?.country_code}`}</MenuItem>
                                    </>
                                )
                            })}
                        </Grid>
                    </Popover>
                }
            </>
        )
    }
}

export const createThemeStyles: any = (theme: any) => ({
    apply_coupon_modal: {
        width: 320,
        background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
        backgroundSize: "100% 100%",
        boxShadow: "-6px 8px 25px rgba(0,0,0, 0.4)",
        [theme.breakpoints.only("xs")]: {
            width: "80%",
            maxWidth: 320,
        },
        scrollbarColor: "#B7B7B7 transparent",
        "&::-webkit-scrollbar": {
            width: 6,
            height: 6,
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 6,
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
            minHeight: 24,
            minWidth: 24,
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
        },
    },
    countrySearchInput: {
        width: "100%",
        padding: "12px",
        background: theme.palette.type === "dark" ? "rgba(33, 35, 48, 1)" : "#D3D3D3",
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px"
    },
    MenuItems: {
        fontSize: 14,
        minHeight: 20,
        color: theme.palette.primary.main,
        paddingTop: 10,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
        "&:hover": {
            background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
            color: theme.palette.primary.main,
        }
    },
    InputBox: {
        width: "100%",
        position: "sticky",
        top: "0",
        padding: "6px",
        background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
        zIndex: 100,
    },
    spanText: {
        color: theme.palette.primary.main,
        fontFamily: "Araboto",
        opacity: 1,
        cursor: 'pointer',
        borderRadius: '10',
        direction: 'ltr'
    }
})

export default withStyles(createThemeStyles)(CustomCountryCodePicker);

