Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationApiMethodTypePOST = "POST";
exports.validationApiMethodTypePUT = "PUT";
exports.validationApiMethodTypeDEL = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomAdvertisements";
exports.labelBodyText = "CustomAdvertisements Body";
exports.getCustomAdvertisement="bx_block_custom_ads/advertisements";
exports.userLoginAPIEndPoint = "bx_block_login/logins";
exports.addBannerText = "Add Banner";
exports.editBannerText = "Edit Banner";
exports.uploadBannerImageText = "Upload Banner Image";
exports.nameText = "Name";
exports.URLText = "URL";
exports.descriptionText = "Description";
exports.titleText = "Delete";
exports.confirmText = "Are you sure you want to delete?";
exports.cancelText = "Cancel";
exports.btnExampleTitle = "CLICK ME";
exports.AddAdvertisements = "bx_block_custom_ads/advertisements";
exports.validationApiMethodDelete = "DELETE";
exports.validationApiMethodPatch = "PATCH";
exports.deleteSuccessMessage = "Advertisement successfully deleted.";
exports.addSuccessMessage = "Advertisement added successfully.";
exports.editSuccessMessage = "Advertisement updated successfully.";
exports.addEditErrorMessage = "Something wents wrong.";
exports.userNotFound = "User Not Found";
exports.nameTitle ="Name";
exports.createBanner ="Create banner";
exports.deleteTitle ="Delete";
exports.areDelete ="Are you sure you want to Delete?";
exports.NoTitle ="No";
exports.cancelTitle ="cancel";
exports.yesTitle ="Yes";
exports.congratulations ="Congratulations";
exports.okTitle ="Ok";
exports.alertTitle ="Alert";
exports.urlTitle ="URL";
exports.discription ="Discription";
exports.uploadBannerImg ="Upload Banner Image";
exports.noAccess ="No access available";
exports.enterBanner ="Enter Banner name";
exports.enterUrl ="Enter URL";
exports.enterDiscription ="Enter Discription";
exports.selectBanner="Select banner image";
exports.tokenKey="Token";
exports.userRole="user_role";
exports.adminRole="admin"
// Customizable Area End
