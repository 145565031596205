import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// @ts-ignore
import CONTENT_TYPES from '../../../components/src/Enums';
import React from 'react';
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    contentDetails?: any,
    onPlayVideo?: any,
    seasonsData?: any,
    getEpisodesByRelease?: any
    playingSeason?: any;
    updateMovieDetailsState?:any;
    updatePodcastParts?:boolean;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    imgIndex: any;
    loading: boolean;
    selectedSeason: any;
    slides: any;
    releases: any[];
    isControll: boolean;
    windowSize: number;
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class EpisodesSliderController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start;
    sliderRef: any;
    SliderRef: React.RefObject<HTMLInputElement> = React.createRef();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.sliderRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            errorMsg: "",
            token: "",
            loading: false,
            imgIndex: null,
            selectedSeason: '',
            slides: null,
            releases: [],
            isControll: false,
            windowSize: window.innerWidth,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        this.onInitialized(this.props)
        window.addEventListener('resize',() =>this.handleResize())
        // Customizable Area End
    }

    handleResize = () => {
        try {
            const windowWidth = window.innerWidth;
            let spacing: number = windowWidth * 0.05 + 20;
            let box: any = {};
            box = this.SliderRef.current;
            if (!box) return;
            let width = box.clientWidth;
            console.log(width,box,"width",windowWidth, spacing)
            this.setState({
              isControll: width >= (windowWidth - spacing),
              windowSize: window.innerWidth,
            });
        } catch {}
    }

    async componentWillReceiveProps(nextProps: any) {
        if (nextProps?.seasonsData?.data && this.props?.seasonsData?.data && nextProps?.seasonsData?.data[0]?.id !== this.props?.seasonsData?.data[0]?.id) {
            const { contentDetails = {}, seasonsData, playingSeason } = nextProps;
            const episodes = (seasonsData?.data || []);
            this.setState({ slides: episodes, selectedSeason: playingSeason });
        }
        if(nextProps?.updatePodcastParts && nextProps?.contentDetails?.id != this.props?.contentDetails?.id){
            this.onInitialized(nextProps);
            this.props?.updateMovieDetailsState({updatePodcastParts:false});
        }
        if(this.props.contentDetails?.parts !== nextProps?.contentDetails?.parts){
            const parts = (nextProps?.contentDetails?.parts || []);
            this.setState({ slides: parts });
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
        if(prevProps.seasonsData?.releases?.length !==  this.props.seasonsData?.releases?.length ){
            this.onInitialized(this.props)
        }
    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.setState({
            loading: false
        })

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && !responseJson.errors && responseJson.data) {
                if (responseJson.data.length === 0) {
                    this.setState({
                        errorMsg: "Data Not Found",
                        loading: false
                    });
                } else {
                    this.setState({
                        errorMsg: "",
                        loading: false
                    });
                }
            } else {
                if (errorReponse === undefined) {
                    this.setState({
                        errorMsg: "Something went wrong",
                        loading: false
                    });
                } else {
                    this.setState({
                        errorMsg: errorReponse,
                        loading: false
                    });
                }
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    onInitialized = (propsAsParam:any) => {
        const { contentDetails = {}, seasonsData, playingSeason } = propsAsParam;
        switch (contentDetails?.content_type) {
            case CONTENT_TYPES.WEBSERIES:
                const episodes = (seasonsData?.data || []);
                const releases = (seasonsData?.releases || []);
                this.setState({ slides: episodes, releases, selectedSeason: playingSeason });
                break;
            case CONTENT_TYPES.MOVIES:
                const parts = (contentDetails?.parts || []);
                this.setState({ slides: parts });
                break;
            case CONTENT_TYPES.AUDIOPODCAST:
                const audioParts = (contentDetails?.parts || []);
                const shortedById = audioParts?.sort((a: any, b: any) => a.id - b.id);
                this.setState({ slides: shortedById });
                break;
            default:
                break;
        }
    }
    handleSelectChange = (event: any) => {
        const { contentDetails, getEpisodesByRelease } = this.props;
        this.setState({ selectedSeason: event.target.value as string });
        getEpisodesByRelease(contentDetails?.id, event.target.value);
    };

    previewHandler = () => {
        let box: any = {};
        box = this.SliderRef.current;
        if (!box) return;
        let width = box.clientWidth;
        box.scrollLeft = box.scrollLeft - width - 80;
      };
    
      nextHandler = () => {
        let box: any = {};
        box = this.SliderRef.current;
        if (!box) return;
        let width = box.clientWidth;
        box.scrollLeft = box.scrollLeft + width - 80;
      };

    //   handleResize = () => {
    //     this.setState({
    //       windowSize: window.innerWidth,
    //     });
    //   };

      async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
      }


    // Customizable Area End
}
