import React from "react";

import {
  Grid,
  Typography,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from "@material-ui/core";
import HelpAndFaqController, { Props } from "./HelpAndFaqController.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FaqItemBg } from "./assets";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import RaiseTicketWeb from "./RaiseTicketWeb.web";
import { withTranslation } from 'react-i18next';

export class HelpAndFaqWeb extends HelpAndFaqController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes }: any = this.props;
    const { t }: any = this.props;
    const { langaugeCode } = this.state;
    const tabList = [
      {
        id: 1,
        key: "faq",
        label: langaugeCode ? "التعليمات" : "Faqs"
      },
      {
        id: 2,
        key: "raise-ticket",
        label: langaugeCode ? "رفع التذكرة" : "Raise ticket"
      }
    ]

    return (
      // Customizable Area Start
      <Grid>
        {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
        <Grid>
          <Grid className={classes.Container}>
            <Grid className={classes.HelpHeader}>
              <Grid  data-test-id="backHistory" className={classes.BackBlock}
                onClick={() => this.setState({ tabView : 'faq'})}>
              
                <Typography className={classes.Heading}>{t("helpandfaqweb.headerTitle")}</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.FaqContainer}>
              <Grid item sm={12} lg={12} >
                {this.state.tabView === "faq" && <>{this.state.faqList.map((item: any, index: any) => {
                  return (
                    <React.Fragment key={item?.id}>
                      <Accordion className={classes.Accordion} >
                        <AccordionSummary
                          expandIcon={<KeyboardArrowDownIcon className={classes.DropDownIcon} />}
                          className={classes.setQuestion}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.Question}>{item?.attributes?.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.AccordionDetails}>
                          <Typography className={classes.Answer}>{item?.attributes?.answer}</Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: 1,
                          background: "linear-gradient( 90deg, #6869AC , #EA435D )",
                        }}
                      />
                    </React.Fragment>
                  )
                })}</>}
                {this.state.tabView === "faq" &&
                  <Box className={classes.MainTicketDiv} >
                    <div className={classes.setTextButton} >
                      <div className={classes.setWidth} >
                        <Typography className={classes.title1} >{t("helpandfaq.title1")}</Typography>
                        <Typography className={classes.title2}>{t("helpandfaq.title2")}</Typography>
                      </div>
                      <div>
                        <button className={classes.ticketButton} onClick={() => this.setState({ tabView: "raise-ticket" })}>{t("helpandfaq.ticket")}</button>
                      </div>
                    </div>
                  </Box>}
                  </Grid>
                {this.state.tabView === "raise-ticket" && <RaiseTicketWeb
                  handleChangeTab={(key: any) => this.handelTab(key)} data-test-id="raise-ticket" {...this.props} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  ScrollBar: {
    scrollbarWidth: 'thin',
    scrollbarColor: '#242830 transparent',
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "#242830",
      minHeight: 24,
      minWidth: 24,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },
  Container: {
    // padding: "0 5vw 0 5vw"
  },
  setWidth: {
    width: "80%",
    '@media (max-width: 600px)': {
      width: '100%', // Change the padding for screens smaller than 600px
    },
  },
  ArrowIcon: {
    fontSize: "22px",
    transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
    color: theme.palette.primary.main,
  },
  Question: {
    margin: "12px 0",
    padding: "0",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  DropDownIcon: {
    color: theme.palette.primary.main,
    '& .MuiAccordionSummary-root-627':{
      padding: '0px'
    }
  },
  Answer: {
    fontSize: "14px",
    fontWeight: 400,
    color: theme.palette.secondary.main
  },
  HelpHeader: {
    padding: "30px 0 20px 0",
    width: "100%",
    borderBottom: "2px solid",
    borderImage: "linear-gradient( 90deg, #E14663 , #5772A9 , #288AA2 ) 1",
    // borderBottom: "0.5px solid #3C4557"
  },
  Heading: {
    fontSize: "28px",
    lineHeight: "33px",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  font_size_16: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 14.5,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
  BackBlock: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    padding: '0px 30px'
  },
  FaqContainer: {
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  FaqLeft: {
    padding: theme.direction === "rtl" ? "30px 0 0 30px" : "30px 30px 0 0",
    borderRight: theme.direction === "rtl" ? "none" : "0.5px solid #3C4557",
    borderLeft: theme.direction === "rtl" ? "0.5px solid #3C4557" : "none",
    [theme.breakpoints.down("sm")]: {
      borderRight: "0.5px solid transparent",
      padding: "30px 0 0 0"
    },
  },
  FaqRight: {
    padding: '30px 0px 40px 0px',
    height: "calc(100vh - 26vh)",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: '30px 0',
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  FaqList: {
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    },
  },
  FaqItem: {
    padding: "27px 16px 23px 16px",
    border: "1px solid transparent",
    cursor: "pointer",
    "& p": {
      fontSize: "16px",
      lineHeight: "16px",
      color: "#7A7B7F",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  FaqItemActive: {
    padding: "27px 16px 23px 16px",
    background: theme.palette.type === "dark" ? `url(${FaqItemBg})` : `#D3D3D3`,
    border: theme.palette.type === "dark" ? "1px solid transparent" : "1px solid #D3D3D3",
    borderRadius: "10px",
    boxShadow: theme.palette.type === "dark" ? "none" : "6px 6px 12px RGBA(0, 0, 0, 0.2)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    "& p": {
      fontSize: "16px",
      lineHeight: "16px",
      color: theme.palette.type === "dark" ? "#6869AC" : "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  Accordion: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiAccordionSummary-root": {
      padding: "0 20px 0 0"
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0
    },
    "& .MuiAccordionSummary-content": {
      padding: "24px 0",
      margin: 0,
    }
  },
  AccordionDetails: {
    padding: "20px",
    borderRadius: "15px",
    border: "1px solid #1A1B22",
    background: `${theme.palette.type == "dark" ? '#1A1B22' : "#D3D3D3"}`
  },
  MainTicketDiv: {
    padding: '2px',
    backgroundImage: 'linear-gradient(black, black), linear-gradient(to right, #E9445E, #5772A9, #288AA2)',
    // borderWidth: '3px',
    // borderStyle: 'solid',
    marginTop: '220px',
    marginBottom: '25px',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '6px',
    border: 'solid 16px trasparent'
    // overflow: 'hidden',
  },
  title1: {
    color: 'white',
    fontSize: '16px',
    fontWeight: '700',
    LineHeight: '22px'
  },
  title2: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '400',
    LineHeight: '18px'
  },
  ticketButton: {
    width: '300px',
    height: '54px',
    borderRadius: '8px',
    padding: '10px',
    gap: '10px',
    backgroundImage: 'linear-gradient(to right, #EA435D,#6869AC,#248CA1)',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    fontWeight: '700',
    LineHeight: '22px',
    cursor: 'pointer',
    '@media (max-width: 1200px)': {
      width: '250px', // Change the padding for screens smaller than 600px
    },
    '@media (max-width: 900px)': {
      width: '220px', // Change the padding for screens smaller than 600px
    },
    '@media (max-width: 600px)': {
      width: '180px', // Change the padding for screens smaller than 600px
      marginTop: '20px'
    },
  },
  setTextButton: {
    display: 'flex', 
    justifyContent: 'center',
    background: '#212121',
    padding: '20px',
    '@media (max-width: 600px)': {
      flexDirection: 'column', // Change the padding for screens smaller than 600px
    },
  },
  setQuestion: {
    padding: '0px'
  }
});
export default withStyles(styles)(withTranslation()(HelpAndFaqWeb));
// Customizable Area End
