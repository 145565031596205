import React from "react";
import { Typography, Grid, Box, LinearProgress } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
// @ts-ignore
import cssClasses from './EpisodeComponent.module.css';
import { withStyles } from '@material-ui/core/styles';
import EpisodeComponentController from "./EpisodeComponentController.web";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
export const configJSON = require("./config.js");

export class EpisodeComponent extends EpisodeComponentController {

    render() {
        const { index, episode, classes, t, contentDetails }: any = this.props;
        
        return (
            <Card data-test-id="movieDetails" className={`${localStorage.getItem("i18nextLng") === "ar" ? cssClasses.episodeArabicCard : cssClasses.episodeCard}`}
                onClick={() => { this.onClickCard(contentDetails, episode, index) }}
            >
                <div className={`${cssClasses.episodeContainer}`}>
                    <Grid item>
                   <CardMedia
                            component="img"
                            className={`${cssClasses.episodeAvatar}`}
                            alt="poster"
                            src={episode?.thumbnail_image}
                        /> 
                        </Grid>   
                        <Grid item className={classes.historyContainer}> 
                        <Grid container className={classes.episodeDes}>              
                    <div className={classes.EpisodeDetails}>
                        <CardContent className={cssClasses.episodeContent}>
                            <Typography color='primary' className={`${classes.EpisodeTitle} ${cssClasses.lineClamp1}`}>
                                {episode?.title}
                            </Typography>
                        </CardContent>
                        <div className={cssClasses.textDiv} >
                            <Typography color='secondary' className={`${classes.EpisodeText} ${cssClasses.lineClamp2}`} >
                                {episode?.description}
                            </Typography>
                        </div>
                    </div>
                    <div className={cssClasses.columnRight}>
                        <div>
                            <IconButton color='secondary' className={cssClasses.episodeMoreButton} aria-label="options">
                                {episode?.episode_duration ? this.handleTimeFormat(episode?.episode_duration) : this.handleTimeFormat(episode?.duration)}
                            </IconButton>
                        </div>
                    </div>     
                    </Grid>                
                        {
                            this.checkWatchHistory() && 
                            <Box width="100%" mt={1.5} mb={1.5} className={cssClasses.progressContainer}>
                                <Box display="flex" alignItems="center">
                                    <IconButton className={cssClasses.playIcon} onClick={() => { this.onClickCard(contentDetails, episode, index) }}>
                                        <PlayCircleOutlineIcon />
                                    </IconButton>
                                    <Typography className={cssClasses.percentageText}>
                                    {t("movieDetails.resume")} {this.state.percentageValue}%
                                    </Typography>
                                </Box>
                            <LinearProgress
                              classes={{
                                colorPrimary: classes.colorPrimary,
                                barColorPrimary: classes.barColorPrimary,
                              }}
                              variant="determinate"
                              value={this.watchingTime(
                                parseInt(episode?.watch_history?.duration),
                                parseInt(episode?.watch_history?.watching_duration)
                              )}
                            />
                          </Box>
                        }
                    </Grid> 
                </div>
            </Card>
        )
    }
}

export const styles: any = (theme: any) => ({
    EpisodeTitle: {
        textAlign: theme.direction === 'rtl' ? 'right' : 'left',
    },
    EpisodeText: {
        opacity: 1,
        fontFamily: 'Araboto-Normal !important',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        fontStyle: 'normal !important',
        letterSpacing: '0px !important',
        textAlign: theme.direction === 'rtl' ? 'right' : 'left',
        lineHeight: '18px !important',
        display: '-webkit-box',
        marginBottom: 'auto !important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    EpisodeDetails: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "165px",
        minWidth: "165px",
    },
    colorPrimary: {
        backgroundColor: '#242830',
        height: "7px",
        borderRadius: "4px"
      },
      barColorPrimary: {
        background: 'linear-gradient(90deg, rgba(234,67,93,1) 0%, rgba(104,105,172,1) 100%);',
      },
      historyContainer:{
        padding: theme.direction === 'rtl' ? "0 10px 0 0 !important" : "0 0 0 10px !important",        
      },
      episodeDes:{
        flexWrap: "nowrap"
      },
      watchedPercentageText: {
        marginBottom: theme.spacing(0.5),
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    playIcon: {
        color: theme.palette.primary.main,
    },
    percentageText: {
        marginLeft: theme.spacing(1),
        fontWeight: 500,
    },
});

export default withStyles(styles)(EpisodeComponent);
