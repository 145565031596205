import React from "react";
import { LikeIconWeb, ShareIconWeb, Star, play } from "./assets";
import { Box, Typography, Grid, Button, LinearProgress } from "@material-ui/core";
import "./MovieDetails.css";
import SeeMoreProps from "../../../components/src/SeeMoreProps.web";
import { withStyles } from "@material-ui/core/styles";
import LikeCommentShare from "./LikeCommentShare.web";
import EpisodesSliderWeb from "./EpisodesSlider.web";
import { withTranslation } from "react-i18next";
import BannerController from "./BannerController.web";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
export class Banner extends BannerController {
  render() {
    const { classes, t, contentDetails, width }: any = this.props;
    const isMobile = !isWidthUp('sm', width);
    const duration = parseInt(contentDetails?.watch_history?.duration);
    const watchingDuration = parseInt(contentDetails?.watch_history?.watching_duration);

    const watchedPercentage = (duration && watchingDuration)
      ? Math. floor((watchingDuration / duration) * 100)
      : 0;

    const remainingTime = parseInt(contentDetails?.watch_history?.duration) && parseInt(contentDetails?.watch_history?.watching_duration)
      ? (parseInt(contentDetails?.watch_history?.duration) - parseInt(contentDetails?.watch_history?.watching_duration)) / 60
      : null;

      const backgroundImage = isMobile
      ? `url(${contentDetails?.mobile_image})`
      : `url(${contentDetails?.image})`;

    return (
      <>
        <Grid className={`${classes.BannerImg}`} container>
          <Box
            className={`${classes.BannerBg}`}
            style={{ backgroundImage: backgroundImage }}
          />
          <Box className={`${classes.overLay}`} />
          <Grid item className={classes.moviegrid} xs={12} sm={10} lg={10}>
            <Box className={classes.Moviedetails}>
              {contentDetails?.title_image ? (
                <img
                  className={classes.tittleImg}
                  src={contentDetails?.title_image}
                />
              ) : (
                <Typography className={classes.movieTittle}>
                  {contentDetails?.title}
                </Typography>
              )}
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0 5px 0",
                }}
              >
                {contentDetails?.rating && <Box className={classes.divider} />}
                <Typography className={classes.shortDetails}>
                  {contentDetails?.genres && contentDetails?.genres?.length > 0
                    ? (
                      (contentDetails?.genres || []).map(
                        (el: any) => el
                      ) || []
                    ).join("/ ")
                    : t("genre.comdey")}
                </Typography>
                <Box className={classes.divider} />
                <Typography className={classes.shortDetails}>
                  {contentDetails?.publish_date
                    ? moment(contentDetails?.publish_date).format("YYYY")
                    : moment().format("YYYY")}
                </Typography>
                <Box className={classes.divider} />
                {contentDetails?.rating && (
                  <Typography className={classes.shortDetails}>
                    {contentDetails?.rating}
                  </Typography>
                )}
              </Box>
              {(contentDetails?.description || "").length > 0 && (
                <Box className={classes.descDetails}>
                  <SeeMoreProps
                    text={contentDetails?.description}
                    limit={190}
                    t={t}
                  />
                </Box>
              )}
              <Grid container
                className={classes.ButtonContainer}
              >
                {watchedPercentage > 0 && watchedPercentage < 95 && contentDetails?.watch_history?.content_type === "Movie" && (
                  <Box mb={2} className={classes.progressContainer}>
                    <LinearProgress
                      variant="determinate"
                      value={watchedPercentage}
                      classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                      }}
                      className={classes.ClasssLinearProgress}
                    />
                    {remainingTime !== null && (
                      <Typography className={classes.remainingTimeText}>
                        {Math.round(remainingTime)} {t("movieDetails.minRemaining")} 
                      </Typography>
                    )}
                  </Box>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  data-test-id="navtoPlayer"
                  onClick={() => this.navToPlayer(contentDetails)}
                  className={classes.playButton}
                >
                  <img className={classes.DetailImage} src={play} />
                  {this.handlePlayButtonText(contentDetails)}
                </Button>
                <div>
                  <LikeCommentShare {...this.props} id={"0"} isLikeModal={true} />
                  {/* <img src={LikeIconWeb} className={classes.likeShareIcons} alt="like"/> */}
                  <img src={ShareIconWeb} className={classes.likeShareIcons} onClick={() => this.handleShareDialog()} alt="share" />
                </div>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} lg={2} style={{ zIndex: 2 }}>
            <LikeCommentShare {...this.props} id={"0"} isShareDialog={this.state.isSharedModal} handleCloseShare={() => this.handleShareDialog()} />
          </Grid>
        </Grid>
        <Box>{contentDetails && <EpisodesSliderWeb {...this.props} />}</Box>
      </>
    );
  }
}
export const styles: any = (theme: any) => {
  let themeDirection = (arrValue: any, enValue: any) =>
    theme.direction == "rtl" ? arrValue : enValue;
  let themePalette = (lightValue: any, darValue: any) =>
    theme.palette.type == "dark" ? lightValue : darValue;

  return {
    BannerImg: {
      minHeight: "calc(100vh - 10vh)",
      maxHeight: "100%",
      backgroundSize: "100% 100%",
      backgroundPosition: "center center",
      position: "relative",
      objectFit: "contain"
    },
    ButtonContainer: {
      flexDirection: "row",
      alignItems: "flex-start",
      [theme.breakpoints.only("xs")]: {
        flexDirection: "row",
      },
    },
    BannerBg: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "inherit",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      right: 0,
      transform: theme.direction === "rtl" ? "rotateY(180deg)" : "none",
    },
    overLay: {
      width: "100%",
      height: "100%",
      background: themeDirection(
        `linear-gradient(90deg, #000000 -0.12%, rgba(7, 7, 14, 0.1) -0.12%, rgba(6, 6, 13, 0.3) 15.46%, rgba(4, 4, 8, 0.7) 42.99%, rgba(5, 5, 11, 0.9) 56.09%, #07070E 100%)`,
        `linear-gradient(270deg, #000000 -0.12%, rgba(7, 7, 14, 0.1) -0.12%, rgba(6, 6, 13, 0.3) 15.46%, rgba(4, 4, 8, 0.7) 42.99%, rgba(5, 5, 11, 0.9) 56.09%, #07070E 100%)`
      ),
      backgroundBlendMode: "multiply",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      [theme.breakpoints.only("xs")]: {
        background: "linear-gradient(0deg, #000000 -0.12%, rgba(7, 7, 14, 0.1) -0.12%, rgba(6, 6, 13, 0.3) 15.46%, rgba(4, 4, 8, 0.7) 42.99%, rgba(5, 5, 11, 0.9) 56.09%, #07070E 100%)",
      },
    },
    likeShareIcons: {
      width: '54px',
      height: '54px',
      margin: '12px 10px 0',
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.only("xs")]: {
        width: '44px',
        height: '44px',
      },

    },
    Moviedetails: {
      width: "60%",
      padding: "9% 0",
      [theme.breakpoints.only("xs")]: {
        padding: "50px 0 0",
        width: "100%",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "90px 5% 5%",
        width: "100%",
      },
    },
    tittleImg: {
      width: "auto",
      height: "auto",
      maxWidth: "80%",
      maxHeight: "180px",
      objectFit: "contain",
      marginBottom: "20px",
      [theme.breakpoints.only("xs")]: {
        maxWidth: "100%",
      },
    },
    moviegrid: {
      display: "flex",
      alignItems: "center",
      padding: "5%",
      zIndex: 2,
      [theme.breakpoints.only("sm")]: {
        marginBottom: "10px",
        padding: "0",
      },
    },
    placeholder: {
      "&::placeholder": {
        color: "#fff",
      },
    },
    DetailImage: {
      margin: themeDirection("0 0 0 7px", "0 7px 0 0"),
      filter: themePalette("none", "invert(1)"),
    },
    movieTittle: {
      color: themePalette("#FFFFFF", "#3c4251"),
      opacity: 1,
      lineHeight: "3rem",
      alignItems: "center",
      fontFamily: "Roboto",
      fontWeight: 600,
      marginRight: "10px",
      width: "fit-content",
      textTransform: "capitalize",
      [theme.breakpoints.only("xs")]: {
        fontSize: 42,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 36,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 45,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 36,
        lineHeight: "36px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
        lineHeight: "36px",
      },
    },
    playButton: {
      borderRadius: "10px",
      boxshadow: "4px 6px 40px rgba(0,0,0, 0.8)",
      opacity: 1,
      width: "auto",
      minWidth: "150px",
      height: "54px",
      fontWeight: 700,
      fontFamily: "Araboto",
      margin: themeDirection("12px 0px 0px 15px", "12px 15px 0px 0px"),
      color: theme.palette.primary.main,
      [theme.breakpoints.only("xs")]: {
        height: '44px'
      },
    },
    movieSubTitle: {
      color: themePalette("#FFFFFF", "#3c4251"),
      opacity: 1,
      fontSize: "1.3rem",
      alignItems: "center",
      fontFamily: "Plus Jakarta Display",
      fontWeight: 500,
      marginRight: "10px",
      borderRadius: "16px",
      backgroundColor: themePalette("rgba(36,40,48, 1)", "#ffffff"),
      width: "fit-content",
      padding: "6px 20px 6px 20px",
    },
    shortDetails: {
      color: themePalette("#757A8C", "#757A8C"),
      opacity: 1,
      lineHeight: "24px",
      alignItems: "center",
      fontFamily: "Araboto-Normal",
      fontWeight: 500,
      width: "fit-content",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 20,
      },
    },
    divider: {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      background: "#FFFFFF",
      margin: "auto 12px",
    },
    Timing: {
      color: themePalette("#FFFFFF", "#3c4251"),
      fontSize: "1.1em",
      alignItems: "center",
      fontFamily: "Araboto-Normal",
      fontWeight: 500,
      width: "fit-content",
      marginRight: "10px",
      borderRadius: "16px",
      backgroundColor: themePalette("rgba(36,40,48, 1)", "#fffffd"),
      padding: "6px 12px",
    },
    descDetails: {
      color: themePalette("#FFFFFF", "#3c4251"),
      margin: "3% 0 5% 0",
      display: "inline-flex",
    },
    progressContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    ClasssLinearProgress: {
      width: '50%',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
      },
    },
    remainingTimeText: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
      color: theme.palette.text.primary,
      width: "100%"
    },
    colorPrimary: {
      backgroundColor: '#242830',
      height: "7px",
      borderRadius: "4px"
    },
    barColorPrimary: {
      background: 'linear-gradient(90deg, rgba(234,67,93,1) 0%, rgba(104,105,172,1) 100%);',
    },
  };
};
export default withStyles(styles)(withTranslation()(withWidth()(Banner)));
