import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
import moment from "moment";
import { setStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: any;
  deviceList: any;
  deviceID: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ManageYourDeviceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiManageDevice: string = "";
  apiLogoutDevice: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      deviceList: [],
      deviceID: ''
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.manageDeviceApi();

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("Message Recived", message);

      switch (apiRequestCallId) {
        case this.apiManageDevice:
          this.handleManageDeviceResponse(responseJson);
          break;
        case this.apiLogoutDevice:
          this.handleLogoutDeviceResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  manageDeviceApi = () => {

    this.setState({ loading: true })

    const header = {
      token: localStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiManageDevice = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deviceManageEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeDateFormat = (_date: string) => {
    return moment(_date, "YYYY-MM-DD").format("DD/MM/YYYY")
}

  logOutDevice = (deviceId: any) => {

    const header = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
    };
    this.setState({ deviceID: deviceId })
    let formData = new FormData();

    formData.append('device_unique_id', deviceId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLogoutDevice = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deviceLogoutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethodDelete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // API Response
  handleManageDeviceResponse = (json: any) => {
    if (json && json.data) {
      this.setState({
        deviceList: json.data
      })
      this.setState({ loading: false })

    }
    if (json?.errors) {
      this.setState({ loading: false })
      let key = Object.keys(json?.errors[0])[0]
      toast.error(json?.errors[0][key]);
    }
  }

  handleLogoutDeviceResponse = (json: any) => {
    if (json) {
      let device = localStorage.getItem("deviceId")
      toast.success(json?.message)
      if (this.state.deviceID == device) {
        let darkMode = localStorage.getItem("darkMode") || "true"
        let lang = localStorage.getItem("i18nextLng") || "en"
        localStorage.clear()
        setStorageData("deviceId", device)
        localStorage.setItem("darkMode", darkMode)
        localStorage.setItem("i18nextLng", lang)
        this.props.navigation.navigate("EmailAccountLoginBlockWeb")
      } else {
        this.manageDeviceApi();
      }
    }
  }
  isLastDevice(index: number){
    return this.state.deviceList.length  === index + 1
  }
  // Customizable Area End
}
