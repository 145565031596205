import React from "react";

import {
  Grid,
  Typography,
  withStyles,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withTranslation } from "react-i18next";
// Customizable Area End

import PrivacyPolicyController, { Props } from "./PrivacyPolicyController.web";
import { backArrow } from "./assets";

export class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    const { t }: any = this.props;
    return (
      // Customizable Area Start
      <Grid container className={this.props.isSmallScreen !== true && classes.privacyFullScreenPage}>
      <Grid
          container
          className={classes.privacy_us_main_container}
          alignItems="center"
      >
        {!this.props.isSmallScreen &&  <img
                            src={backArrow}
                            alt="back_arrow"
                            data-test-id= "privacy_back_button"
                            onClick={this.privacyBackClick}
                            className={classes.privacyBackImage}
                        />}
          <Typography
              className={classes.privacy_heading}                        
          >
              {t("footer.privacyPolicy")}
          </Typography>
      </Grid>
      <Grid
          item
          xs={12}
          className={classes.privacyUnderLineCSS}
      />
      <Grid
          item
          xs={12}
          className={classes.privacy_us_main_container}
      >
         <Typography className={classes.privacy_font_20} style={{marginTop: 0}}>{t("privacyPolicy.headerTitle")}</Typography>
           <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph1")} </Typography>
           <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph2")} </Typography>

           <Typography className={classes.privacy_font_20}>{t("privacyPolicy.headerTitle2")}</Typography>
           <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph3")} </Typography>
           <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph4")} </Typography>
          <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph4")} </Typography>
          <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph5")} </Typography>
   
          <Typography className={classes.privacy_font_20} >{t("privacyPolicy.headerTitle3")}</Typography>
          <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph3")} </Typography>
           <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph4")} </Typography>
          <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph5")} </Typography>
          <Typography className={classes.privacy_text_14} >{t("privacyPolicy.paraGraph6")} </Typography>
          
      </Grid>
  </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const PrivacyPolicyStyle = (theme: any) => ({
  privacy_us_main_container: {
    [theme.breakpoints.only("xs")]: {
      padding: "15px 20px 5px",
    },
    padding: "30px 30px 10px",
    [theme.breakpoints.only("sm")]: {
      padding: "20px 25px 7px",
    },
  },
  privacyUnderLineCSS: {
    marginTop: 10,
    height: "2px",
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)"
  },
  privacyBackImage: {
    width: 11,
    margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
    cursor: 'pointer',
    height: 20,
    transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
  },
  privacy_font_20: {
    fontWeight: 700,
    fontSize: 20,
    margin: '22px 0px 10px',
    color: "#F6F6F6",
    fontFamily: "Roboto",
    [theme.breakpoints.only("sm")]: {
      fontSize: 19,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    }

  },
  privacy_text_14: {
    fontWeight: 400,
    color: "#F6F6F6",
    fontSize: 14,
    fontFamily: "Roboto",
    marginBottom: '10px',
    lineHeight: '18px',
    [theme.breakpoints.only("sm")]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 13,
    },
  },
  privacy_heading: {
    fontWeight: 700,
    fontFamily: "Roboto",
    fontSize: 24,
    color: "#F6F6F6",
    [theme.breakpoints.only("xs")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 22,
    }
  },
  privacyFullScreenPage: {
    padding: '0px 60px',
    [theme.breakpoints.only("md")]: {
      padding: '0 45px',
    },
    [theme.breakpoints.only("xs")]: {
      padding: '0 20px',
    },
    [theme.breakpoints.only("sm")]: {
      padding: '0 30px',
    }
  }

});

export default withStyles(PrivacyPolicyStyle)(withTranslation()(PrivacyPolicy));
// Customizable Area End
