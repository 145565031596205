import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    Grid,    
} from "@material-ui/core";
import {
   withStyles,
} from "@material-ui/core/styles";
import SessionExp from "../../dashboard/src/SessionExp.web";
import {
    dummy_icon,
    rightTick,
} from "./assets";
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    backgroundImage_div: {
        maxWidth:'3000px',
       width:'100%',
        [theme.breakpoints.only("xs")]: {
            padding: "40px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "65px 50px",
        },
        [theme.breakpoints.only("lg")]: {
            padding: "90px 100px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "100px 120px",
        },
        [theme.breakpoints.only("md")]: {
            padding: "80px",
        },
    },
    search_icon: {
        marginRight: 10,
        [theme.breakpoints.only("lg")]: {
            width: 20,
            height: 20,
        },
        [theme.breakpoints.up("lg")]: {
            width: 20,
            height: 20,
        },
        [theme.breakpoints.only("xs")]: {
            width: 16,
            height: 16,
        },
        [theme.breakpoints.only("md")]: {
            width: 20,
            height: 20,
        },
        [theme.breakpoints.only("sm")]: {
            width: 18,
            height: 18,
        },
    },
    description_text: {
        color: "rgba(129,135,164,1)",
        fontFamily: "Araboto",
        fontWeight: 400,
        fontStyle: "normal",
        letterSpacing: 0,
    },
    font_size_26: {
        fontSize: 26,
        [theme.breakpoints.only("xs")]: {
            fontSize: 18,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 24,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 22,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 26,
        },
    },
    font_size_22: {
        fontSize: 22,
        [theme.breakpoints.only("xs")]: {
            fontSize: 16.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 20,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 18.5,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 22,
        },
    },


    description_Box: {
        marginTop: 60,
        [theme.breakpoints.only("xs")]: {
            marginTop: 35,
            padding: '0px 30px'
        },
        [theme.breakpoints.only("md")]: {
            marginTop: 55,
            padding: '0px 50px'
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: 45,
            padding: '0px 50px'
        },
        [theme.breakpoints.only("lg")]: {
            marginTop: 60,
        },
    },
    divider_css: {
        background: "linear-gradient( 90deg, #6869AC , #EA435D )",
        margin: 'auto',

        [theme.breakpoints.only("xs")]: {
            height: 1,
            margin: '10px 0',

            width: '100%'
        },
        [theme.breakpoints.only("md")]: {
            height: 30,
            width: 1,

        },
        [theme.breakpoints.only("sm")]: {
            height: 28,
            width: 1
        },
        [theme.breakpoints.only("lg")]: {
            height: 35,
            width: 1
        },
        [theme.breakpoints.up("lg")]: {
            height: 35,
            width: 1
        },
    },
    details_description_text: {
        color: "rgba(129,135,164,1)",
        fontFamily: "Araboto",
        fontWeight: 400,
        fontStyle: "normal",
        letterSpacing: 0,
    },
    choose_profile_div: {
        [theme.breakpoints.only("xs")]: {
            margin: '20px 0'
        },
        [theme.breakpoints.only("md")]: {
            margin: '52px 0'
        },
        [theme.breakpoints.only("sm")]: {
            margin: '30px 0'
        },
        [theme.breakpoints.only("lg")]: {
            margin: '60px 0'
        },
        [theme.breakpoints.up("lg")]: {
            margin: '60px 0'
        },
    },
    font_size_36: {
        fontSize: 36,
        [theme.breakpoints.only("xs")]: {
            fontSize: 29,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 32,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 34,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 36,
        },
    },
    font_size_32: {
        fontSize: 32,
        [theme.breakpoints.only("xs")]: {
            fontSize: 25,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 28,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 30,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 32,
        },
    },
    font_size_24: {
        fontSize: 24,
        [theme.breakpoints.only("xs")]: {
            fontSize: 18,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 22,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 21,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 24,
        },
    },
    button_div: {
        display: "flex",
        [theme.breakpoints.only("xs")]: {
            marginTop: 10,
            justifyContent: "flex-start",
        },
        [theme.breakpoints.only("md")]: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        [theme.breakpoints.only("sm")]: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        [theme.breakpoints.only("lg")]: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-end",
            alignItems: "flex-end",
        },
    },


    typo_background: {
        background: `${theme.palette.type == "dark" ? '#212330' : "#b2bfd9"}`,
    },

    description_text_css: {
        color: `rgba(177,182,205,1)`,
        fontFamily: "Araboto",

        fontWeight: 400,
        fontStyle: `normal`,
        letterSpacing: "0px",

        lineHeight: 1,
        marginTop: 10,
        fontSize: 14,
        [theme.breakpoints.only("xs")]: {
            // height: 25,
            fontSize: 11.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 13.5,
            // height: 30
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 12.5,
            // height: 27
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 14,
            // height: 32
        },
    },

    font_size_16: {
        fontSize: 16,
        [theme.breakpoints.only("xs")]: {
            fontSize: 14,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 15.5,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 14.5,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 16,
        },
    },
    font_size_12: {
        fontSize: 12,
        [theme.breakpoints.only("xs")]: {
            fontSize: 10.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 11.5,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 11,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 12,
        },
    },
    may_be_button_css: {
        boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
        backgroundColor: `rgba(234,67,93, 1)`,
        color: `${theme.palette.type == "dark" ? '#FFFFFF' : "#000"}`,
        borderRadius: 10,
        fontWeight: 700,
        opacity: 0.9,

        [theme.breakpoints.only("xs")]: {
            fontSize: 14,
            marginTop: 35,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 17,
            marginTop: 42,
            maxWidth: 280,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 16,
            marginTop: 37,
            maxWidth: 230,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 18,
            marginTop: 45,
            maxWidth: 280,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 18,
            marginTop: 45,
            maxWidth: 280,
        },
        "&:hover": {
            opacity: 1,
            backgroundColor: `rgba(234,67,93, 1)`,
        },
        "&:disabled": {
            opacity: 0.5,
            backgroundColor: `rgba(234,67,93, 1)`,
            color: "#7A7B7F",
        },
    },

    heading: {
        color: theme.palette.primary.main,
        fontFamily: "Araboto",
        fontWeight: 700,
        fontStyle: "normal",
        letterSpacing: 0,
        lineHeight: 1,
        breakWord: "break-all",
        fontSize: 54,

        [theme.breakpoints.only("xs")]: {
            fontSize: 38,

        },
        [theme.breakpoints.only("md")]: {
            fontSize: 50,

        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 45,

        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 54,

        },
    },

    scrollBar: {
        // scrollbarWidth: 'thin',
        scrollbarColor: '#B7B7B7 transparent',
        '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 6,
            background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 90%)',
            minHeight: 24,
            minWidth: 24,
        },
        '&::-webkit-scrollbar-thumb:focus': {
            background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
        },
        '&::-webkit-scrollbar-thumb:active': {
            background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
        },
        '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
        },
    },
    first_letter: {
        color: 'transparent',
        WebkitTextStroke: `1px ${theme.palette.primary.main}`,

        background: `linear-gradient(142deg, transparent 0%,transparent 44%, ${theme.palette.primary.main} 45%, ${theme.palette.primary.main} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    last_letter: {
        color: 'transparent',
        WebkitTextStroke: `1px ${theme.palette.primary.main}`,
        background: `linear-gradient(322deg, transparent 0%,transparent 49%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,

        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    }
});
// Customizable Area End

import ChoosePreferenceController, {
    Props,
} from "./ChoosePreferenceController.web";
import Spinner from "./shared/Spinner.web";
import "./ChoosePreference.css"
import Slider from "react-slick";

export class ChoosePreference extends ChoosePreferenceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            padding: "60px",
            slidesToShow: 6,
            rows: 3,            
            speed: 500,
            variableWidth: true,
            afterChange: () => this.setState({ click: this.state.click + 1 }),
            beforeChange: () => this.setState({ click: this.state.click - 1 }),
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                }

            ]
        };

        // Customizable Area Start
        return (
            <Grid container style={{
                justifyContent:'center'
            }}>
                {this.state.logout && <SessionExp deleteModal={this.state.logout} onCloseModal={this.handleLogout} />}
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Grid
                    item
                    xs={12}
                    className={classes.backgroundImage_div}
               >

                    <Grid >
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {localStorage.getItem("i18nextLng") === 'ar' ? <Typography className={classes.heading} color="primary">
                                        اختر ما تفضله
                                    </Typography>
                                        : <Typography className={classes.heading}>
                                            <span className={classes.first_letter}>C</span>hoose Your Preferenc<span className={classes.last_letter} >e</span>
                                        </Typography>}

                                </Grid>

                                <Grid item xs={12} className={classes.description_Box}>
                                    {
                                        this.state.choosePreferenceDetails.length > 0 &&
                                        <Slider {...settings} data-test-id="settings" key="settings">
                                            {
                                                this.state.choosePreferenceDetails.map((_item: any, i:any) => {
                                                    return (
                                                        <div key={_item.id} >
                                                            <Grid item xs={12} key={_item.id} data-test-id='handle_logout'
                                                            style={{ display: 'flex', width: '80%', height: '80%', position: 'relative', justifyContent: 'center', alignItems: "center", borderRadius: 20, cursor: 'pointer' }} 
                                                            onClick={this.handleToggle(_item.id)
                                                            }>

                                                                {this.state.selectedUserID.includes(parseInt(_item.id)) && <img
                                                                    style={{ width: '40px', height: '40px', position: 'absolute', top: 0, left: 0, zIndex: 1111 }}
                                                                    src={rightTick}
                                                                />}
                                                                <img
                                                                    className={this.state.selectedUserID.includes(parseInt(_item.id)) ? "slick-img-selected" : "slick-img"}
                                                                    style={{ width: '100%', height: '100%', borderRadius: 20 }}
                                                                    src={_item?.attributes?.image == null ? dummy_icon : _item?.attributes?.image}
                                                                />      <Grid item xs={12} style={{ width: '100%', position: 'absolute', left: 0, height: '20px', bottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                                    <Typography
                                                                        className={`${classes.typo_background} single_line`}
                                                                        style={{

                                                                            fontSize: 14,
                                                                            fontFamily: "Araboto",
                                                                            padding: '0px 12px',
                                                                            fontWeight: 700,
                                                                            wordBreak: 'break-all',
                                                                            borderRadius: '10px'

                                                                        }}
                                                                        color="primary"
                                                                    >{_item?.attributes?.name}</Typography>


                                                                </Grid>
                                                            </Grid>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    }


                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <Button fullWidth className={classes.may_be_button_css}
                            data-test-id="applyPreference"
                                disabled={this.state.selectedUserID == "" || this.state.selectedUserID.length == 0}
                                onClick={this.applySelectedPlan}
                            >
                                {localStorage.getItem("i18nextLng") === 'ar' ? "إرسال" : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(themeCreatStyle)(ChoosePreference);
// Customizable Area End
