import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    Box,
    InputAdornment,
    InputBase
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withTranslation } from "react-i18next";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";

// Customizable Area End
import PlanSubscriptionController from "./PlanSubscriptionController.web";
export class PlanSubscription extends PlanSubscriptionController {
    render() {
        const { classes, t } = this.props;
        return (
            <Box className={classes.subscription_main_div}>
                <Grid container justifyContent="center">
                    {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Typography variant="body1" component={"div"} className={classes.subscription_details_outer_div}>
                            <Grid container className={classes.subscription_details_div}>
                                <Grid className={classes.back_arrow_button_css}>
                                    <ArrowBackIosIcon
                                        data-test-id="back_btn"
                                        className={classes.ArrowIcon}
                                        style={{ cursor: "pointer" }}
                                        onClick={(event: any) => this.onBackClick(event)} />
                                    <Typography component={"span"} className={classes.back_link}>
                                        {t("plan_subscription.Back")}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.subscription_content_div}>
                                    {this.state.currentPlansDetails?.attributes?.price !== 0 &&
                                        < Grid className={classes.coupounInputBase}
                                        >
                                            <InputBase
                                                placeholder={t("plan_subscription.CC")}
                                                className={classes.inputbase_css}
                                                value={this.state.coupon}
                                                data-test-id="applied_coupon"
                                                onChange={this.handleChangeCoupon}
                                                disabled={this.state.appliedCoupon}
                                                endAdornment={this.state.appliedCoupon ?
                                                    <InputAdornment position="end">
                                                        <Button
                                                            className={classes.remove_btn}
                                                            onClick={this.handleRemove}
                                                        >
                                                            {t("plan_subscription.Remove_btn")}
                                                        </Button>
                                                    </InputAdornment> :
                                                    <InputAdornment position="end">
                                                        <Button
                                                            className={classes.apply_btn}
                                                            onClick={this.applySubscriptionCoupon}
                                                        >
                                                            {t("plan_subscription.Apply")}
                                                        </Button>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                    }
                                    {this.state.currentPlansDetails.id > 0 &&
                                        <>
                                            <Grid className={classes.plan_details}>
                                            {this.checkLanguage() ? <span>{this.state.currentPlansDetails.attributes?.ar_sub_mess}</span>
                                                : <span>
                                                    {t("plan_subscription.You_choosen")}
                                                    {this.state.currentPlansDetails.attributes.price === 0 ? t("plan_subscription.Free") : ""}
                                                    {this.state.currentPlansDetails.attributes.name}
                                                    {t("plan_subscription.Plan_for")}
                                                    {this.state.currentPlansDetails.attributes.duration}
                                                </span>}
                                            </Grid>
                                            <Grid className={classes.amount_section}>
                                                <span>{t("plan_subscription.Amt")}</span>
                                            </Grid>
                                            <Grid container className={classes.current_plan_amount} justifyContent="space-between">
                                            {this.checkLanguage() ? <span>{this.state.currentPlansDetails.attributes?.ar_name_with_dura}</span>
                                                :  <span>
                                                    {this.state.currentPlansDetails.attributes.name}
                                                    {t("plan_subscription.Plan_for")}
                                                    {this.state.currentPlansDetails.attributes.duration}
                                                </span>}
                                                <span>${this.state.currentPlansDetails.attributes.price}</span>
                                            </Grid>
                                            {this.state.discount > 0 &&
                                                <Grid container className={classes.amount_section} justifyContent="space-between">
                                                    <span>{t("plan_subscription.CD")}</span>
                                                    <span className={classes.coupon_amount_span}>
                                                        <span>-</span>
                                                        <span>${this.state.discount}</span>
                                                    </span>
                                                </Grid>
                                            }
                                            <Grid className={classes.total_divider} />
                                            <Grid container className={classes.amount_section} justifyContent="space-between">
                                                <span>{t("plan_subscription.TP")}</span>
                                                <span>${this.state.totalPayAmount}</span>
                                            </Grid>
                                        </>
                                    }
                                    <Grid >
                                        <Button
                                        variant="contained"
                                            data-test-id="proceed_btn"
                                            fullWidth
                                            className={classes.choose_button_css}
                                            style={{ textTransform: "uppercase" }}
                                            onClick={(event: any) => this.applySelectedSubsPlan(event)}
                                        >
                                            {t("plan_subscription.Proceed_btn")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid >
            </Box>
        )
    }
}
export const themeCreatStyle = (theme: any) => {
    return {
        subscription_main_div: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            [theme.breakpoints.only("xs")]: {
                margin: "0px 10px"
            },
            [theme.breakpoints.only("sm")]: {
                margin: "0px 10px"
            },
            [theme.breakpoints.up("md")]: {
                margin: "5px"
            },
        },
        subscription_details_outer_div: {
            height: "100%",
            padding: 1,
            borderRadius: 5,
            background:
                "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        },
        subscription_details_div: {
            background: "#222222",
            height: "100%",
            width: "100%",
            borderRadius:'5px',
            [theme.breakpoints.only("xs")]: {
                padding: "20px"                
            },
            [theme.breakpoints.only("sm")]: {
                padding: "23px"
            },
            [theme.breakpoints.up("md")]: {
                padding: "27px"
            },
            [theme.breakpoints.only("lg")]: {
                padding: "30px"
            },
        },
        choose_button_css: {
            color: theme.palette.primary.main,
            boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
            fontWeight: 700,
            backgroundColor: `rgba(234,67,93, 1)`,
            opacity: 0.9,
            borderRadius: 10,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
                marginTop: 35,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
                marginTop: 37,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 17,
                marginTop: 42,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 18,
                marginTop: 45,
            },
            "&:hover": {
                opacity: 1,
                backgroundColor: `rgba(234,67,93, 1)`,
            },
        },
        subscription_content_div: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                margin: "0px 5px",
            },
            [theme.breakpoints.only("sm")]: {
                margin: "0px 5px",
            },
            [theme.breakpoints.up("md")]: {
                margin: "0px 15px",
            },
        },
        back_link: {
            lineHeight: 1,
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        ArrowIcon: {
            transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        plan_details: {
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            margin: "40px 0px 20px 0px",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        amount_section: {
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            margin: "5px 0px",
            fontWeight: 700,
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        current_plan_amount: {
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            margin: "5px 0px",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        discount_amount: {
            color: "#138457"
        },
        apply_btn: {
            color: "rgba(234,67,93, 1)",
            fontWeight: 600,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        coupounInputBase: {
            display: "flex",
            justifyContent: "center",
            padding: 1,
            borderRadius: 8,
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        },
        remove_btn: {
            color: "#C8536B",
            fontWeight: 600,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        coupon_amount_span: {
            flexDirection: (theme.direction === "rtl" ? 'row-reverse' : 'row') as 'row' | 'row-reverse',
            display: "flex"
        },
        total_divider: {
            height: "1px",
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
            margin: "20px 0px",
        },
        back_arrow_button_css: {
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            display: "flex",
            justifyContent: "left",
            marginBottom: "24px",
            alignItems: "center",
            [theme.breakpoints.only("xs")]: {
                fontSize: 12,
                marginBottom: "16px",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
                marginBottom: "20px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
                marginBottom: "24px",
            },
        },
        inputbase_css: {
            backgroundColor: "#07070E",
            borderRadius: 8,
            width: "100%",
            padding: "15px",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        }
    }
};
export default withStyles(themeCreatStyle)(withTranslation()(PlanSubscription));
// Customizable Area End
