import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utility";
import moment from "moment";

// Customizable Area Start
// import { URLSearchParams } from "url";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  profileId: any,
  profileType: any,
  onChangeTab: any,
  t?: any,
  i18n:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  token2: string;
  searchText: string;
  searchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  editProfile: boolean;
  openPhoneOtpBox: boolean;
  openEmailOtpBox: boolean;
  otp: any[];
  emailOTP: any[];
  phoneOTP: any[];
  minutes: any;
  seconds: any;
  editProfilePic: any;
  imagePreview: any;
  editMemberPic: any;
  memberImagePreview: any;
  selectDate: any;
  editName: string;
  editEmail: string;
  profileInfo: any;
  memberName: string;
  editPhoneNumber: string;
  disableEmailInput: boolean;
  disablePhoneInput: boolean;
  phoneOTPId: number;
  emailOTPId: number;
  checkExistingEmail: boolean;
  checkExistingPhone: boolean;
  onEmailChange: boolean;
  openEditDetailsBox: boolean;
  AccountInfoBox: boolean;
  validationSucess: boolean;
  openNewEmailOTPBox: boolean;
  newEmailVerified: boolean;
  newPhoneVerified: boolean;
  openEditPhoneDetailsBox: boolean;
  openNewPhoneOTPBox: boolean;
  confirmButton: boolean,
  startTimer: number;
  memberPic: string;
  saveMemberChanges: boolean;
  deleteProfile: boolean;
  showErrMsg: string;
  showNewEmailErr: string;
  showPhoneErrMsg: string;
  showNewPhoneErr: string;
  openList: any;
  selctCountryCode: string;
  selectedCountry: any;
  showCountryList: any[];
  getUserData: any;
  loading: boolean;
  newEmail: boolean;
  newPhone: boolean;
  editNameError: string;
  memberNameError: boolean;
  isSaveButtonDisabled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailVerificationContoller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  editProfileAPICallId: any;
  timer: any;
  myInterval: any;
  inputRef: React.RefObject<HTMLInputElement> = React.createRef();
  editEmailOTPAPICallId: any = "";
  editEmailVerifyOTPAPICallId: any = "";
  getProfileInfoAPICallId: any = "";
  sendPhoneOtpAPICallId: any = "";
  verifyPhoneOTPAPICallId: any = "";
  updateEmailAPICallId: any = "";
  verifyNewEmailOTPAPICallId: any = "";
  updatedEmailChangeAPICallId: any = "";
  updatePhoneNumberAPICallId: any = "";
  verifyNewPhoneOTPAPICallId: any = "";
  updatedPhoneNumberAPICallId: any = "";
  getMemberAPICallId: any = "";
  editMemberProfileAPICallId: any = "";
  interval: any = "";
  getCountryListAPICallId: any = "";
  getUserProfileApiCallId: any = "";
  sendOldEmailOTPAPICallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      token2: "",
      searchText: "",
      searchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      editProfile: false,
      openPhoneOtpBox: false,
      openEmailOtpBox: false,
      otp: new Array(4).fill(''),
      minutes: 1,
      seconds: 0,
      editProfilePic: '',
      imagePreview: '',
      selectDate: '',
      editName: '',
      emailOTP: new Array(4).fill(''),
      phoneOTP: new Array(4).fill(''),
      editEmail: '',
      profileInfo: {},
      editPhoneNumber: '',
      disableEmailInput: true,
      disablePhoneInput: true,
      phoneOTPId: 0,
      emailOTPId: 0,
      checkExistingEmail: false,
      checkExistingPhone: false,
      onEmailChange: false,
      openEditDetailsBox: false,
      AccountInfoBox: true,
      validationSucess: false,
      openNewEmailOTPBox: false,
      newEmailVerified: false,
      newPhoneVerified: false,
      openEditPhoneDetailsBox: false,
      openNewPhoneOTPBox: false,
      confirmButton: false,
      startTimer: 59,
      memberName: '',
      memberPic: '',
      editMemberPic: '',
      memberImagePreview: '',
      saveMemberChanges: false,
      deleteProfile: false,
      showErrMsg: '',
      showNewEmailErr: '',
      showPhoneErrMsg: '',
      showNewPhoneErr: '',
      openList: null,
      selctCountryCode: "+966",
      selectedCountry: 'SA',
      showCountryList: [],
      getUserData: [],
      loading: false,
      newEmail: true,
      newPhone: true,
      editNameError: "",
      memberNameError: false,
      isSaveButtonDisabled: true,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (this.props.profileType === "member") {
      this.getMemberInfo()

    } else {
      this.getUserAccountInfo()
    }

    this.setState({
      token2: localStorage.getItem('Token')!
    })

    this.getCountryCodeList();
    // Customizable Area End
  }

  async componentWillUnmount(){
    let currentLanguage = this.props.i18n.language
    moment.locale(currentLanguage)
  }
  componentDidUpdate() {
    if (this.state.startTimer === 0) {
      clearInterval(this.interval);
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      switch (apiRequestCallId) {
        case this.getUserProfileApiCallId:
          this.handleSelectedUserResponse(apiResponse);
          break;
        case this.editProfileAPICallId:
          this.handleEditProfileResponse(apiResponse);
          break;
        case this.editMemberProfileAPICallId:
          this.handleMemberEditProfileResponse(apiResponse);
          break;
        case this.sendOldEmailOTPAPICallId:
          this.handleSendOldEmailOtpResponse(apiResponse);
          break;
        case this.getProfileInfoAPICallId:
          this.handleGetUserProfileResponse(apiResponse);
          break;
        case this.getCountryListAPICallId:
          this.handleCountryCodeListResponse(apiResponse);
          break;
        case this.getMemberAPICallId:
          this.handleMemberInfoResponse(apiResponse);
          break;
        case this.sendPhoneOtpAPICallId:
          this.handleSendOldPhoneOtpResponse(apiResponse);
          break;
        case this.verifyPhoneOTPAPICallId:
          this.handleVerifyOldPhoneOtpResponse(apiResponse);
          break;
        case this.editEmailVerifyOTPAPICallId:
          this.handleVerifyOldEmailOtpResponse(apiResponse);
          break;
        case this.updateEmailAPICallId:
          this.handleUpdateEmailResponse(apiResponse);
          break;
        case this.updatePhoneNumberAPICallId:
          this.handleUpdatePhoneResponse(apiResponse);
          break;
        case this.verifyNewEmailOTPAPICallId:
          this.handleVerifyNewEmailResponse(apiResponse);
          break;
        case this.verifyNewPhoneOTPAPICallId:
          this.handleVerifyNewPhoneResponse(apiResponse);
          break;
        case this.updatedEmailChangeAPICallId:
          this.handleUpdatedEmailChangesResponse(apiResponse);
          break;
        case this.updatedPhoneNumberAPICallId:
          this.handleUpdatedPhoneChangesResponse(apiResponse)
      }
    }
    // Customizable Area End
  }

  handleMemberSave = () => {
    this.setState({
      saveMemberChanges: !this.state.saveMemberChanges
    })
  }

  handleDelteProfile = () => {
    this.setState({
      deleteProfile: !this.state.deleteProfile
    })
  }

  handleReadOnly=()=>{
    if(this.state.openEditDetailsBox === false && this.state.openEditPhoneDetailsBox === false){
      return false
    } else{
      return true
    }
  }

  handleReadOnlySuccess=()=>{
    if(this.state.validationSucess === true){return false }
    else {return true}
  }

  onEditProfile = () => {
    if (this.state.openEditDetailsBox === false && this.state.openEditPhoneDetailsBox === false) {
      this.setState({
        editProfile: true,
      })
    }
  };

  handleEmailChange = (e: any) => {
    this.setState({
      editEmail: e.target.value,
      onEmailChange: true
    })
  }

  handlePhoneNumberChange = (e: any) => {
    this.setState({
      editPhoneNumber: e.target.value.trim(),
      onEmailChange: true
    })
  }

  onProfileChange = (e: any) => {
    e.preventDefault();
    const reader: any = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        editProfilePic: file,
        imagePreview: reader.result
      });
    }
    reader.readAsDataURL(file);
  };

  selectCountryCode = (item: any, cName: any) => {
    this.setState({
      selctCountryCode: '+' + item,
    })
  }

  onMemberProfileChange = (e: any) => {
    e.preventDefault();
    const reader: any = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        editMemberPic: file,
        isSaveButtonDisabled: false,
        memberImagePreview: reader.result
      });
    }
    reader.readAsDataURL(file);
  };

  handleEditName = (e: any) => {

    if (e.target.value.trim() !== "") {
        this.setState({ editNameError: "" });
    }
    this.setState({ editName: e.target.value });
    }

  handleEditMemberName = (e: any) => {
    this.setState({
      memberName: e.target.value,
      memberNameError: false,
      isSaveButtonDisabled: false,
    })
  }

  onClickEditIcon = () => {
    this.inputRef.current?.click()
  }

  onChangeDate = (date: any) => {
    this.setState({ selectDate: moment(date).format("DD/MM/YYYY"), editProfile: true })
  }

  onClosePopOver = () => {
    this.setState({
      openList: null,
    });
  }

  onClosedeleteDialog = () => {
    this.setState({ deleteProfile: false })
  }

  onSuccessFullyDeleteDialog = () => {
    this.setState({ deleteProfile: false },
      () => this.props.onChangeTab())
  }

  handleEmailOTPChange = (element: any, index: any) => {
    if (isNaN(element.value) || element.value == " ") return false;
    this.setState({
      emailOTP: [...this.state.emailOTP.map((d, idx) => (idx === index) ?
        element.value : d)]
    });
    if (element.nextSibling && element.value.length !== 0) {
      element.nextSibling.focus()
    }
  };

  handleEmailKeyDown = (event: any, index: any) => {
    let array = [...this.state.emailOTP]
    if (event.key === 'Backspace') {
      if (event.target.previousSibling && array[index].length == 0) {
        event.preventDefault()
        event.target.previousSibling.focus()
      }
    }
  }

  handlePhoneOTPChange = (element: any, index: any) => {
    if (isNaN(element.value) || element.value == " ") return false;
    this.setState({
      phoneOTP: [...this.state.phoneOTP.map((d, idx) => (idx === index) ?
        element.value : d)]
    });

    if (element.nextSibling && element.value.length !== 0) {
      element.nextSibling.focus()
    }
  };

  handlePhoneKeyDown = (event: any, index: any) => {
    let array = [...this.state.phoneOTP]
    if (event.key === 'Backspace') {
      if (event.target.previousSibling && array[index].length == 0) {
        event.preventDefault()
        event.target.previousSibling.focus()
      }
    }
  }

  closeEditEmailBox = () => {
    this.setState({
      openEditDetailsBox: false,
      AccountInfoBox: true,
      newEmail: true,
      editEmail: this.state.getUserData?.attributes?.email,
      validationSucess: false,
      openEmailOtpBox: false,
      openNewEmailOTPBox: false
    })
    clearInterval(this.interval)
  }

  closeEditPhoneBox = () => {
    this.setState({
      openEditPhoneDetailsBox: false,
      AccountInfoBox: true, newPhone: true,
      editPhoneNumber: this.state.getUserData?.attributes?.full_phone_number,
      openPhoneOtpBox: false,
      openNewPhoneOTPBox: false,
      validationSucess: false
    })
    clearInterval(this.interval)
  }

  onDateChange = (date: any) => {
    this.setState({ selectDate: moment(date).format("YYYY-MM-DD"), editProfile: true })
  }

  handleCountryCodeChange = (json: any) => {
    this.setState({ selctCountryCode: json?.country_code },
      () => { this.setState({ openList: null }) })
  }

  resendEmailOTP = (type: any) => {
    this.sendEmailOldOtp(type)
    this.setState({ emailOTP: new Array(4).fill(''), showErrMsg:""})
    toast.success(this.props.t("loginScreen.OHBR"))
    this.interval = setInterval(() => {
      this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 }))
    }, 1000);
    if (this.state.startTimer === 0) {
      clearInterval(this.interval)
    }
  }

  disableFutureDt = (current: any) => {
    let today = moment().subtract(18, 'years');
    return current.isBefore(today);
  };

  resendNewOTP = () => {
    this.setState({ emailOTP: new Array(4).fill(''), showNewEmailErr:""})
    toast.success(this.props.t("loginScreen.OHBR"))
    this.updateEmail()
  }

  resendNewPhoneOTP = () => {
    this.setState({ phoneOTP: new Array(4).fill(''), showNewPhoneErr:"" })
    toast.success(this.props.t("loginScreen.OHBR"))
    this.updatePhoneNumber()
  }

  resendPhoneOTP = (type: any) => {
    toast.success(this.props.t("loginScreen.OHBR"))
    this.sendPhoneOTP(type)
    this.setState({ phoneOTP: new Array(4).fill(''), showPhoneErrMsg:"" })
    this.interval = setInterval(() => {
      this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 }))
    }, 1000);
    if (this.state.startTimer === 0) {
      clearInterval(this.interval)
    }
  }

  //get admin changes 
  getUserProfile = () => {
    this.getUserProfileApiCallId = sendAPIRequest(
      configJSON.getUserProfileEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  onBlurUserName = () =>{
    let {editName} = this.state
    if(editName === null || editName.trim() === ""){
      this.setState({ editNameError: this.props.t("profile.errorNameReqMsg")});
        return;
    }
  }

  onBlurMemberName = () =>{
    let {memberName} = this.state
    if(memberName === null || memberName.trim() === ""){
      this.setState({ memberNameError: this.props.t("profile.errorNameReqMsg")});
        return;
    }
  }

  // Edit Profile change API
  handleSaveUserChanges = () => {
    let formdata = new FormData();
    if (this.state.editProfilePic !== undefined && this.state.editProfilePic !== '' && this.state.editProfilePic?.name) {
      formdata.append("account[profile_picture]", this.state.editProfilePic);
    }
    formdata.append("account[date_of_birth]", moment(this.state.selectDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
   
    if(this.state.editName === undefined ||  this.state.editName.trim() === "" || this.state.editName === null){
      this.setState({ editNameError: this.props.t("profile.errorNameReqMsg")});
        return;
    }

    formdata.append("account[full_name]", this.state.editName);
    this.editProfileAPICallId = sendAPIRequest(
      configJSON.editProfileEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Save Member Changes
  saveMemberChanges = () => {
    if(this.state.memberName.trim() === ""){
      this.setState(
        {
          memberNameError : true,
          isSaveButtonDisabled : true
        }
        )
      return false
    }
    this.setState({ loading: true })
    let formdata = new FormData();
    if (this.state.editMemberPic !== undefined && this.state.editMemberPic !== '' && this.state.editMemberPic?.name) {
      formdata.append("profile[photo]", this.state.editMemberPic);
    }
    formdata.append("profile[id]", this.props.profileId);
    formdata.append("profile[name]", this.state.memberName);

    this.editMemberProfileAPICallId = sendAPIRequest(
      configJSON.editMemberProfileEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // get account info API
  getUserAccountInfo = () => {
    this.getProfileInfoAPICallId = sendAPIRequest(
      `${configJSON.getAccountInfoAPIEndPoints}${this.props.profileId}`,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Get Country List
  getCountryCodeList = () => {
    this.getCountryListAPICallId = sendAPIRequest(
      configJSON.getCountryCodeEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  //Get Member Profiles
  getMemberInfo = () => {
    this.getMemberAPICallId = sendAPIRequest(
      `${configJSON.getMemberInfo}${this.props.profileId}`,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  //API For Email Verification

  // Send OTP to old email address
  sendEmailOldOtp = (type: any) => {
    this.setState({
      openEmailOtpBox: true,
      openEditDetailsBox: true,
      AccountInfoBox: false,
      // editProfile: false,
      emailOTP: new Array(4).fill('')
    })

    let formdata = new FormData();
    formdata.append("email", this.state.editEmail);
    formdata.append("otp_type", "email");
    formdata.append("check_existing", `${this.state.newEmail}`);

    this.sendOldEmailOTPAPICallId = sendAPIRequest(
      configJSON.sendEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Verify otp sent to old email address
  varifyOldEmailOTP = () => {

    let formdata = new FormData();
    formdata.append("otp_id", this.state.emailOTPId.toString());
    formdata.append("otp_code", this.state.emailOTP.join(''));
    formdata.append("otp_type", "email");

    this.editEmailVerifyOTPAPICallId = sendAPIRequest(
      configJSON.verifyEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Update old Email API

  updateEmail = () => {
    if (!this.emailAndMobileValidation('email'))
      return;
    let formdata = new FormData();
    formdata.append("email", this.state.editEmail);
    formdata.append("otp_type", "email");
    formdata.append("check_existinnumberRegg", this.state.checkExistingEmail.toString());

    this.updateEmailAPICallId = sendAPIRequest(
      configJSON.sendEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Verify New Email Address

  varifyNewEmailOTP = () => {
    let formdata = new FormData();
    formdata.append("otp_id", this.state.emailOTPId.toString());
    formdata.append("otp_code", this.state.emailOTP.join(''));
    formdata.append("otp_type", "email");

    this.verifyNewEmailOTPAPICallId = sendAPIRequest(
      configJSON.verifyEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Get Updated Email

  getUpdatedEmail = () => {
    this.setState({
      AccountInfoBox: true,
      openEditDetailsBox: false,
      newEmailVerified: false
    })

    let formdata = new FormData();
    formdata.append("email", this.state.editEmail);

    this.updatedEmailChangeAPICallId = sendAPIRequest(
      configJSON.updateEmailAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Email Validations
  emailAndMobileValidation = (type: any) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let regMobile = /^[0-9]*$/;
    let emailvalidation = this.props.t("loginScreen.PECEA");
    let mobilevalidation = this.props.t("loginScreen.PECMN");
    if (!this.state.editEmail || !this.state.editPhoneNumber) {
      toast.error(type == 'email' ? emailvalidation : mobilevalidation);
      return false;
    } else if (type == 'email' && !reg.test(this.state.editEmail)) {
      toast.error(emailvalidation);
      return false;
    } else if (type == 'phone' && (!regMobile.test(this.state.editPhoneNumber) || this.state.editPhoneNumber.length < 7 || this.state.editPhoneNumber.length > 15)) {
      toast.error(mobilevalidation);
      return false;
    } else {
      return true;
    }
  }

  // API For Phone Verification
  // Edit Phone OTP
  sendPhoneOTP = (type: any) => {
    let phone = this.state.editPhoneNumber.trim()
    this.setState({
      openEditPhoneDetailsBox: true,
      openPhoneOtpBox: true,
      AccountInfoBox: false,
      editProfile: false,
      phoneOTP: new Array(4).fill('')
    })
    let formdata = new FormData();
    formdata.append("full_phone_number", phone.toString());
    formdata.append("otp_type", "phone");
    formdata.append("check_existing", `${this.state.newPhone}`);

    this.sendPhoneOtpAPICallId = sendAPIRequest(
      configJSON.sendEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Verify phone OTP
  verifyPhoneOTP = () => {
    let formdata = new FormData();
    formdata.append("otp_id", this.state.phoneOTPId.toString());
    formdata.append("otp_code", this.state.phoneOTP.join(''));
    formdata.append("otp_type", "phone");

    this.verifyPhoneOTPAPICallId = sendAPIRequest(
      configJSON.verifyEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Update Phone Number API
  updatePhoneNumber = () => {
    if (!this.emailAndMobileValidation('phone'))
      return;
    let phone = this.state.editPhoneNumber.trim()

    let formdata = new FormData();
    formdata.append("full_phone_number", this.state.selctCountryCode + phone.toString());
    formdata.append("otp_type", "phone");
    formdata.append("check_existing", this.state.checkExistingPhone.toString());

    this.updatePhoneNumberAPICallId = sendAPIRequest(
      configJSON.sendEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // Verify New Phone Number
  veryNewPhoneOTP = () => {

    let formdata = new FormData();
    formdata.append("otp_id", this.state.phoneOTPId.toString());
    formdata.append("otp_code", this.state.phoneOTP.join(''));
    formdata.append("otp_type", "phone");

    this.verifyNewPhoneOTPAPICallId = sendAPIRequest(
      configJSON.verifyEmailOTPAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // get updated phone number
  getUpdatedPhoneNumber = () => {

    this.setState({
      AccountInfoBox: true,
      openEditPhoneDetailsBox: false,
    })

    let formdata = new FormData();
    formdata.append("full_phone_number", this.state.selctCountryCode + this.state.editPhoneNumber.toString());

    this.updatedPhoneNumberAPICallId = sendAPIRequest(
      configJSON.updateEmailAPIEndPoints,
      {
        method: configJSON.postMethod, body: formdata,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  // API Responses
  handleSelectedUserResponse = (json: any) => {
    if (json != null) {
      if (!json.errors) {
        localStorage.setItem('selected_user_data', JSON.stringify(json?.data[0]?.attributes));
        const eventNew = new StorageEvent('storage')
        window.dispatchEvent(eventNew)
        this.setState({
          editProfile: false
        })
        this.props.onChangeTab()
        this.setState({ loading: false })
      } else {
        this.setState({ loading: false })
        let key = Object.keys(json?.errors[0])[0]
        json?.errors == "Not Authorized, Token Expired" ? toast.error(json?.errors[0][key]) : toast.error(json?.errors);
      }
    }
  }

  handleEditProfileResponse = (json: any) => {
    if (json?.errors) {
      toast.error(this.props.t("loginScreen.stww"))
      this.setState({ loading: false })
    } else {
      if (json) {
        this.getUserProfile()
        toast.success(this.props.t("loginScreen.ES"))
      }
    }
  }

  handleMemberEditProfileResponse = (json: any) => {
    if (json?.errors) {
      this.setState({ loading: false })
      toast.error(this.props.t("loginScreen.stww"))
    } else {
      if (json) {
        if (localStorage.getItem("selectedUser") == json?.data?.id) {
          localStorage.setItem('selected_user_data', JSON.stringify(json?.data?.attributes));
          const eventNew = new StorageEvent('storage')
          window.dispatchEvent(eventNew)
        }
        toast.success(this.props.t("loginScreen.ES"))
        this.props.onChangeTab()
        this.setState({ loading: false })
      }
    }
  }

  handleSendOldEmailOtpResponse = (json: any) => {
    if (json?.errors) {
      console.log("error", json?.errors)
    } else {
      if (json) {
        this.setState({
          emailOTPId: json?.otp_id,
          editProfile: false,
        });
        this.setState({ startTimer: 59 }, () => {
          this.interval = setInterval(
            () => this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 })),
            1000
          );
        })
        if (this.state.startTimer === 0) {
          clearInterval(this.interval)
        }
      }
    }
  }

  handleGetUserProfileResponse = (json: any) => {
    if (json?.errors) {
      console.log(json?.errors)
    } else {
      this.setState({
        getUserData: json?.data,
        profileInfo: json?.data?.attributes,
        editName: `${json?.data?.attributes?.first_name} ${json?.data?.attributes?.last_name}`,
        editEmail: json?.data?.attributes?.email,
        editPhoneNumber: json?.data?.attributes?.full_phone_number,
        imagePreview: json?.data?.attributes?.profile_picture,
        selectDate: moment(json?.data?.attributes?.date_of_birth, "DD/MM/YYYY").format("DD/MM/YYYY"),
      })
    }
  }

  handleCountryCodeListResponse = (json: any) => {
    if (json?.errors) {
      // console.log('error for country country List API', json?.errors)
    } else {
      console.log('Response for country country List API', json);
      if (json) {
        this.setState({
          showCountryList: json?.country_codes
        })
      }
    }
  }

  handleMemberInfoResponse = (json: any) => {
    if (json?.errors) {
      console.log(json?.errors)
    } else {
      this.setState({
        memberName: json?.data?.attributes?.name,
        memberImagePreview: json?.data?.attributes?.photo,
      })
    }
  }

  handleSendOldPhoneOtpResponse = (json: any) => {
    if (json?.errors) {
      console.log(json?.error)
    } else {
      this.setState({
        phoneOTPId: json?.otp_id,
      });
      this.setState({ startTimer: 59 }, () => {
        this.interval = setInterval(
          () => this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 })),
          1000
        );
      })
      if (this.state.startTimer === 0) {
        clearInterval(this.interval)
      }
    }
  }

  handleVerifyOldPhoneOtpResponse = (json: any) => {
    if (json?.errors) {
      this.setState({
        showPhoneErrMsg: json?.errors[0]?.pin ? json?.errors[0]?.pin : json?.errors[0]?.otp
      })
    } else {
      if (json) {
        this.setState({
          validationSucess: true,
          phoneOTP: new Array(4).fill(''),
          openPhoneOtpBox: !this.state.openPhoneOtpBox,
          editPhoneNumber: '',
          newPhone: false
        })
      }
      if (json) {
        clearInterval(this.interval);
      }
    }
  }

  handleVerifyOldEmailOtpResponse = (json: any) => {
    if (json?.errors) {
      this.setState({
        showErrMsg: json?.errors[0]?.otp
      })
    } else {
      if (json) {
        this.setState({
          validationSucess: true,
          emailOTP: new Array(4).fill(''),
          openEmailOtpBox: !this.state.openEmailOtpBox,
          editEmail: "",
          newEmail: false
        })
      }
      if (json) {
        clearInterval(this.interval);
      }
    }
  }

  handleUpdateEmailResponse = (json: any) => {
    if (json?.errors) {
      toast.error(json?.errors[0]?.otp)
    } else {
      if (json) {
        this.setState({
          emailOTPId: json?.otp_id,
          });
        this.setState({ startTimer: 59 }, () => {
          this.interval = setInterval(
            () => this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 })),
            1000
          );
        })
        this.setState({
          openNewEmailOTPBox: true,
          validationSucess: false,
        })
      }
    }
  }

  handleUpdatePhoneResponse = (json: any) => {
    if (json?.errors) {
      this.setState({
        validationSucess: true,
      })
      toast.error(this.props.t("loginScreen.IOUPN"))
    } else {
      if (json) {
        this.setState({
          openNewPhoneOTPBox: true,
          validationSucess: false,
          phoneOTPId: json?.otp_id
        })
        this.setState({ startTimer: 59 }, () => {
          this.interval = setInterval(
            () => this.setState((prevState) => ({ startTimer: prevState.startTimer - 1 })),
            1000
          );
        })
      }
    }
  }

  handleVerifyNewEmailResponse = (json: any) => {
    if (json?.errors) {
      this.setState({
        showNewEmailErr: json?.errors[0]?.otp
      })
    } else {
      if (json) {
        this.setState({
          newEmailVerified: true,
          openNewEmailOTPBox: false
        })
      }
    }
  }

  handleVerifyNewPhoneResponse = (json: any) => {
    if (json?.errors) {
      this.setState({
        showNewPhoneErr: json?.errors[0]?.pin ? json?.errors[0]?.pin : json?.errors[0]?.otp
      })
    } else {
      if (json) {
        this.setState({
          newPhoneVerified: true,
          openNewPhoneOTPBox: false
        })
      }
    }
  }

  handleUpdatedEmailChangesResponse = (json: any) => {
    if (json?.errors) {
      this.setState({ loading: false })
    } else {
      if (json) {
        if (localStorage.getItem("selectedUser") == json?.data?.id) {
          localStorage.setItem('selected_user_data', JSON.stringify(json?.data?.attributes));
          const eventNew = new StorageEvent('storage')
          window.dispatchEvent(eventNew)
        }
        toast.success(json?.message)
        this.props.onChangeTab()
        this.setState({ loading: false, checkExistingEmail: true })
      }
    }
  }

  handleUpdatedPhoneChangesResponse = (json: any) => {
    if (json?.errors) {
      this.setState({ loading: false })
    } else {
      if (json) {
        if (localStorage.getItem("selectedUser") == json?.data?.id) {
          localStorage.setItem('selected_user_data', JSON.stringify(json?.data?.attributes));
          const eventNew = new StorageEvent('storage')
          window.dispatchEvent(eventNew)
        }
        toast.success(json?.message)
        this.props.onChangeTab()
        this.setState({ loading: false, checkExistingPhone: true })
      }
    }
  }
  // Customizable Area End
}
