import React from "react";
import {
    Grid,
    Typography,
    withStyles,
    // Customizable Area Start
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

// Customizable Area End
import { backgroundPDF, Logo, rightArrow } from "./assets";
import TransactionInvoiceController, { Props } from "./TransactionInvoiceController.web";
import { withTranslation } from 'react-i18next';

export class TransactionInvoice extends TransactionInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        const { t }: any = this.props;
        const { data } = this.props;

        return (
            // Customizable Area Start
            <Grid container className={classes.pdfContainer}>
                <img src={backgroundPDF} alt="arrow" className={classes.backgroundImage} />
                <Grid container className={classes.imageContainer}>
                    <Grid item xs={4}>
                        <img alt="logo" src={Logo} className={classes.invoiceLogo} />
                    </Grid>
                    <Grid item xs={8} className={classes.invoiceTextContainer}>
                        <Typography className={classes.invoiceText}>
                            {t("settingWeb.transactions.invoice")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.detailsContainer}>
                    <Grid item xs={5} className={classes.rightIcon}>
                        <img src={rightArrow} alt="arrow" className={classes.rightArrow} />
                    </Grid>
                    <Grid item xs={7} className={classes.invoiceID}>
                        <Typography className={classes.typoText}>
                            {t("settingWeb.transactions.invoice")} : {data?.id}
                        </Typography>
                        <Typography className={classes.typoText}>
                            {t("settingWeb.transactions.Date")} : {this.invoiceDateFormat(data?.start_date)}
                        </Typography>
                    </Grid>
                    <Grid container spacing={1} className={classes.userInfo}>
                        <Grid item xs={12}>
                            <Typography className={classes.typoText}>
                                {t("settingWeb.transactions.invoiceTo")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.typoTextBold}>
                                {data?.userData?.first_name} {data?.userData?.last_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.typoText}>
                                {data?.userData?.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.phone_number_span}>
                            <Typography className={classes.typoText}>
                                +{data?.userData?.country_code}-{data?.userData?.phone_number}
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} className={classes.invoiceContainer}>
                    <Grid container className={classes.tableContainer}>
                        <Grid item xs={12} className={classes.invoiceMonth}>
                            <Typography className={classes.invoiceMonthTypo}>
                                {t("settingWeb.transactions.BDFM")} {this.getBillMonth(data?.start_date)}
                            </Typography>
                        </Grid>
                        <TableContainer>
                            <Table className={classes.table} >
                                <TableBody>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {this.state.currentLang === 'en' ? data?.plan_name : data?.plan_name_in_arabic} {t("settingWeb.transactions.plan")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {this.state.currentLang === 'en' ? data?.plan_duration_in_month : data?.plan_duration_in_arabic}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.paymentDate")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {this.dateFormat(data?.created_at)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.mode")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {this.checkForLang(data?.payment_mode , data.mode_in_arabic)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.method")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {this.checkForLang(data?.payment_method ,data.method_in_arabic )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.amount")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            ${data?.plan_price}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.valid")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {this.dateFormat(data?.start_date)} - {this.dateFormat(data?.end_date)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell scope="row" className={classes.invoiceTypograph}>
                                            {t("settingWeb.transactions.transactionId")} :
                                        </TableCell>
                                        <TableCell className={classes.invoiceTypographValue}>
                                            {data?.transaction_id}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.invoiceThankuContainer}>
                    <Typography className={classes.invoiceThanku}>
                        {t("settingWeb.transactions.Thanky")}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    invoiceContainer: {
        padding: "20px 25px"
    },
    pdfContainer: {
        width: '100%',
        height: '100%',
        direction: theme.direction === "rtl" ? 'rtl' : 'ltr'
    },
    borderStyle: {
        width: "100%",
        height: "30px"
    },
    invoiceLogo: {
        width: "90px"
    },
    invoiceID: {
        display: "flex",
        justifyContent: "space-between"
    },
    invoiceTextContainer: {
        textAlign: "end",
        position: "relative"
    },
    invoiceText: {
        fontSize: 52,
        bottom: -30,
        fontWeight: 700,
        color: "#0A405E",
        position: "absolute",
        right: theme.direction === "rtl" ? "auto" : 0,
        left: theme.direction === "rtl" ? 0 : "auto",
        fontFamily: "Poppins",
        textTransform: "uppercase"
    },
    rightArrow: {
        width: '90%',
        height: 15,
        transform: theme.direction === "rtl" ? "rotate(180deg)" : "none"
    },
    backgroundImage: {
        position: "absolute",
        width: '100%',
        height: 160,
        transform: theme.direction === "rtl" ? "scaleX(-1)" : "none"
    },
    imageContainer: {
        position: "relative",
        padding: "20px 20px",
        marginBottom: "35px"
    },
    detailsContainer: {
        padding: "20px 40px 0px 40px"
    },
    rightIcon: {
        display: "flex",
        alignSelf: "center"
    },
    ownerDetails: {
        padding: "8px"
    },
    invoiceMonth: {
        padding: "5px",
        marginTop: "10px"
    },
    invoiceMonthTypo: {
        color: "white",
        fontSize: "15px",
        fontFamily: "Poppins",
        fontWeight: 700,
        textAlign: "center",
        textTransform: "uppercase",
        background: "#0A405E",
        width: '80%',
        margin: "0px auto",
        borderRadius: "15px",
        padding: "5px"
    },
    invoiceTable: {
        padding: 8,
        borderBottom: "1px solid grey"
    },
    invoiceTypograph: {
        fontSize: "14px",
        width: "30%",
        color: "#0A405E",
        fontFamily: "Poppins",
        textAlign: theme.direction === "rtl" ? "right" : "left"
    },
    invoiceTypographValue: {
        fontSize: "14px",
        color: "#4D4C4C",
        width: "70%",
        fontFamily: "Poppins",
        textAlign: theme.direction === "rtl" ? "right" : "left"
    },
    invoiceThanku: {
        fontSize: "25px",
        fontWeight: 900,
        textAlign: "center",
        fontFamily: "Poppins"
    },
    invoiceThankuContainer: {
        margin: "20px 0px 30px",
        background: "#D0E9F7"
    },
    userInfo: {
        marginTop: 15
    },
    tableContainer: {
        maxWidth: "90%",
        margin: "0px auto"
    },
    typoText: {
        fontSize: 14,
        fontWeight: 400,
        color: "#4D4C4C",
        fontFamily: "Poppins",
        fontStyle: "normal",
        lineHeight: "normal"
    },
    typoTextBold: {
        fontSize: 14,
        fontWeight: 600,
        color: "#4D4C4C",
        fontFamily: "Poppins",
        fontStyle: "normal",
        lineHeight: "normal"
    },
    phone_number_span: {
        direction: "ltr",
        textAlign: (theme.direction === "rtl" ? 'right' : 'left')
    },
});
export default withStyles(styles)(withTranslation()(TransactionInvoice))
// Customizable Area End
