import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  withStyles,
  TextField,
  Divider,
  Drawer,
  IconButton,
  Chip,
  Slider,
  Grid,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import StarRateIcon from '@material-ui/icons/StarRate';
import { cross, search } from "./assets";
import 'rc-slider/assets/index.css';
import { withTranslation } from "react-i18next";
// Customizable Area End
// Customizable Area End

import SearchControllerWeb, { Props } from "./SearchControllerWeb";
import SearchSlider from "./SearchSlider.web";

export class SearchWeb extends SearchControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderWebDrawer = (classes: any, t: any) => {
    return (
      <Drawer
        variant="persistent"
        anchor='right'
        open={this.state.drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box className={classes.drawerHeader}>
          <Typography className={classes.fonts}>{t("searchscreen.filter")}</Typography>
          <IconButton onClick={this.handleDrawerClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Divider style={{ backgroundColor: '#7A7B7F' }} />
        <Box className={classes.BoxStyle}>
          <Typography className={classes.FontStyle}>{t("searchscreen.category")}</Typography>
          <Box style={{ padding: '5px' }}>
            {this.state.genreList?.map((data) => {
              return (
                <Chip
                  data-test-id="chipId"
                  key={data.id}
                  variant="outlined"
                  onClick={() => this.handleFilterChips(data?.id)}
                  size="medium"
                  label={data?.attributes?.name}
                  className={classes.chips}
                  style={{
                    background: this.state.selectedFilter.includes(data.id) ?
                      `linear-gradient(to left, #6869AC 0%, #0097A8 100%)` : 'transparent'
                  }}
                />
              )
            })}
          </Box>
        </Box>
        <Divider style={{ backgroundColor: '#7A7B7F' }} />

        <Box className={classes.BoxStyle}>
          <Typography className={classes.FontStyle}>
            {t("searchscreen.ratings")}
          </Typography>
          <Slider
            data-test-id="sliderId"
            className={classes.sliders}
            min={1}
            max={5}
            value={this.state.ratings}
            onChange={this.handleChangeRatings}
            aria-labelledby="range-slider"
            valueLabelFormat={(value: number) => {
              return (
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px' }}>
                  {value} <StarRateIcon style={{ color: '#F8B84E', fontSize: '15px' }} />
                </Box>
              );
            }
            }
            valueLabelDisplay="auto"
          />
        </Box>
        <Box className={classes.buttonBoxStyle}>
          <Button data-test-id="cancelBtn" className={classes.cancelButton} onClick={this.onClickCancelButton}>{this.state.applyFilter === true ? t("searchscreen.clear") : t("searchscreen.cancel")}</Button>
          <Button data-test-id="continueBtn" className={classes.continueButton} onClick={this.onClickContinueButton}>{t("searchscreen.continue")}</Button>
        </Box>
      </Drawer>
    )
  }

  renderMobileDrawer = (classes: any, t: any) => {
    return (
      <Drawer
        variant="persistent"
        open={this.state.drawerOpen}
        classes={{
          paper: classes.mobileDrawerPaper,
        }}
      >
        <Box className={classes.drawerHeader}>
          <Typography className={classes.fonts} style={{ textAlign: 'center' }}>{t("searchscreen.category")}</Typography>
          <IconButton onClick={this.handleDrawerClose}>
            <CloseIcon style={{ color: 'B1B6CD' }} />
          </IconButton>
        </Box>
        <Divider style={{ backgroundColor: '#7A7B7F' }} />
        <Box className={classes.BoxStyle}>
          <Typography className={classes.FontStyle}>{t("searchscreen.category")}</Typography>
          <Box style={{ padding: '5px' }}>
            {this.state.genreList?.map((data) => {
              return (
                <Chip
                  data-test-id="chipId"
                  key={data?.id}
                  variant="outlined"
                  onClick={() => this.handleFilterChips(data?.id)}
                  size="medium"
                  label={data?.attributes?.name}
                  className={classes?.chips}
                  style={{
                    background: this.state.selectedFilter.includes(data.id) ? `linear-gradient(to left, #6869AC 0%, #0097A8 100%)` : 'transparent'
                  }}
                />
              )
            })}
          </Box>
        </Box>
        <Divider style={{ backgroundColor: '#7A7B7F' }} />

        <Box className={classes.BoxStyle}>
          <Typography className={classes.FontStyle}>
            {t("searchscreen.ratings")}
          </Typography>
          <Slider
            className={classes.sliders}
            min={1}
            max={5}
            value={this.state.ratings}
            onChange={this.handleChangeRatings}
            aria-labelledby="range-slider"
            valueLabelFormat={(value: number) => {
              return (
                <>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '4px' }}>
                    {value} <StarRateIcon style={{ color: '#F8B84E', fontSize: '18px' }} />
                  </Box>
                </>
              );
            }
            }
            valueLabelDisplay="auto"
          />
        </Box>
        <Box className={classes.mobBtnCont}>
          <Button className={classes.cancelButton}
            data-test-id="cancelBtn"
            onClick={this.onClickCancelButton}>{t("searchscreen.cancel")}</Button>
          <Button data-test-id="continueBtn" className={classes.continueButton} onClick={this.onClickContinueButton}>{t("searchscreen.continue")}</Button>
        </Box>
      </Drawer>
    )
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t }: any = this.props;
    return (
      <Container maxWidth={false} className={classes.searchContainer}>
        {/* Customizable Area Start */}
        <Container className={classes.containerStyle}>
          <Box className={classes.boxStyle}
            // style={{
            //   marginRight: this.state.drawerOpen === true &&
            //     localStorage.getItem("i18nextLng") === 'en' ?
            //     200 : 0,
            //   marginLeft: this.state.drawerOpen === true &&
            //     localStorage.getItem("i18nextLng") === 'ar' ? 200 : 0,
            //   width: this.state.drawerOpen === true ? `calc(85% - ${drawerWidth}px)` : '100%',
            // }}
          >
            <img src={search} className={classes.imageStyle} alt="search"/>
            <StyledTextField
              data-test-id="searchMovie"
              value={this.state.searchMovie}
              onChange={(event: any) => this.handleSearchMovie(event)}
              placeholder = {t("searchscreen.searchreq")}
              fullWidth
              InputProps={{
                disableUnderline: true,
                classes: { input: classes["searchTextFeild"] }
              }}
            />
            {this.state.searchMovie && <Box data-test-id="clearIcon" onClick={this.handleDrawerOpen} className={classes.SearchRight}>
              <img src={cross} className={classes.filterIcon} />

              {/* <img src={this.state.applyFilter ? filterRedIcon : filterIcon} className={classes.filterIcon} />
              {this.state.selectedFilter.length > 0 && <span className={classes.filterNum}>({this.state.selectedFilter.length})</span>} */}
            </Box>}

            {/* {window.innerWidth > 600 ?
              this.renderWebDrawer(classes, t) : this.renderMobileDrawer(classes, t)
            } */}
          </Box>
        </Container>
        
          <Grid container direction="row" justifyContent='center' alignItems="center"
            // style={{
            //   marginRight: this.state.drawerOpen === true ?
            //     "200px" : 0,
            //   marginLeft: this.state.drawerOpen === true ? '200px' : 0,
            // }}
          >

            {
              this.state.getMovieData?.length !== 0 && <SearchSlider sliderTitle={t("searchscreen.movies")}
                similarMovies={this.state.getMovieData}
                navigateHandler={this.movieNavigateHandler} />
            }

            {
              this.state.getPodcastData?.length !== 0 && <SearchSlider sliderTitle={t("searchscreen.podcast")}
                similarMovies={this.state.getPodcastData}
                navigateHandler={this.podcastNavigateHandler} />
            }

            {
              this.state.getSeriesData?.length !== 0 && <SearchSlider sliderTitle={t("searchscreen.shows")}
                similarMovies={this.state.getSeriesData}
                navigateHandler={this.movieNavigateHandler} />
            }
            {this.state.showSearchData && this.state.getSeriesData?.length == 0 && this.state.getPodcastData?.length == 0 && this.state.getMovieData?.length == 0 &&
              <Typography color='primary'>{t("movieDetails.nodatafound")}</Typography>
            }
          </Grid>
       
        {/* Customizable End Start */}
      </Container>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const drawerWidth = 350;
const StyledTextField = withStyles({
  root: {
    fontSize: "14",
    marginLeft: 5,
    "& .MuiInputBase-input": {
      caretColor: '#EA435D',
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
  },
})((props: any) => <TextField {...props} />);

export const useStyles = (theme: any) => {

  let themeDirection = (arrValue: any, enValue: any) => theme.direction == "rtl" ? arrValue : enValue;
  let themePalette = (lightValue: any, darValue: any) => theme.palette.type == 'dark' ? lightValue : darValue;

  return {
    root: {
      display: 'flex',
      color: '#52af77',
      height: 8,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },

    imageStyle: {
      width: 20,
      height:20,
      padding: 12,
    },
    SearchRight: {
      display: "flex",
      alignItems: "center"
    },
    filterIcon: {
      height: '20px',
      width: '20px',
      cursor: 'pointer',
      padding: themeDirection(10, 10),
    },
    filterNum: {
      color: theme.palette.primary.main,
    },
    containerStyle: {
      background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
padding: 1,
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 8
    },
    searchContainer:{
      width: "100%",
      padding: "0 10%",
      [theme.breakpoints.only("xs")]: {
        padding: '0 20px'
      }
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      height: 'auto',
      maxHeight: '80%',
      top: 70,
      padding: 20,
      backgroundColor: themePalette('#101014', '#FFFFF'),
      borderLeft: '1px solid black',
    },

    mobileDrawerPaper: {
      width: '90%',
      padding: 20,
      backgroundColor: themePalette('#101014', '#FFFFF'),
      borderLeft: '1px solid white'
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      color: '#FFFFFF',
    },
    fonts: {
      fontSize: '28px',
      fontWeight: 700,
      color: themePalette('#7A7B7F', 'black')
    },
    FontStyle: {
      fontSize: '16px',
      fontWeight: 400,
      color: themePalette('#B1B6CD', 'black')
    },
    BoxStyle: {
      padding: '10px 0 10px 0'
    },
    boxStyle: {
      background: "#222222",
      height: 35,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      padding: "10px 15px",
      width: '100%'
    },
    buttonBoxStyle: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 20,
      padding: 20
    },

    mobBtnCont: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '90%'
    },

    cancelButton: {
      border: '1px solid #979797',
      color: themePalette('#FFFFF', 'black'),
      borderRadius: 10,
      fontSize: '20px',
      fontWeight: 700,
      textTransform: 'capitalize' as 'capitalize',
      padding: "5px 25px 5px 25px",
      backgroundColor: themePalette('none', '#85868b'),
      '&:hover': {
        backgroundColor: themePalette('none', '#85868b'),
      }
    },

    continueButton: {
      color: themePalette('#FFFFF', 'black'),
      borderRadius: 10,
      fontSize: '20px',
      fontWeight: 700,
      backgroundColor: '#EA435D',
      textTransform: 'capitalize' as 'capitalize',
      padding: "5px 15px 5px 15px",
      '&:hover': {
        backgroundColor: '#EA435D',
      }
    },

    chips: {
      fontSize: '14px',
      fontWeight: 700,
      margin: "5px 5px 5px 5px",
      color: themePalette('#FFFFF', 'black'),
      border: '1px solid #0097A8',
    },

    sliders: {
      color: '#4685cf',
      marginTop: 30,
      marginLeft: 8,
      width: '90%',
      '& .MuiSlider-track': {
        height: '5px',
        borderRadius: 3,
      },
      '& .MuiSlider-rail': {
        background: 'linear-gradient(to left, #6869AC 0%, #0097A8 100%)',
        height: '5px',
        borderRadius: 3,
      },
      '& .PrivateValueLabel-circle-876': {
        width: '20px',
        height: ' 40px',
        transform: 'rotate(-90deg)',
        borderRadius: '5px',
        padding: '0 3px 0 5px',
        background: 'linear-gradient(to left, #6869AC 0%, #0097A8 100%)',
        marginTop: 15
      },
      '& .PrivateValueLabel-label-877': {
        transform: 'rotate(90deg)',
        fontWeight: 700
      }
    },
    tooltipStyle: {
      '& .MuiTooltip-popper': {
        backgroundColor: "red",
        color: '#fff',
        fontSize: "14px"
      }
    },
    cardStyle: {
      width: '100%',
      background: "transparent",
      boxShadow: "none",
      cursor: "pointer"
    },

    searchTextFeild: {
      color: theme.palette.primary.main,
      "&::placeholder": {
        color: theme.palette.primary.main,
      },
    },
    closeIcon: {
      color: `${theme.palette.type == "dark" ? '#B1B6CD' : 'black'}`
    }
  }
};
export default withStyles(useStyles)(withTranslation()(SearchWeb))
// Customizable Area End
