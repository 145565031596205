import React from 'react'
//@ts-ignore
import Banner from './Banner.web';
import PodcastDetailsController, { Props } from './PodcastDetailsController.web';
import Spinner from '../../user-profile-basic/src/shared/Spinner.web';
import MesDialog from "../../user-profile-basic/src/shared/MesDialog.web";
import { withTranslation } from 'react-i18next';
import MoviesSlider from './MoviesSlider.web';
import { withStyles, } from "@material-ui/core/styles";
import { Container, Typography, Grid } from "@material-ui/core";
import VideoShakaPlayer from "../../videos/src/VideoShakaPlayer.web";
import  LikeCommentShare  from './LikeCommentShare.web';
import MovieSwiperSkelton from './Swiperskelton.web';

export class PodcastDetails extends PodcastDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t }: any = this.props;
    const { similarMovies, contentDetails, playVideo, streamingURL, loadingPodcast } = this.state;
    return (
      <>
        <>
          {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
          {playVideo && streamingURL ?
            <VideoShakaPlayer
              {...this.state}
              data-test-id="video-js-test-id"
              {...this.props}
              createWatchHistory={this.createWatchHistory}
              onClosePlayer={this.onClosePlayer}
              audioOnly={true}
            />
            : (loadingPodcast ? <MovieSwiperSkelton /> : <Banner {...this.props}
              navigation={this.props.navigation} id={''}
              isPodcast={true}
              onPlayVideo={this.onPlayVideo}
              updateMovieDetailsState={this.updateState}
              {...this.state}
            />)
          }
          {contentDetails && !this.state.isGuest && <Grid container className={classes.commentContainer}>
             <Typography className={classes.tabTypo} >
              {t("movieDetails.Comments")}
              </Typography>
            <LikeCommentShare {...this.props} {...this.state} id={"0"} isComment={true} /> </Grid>}
          {similarMovies.length > 0 &&
            <Container maxWidth={false} className={classes.MovieContainer}>
              <MoviesSlider
                similarMovies={similarMovies}
                navHandler={this.navHandler}
                slidesToShow={10}
                slidesToScroll={5}
               sliderTitle={t("podcastDetails.similarPodcastListen")} {...this.props}
              />
            </Container>
          }
        </>
        {this.state.userCountError && <MesDialog
          title={t("settingWeb.userLimit")}
          deleteModal={this.state.userCountError}
          onCloseModal={() => {
            this.setState({ userCountError: false });
          }}
          confirmMes={t("settingWeb.UIAA")}
          okButtonText={t("settingWeb.manageDevice")}
          cancelButtonText={t("settingWeb.cancel")}
          deleteClick={(event: any) => {
            this.props.navigation.navigate("Setting")
            this.setState({ userCountError: false })
          }
          } />
        }
      </>
    )
  }
}

const styles: any = (theme: any) => ({
  MovieContainer: {
    paddingLeft: theme.direction === "rtl" ? '0' : '5%',
    paddingRight: theme.direction === "rtl" ? '5%' : '0'
  },
  commentContainer: {
    padding : theme.direction === "rtl" ? '0px 5% 20px 0' : '0px 0px 20px 5%'
  },
  tabTypo:{
    color: "#F6F6F6",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 600,
    padding: '20px 10px 10px '
  },
});
export default withStyles(styles)(withTranslation()(PodcastDetails));
