import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    Button
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import MesDialog from "../../user-profile-basic/src/shared/MesDialog.web";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import { removeIcon } from "./assets";
import { withTranslation } from 'react-i18next';
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    historyContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "row" as const,
    },
    backgroundImage_div: {
        padding: "30px 30px 10px",
        [theme.breakpoints.only("xs")]: {
            padding: "15px 20px 5px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "20px 25px 7px",
        }
    },
    noDataFoundText: {
        fontFamily: "Roboto",
        fontWeight: 500,
        textAlign: 'center' as const,
        maxWidth: '75%',
        fontSize: 20,
        [theme.breakpoints.only("xs")]: {
            fontSize: 16.5,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 18.5,
        },
    },
    descriptionText20: {
        fontFamily: 'Roboto',
        fontSize: 20,
        [theme.breakpoints.only("xs")]: {
            fontSize: 16.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 19,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 18.5,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 20,
        },
    },
    font_size_12: {
        fontSize: 12,
        fontFamily: "Roboto",
       [theme.breakpoints.only("sm")]: {
          fontSize: 11,
        },
        [theme.breakpoints.only("xs")]: {
          fontSize: 10.5,
        },
        color: "#F6F6F6"
      },
    font_size_14: {
        fontFamily: "Roboto",
        marginTop: 5,
        fontSize: 14,
        [theme.breakpoints.only("xs")]: {
            fontSize: 12.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 13.5,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 13,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 14,
        },
    },
    font_Size_20: {
        fontWeight: 700,
            fontSize: 20,
            fontFamily: "Roboto",
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 19,
            }
    },

    removeHistoryTypo:{
        color: "#F95C5C",
        fontSize: "14px",
        fontWeight: 400,
        display: "flex",
        alignItems: "center" as const,
        cursor: "pointer"
    },
    historyFooter:{
        marginTop: '13px',
        display: "flex",
        alignItems: "center" as const,
        justifyContent: 'space-between'
    },
    removeHistory: {
        height: 20,
        width: 20,
        margin: theme.direction === "ltr" ? "0 12px 0 0" : "0 0px 0 12px",
    },
    noDataFound:{
        display: 'flex',
        justifyContent: 'center' as const,
        flexDirection: 'column' as const,
        alignItems: 'center' as const
    },
    image_div: {
        width: '133.33',
        borderRadius: 6,
        height: '160px',
        border: '1px solid #F6F6F6',
        minWidth: '133.33',
        minHeight: '138px',
        [theme.breakpoints.only("xs")]: {
            minWidth: '107.90px',
            width: '107.90px'
            
        },
        [theme.breakpoints.only("md")]: {
            width: '120.90px',
            minWidth: '120.90px',
            height: '144px'
        },
        [theme.breakpoints.only("sm")]: {
            width: '110.90px',
            minWidth: '110.90px',
            height: '138px'
        }
    },
    historyDetails:{
        display: "flex",
        flexDirection: "column" as const,
        margin: theme.direction === 'rtl' ? "0 10px 0 0" : "0 0px 0 10px",
        justifyContent: 'space-between' as const,
        width: "inherit"
    },
    may_be_button_css: {
        color: theme.palette.primary.main,
        borderRadius: 10,
        fontWeight: 700,        
        [theme.breakpoints.only("xs")]: {
            fontSize: 14,
            marginTop: 45,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 17,
            marginTop: 52,
            maxWidth: 280,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 16,
            marginTop: 47,
            maxWidth: 230,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 18,
            marginTop: 55,
            maxWidth: 280,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 18,
            marginTop: 55,
            maxWidth: 280,
        },
        "&:hover": {
            color: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: `rgba(234,67,93, 1)`,
            color: theme.palette.primary.main,
        },
    },
    watchUnderLine:{
        height: "2px",
        background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
        marginTop: 16
    }
});

// Customizable Area End

import WatchHistoryController, { Props } from "./WatchHistoryController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import moment from "moment";

export class WatchHistory extends WatchHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
        const { t }: any = this.props;

        // Customizable Area Start
        return (
            <Grid container>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Grid
                    item
                    xs={12}
                    className={classes.backgroundImage_div}
                >
                    <Typography
                        className={classes.font_Size_20}
                        color="primary"
                    >
                        {t("settingWeb.watchHistory")}
                    </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.watchUnderLine}
                        />
                         <Grid
                    item
                    xs={12}
                    className={classes.backgroundImage_div}
                >
                       <Grid container spacing={2}>
                                {
                                    this.state.userWatchHistory?.length !== 0 && this.state.userWatchHistory.map((_data: any , index: number) => {
                                        return (
                                            <Grid item xs={12} key={_data?.id}>
                                                <Grid container >
                                                    <div
                                                        id="movie-navigate"
                                                        onClick={() => this.navigateToDetails(_data?.attributes)}
                                                       className={classes.historyContainer}
                                                    >
                                                        <img
                                                            src={_data?.attributes?.image_url}
                                                            alt="moive_poster"
                                                            className={classes.image_div}
                                                        />
                                                        <div
                                                            className={classes.historyDetails}                                                           
                                                        >
                                                            <div>
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 500,
                                                                    wordBreak: 'break-all'
                                                                }}
                                                                className={`${classes.descriptionText20} single_line`}
                                                                color="primary"
                                                            >
                                                                {_data?.attributes?.title}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 400,
                                                                    wordBreak: 'break-all'
                                                                }}
                                                                className={`${classes.font_size_14} four_lines`}
                                                                color="secondary"
                                                            >
                                                                {localStorage.getItem("i18nextLng") === 'ar' ? _data?.attributes?.description_in_arabic : _data?.attributes?.description}
                                                            </Typography>
                                                            </div>
                                                           
                                                    <div className={classes.historyFooter}>
                                                    <Button id="delete-icon" onClick={(event: React.MouseEvent<HTMLButtonElement>)=> this.removeHistory(event, _data?.attributes?.asset_id)} className={classes.removeHistoryTypo}><img src={removeIcon} className={classes.removeHistory} /> {t("settingWeb.removeHistory")}</Button>   
                                                    <Typography className={classes.font_size_12}>
                                                    {moment(_data.attributes.updated_at).format('DD/MM/YYYY')}
                                                        </Typography>                         
                                                    </div>
                                                    </div>
                                                        </div>  
                                                        {!this.isLastHistory(index) &&<Grid
                                                        item
                                                        xs={12}
                                                        className={classes.watchUnderLine}
                                                    />     }                                           
                                                </Grid>
                                               
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    this.state.userWatchHistory.length == 0 && <Grid item xs={12} className={classes.noDataFound}>
                                        <Grid container justifyContent="center">
                                            <LiveTvIcon fontSize="large" style={{ color: 'grey' }} />
                                        </Grid>
                                        <Button data-test-id="navigate-btn" variant="contained" fullWidth className={classes.may_be_button_css}
                                            onClick={() => { this.props.navigation.navigate("DashboardWeb") }}
                                        >
                                            {t("settingWeb.FSTW")}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        {this.state.removeDialog && <MesDialog
                            title={t("settingWeb.delete_watch")}
                            deleteModal={this.state.removeDialog}
                            onCloseModal={() => {
                                this.setState({ selectedId: '', removeDialog: false });
                            }}
                            confirmMes={t("settingWeb.delete_fav_mes")}
                            // description="This item will delete all your previous data."
                            cancelButtonText={t("settingWeb.cancel")}
                            okButtonText={t("settingWeb.delete")}
                            deleteClick={() => {
                                this.deleteUserWatchHistory(this.state.selectedId)
                                this.setState({ removeDialog: false })
                            }
                            } />
                        }
                   
                </Grid>
            </Grid>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(WatchHistory));
// Customizable Area End
