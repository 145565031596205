import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useState } from 'react';
import { theme, ThemeItem, ThemeMode } from './theme';

export type CommonContextType = {
  mode: ThemeMode;
  addData?: (mode: ThemeMode) => void;
  colors: ThemeItem;
};
export const CommonContext = React.createContext<CommonContextType>({
  colors: theme.dark,
  mode: 'dark',
});
export const CommonContextProvider = (props: any) => {
  const [mode, setData] = useState<ThemeMode>('dark');
  const [colors, setColors] = useState<ThemeItem>(theme.dark);
  const addData = (_mode: ThemeMode) => {
    if (_mode !== mode) {
      setData(_mode);
      setColors(theme[_mode]);
      AsyncStorage.setItem('Theme', _mode);
    }
  };
  useEffect(() => {
    getTheme();
  }, []);
  const getTheme = async () => {
    const mode = (await AsyncStorage.getItem('Theme')) as ThemeMode;

    addData(mode || 'dark');
  };

  return (
    <CommonContext.Provider
      value={{
        mode,
        addData,
        colors
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};
