import React from "react";

// Customizable Area Start
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
// Customizable Area End

// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateForwardButton extends shaka.ui.Element<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  constructor(parent: any, controls: any) {
    super(parent, controls);

    /** @private {!HTMLButtonElement} */
    this.button_ = document.createElement("button");
    this.button_.classList.add("material-icons-round");
    this.button_.classList.add(`shaka-forward-ten-button`);
    this.button_.textContent = `forward_10`;
    this.button_.ariaLabel = `Forward 10`;
    this.parent.appendChild(this.button_);

    this.eventManager.listen(this.button_, "click", () => {
      this.forward_();
    });
  }

  forward_() {
    if (!this.video.duration || this.video.duration < 10) {
      return;
    }

    if (this.video.currentTime + 10 < this.video.duration) {
      this.video.currentTime += 10;
    } else {
      this.video.currentTime = this.video.duration - 10;
    }
  }
  // Customizable Area End
}

// Customizable Area Start
CreateForwardButton.Factory = class {
  create(rootElement: any, controls: any) {
    return new CreateForwardButton(rootElement, controls);
  }
};
// Customizable Area End
