import React from "react";

const HeaderLogo=(props:any)=>{
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 186.4 186.4" enable-background="new 0 0 186.4 186.4" xmlSpace="preserve" {...props}>
<g>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="17.7312" y1="167.0254" x2="209.7043" y2="-20.7362">
		<stop  offset="3.541639e-03" style={{stopColor:"#EA435D"}}/>
		<stop  offset="0.1672" style={{stopColor:"#D75079"}}/>
		<stop  offset="0.2649" style={{stopColor:"#B65788"}}/>
		<stop  offset="0.4443" style={{stopColor:"#7E64A2"}}/>
		<stop  offset="0.531" style={{stopColor:"#6869AC"}}/>
		<stop  offset="0.7933" style={{stopColor:"#0197A8"}}/>
	</linearGradient>
	<path fill="url(#SVGID_1_)" d="M36.7,186.4h113.1c20.3,0,36.7-16.4,36.7-36.7V36.7C186.4,16.4,170,0,149.7,0H36.7
		C16.4,0,0,16.4,0,36.7v113.1C0,170,16.4,186.4,36.7,186.4z"/>
	<g>
		<path fill="#FFFFFF" d="M127.9,97.5l1.8-4.9c0.7,0.5,1.7,0.8,2.8,0.8c1.7,0,2.8-1.1,2.8-3.4V67h6.7v23.1c0,5.3-3.1,8.7-8.7,8.7
			C131.2,98.8,129.2,98.4,127.9,97.5z"/>
		<path fill="#FFFFFF" d="M48.4,85h35.9v6.7H48.4L48.4,85z"/>
		<path fill="#FFFFFF" d="M41,78.6c3.1,0,5.8,1.1,7.7,3.1V79h6.4v12.7h-6.7v-1c0-4.3-2.3-6.2-5.5-6.2c-3,0-4.7,1.7-4.7,5.5v12.2
			h-6.7V88.9C31.4,81.8,35.5,78.6,41,78.6z"/>
		<path fill="#FFFFFF" d="M145.8,67h7v35.1h-7V67z"/>
		<path fill="#FFFFFF" d="M84.3,91.7l-4.6-3.9c2.2-1.6,3.3-3.4,3.3-5.6c0-2.8-1.7-4.3-5-4.3H63.7v10.6h-5.6V71.1h19.5
			c7.4,0,11.1,3.7,11.1,10.8C88.7,85.9,87.1,89.5,84.3,91.7z"/>
		<g>
			<path fill="#FFFFFF" d="M106,90.3c0-6.9,5.3-11.7,12.5-11.7c7.2,0,12.5,4.9,12.5,11.7c0,6.9-5.2,11.7-12.5,11.7
				C111.3,102.1,106,97.2,106,90.3z M124.3,90.3c0-3.9-2.5-6.3-5.8-6.3s-5.8,2.4-5.8,6.3s2.5,6.3,5.8,6.3S124.3,94.3,124.3,90.3z"/>
		</g>
		<path fill="#FFFFFF" d="M88.2,102.1c1,0,2-0.1,2.9-0.3c1.7-0.4,3.2-1.3,4.3-2.6c0,0,0-0.1,0.1-0.1c0.9-1.1,1.6-2.5,2-4.2
			c0.2-0.9,0.3-2,0.3-3.1v-8.9h-6.7v7.9c0,2.4-0.7,4-2,4.8c-0.7,0.4-1.6,0.7-2.7,0.7"/>
		<path fill="#FFFFFF" d="M97.8,86.6h-6.7V90c0,4.3-2.3,6.2-5.5,6.2c-1,0-1.9-0.2-2.7-0.7c-1.3-0.8-2-2.4-2-4.8v-4.2h-6.7v5.2
			c0,1.1,0.1,2.1,0.3,3.1c0.4,1.7,1,3.1,2,4.2c0,0,0,0.1,0.1,0.1c1.1,1.3,2.6,2.1,4.3,2.6c0.9,0.2,1.9,0.3,2.9,0.3
			c3.1,0,14.1,1.5,14.1-10.4V86.6z"/>
		<path fill="#FFFFFF" d="M95.5,99.2V99C95.5,99,95.5,99.1,95.5,99.2c1.9,1.9,4.6,2.9,7.7,2.9c5.5,0,9.6-3.2,9.6-10.2V78.6H106v12.2
			c0,3.7-1.7,5.5-4.7,5.5c-1.5,0-2.8-0.4-3.8-1.4L95.5,99.2z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M93.4,64.3l8.7,6.1c0.3,0.2,0.3,0.7,0,0.9l-8.7,6.2c-0.4,0.3-0.9,0-0.9-0.5l0-12.3
			C92.5,64.4,93,64.1,93.4,64.3z"/>
	</g>
	<g>
		<circle fill="#FFFFFF" cx="45.9" cy="69.6" r="5.3"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M107.2,115.8v6.3h-3v-1.4c-0.6,1-1.7,1.5-3.4,1.5c-2.6,0-4.1-1.4-4.1-3.3c0-1.9,1.4-3.3,4.7-3.3h2.5
			c0-1.4-0.8-2.2-2.5-2.2c-1.2,0-2.4,0.4-3.2,1l-1.1-2.2c1.2-0.9,3-1.3,4.8-1.3C105.3,110.9,107.2,112.4,107.2,115.8z M104,118.6
			v-1.1h-2.2c-1.5,0-2,0.6-2,1.3c0,0.8,0.7,1.3,1.8,1.3C102.7,120.1,103.7,119.6,104,118.6z"/>
		<path fill="#FFFFFF" d="M82.3,115.8v6.3h-3v-1.4c-0.6,1-1.7,1.5-3.4,1.5c-2.6,0-4.1-1.4-4.1-3.3c0-1.9,1.4-3.3,4.7-3.3h2.5
			c0-1.4-0.8-2.2-2.5-2.2c-1.2,0-2.4,0.4-3.2,1l-1.1-2.2c1.2-0.9,3-1.3,4.8-1.3C80.3,110.9,82.3,112.4,82.3,115.8z M79.1,118.6v-1.1
			h-2.2c-1.5,0-2,0.6-2,1.3c0,0.8,0.7,1.3,1.8,1.3C77.8,120.1,78.7,119.6,79.1,118.6z"/>
		<path fill="#FFFFFF" d="M141,115.8v6.3h-3v-1.4c-0.6,1-1.7,1.5-3.4,1.5c-2.6,0-4.1-1.4-4.1-3.3c0-1.9,1.4-3.3,4.7-3.3h2.5
			c0-1.4-0.8-2.2-2.5-2.2c-1.2,0-2.4,0.4-3.2,1l-1.1-2.2c1.2-0.9,3-1.3,4.8-1.3C139,110.9,141,112.4,141,115.8z M137.8,118.6v-1.1
			h-2.2c-1.5,0-2,0.6-2,1.3c0,0.8,0.7,1.3,1.8,1.3C136.5,120.1,137.4,119.6,137.8,118.6z"/>
		<path fill="#FFFFFF" d="M46.6,107.2h3.2v15h-3.2V107.2z"/>
		<path fill="#FFFFFF" d="M95.2,115.8v6.3H92v-5.8c0-1.8-0.8-2.6-2.2-2.6c-1.5,0-2.6,0.9-2.6,3v5.5H84v-11H87v1.3
			c0.9-0.9,2.2-1.5,3.7-1.5C93.3,111,95.2,112.5,95.2,115.8z"/>
		<path fill="#FFFFFF" d="M108.7,121.1l1.1-2.3c1,0.6,2.5,1.1,3.8,1.1c1.5,0,2-0.4,2-1c0-1.8-6.7,0-6.7-4.4c0-2.1,1.9-3.6,5.1-3.6
			c1.5,0,3.2,0.3,4.2,1l-1.1,2.3c-1.1-0.6-2.2-0.8-3.2-0.8c-1.4,0-2.1,0.5-2.1,1c0,1.9,6.7,0.1,6.7,4.4c0,2.1-1.9,3.5-5.2,3.5
			C111.7,122.2,109.8,121.7,108.7,121.1z"/>
		<path fill="#FFFFFF" d="M119.4,121.1l1.1-2.3c1,0.6,2.5,1.1,3.8,1.1c1.5,0,2-0.4,2-1c0-1.8-6.7,0-6.7-4.4c0-2.1,1.9-3.6,5.1-3.6
			c1.5,0,3.2,0.3,4.2,1l-1.1,2.3c-1.1-0.6-2.2-0.8-3.2-0.8c-1.4,0-2.1,0.5-2.1,1c0,1.9,6.7,0.1,6.7,4.4c0,2.1-1.9,3.5-5.2,3.5
			C122.3,122.2,120.4,121.7,119.4,121.1z"/>
		<g>
			<path fill="#FFFFFF" d="M62.9,114.4c-0.2-0.8-0.5-1.5-0.9-2c0,0,0,0,0,0c-0.5-0.6-1.2-1-2.1-1.2c-0.4-0.1-0.9-0.2-1.4-0.2
				c-1.5,0-2.8,0.5-3.6,1.4v-1.3h-3v11H55v-5.4c0-2,1.1-3,2.6-3c0.5,0,0.9,0.1,1.3,0.3c0.6,0.4,1,1.1,1,2.3v5.8H63v-6.3
				C63,115.3,63,114.8,62.9,114.4z"/>
			<path fill="#FFFFFF" d="M62.9,114.4c0.5-0.4,1.1-0.6,1.8-0.6c1.4,0,2.2,0.8,2.2,2.6v5.8h3.2v-6.3c0-3.3-2-4.9-4.5-4.9
				c-1.5,0-2.7,0.5-3.6,1.4c0,0,0,0,0,0v-0.1L62.9,114.4z"/>
		</g>
		<path fill="#FFFFFF" d="M36,118.4l-1.1,3.9h-3.5l4.6-15.1h4.5l4.7,15.1h-3.7l-1.2-3.9H36z M39.8,115.9l-0.9-3.2
			c-0.3-0.9-0.5-2-0.8-2.9h0c-0.2,0.9-0.4,2-0.7,2.9l-0.9,3.2H39.8z"/>
		<path fill="#FFFFFF" d="M142.4,107.2h3.4v5.3h0c0.4-0.5,0.8-0.8,1.3-1.1c0.5-0.3,1.1-0.4,1.8-0.4c2.2,0,3.8,1.5,3.8,4.8v6.4h-3.4
			v-6c0-1.4-0.5-2.4-1.8-2.4c-0.9,0-1.5,0.6-1.7,1.2c-0.1,0.2-0.1,0.5-0.1,0.8v6.5h-3.4V107.2z"/>
	</g>
</g>
</svg>
    )
}

export default HeaderLogo