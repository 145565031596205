/* eslint-disable no-undef */
import React from "react";
// Customizable Area Start
import { Button, Grid, withStyles, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
TimeAgo.addDefaultLocale(en);
// Customizable Area End

import ConfirmationController, { Props } from "./ConfirmationController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { error, success } from "./assets";

export class Confirmation extends ConfirmationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t }: any = this.props;

    return (
      <>
        {this.state.loading && (
          <Spinner spinnerModal={this.state.loading} data-test-id="spinner" />
        )}
        {this.state.success && (
          <Grid className={classes.MainWrap}>
            <img
              className={classes.SuccesImg}
              src={success}
              alt="success-image"
            />
            <Typography className={classes.Message}>
              {t("confirmationPayment.SuccessMsg")}
            </Typography>
            <Typography className={`${classes.Message} ${classes.darkText}`}>
              {this.state.successMessage}
            </Typography>
            <Button
              variant="contained"
              onClick={this.handleSuccessNavigate}
              className={classes.GoHomeButton}
            >
              <span className={classes.ButtonText}>
                {t("confirmationPayment.GB")}
              </span>
            </Button>
          </Grid>
        )}
        {this.state.error && (
          <Grid className={classes.MainWrap}>
            <img
              className={classes.SuccesImg}
              src={error}
              alt="success-image"
            />
            <Typography className={classes.Message}>
              {t("confirmationPayment.FailedMsg")}
            </Typography>
            <Button
              variant="contained"
              onClick={this.handleFailedNavigate}
              className={classes.GoHomeButton}
            >
              <span className={classes.ButtonText}>
                {t("confirmationPayment.GB")}
              </span>
            </Button>
          </Grid>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  MainWrap: {
    maxWidth: "500px",
    margin: "0 auto",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  SuccesImg: {
    width: "150px",
    height: "150px"
  },
  Message: {
    fontSize: "18px",
    color: theme.palette.primary.main,
    fontFamily: "Araboto",
    textAlign: "center",
    margin: "20px 0"
  },
  darkText: {
    fontWeight: 600
  },
  GoHomeButton: {
    width: "150px",
    height: "46px",
    backgroundColor: "rgba(234, 67, 93, 1)",
    opacity: 1,
    "&:hover": {
      backgroundColor: "rgba(234, 67, 93, 1)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px"
    }
  },
  ButtonText: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "16px"
    }
  }
});
export default withStyles(styles)(withTranslation()(Confirmation));
// Customizable Area End
