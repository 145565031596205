import React from "react";
// Customizable Area Start
import {
    Container,
    withStyles,
    TextField,
    Box,
    Grid,
    Typography,
    Divider,
    Avatar,
    IconButton,
    InputAdornment,
    Button,
    Popover,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import "react-datepicker/dist/react-datepicker.css";
import EmailVerificationContoller, { Props } from "./EmailVerificationController.web";
import { calenderGradient, dummyIcon, successIcon, calender } from "./assets";
import DeleteProfile from "../../user-profile-basic/src/DeleteProfile.web";
import { withTranslation } from "react-i18next";
import CustomCountryCodePicker from "./customCountryCodePicker";
import Datetime from "react-datetime";
import moment from "moment";
import './EmailVerification.css';
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";


// Customizable Area End

export class EmailVerificationWeb extends EmailVerificationContoller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        moment.locale('en');
        // Customizable Area End
    }

    profileChange = (classes: any, t: any) => {
        return (
            <Grid item className={classes.griditem2}>
                <IconButton className={classes.iconbtn}
                    disabled={this.handleReadOnly()} data-test-id="ref"
                    onClick={this.onClickEditIcon}>
                    <input hidden ref={this.inputRef} type="file" onChange={this.onProfileChange} />
                    <EditIcon className={classes.editIcon} onClick={this.onEditProfile} />
                </IconButton>
                <Avatar alt="#"
                    src={this.state.imagePreview === null ? dummyIcon : this.state.imagePreview}
                    className={classes.avatar} />
            </Grid>
        )
    }

    nameAndDate = (classes: any, t: any) => {
        return (
            <Grid style={{ marginTop: '10px'}} item xl={12} lg={12} md={12} sm={12} xs={12} >
                <StyledTextField
                    variant="outlined"
                    fullWidth
                    data-test-id="edite_name"
                    value={this.state.editName}
                    onChange={this.handleEditName}
                    onBlur= {this.onBlurUserName}
                    InputProps={{
                        disableUnderline: true,
                        classes: { input: classes["editNameStyle"] },
                        readOnly: this.handleReadOnly()
                    }}
                    onClick={this.onEditProfile}
                    color="primary"
                    className={classes.nameTextfeild}                    
                    style={{
                        "& .MuiInputLabel-root": { color: 'green' },
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "orange" },
                        },
                    }}
                />
                {this.state.editNameError && (
                    <Typography style={{ color: 'white', fontStyle: 'italic'  }}>{this.state.editNameError}</Typography>
                )}

                

                <Box className={classes.styledBox2}>
                    <Datetime
                        data-test-id="date_set"
                        dateFormat={"DD/MM/YYYY"}
                        locale="en"
                        timeFormat={false}
                        strictParsing={true}
                        initialViewDate={moment().subtract(18, 'year')}
                        input={true}
                        value={this.state.selectDate == "" ? "DD/MM/YYYY" : this.state.selectDate}
                        isValidDate={this.disableFutureDt}
                        onChange={this.onChangeDate}
                        className={`date-input-schedule`}

                        inputProps={{
                            placeholder: "Select Date",
                            className: `date_picker_icon ${classes.input_theme_style}`,
                            disabled: this.handleReadOnly()
                        }}
                        renderView={(mode, renderCalendar) => (
                            <div style={{ direction: "ltr" }}>{renderCalendar()}</div>
                        )}
                        closeOnSelect
                    />
                </Box>
            </Grid>
        )
    }

    ownerEditDetailSection = (classes: any, t: any) => {
        return (
            <Box className={classes.styledBox3} mt={4}>
                <Box style={{ width: '100%', display: 'flex', justifyContent: "space-between" }}>
                    <Typography className={classes.font1}>{t("accountInfo.editdetails")}</Typography>
                    <CloseIcon className={classes.font1} cursor="pointer" data-test-id="close_phone"
                        onClick={this.closeEditPhoneBox} />
                </Box>

                <Divider style={{ backgroundColor: '#3C4557', height: '2px', width: '100%' }} />

                {/* Old Phone OTP Sucess Message */}
                {this.state.validationSucess === true &&
                    <Box style={{ margin: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }}>
                        <img src={successIcon} />
                        <Typography className={classes.OTPFonts}>{t("accountInfo.validateSucPhoneMsg")}</Typography>
                    </Box>
                }
                {/* New Phone Sucess Message */}
                {this.state.newPhoneVerified === true &&
                    <Box style={{ margin: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }}>
                        <img src={successIcon} />
                        <Typography className={classes.OTPFonts}>{t("accountInfo.newPhoneSucesMsg")}</Typography>
                    </Box>
                }
                <Box style={{ marginTop: 20 }}>
                    <StyledTextField
                        variant='outlined'
                        fullWidth
                        value={this.state.editPhoneNumber}
                        onChange={this.handlePhoneNumberChange}
                        className={classes.nameTextfeild}
                        placeholder={t("newplaceholder.newphone")}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: this.handleReadOnlySuccess(),
                            classes: { input: classes["editNameStyle"] },
                            style: {
                                color: "#",
                                fontFamily: "Araboto",
                                opacity: 1,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    {this.state.openPhoneOtpBox !== true &&
                                        <Box
                                            style={{
                                                color: "#red",
                                                fontFamily: "Araboto",
                                                opacity: 1,
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                direction: 'ltr'
                                            }}
                                            className={classes.nameTextfeild}
                                        >
                                            {this.state.editPhoneNumber.length > 1 && <CustomCountryCodePicker
                                                data-test-id="country_codes"
                                                {...this.props} countryList={this.state.showCountryList} selectedCountryCode={this.state.selctCountryCode} selectCountryCode={this.selectCountryCode} />}
                                        </Box>}
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            style: {
                                direction: "ltr",
                                textAlign: localStorage.getItem("i18nextLng") === 'ar' ? 'end' : 'start'
                            },
                        }}
                    />
                    <Popover
                        data-test-id="close"
                        anchorEl={this.state.openList}
                        open={Boolean(this.state.openList)}
                        onClose={this.onClosePopOver}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "center",
                        }}
                        style={{
                            backgroundColor: "rgba(16,16,20, 0.60108)",
                            opacity: 1,
                        }}
                        PaperProps={{
                            className: classes.apply_coupon_modal,
                            style: {
                                maxHeight: '50%',
                                maxWidth: '350px'
                            },
                        }}
                    >
                        <Box>
                            {this.state.showCountryList?.map((_data: any) => {
                                return (
                                    <MenuItem key={_data?.id} className={classes.menuItemStyle} data-test-id="country_codes"
                                        onClick={() => this.handleCountryCodeChange("json")}>{`${_data?.country_name}  +${_data?.country_code}`}</MenuItem>
                                )
                            })}
                        </Box>
                    </Popover>
                </Box>
                {this.state.newPhoneVerified === true &&
                    <Button fullWidth className={classes.confirmBtn} onClick={this.getUpdatedPhoneNumber} variant="contained">{t("buttons.done")}</Button>
                }
                {/* Open Phone OTP box for old phone number */}
                {this.state.openPhoneOtpBox === true &&
                    <Box>
                        <Box style={{ margin: '10px 0px 10px 0px' }}>
                            <Typography className={classes.OTPFonts}>{t("accountInfo.oldPhoneOTPMsg")}</Typography>
                        </Box>
                        <Grid className={classes.OTPBoxStyle}>
                            {
                                this.state.phoneOTP.map((val, index) => {
                                    return (
                                        <input id="otp-box-phone" className={classes.otpBox} style={{ textAlign: 'center' }} type="text" value={val} data-test-id={`old_phone-${index}`}
                                            key={val?.id}
                                            maxLength={1}
                                            onChange={(e) => this.handlePhoneOTPChange(e.target, index)}
                                            onKeyDown={(e: any) => this.handlePhoneKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    )
                                })
                            }
                        </Grid>
                        <Box>
                            <Typography style={{ color: 'red' }}>{this.state.showPhoneErrMsg}</Typography>
                        </Box>
                        <Box style={{ textAlign: 'end' }}>
                            {this.state.startTimer === 0 ? <Typography data-test-id="resend_phone_otp" className={classes.otpTimer}
                                style={{ fontSize: 12, cursor: 'pointer', color: '#EA435D', textDecoration: 'underline' }}
                                onClick={() => this.resendPhoneOTP('newPhone')}>
                                {t("newplaceholder.resend")}
                            </Typography> : <Typography className={classes.otpTimer} >
                                {this.state.startTimer < 10 ? `00:0${this.state.startTimer}` : `00:${this.state.startTimer}`}
                            </Typography>}
                        </Box>
                        <Button fullWidth className={classes.confirmBtn} onClick={this.verifyPhoneOTP} variant="contained">{t("buttons.confirm")}</Button>
                    </Box>
                }
                {
                    this.state.validationSucess === true &&
                    <Box className={classes.buttonBoxStyle}>
                        <Button 
                        className={classes.continueButton} 
                        onClick={this.updatePhoneNumber}
                        variant="contained"
                        >
                            {t("buttons.savechanges")}
                        </Button>
                    </Box>
                }
                {/* Edit phone and OTP box for new phone number */}
                {this.state.openNewPhoneOTPBox === true &&
                    <Box>
                        <Box style={{ margin: '10px 0px 10px 0px' }}>
                            <Typography className={classes.OTPFonts}>{t("accountInfo.otSendNewPhone")}</Typography>
                        </Box>
                        <Grid className={classes.OTPBoxStyle}>
                            {
                                this.state.phoneOTP.map((val, index) => {
                                    return (
                                        <TextField className={classes.otpBox} style={{ textAlign: 'center' }} type="text" value={val} data-test-id={`new_phone-${index}`}
                                            key={val?.id}
                                            variant="outlined"
                                            onChange={(e) => this.handlePhoneOTPChange(e.target, index)}
                                            onKeyDown={(e: any) => this.handlePhoneKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    )
                                })
                            }
                        </Grid>
                        <Box>
                            <Typography style={{ color: 'red' }}>{this.state.showNewPhoneErr}</Typography>
                        </Box>
                        <Box style={{ textAlign: 'end' }}>
                            {this.state.startTimer === 0 ? <Typography data-test-id="resend_new_phone" className={classes.otpTimer}
                                style={{ fontSize: 12, cursor: 'pointer', color: '#EA435D', textDecoration: 'underline' }}
                                onClick={this.resendNewPhoneOTP}>
                                {t("newplaceholder.resend")}
                            </Typography> : <Typography className={classes.otpTimer} >
                                {this.state.startTimer < 10 ? `00:0${this.state.startTimer}` : `00:${this.state.startTimer}`}
                            </Typography>}
                        </Box>
                        <Button fullWidth className={classes.confirmBtn} onClick={this.veryNewPhoneOTP} variant="contained">{t("buttons.confirm")}</Button>
                    </Box>
                }
            </Box>
        )
    }
    ownerEmailPhoneDetails = (classes: any, t: any) => {
        return (
            <Box className={classes.styledBox3} data-test-id='parent'>
                <Box style={{ width: '100%' }}>
                    {/* <Typography className={classes.font1}>{t("accountInfo.accountdetails")}</Typography> */}
                    {/* <Divider style={{ backgroundColor: '#3C4557', height: '2px', width: '100%' }} /> */}
                     {/* Phone Box */}
                    <Box style={{ marginTop: 20 }}>
                        <StyledTextField
                            data-test-id="emailOLdOtp"
                            variant='outlined'
                            fullWidth
                            value={this.state.editPhoneNumber}
                            onChange={this.handlePhoneNumberChange}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                classes: { input: classes["editNameStyle"] },
                                endAdornment: (
                                    <InputAdornment position="end" style={{ backgroundColor: '#212330' }}>
                                        <IconButton className={classes.iconbtn2}
                                            onClick={() => this.sendPhoneOTP('existingPhone')} >
                                            <EditIcon className={classes.editIcon} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: {
                                    direction: "ltr",
                                    textAlign: localStorage.getItem("i18nextLng") === 'ar' ? 'end' : 'start'
                                },
                            }}
                            className={classes.nameTextfeild}
                        />
                    </Box>
                    {/* Email Box */}
                    <Box style={{ marginTop: 20 }}>
                        <StyledTextField
                            data-test-id="emailOLdOtp"
                            variant="outlined"
                            fullWidth
                            value={this.state.editEmail}
                            onChange={this.handleEmailChange}
                            InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                                classes: { input: classes["editNameStyle"] },
                                endAdornment: (
                                    <InputAdornment position="end" style={{ backgroundColor: '#212330' }}>
                                        <IconButton data-test-id="send_email" className={classes.iconbtn2}
                                            onClick={() => this.sendEmailOldOtp('existingEmail')}>
                                            <EditIcon className={classes.editIcon} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.nameTextfeild}
                        />
                    </Box>

                   
                </Box>
            </Box>
        )
    }

    ownerEditEmailDetails = (classes: any, t: any) => {
        return (
            <Box className={classes.styledBox3} mt={4}>
                <Box style={{ width: '100%', display: "flex", justifyContent: "space-between" }}>
                    <Typography className={classes.font1}>{t("accountInfo.editdetails")}</Typography>
                    <CloseIcon className={classes.font1} cursor="pointer" data-test-id="close_email"
                        onClick={this.closeEditEmailBox} />
                </Box>
                <Divider style={{ backgroundColor: '#3C4557', height: '2px', width: '100%' }} />

                {/* Old Email Sucess Message */}
                {this.state.validationSucess === true &&
                    <Box style={{ margin: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }}>
                        <img src={successIcon} />
                        <Typography className={classes.OTPFonts}>{t("accountInfo.validateSucMsg")}</Typography>
                    </Box>
                }
                {/* New Email Sucess Message */}
                {this.state.newEmailVerified === true &&
                    <Box style={{ margin: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }} >
                        <img src={successIcon} />
                        <Typography className={classes.OTPFonts}>{t("accountInfo.newEmailVarify")}</Typography>
                    </Box>
                }
                <Box style={{ marginTop: 20 }}> 
                    <StyledTextField
                        variant='outlined'
                        fullWidth
                        value={this.state.editEmail}
                        onChange={this.handleEmailChange}
                        placeholder={t("newplaceholder.newemail")}
                        InputProps={{
                            disableUnderline: true,
                            readOnly: this.handleReadOnlySuccess(),
                            classes: { input: classes["editNameStyle"] },
                        }}
                        className={classes.nameTextfeild}
                    />
                </Box>
                {this.state.newEmailVerified === true &&
                    <Button fullWidth className={classes.confirmBtn} onClick={this.getUpdatedEmail} variant="contained">{t("buttons.done")}</Button>
                }
                {/* Open Email OTP box for old email */}
                {this.state.openEmailOtpBox === true &&
                    <Box>
                        <Box style={{ margin: '10px 0px 10px 0px' }}>
                            <Typography className={classes.OTPFonts}>{t("accountInfo.oTPSendMsgforemail")}{this.state.editEmail}</Typography>
                        </Box>
                        <Grid className={classes.OTPBoxStyle}>
                            {
                                this.state.emailOTP.map((val, index) => {
                                    return (
                                        <input id="otp-box-email" className={classes.otpBox} style={{ textAlign: 'center' }} type="text" value={val} data-test-id={`old-${index}`}
                                            key={val?.id}
                                            maxLength={1}
                                            onChange={(e) => { this.handleEmailOTPChange(e.target, index) }}
                                            onKeyDown={(e: any) => this.handleEmailKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    )
                                })
                            }
                        </Grid>

                        <Box>
                            <Typography style={{ color: 'red' }}>{this.state.showErrMsg}</Typography>
                        </Box>
                        <Box style={{ textAlign: "end" }}>
                            {this.state.startTimer === 0 ? <Typography data-test-id="resend_email" className={classes.otpTimer} style={{ fontSize: 12, cursor: 'pointer', color: '#EA435D', textDecoration: 'underline' }}
                                onClick={() => this.resendEmailOTP('newEmail')}>
                                {t("newplaceholder.resend")}
                            </Typography> : <Typography className={classes.otpTimer} >
                                {this.state.startTimer < 10 ? `00:0${this.state.startTimer}` : `00:${this.state.startTimer}`}
                            </Typography>}
                        </Box>
                        <Button fullWidth className={classes.confirmBtn} onClick={this.varifyOldEmailOTP} variant="contained">{t("buttons.confirm")}</Button>
                    </Box>
                }
                {
                    this.state.validationSucess === true &&
                    <Box className={classes.buttonBoxStyle}>
                        <Button className={classes.continueButton} onClick={this.updateEmail} variant="contained" >{t("buttons.savechanges")}</Button>
                    </Box>
                }
                {/* Edit Email and OTP box for new Email */}
                {this.state.openNewEmailOTPBox === true &&
                    <Box>
                        <Box style={{ margin: '10px 0px 10px 0px' }}>
                            <Typography className={classes.OTPFonts}>{t("accountInfo.oTPSendMsgforemail")}{this.state.editEmail}</Typography>
                        </Box>
                        <Grid className={classes.OTPBoxStyle}>
                            {
                                this.state.emailOTP.map((val, index) => {
                                    return (
                                        <input className={classes.otpBox} style={{ textAlign: 'center' }} type="text" value={val} data-test-id={`new-${index}`}
                                            key={val?.id}
                                            maxLength={1}
                                            onChange={(e) => this.handleEmailOTPChange(e.target, index)}
                                            onKeyDown={(e: any) => this.handleEmailKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    )
                                })
                            }
                        </Grid>
                        <Box>
                            <Typography style={{ color: 'red' }}>{this.state.showNewEmailErr}</Typography>
                        </Box>
                        <Box style={{ textAlign: 'end' }}>
                            {this.state.startTimer === 0 ? <Typography data-test-id="resend_phone" className={classes.otpTimer}
                                style={{ fontSize: 12, cursor: 'pointer', color: '#EA435D', textDecoration: 'underline' }}
                                onClick={this.resendNewOTP}>
                                {t("newplaceholder.resend")}
                            </Typography> : <Typography className={classes.otpTimer} >
                                {this.state.startTimer < 10 ? `00:0${this.state.startTimer}` : `00:${this.state.startTimer}`}
                            </Typography>}
                        </Box>
                        <Button fullWidth className={classes.confirmBtn} onClick={this.varifyNewEmailOTP} variant="contained" >{t("buttons.confirm")}</Button>
                    </Box>
                }
            </Box>
        )
    }
    ownerScreen = (classes: any, t: any) => {
        return (
            <>
            {this.props.onChangeTab === "" && <>
                <Grid >
                    <p style={{ color: 'white', fontSize: 22, fontWeight: 700, fontFamily: 'Roboto', paddingLeft: 30, paddingTop: 8, paddingRight:30}}>{t("profile.headerTitle")}</p>
                </Grid >
                <Grid item xs={12} className={classes.underLine}></Grid>
            </>}
                <Grid container justifyContent="center" spacing={5} className={classes.mainContainer}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Grid container spacing={3} direction="column">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container justifyContent="center">
                                    {this.profileChange(classes, t)}
                                </Grid>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                {this.nameAndDate(classes, t)}

                                {/* Email and Phone Details Section */}
                                {this.state.AccountInfoBox === true && this.ownerEmailPhoneDetails(classes, t)}

                                {/* Edit Email Details Box */}
                                {this.state.openEditDetailsBox === true && this.ownerEditEmailDetails(classes, t)}

                                {/* Edit Details Section For Phone Number */}
                                {this.state.openEditPhoneDetailsBox === true && this.ownerEditDetailSection(classes, t)}

                                {!this.state.openEditDetailsBox && (
                                    <Box className={classes.buttonBoxStyle}>
                                        <Button className={classes.continueButton} onClick={this.handleSaveUserChanges}
                                        variant="contained" disabled={!this.state.editProfile}>
                                            {t("buttons.savechanges")}
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>

        )
    }
    memberScreen = (classes: any, t: any) => {
        return (
            <Grid container spacing={5} className={classes.mainContainer}>
                <Grid item xs={12} className={classes.griditem2}>
                    <IconButton data-test-id="ref_1" className={classes.iconbtn} onClick={this.onClickEditIcon}>
                        <input hidden data-test-id="profile_change" ref={this.inputRef} type="file" onChange={this.onMemberProfileChange} />
                        <EditIcon data-test-id="memberSave" className={classes.editIcon} onClick={this.handleMemberSave} />
                    </IconButton>
                    <Avatar alt="#" src={this.state.memberImagePreview} className={classes.avatar} />
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3} direction="column">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Box className={classes.styledBox1}>
                                <StyledTextField
                                    variant='outlined'
                                    data-test-id="edite_member_name"
                                    fullWidth
                                    value={this.state.memberName}
                                    onChange={this.handleEditMemberName}
                                    onBlur={this.onBlurMemberName}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: { input: classes["editNameStyle"] },
                                    }}
                                    className={classes.nameTextfeild}
                                />
                            </Box>
                            {this.state.memberNameError && (
                                    <Typography className={classes.errorTypo}>{t("profile.errorNameReqMsg")}</Typography>
                                )}
                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }} item xs={12}>
                            <Button variant="contained" data-test-id="save_member_details" className={classes.saveChanges}
                            disabled={this.state.isSaveButtonDisabled} onClick={this.saveMemberChanges}>{t("buttons.savechanges")}</Button>
                            <Button className={classes.delteBtn} data-test-id="delete_profile" onClick={this.handleDelteProfile}>{t("accountInfo.deleteprofiles")}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const { t }: any = this.props;
        return (
            <>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Container style={{padding:0}} maxWidth={false}>
                    {/* Customizable Area Start */}
                    {this.props.profileType === "Owner" ?
                        this.ownerScreen(classes, t)
                        :
                        // Member Details Section
                        this.memberScreen(classes, t)
                    }
                    {/* Customizable End Start */}
                </Container>
                {
                    this.state.deleteProfile && <DeleteProfile {...this.props} deleteDialog={this.state.deleteProfile} data-test-id="delete"
                        onCloseDeleteDialog={this.onClosedeleteDialog}
                        userId={this.props.profileId}
                        onSuccessFullyDeleted={this.onSuccessFullyDeleteDialog}
                    />
                }
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledTextField = withStyles({
    root: {
        fontSize: "14",
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontSize: "14",
            fontWeight: 400,
        },
        "& .MuiInputBase-input-283:focus": {
            backgroundColor: "transparent",
            borderColor: "5px solid black !important",
            outline: 'none'
        },
    },
})((props: any) => <TextField {...props} />);

export const useStyles = (theme: any) => {
    let themeDirection = (arrValue: any, enValue: any) => theme.direction == "rtl" ? arrValue : enValue;
    let themePalette = (lightValue: any, darValue: any) => theme.palette.type == 'dark' ? lightValue : darValue;
    return {
        font1: {
            padding: '14px 15px 14px 10px',
            color: `${theme.palette.type == "dark" && '#B5B9CE'}`,
            fontSize: '16px'
        },

        iconbtn: {
            backgroundColor: themePalette('#EA435D', "#c5bfbf"),
            top: '50px',
            left: themeDirection('8px', '90px'),
            zIndex: 1,
            padding: '5px',
            border: '1px solid white',
            '&:hover': {
                backgroundColor: themePalette('#EA435D', '#c5bfbf')
            },
            "&:disabled": {
                backgroundColor: themePalette('#EA435D', '#c5bfbf')
            }
        },

        OTPFonts: {
            color: `${theme.palette.type == "dark" && '#B1B6CD'}`,
            fontSize: '14px',
            fontWeight: 400
        },
        input_theme_style: {
            color: theme.palette.primary.main,
            backgroundColor: '#212121',
            fontWeight: 500,
            fontSize: 15,
            backgroundRepeat: "no-repeat ",
            backgroundImage: themePalette(`url(${calenderGradient})`, `url(${calenderGradient})`),
            backgroundSize: "20px 20px ",
            backgroundPosition: themeDirection("top 17px left 20.3px ", "top 17px right 20.3px"),
            input_theme_style: {
            color: theme.palette.primary.main,
            backgroundColor: '#212121',
            fontWeight: 500,
            fontSize: 15,
            backgroundRepeat: "no-repeat ",
            backgroundImage: themePalette(`url(${calenderGradient})`, `url(${calenderGradient})`),
            backgroundSize: "20px 20px ",
            backgroundPosition: themeDirection("top 17px left 20.3px ", "top 17px right 20.3px"),
        },
        },
        iconbtn2: {
            backgroundColor: themePalette('#EA435D', '#c5bfbf'),
            padding: '2px',
            border: '1px solid white',
            '&:hover': {
                backgroundColor: themePalette('#EA435D', '#c5bfbf'),
            }
        },

        editIcon: {
            fontSize: '15px',
            color: themePalette('white', 'red'),
        },

        avatar: {
            height: '110px',
            width: '110px',
            border: '1px solid white',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                alignItems: 'center'
            },
        },

        nameTextfeild: {
            width: "100%",
            borderRadius: "10px !important",
            alignSelf: "center",
            backgroundColor: themePalette('rgba(33, 35, 48, 1)', '#D3D3D3'),
            fontWeight: 500,
            fontFamily: 'Araboto',
            fontSize: 14,
            color: theme.palette.primary.main,
        },

        mainContainer: {
            paddingBottom: 20,
            justifyContent: "center",
            alignItems: 'center',
        },

        styledBox1: {
            backgroundColor: themePalette('rgba(33, 35, 48, 1)', 'none'),
            borderRadius: 20,
            height: 45,
            display: 'flex',
            marginBottom: 10
        },
        errorTypo: {
            color: "white",
            fontStyle: 'italic'
        },
        styledBox2: {
            background: themePalette('#212330', 'red'),
            borderRadius: 10,
            height: 45,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20
        },

        styledBox3: {
        },

        buttonBoxStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 30,
        },

        saveChanges: {
            color: themePalette('#FFFFFF', 'primary'),
            borderRadius: 8,
            fontSize: '18px',
            fontWeight: 700,
            backgroundColor: '#EA435D',
            padding: "5px 15px 5px 15px",
            width: '65%',
            '&:hover': {
                backgroundColor: '#EA435D',
            },
            maxHeight: '45px'
        },

        continueButton: {
            color: themePalette("#FFFFFF", "primary"),
            borderRadius: 8,
            fontSize: '20px',
            fontWeight: 700,
            padding: "5px 15px 5px 15px",
            width: '100%',
            height: '54px',
            lineHeight: '22px',
            '&:hover': {
                backgroundColor: '#EA435D',
            }
        },

        delteBtn: {
            borderRadius: 8,
            fontSize: '14px',
            fontWeight: 400,
            width: '40%',
            color: "#EA435D",
            textDecoration: 'underline',
            justifyContent: 'end',
            '&:hover': {
                textDecoration: 'underline',
                background: "none"
            }
        },

        griditem2: {
            flexBasis: 'auto',
        },

        otpBox: {
            height: '57px',
            width: '75%',
            borderRadius: '10px',
            fontWeight: 600,
            fontSize: '16px',
            background: themePalette('#212121', 'transparent'),
            border: themePalette('none', "1px solid black"),
            boxShadow: themePalette('none', "6px 6px 12px RGBA(0, 0, 0, 0.2)"),
            caretColor: '#EA435D',
            color: `${theme.palette.type == "dark" && 'white'}`,
            outline: 'none',
        },

        otpTimer: {
            color: `${theme.palette.type == "dark" && '#B1B6CD'}`,
            fontSize: 12,
            cursor: 'pointer',
            maxWidth: '370px',
        },

        confirmBtn: {
            color: themePalette('#FFFFFF', "primary"),
            borderRadius: 8,
            fontSize: '18px',
            fontWeight: 700,
            marginTop: 15,
            '&:hover': {
            }
        },
        datepicker: {
            backgroundColor: themePalette("#212330", "#D3D3D3"),
            border: themePalette("none", "1px solid grey"),
            width: '100%',
            height: 55,
            borderRadius: 7,
            paddingBottom: '5px',
            fontSize: "16px",
            fontWeight: 400,
            outline: 'none',
            color: themePalette("FFFFFF", "black"),
            padding: 10,
            "&::-webkit-calendar-picker-indicator": {
                background: `url(${calender})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "16px",
                filter: theme.palette.type === 'dark' ? 'brightness(100%)' : 'brightness(10%)',
                fontSize: "20px",
            }
        },

        apply_coupon_modal: {
            width: 320,
            backgroundColor: '#fff',
            backgroundSize: "100% 100%",
            boxShadow: "-6px 8px 25px rgba(0,0,0, 0.4)",
            padding: 0.5,
            [theme.breakpoints.only("xs")]: {
                width: "80%",
                maxWidth: 320,
            },
        },

        menuItemStyle: {
            fontSize: 14,
            minHeight: 20,
            color: `${theme.palette.type == "dark" && '#7A7B7F'}`,
            paddingTop: 10,
            borderBottom: '1px solid #a8a3a3',
            justifyContent: 'center'
        },

        editNameStyle: {
            color: theme.palette.primary.main,
            "&::placeholder": {
                color: theme.palette.primary.main,
            },
        },

        OTPBoxStyle: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            maxWidth: 400,
            gridGap: "0px",
            margin: "20px 0",
        },

        underLine: {
            marginTop: '1px',
            height: '1.5px',
            width:'120%',
            background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
            
        },
    }
};
export default withStyles(useStyles)(withTranslation()(EmailVerificationWeb))
// Customizable Area End
