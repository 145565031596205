Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start	
exports.errorEmailNotValid = "Email not valid.";	
exports.errorPasswordNotValid = "Password not valid.";	
exports.placeHolderEmail = "Email";	
exports.placeHolderPassword = "Password";	
exports.labelHeader = "The reason we require login";	
exports.btnTxtLogin = "LOG IN";	
exports.labelRememberMe = "Remember me";	
exports.enterusername = "Enter Email";	
exports.enterPassword = "Enter Password";	
exports.errorMsg = "User Not Found";	
exports.submitBtn = "Submit";	
exports.admin = "Admin";	
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";	
exports.labelOr = "OR";	
exports.labelTitle = "Log in";	
exports.passwordErrorMsg = "Password must contain at least 8 characters, one uppercase and lowercase letter, one number and one special character";	
exports.PostMethod = "POST";	
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";	
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";	
exports.loginUrl = "bx_block_login/logins/;";	
exports.deviceNotificationUrl = "bx_block_devices/devices";	
exports.myApiEndPoint = "login/login";	
exports.userLoginAPIEndPoint = "bx_block_login/logins";	
exports.accountInfo = "Account Information";	
exports.oldPhoneOTPMsg = "We have sent you the 4 digit code at your registered mobile number.";	
exports.oTPSendMsg = "Check your email. We have sent you the 4 digit code at ";	
exports.validateSucMsg = "OTP Code verified successfully! Now you can enter the new Mail Id.";	
exports.newEmailVarify = "Your new mail id is verified successfully!";	
exports.validateSucPhoneMsg = "OTP Code verified successfully! Now you can enter the new mobile number.";	
exports.otSendNewPhone = "We have sent you the 4 digit code on your new mobile number.";	
exports.newPhoneSucesMsg = "Your new phone number is verified successfully!";	
exports.postMethod = "POST";	
exports.dummyPassword = "Guest@123"
exports.guestType = "guest"
exports.getMethod = "GET";	
exports.almanshaEmail = "@almasha.com"
exports.editProfileEndPoints = "/account_block/accounts/update_account";	
exports.getAccountInfoAPIEndPoints = "/account_block/accounts/";	
exports.getMemberInfo = "/bx_block_profile/profiles/";	
exports.sendEmailOTPAPIEndPoints = "/account_block/accounts/send_opt_to_phone";	
exports.getPerferencePoint = "bx_block_categories/genre";
exports.postPereferencePoint="bx_block_profile/profiles/set_preferences"
exports.verifyEmailOTPAPIEndPoints ="/account_block/accounts/varify_opt_for_phone";	
exports.updateEmailAPIEndPoints = "/account_block/accounts/update_phone";	
exports.editMemberProfileEndPoints = "/bx_block_profile/profiles/update_profile";	
exports.getCountryCodeEndPoint = "/account_block/accounts/get_country_codes";	
exports.deviceTokenEndPoints = "bx_block_devices/devices";	
exports.getUserProfileEndPoint = "bx_block_profile/profiles/user_profiles";	
exports.validationApiMethodTypePOST = "POST";	
exports.userLoginAPIEndPoint = "bx_block_login/logins";	
exports.loginTitle = "Login";	
exports.userNameTitle = "Username";	
exports.hiThereTitle ="Hi, There..!!";	
exports.welcomeBack ="Welcome back! You have been missed.";	
exports.loginBtn = "Login";	
exports.email_field="username";	
exports.pass_field="password";	
exports.submit = "submit";
exports.enterEmail = "Enter Email";
exports.enterPass = "Enter Password";
exports.bbToken = "bbtoken";
exports.admin = "Admin";
exports.unf = "User Not found";
// Customizable Area End	
