//@ts-nocheck
export type ThemeMode = 'dark' | 'light'
export interface Theme {
  light: ThemeItem
  dark: ThemeItem
}
export type ColorValue = string;
export interface ThemeItem {
  white: ColorValue | undefined

  color: string
  backgroundColor: string
  highlight: string
  textColor: string
  lightGray: string
  secondaryColor: string
  secondaryColor2: string
  placeHolderColor: string
  textHolderColor: string
  headeBg: string
  menuColor: string
  searchPlaceHolderTxtColor: string
  curvedBoxView: string
  heading: string
  subHeading: string
  dialogColor: string
  darkgray: string,
  modalBackground: string,
  inactiveBG: string,
  buttonBackground: string,
  disabled: string,
  gray2: string,
  black: string,
  overlay:string,
  redColor:string
}
export const theme = {
  light: {
    color: "rgba(33, 35, 48, 1)",
    backgroundColor: "#F7F7F7",
    highlight: "#ffffff",
    textColor: 'rgba(16, 16, 20, 1)',
    lightGray: '#F4F8F5',
    secondaryColor: 'rgba(215,215,215,255)',
    secondaryColor2: 'rgba(33,35,47,255)',
    placeHolderColor: 'rgba(33, 35, 48, 1)',
    textHolderColor: "rgba(16, 16, 20, 1)",
    headeBg: '#F4F8F5',
    menuColor: "rgba(33, 35, 48, 1)",
    searchPlaceHolderTxtColor: "#74797E",
    curvedBoxView: "#fff",
    heading: "#000",
    subHeading: "rgba(33, 35, 48, 1)",
    dialogColor: "rgba(215,215,215,255)",
    borderColor: "grey",
    redColor: "rgba(234, 67, 93, 1)",
    darkgray: "#c5c5c5",
    modalBackground: 'rgb(255,255,255)',
    inactiveBG: 'rgba(215,215,215,255)',
    buttonBackground: '#ea435d',
    disabled: 'rgb(122, 123, 127)',
    gray2: '#909090',
    black: '#fff',
    white: '#000',
    overlay: 'rgba(255,255,255,0.7)',
    greenColor:'rgb(0, 255, 0)'

  },
  dark: {
    color: "rgba(104, 105, 172, 1)",
    backgroundColor: "rgb(16, 16, 20)",
    highlight: "#343643",
    textColor: 'rgba(231, 233, 240, 1)',
    lightGray: '#74797E',
    secondaryColor: 'rgba(33, 35, 48, 1)',
    secondaryColor2: 'white',
    placeHolderColor: 'rgba(231, 233, 240, 0.8)',
    textHolderColor: "#c4c4c4",
    headeBg: '#000000aa',
    menuColor: "rgba(177, 182, 205, 1)",
    searchPlaceHolderTxtColor: "rgba(122, 123, 127, 1)",
    curvedBoxView: "#242329",
    heading: "rgba(255, 255, 255, 1)",
    subHeading: "rgba(231, 233, 240, 1)",
    dialogColor: "rgba(33,35,48,255)",
    borderColor: "white",
    darkgray: "rgba(37,40,48,255)",
    modalBackground: 'rgba(33,35,47,255)',
    inactiveBG: 'rgba(33,35,47,255)',
    buttonBackground: '#ea435d',
    disabled: 'rgb(122, 123, 127)',
    gray2: '#383a49',
    black: '#000',
    white: '#fff',
    portage: '#A1a1f1', 
    redColor: "rgba(234, 67, 93, 1)",
    overlay: 'rgba(0,0,0,0.7)',
    greenColor:'rgb(0, 255, 0)'
  }
};
