export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
 export const newIcon = require("../assets/NewsIcon1.png");
 export const splash = require("../assets/userProfile.png");
export const calendar = require("../assets/calendar.png");
export const bod = require("../assets/bod.png");
export const whiteUserIcon = require("../assets/whiteUserIcon.png");
export const userScreenIcon = require("../assets/userScreenIcon.png");
export const whitemobileIcon = require("../assets/whitemobileIcon.png");
export const mobileIcon = require("../assets/mobileIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const generalSetting = require("../assets/generalSetting.png");
export const logOut = require("../assets/logoutnew.png");
export const logoutDialog = require("../assets/logoutDialog.png");
export const dilaogStar = require("../assets/dilaogStar.png");
export const whiteplayIcon = require("../assets/whiteplayIcon.png");
export const vedioPlayerIcon = require("../assets/vedioPlayerIcon.png");
export const whiteDownlosdIcon = require("../assets/whiteDownlosdIcon.png");
export const downloadIcon = require("../assets/downloadIcon.png");
export const clickButton = require("../assets/clickButton.png");
export const image_hprizontal_line = require("../assets/image_hprizontal_line.png");
export const line_horizontalcolor = require("../assets/line_horizontalcolor.png");
export const SearchLightImageBackground = require("../assets/SearchLightImageBackground.png");
export const createProfileLogo=require("../assets/createProfileLogo.png");
export const addProfileIcon=require("../assets/addProfileIcon.png");
export const backgroundImagePng = require("../assets/Group_2.png");
export const user_img = require("../assets/portrait.png");
export const download_img = require("../assets/download.png");
export const mobile_img = require("../assets/Shape.png");
export const add_icon = require("../assets/add-1.png");
export const addUserProfile = require("../assets/userprofileadd.png");
export const premiumImage = require("../assets/image-premium.png");
export const edit_icon = require("../assets/edit.png");
export const edit_icon_dark = require("../assets/edit.png");
export const edit_web_dark = require("../assets/editnew.png");
export const add_icon_light = require("../assets/Group_7light.png");
export const edit_icon_light = require("../assets/editnew.png");
export const dummy_icon = require("../assets/dummy.png");
export const profile_icon = require("../assets/Group_8.png");
export const profile_icon_light = require("../assets/Group_8light.png");
export const background_icon = require("../assets/Rectangle_3.png");
export const modalBackground = require("../assets/modalBackground.png");
export const rightTick = require("../assets/plan_tick.png");
export const closeIcon = require("../assets/closeIcon.png");
export const closeModal = require("../assets/closeModal.png");
export const fb = require("../assets/fb.png");
export const insta = require("../assets/insta.png");
export const whatsapp = require("../assets/whatsapp.png");
export const memberIcon = require("../assets/memberIcon.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const helpIcon = require("../assets/helpIcon.png");
export const settingIcon = require("../assets/settingIcon.png");
export const aboutIcon = require("../assets/aboutIcon.png");
export const shareIcons = require("../assets/shareIcons.png");
export const rightIcon = require("../assets/rightIcon.png");
export const backarrow = require("../assets/backarrow.png");
export const outerDivImg = require("../assets/outerDivImg.png");
export const subscribe = require("../assets/subscribe.png");
export const themelogo = require("../assets/themelogo.png");
export const Line = require("../assets/Line.png");
export const NewsIcon = require("../assets/newsIcon.jpeg");
export const backgroundImg = require("../assets/lightBackground.png");
export const profileDeleteIcon= require("../assets/user-delete-Icon.png")
export const editimage= require("../assets/editbutton.png")
export const logo= require("../assets/premium.png")
export const emailIcon = require('../assets/email_icon.png');
export const phoneIcon = require('../assets/phone_icon.png');
export const editButton = require('../assets/edit_button.png');
export const premiumIcon = require('../assets/premium_icon.png');
export const newsIcon = require('../assets/news_icon.png');
export const membersIcon = require('../assets/member_icon.png');
export const profileIcon = require('../assets/switch_profile_icon.png');
export const settingsIcon = require('../assets/settings_icon.png');
export const settingHelpIcon = require('../assets/setting_help_icon.png');
export const aboutsIcon = require('../assets/about_icon.png');
export const deleteAccIcon = require('../assets/delete-account-icon.png');
export const logoutSettingIcon = require('../assets/setting_logout_icon.png');
export const calendarIcon = require('../assets/calendar_icon.png');
export const editCircleIcon = require('../assets/edit_circle_icon.png');
export const linearArrow = require('../assets/linear_arrow.png');
export const imageBackground = require('../assets/image_bg.jpg');
export const plan_tick = require('../assets/plan_tick.png');
export const logoutImage = require('../assets/logout.png');
export const privacy = require('../assets/termOfUse.png');
