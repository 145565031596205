import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utility";
import i18next from "i18next";
import { dummy_icon } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  isMatch: any;
  isMatch1: any;
  profileDialog: boolean;
  showNotification: boolean;
  userDetails: any;
  sideBar: boolean;
  menuOpen: boolean;
  mobileMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  SearchMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  notificationList: any[];
  pageNo: number;
  seeAllNoti: boolean;
  isreadNotifList: any;
  allNotificationLength: any[];
  seeAllNotiList: any;
  languageList: any;
  selectedLanguage: string;
  anchorEl: any;
  categoryList: any[];
  allNotificationList: any[]
  isGuestUser: boolean
  selectedMenu: string;
  notificationAnchor: any;
  hideShowState: boolean;
  settingAnchor: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DrawerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  mobileMenuRef?: any;
  searchMenuRef?: any;
  getAllNotificationAPICallId: any = "";
  seeAllNotificationAPICallId: string = "";
  getCategoryListAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      imgIndex: null,
      isMatch: '',
      isMatch1: '',
      profileDialog: false,
      showNotification: false,
      mobileMenuPosition: undefined,
      SearchMenuPosition: undefined,
      userDetails: {},
      notificationList: [],
      pageNo: 1,
      seeAllNoti: false,
      isreadNotifList: null,
      allNotificationLength: [],
      seeAllNotiList: null,
      sideBar: false,
      menuOpen: false,
      allNotificationList: [],
      languageList: [
        {
          id: '1',
          code: 'en',
          name: 'EN',
        },
        {
          id: '2',
          code: 'ar',
          name: 'AR',
        },
      ],
      categoryList: [],
      selectedLanguage: localStorage.getItem("i18nextLng") || '',
      isGuestUser: false,
      anchorEl: null,
      selectedMenu: "NewsWeb",
      notificationAnchor: null,
      hideShowState: false,
      settingAnchor: null

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.mobileMenuRef = React.createRef();
    this.searchMenuRef = React.createRef();
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let isGuest = await getStorageData("isGuestUser");
    if ((await isGuest) === "true") {
    this.setState({ isGuestUser: true });
   }
    const user_data = localStorage.getItem('selected_user_data');
    const data = JSON.parse(user_data || '{}');
    this.setState({ userDetails: data })    
    // this.getCategoryList()
    this.getSidebarToggle()
    window.addEventListener("storage", () => {
      const user_data1 = localStorage.getItem('selected_user_data');
      const data1 = JSON.parse(user_data1 || '{}');
      if (JSON.stringify(this.state.userDetails) !== JSON.stringify(user_data1)) {
        this.setState({ userDetails: data1 })
      }
    })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleAPIResponse(responseJson)
      } else {
        this.handleAPIResponseError(errorReponse)
      }
      switch (apiRequestCallId) {
        case this.getCategoryListAPICallId:
          this.handleCategoryResponse(responseJson)
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // handers
  openMobileMenu = (event: any) => {
    this.setState({
      isMatch: event.currentTarget
    })
  }
  closeMobileMenu = () => {
    this.setState({
      isMatch: null
    })
  }

  handleMenuClick = (menu:string) => {
    this.setState({ selectedMenu : menu});
  };

  
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  onClickCategory = (categoryId: any) => {
    this.props.navigation.navigate(`DashboardWeb`, { id: categoryId });
    this.handleCloseMenu();
    this.handleDrawerToggle();
  }

  openMobileMenu1 = (event: any) => {
    this.setState({
      isMatch1: event.currentTarget
    })
  }
  closeMobileMenu1 = () => {
    this.setState({
      isMatch1: null
    })
  }

  handleNotification = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLImageElement>) => {
    this.setState({
      showNotification: true,
      notificationAnchor: event.currentTarget
    })
    if(this.state.isGuestUser){
      const notificationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     notificationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
            "Perks")
      notificationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(notificationMes);
    }
  }

  handleUserClick = (event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLImageElement>) => {
    this.setState({
      settingAnchor: event.currentTarget
    })
  }

  setSettingAnchor=()=>{
    this.setState({settingAnchor: null})
  }

  closeDialog = () => {
    this.setState({
      showNotification: false
    })
  }

  setNotificationAnchor(notificationAnchor: any) {
    this.setState({ notificationAnchor });
  }

  handleSearchMenuClose = () => {
    this.setState({ SearchMenuPosition: undefined });
  }

  handleDrawerToggle = () => {
    this.setState({ sideBar: !this.state.sideBar })
  }

  handleMenuToggle = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  getSelectedTab = (tabName: string) => {
    return window.location.href.toLowerCase().includes(tabName)
  }

  openProfileHandler = () => {
    this.setState({ profileDialog: true })
  }

  handleSeeAllNotification = () => {
    this.setState({
      seeAllNoti: true
    })
  }

  getCategoryList = () => {
    this.getCategoryListAPICallId = sendAPIRequest(
      configJSON.categoryListAPIEndPoints,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      }
    );
  }

  changeLanguages = (e: any) => {
    i18next.changeLanguage(e.target.value)
    this.setState({
      selectedLanguage: e.target.value
    })
    window.location.reload()
  }

  // API Response
  handleAPIResponse = (json: any) => {
    if (json.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: "",
        loading: false
      });
    }
  }

  handleAPIResponseError = (json: any) => {
    if (json === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: json,
        loading: false
      });
    }
  }

  avtarImageSrc=()=>{
    if(this.state.userDetails?.photo){
      if(this.state.userDetails?.photo?.includes("https://")) return this.state.userDetails?.photo
      else return configJSON.baseURL + this.state.userDetails?.photo
    }else return dummy_icon    
  }

  handleClickHeading=(seletedUserId:any ,url:string)=>{
    seletedUserId && this.props.navigation.navigate(url)
  }

  handleCategoryResponse = (json: any) => {
    if (json) {
        this.setState({
          categoryList: json?.data,
        })
      }
  }

  handleGalleryOpenImportModal = (status: boolean) => {
    let msgData = new Message(getName(MessageEnum.SidebarHideShowMessage))
    msgData.addData(
      getName(MessageEnum.SidebarHideShowMessageData),
      {hideShowState: status}
    )
    this.send(msgData)
  }

  changeDropdown = (value:boolean) => {
    this.setState({ hideShowState: value },() => {
      setStorageData('sidebarToggle',value)
      this.handleGalleryOpenImportModal(this.state.hideShowState);
    })
  }

  getSidebarToggle = async() => {
    let toggle = await getStorageData("sidebarToggle")
    console.log(toggle,'check toggle');
    toggle = toggle ? JSON.parse(toggle) : false
    this.handleGalleryOpenImportModal(toggle);
  }

  // Customizable Area End
}
