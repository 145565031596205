import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import SearchAdapter from "../../blocks/adapters/src/SearchAdapter";
import  SwitchAccountAdapter from "../../blocks/adapters/src/SwitchAccountAdapter";

import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import DashboardControllerAdapter from '../../blocks/adapters/src/DashboardControllerAdapter'
import CustomAdvertisementAdapter from "../../blocks/adapters/src/CustomAdvertisementAdapter";
import CountrAdminPanelAdapter from "../../blocks/adapters/src/CountrAdminPanelAdapter";
import CheckCountryAdapter from "../../blocks/adapters/src/CheckCountryAdapter";
import AddEditAdvertisementAdapter from "../../blocks/adapters/src/AddEditAdvertisementAdapter";
import BulkUploadAdapter from "../../blocks/adapters/src/BulkUploadAdapter";
import UploadResponseAdapter from "../../blocks/adapters/src/UploadResponseAdapter";
import SwitchAccountsAdapter from "../../blocks/adapters/src/SwitchAccountsAdapter";
import SwitchAccountsDashboardAdapter from "../../blocks/adapters/src/SwitchAccountsDashboardAdapter";
import AumatedPublishingAdapter from "../../blocks/adapters/src/AumatedPublishingAdapter";	
import ContentManagementAdapter from "../../blocks/adapters/src/ContentManagementAdapter";	
import AgeRestrictionAdapter from "../../blocks/adapters/src/AgeRestrictionAdapter";

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const dashboardControllerAdapter = new DashboardControllerAdapter();
const searchAdapter = new SearchAdapter();
const switchAccountAdapter = new SwitchAccountAdapter();
const CountrAdminAdapter = new CountrAdminPanelAdapter();
const CheckCountryBlock = new CheckCountryAdapter();
const AddEditAdvertisement = new AddEditAdvertisementAdapter();
const BulkUpload = new BulkUploadAdapter();
const UploadResponse = new UploadResponseAdapter();
const switchAccountsAdapter = new SwitchAccountsAdapter();
const switchAccountsDashBoardAdapter = new SwitchAccountsDashboardAdapter();
const NavigateContentAdapter = new AumatedPublishingAdapter();	
const NavigateAdminScreenAdapter = new ContentManagementAdapter();	
const NavigateToageres = new AgeRestrictionAdapter();
//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const customAdvertisement = new CustomAdvertisementAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to LocalVODSaudi!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
            <CustomTextItem content={'Scheduling'} onPress={() => navigation.navigate("Scheduling")} />
            <CustomTextItem content={'Search'} onPress={() => navigation.navigate("Search")} />
            <CustomTextItem content={'core'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'Videos'} onPress={() => navigation.navigate("Videos")} />
            <CustomTextItem content={'SocialMediaAccountRegistrationScreen'} onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
            <CustomTextItem content={'social-media-account'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'EmailAccountLoginBlock'} onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
            <CustomTextItem content={'EmailAccountRegistration'} onPress={() => navigation.navigate("EmailAccountRegistration")} />
            <CustomTextItem content={'CountryCodeSelector'} onPress={() => navigation.navigate("CountryCodeSelector")} />
            <CustomTextItem content={'ForgotPassword'} onPress={() => navigation.navigate("ForgotPassword")} />
            <CustomTextItem content={'OTPInputAuth'} onPress={() => navigation.navigate("OTPInputAuth")} />
            <CustomTextItem content={'SocialMediaAccountLoginScreen'} onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
            <CustomTextItem content={'Customisableusersubscriptions'} onPress={() => navigation.navigate("Customisableusersubscriptions")} />
            <CustomTextItem content={'Analytics'} onPress={() => navigation.navigate("Analytics")} />
            <CustomTextItem content={'Pushnotifications'} onPress={() => navigation.navigate("Pushnotifications")} />
            <CustomTextItem content={'comments'} onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
            <CustomTextItem content={'PostCreation'} onPress={() => navigation.navigate("PostCreation")} />
            <CustomTextItem content={'Categoriessubcategories'} onPress={() => navigation.navigate("Categoriessubcategories")} />
            <CustomTextItem content={'PhoneNumberInput'} onPress={() => navigation.navigate("PhoneNumberInput")} />
            <CustomTextItem content={'Customform'} onPress={() => navigation.navigate("Customform")} />
            <CustomTextItem content={'Reviews'} onPress={() => navigation.navigate("Reviews")} />
            <CustomTextItem content={'Dashboard'} onPress={() => navigation.navigate("Dashboard")} />
            <CustomTextItem content={'Catalogue'} onPress={() => navigation.navigate("Catalogue")} />
            <CustomTextItem content={'Contactus'} onPress={() => navigation.navigate("Contactus")} />
            <CustomTextItem content={'Invitefriends'} onPress={() => navigation.navigate("Invitefriends")} />
            <CustomTextItem content={'UserProfileBasicBlock'} onPress={() => navigation.navigate("UserProfileBasicBlock")} />
            <CustomTextItem content={'Sorting'} onPress={() => navigation.navigate("Sorting")} />
            <CustomTextItem content={'Filteritems'} onPress={() => navigation.navigate("Filteritems")} />
            <CustomTextItem content={'Notifications'} onPress={() => navigation.navigate("Notifications")} />
            <CustomTextItem content={'ApiIntegration'} onPress={() => navigation.navigate("ApiIntegration")} />
            <CustomTextItem content={'Payments'} onPress={() => navigation.navigate("Payments")} />
            <CustomTextItem content={'PhotoLibrary3'} onPress={() => navigation.navigate("PhotoLibrary3")} />
            <CustomTextItem content={'BulkUploading'} onPress={() => navigation.navigate("BulkUploading")} />
            <CustomTextItem content={'FriendList'} onPress={() => navigation.navigate("FriendList")} />
            <CustomTextItem content={'CustomAdvertisements'} onPress={() => navigation.navigate("CustomAdvertisements")} />
            <CustomTextItem content={'HamburgerMenu'} onPress={() => navigation.navigate("HamburgerMenu")} />
            <CustomTextItem content={'SubscriptionBilling'} onPress={() => navigation.navigate("SubscriptionBilling")} />
            <CustomTextItem content={'ContentManagement'} onPress={() => navigation.navigate("ContentManagement")} />
            <CustomTextItem content={'SplitPayments'} onPress={() => navigation.navigate("SplitPayments")} />
            <CustomTextItem content={'ContextTagging'} onPress={() => navigation.navigate("ContextTagging")} />
            <CustomTextItem content={'LiveStreaming'} onPress={() => navigation.navigate("LiveStreaming")} />
            <CustomTextItem content={'PaymentAdmin2'} onPress={() => navigation.navigate("PaymentAdmin2")} />
            <CustomTextItem content={'TargetedFeed'} onPress={() => navigation.navigate("TargetedFeed")} />
            <CustomTextItem content={'LiveChat2'} onPress={() => navigation.navigate("LiveChat2")} />
            <CustomTextItem content={'LikeAPost'} onPress={() => navigation.navigate("LikeAPost")} />
            <CustomTextItem content={'LinkShare'} onPress={() => navigation.navigate("LinkShare")} />
            <CustomTextItem content={'CfVideoTagging'} onPress={() => navigation.navigate("CfVideoTagging")} />
            <CustomTextItem content={'RecommendationEngine4'} onPress={() => navigation.navigate("RecommendationEngine4")} />
            <CustomTextItem content={'AdminConsole3'} onPress={() => navigation.navigate("AdminConsole3")} />
            <CustomTextItem content={'RolesPermissions2'} onPress={() => navigation.navigate("RolesPermissions2")} />
            <CustomTextItem content={'Settings5'} onPress={() => navigation.navigate("Settings5")} />
            <CustomTextItem content={'MerchantDashboard2'} onPress={() => navigation.navigate("MerchantDashboard2")} />
            <CustomTextItem content={'DataImportexportcsv'} onPress={() => navigation.navigate("DataImportexportcsv")} />
            <CustomTextItem content={'LanguageOptions'} onPress={() => navigation.navigate("LanguageOptions")} />
            <CustomTextItem content={'LanguageSupport'} onPress={() => navigation.navigate("LanguageSupport")} />
            <CustomTextItem content={'CfTabletScreens1'} onPress={() => navigation.navigate("CfTabletScreens1")} />
            <CustomTextItem content={'CfTabletScreen2'} onPress={() => navigation.navigate("CfTabletScreen2")} />
            <CustomTextItem content={'Themes'} onPress={() => navigation.navigate("Themes")} />
            <CustomTextItem content={'SwitchAccounts'} onPress={() => navigation.navigate("SwitchAccounts")} />
            <CustomTextItem content={'DisplayTheme2'} onPress={() => navigation.navigate("DisplayTheme2")} />
            <CustomTextItem content={'CfThirdPartyContentManagement'} onPress={() => navigation.navigate("CfThirdPartyContentManagement")} />
            <CustomTextItem content={'TermsAndConditions'} onPress={() => navigation.navigate("TermsAndConditions")} />
           
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;