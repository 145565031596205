import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  movieDataProps?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  swiper: any;
  open: any;
  isMatch: any;
  isMatch1: any;
  profileDialog: boolean;
  showNotification: boolean;
  navOnScroll: boolean;
  moviesData: any;
  movieID: any;
  assetID: any;
  moviesLoading: boolean;
  bandData: any[];
  getContinueWatchData: any[];
  categoryId: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class WebSeriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getMoviesCallId: string = "";
  getCategoryBandAPICallId: string = "";
  getContinueWatchDataAPICallId: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      swiper: null,
      imgIndex: null,
      open: false,
      isMatch: '',
      isMatch1: '',
      profileDialog: false,
      showNotification: false,
      navOnScroll: false,
      moviesData: [],
      movieID: '',
      assetID: '',
      moviesLoading: false,
      bandData: [],
      categoryId: '',
      getContinueWatchData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
   await super.componentDidMount();
      // Customizable Area Start   
    this.getCategoryBands();
    this.getContinueWatchData();
    // Customizable Area End
  }
 
  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.handleResponseJson(responseJson);
      } else {
        this.handleErrorResponse(errorReponse);
      }
    }
   
    // Band API response
    if (this.getCategoryBandAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleBandApiResponse(apiResponse);
    }

    if (this.getContinueWatchDataAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleContinueWatchApiResponse(apiResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // handers

  

  onInitialized = () => {
    this.getCategoryBands()
  }
  
  navToMovieDetails = (item: any) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationDashbordControllerMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationDashboardControllerAssetId), item);
    this.send(msg);
  };

  previewHandler = (ref: any) => {
    ref.slickPrev();
  };
  nextHandler = (ref: any) => {
    ref.slickNext();
  };

  navHandler = (contentId: any) => {
    const { navigation } = this.props;
    navigation.navigate(`MovieDetails`, { id: contentId });
  }

 

  // Band API

  getCategoryBands = () => {
    this.setState({moviesLoading:true})
    this.getCategoryBandAPICallId = sendAPIRequest(
      `${configJSON.bandFilterAPIEndPoints}?profile_id=${localStorage.getItem("selectedUser")}&content_type=${window.location.href.toLowerCase().includes('tvshows') ? "WebSeries" : "Movie"}`,
      { headers: { token: localStorage.getItem('Token'), language: localStorage.getItem("i18nextLng") } }
    );
  }

  getContinueWatchData = () => {
    this.setState({ loading: true })
    this.getContinueWatchDataAPICallId = sendAPIRequest(
      `${configJSON.getContinueWatchDataAPIEndPoints}?profile_id=${localStorage.getItem("selectedUser")}&content_type=${window.location.href.toLowerCase().includes('tvshows') ? "WebSeries" : "Movie"}`,
      { headers: { token: localStorage.getItem('Token'), language: localStorage.getItem("i18nextLng") } }
    );
  }

  handleResponseJson = (responseJson:any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false
      });
    } else {
      this.setState({
        dashboardData: responseJson.data,
        errorMsg: "",
        loading: false
      });
    }
  }

  handleErrorResponse = (errorReponse:any) => {
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false
      });
    }
  }

  handleBandApiResponse = (apiResponse:any) => {
    if (apiResponse?.errors) {
      this.setState({loading:false})
    } else {
      if (apiResponse) {
        this.setState({
          moviesData:apiResponse?.thumbnail_contents?.data,
          moviesLoading: false,
          bandData: apiResponse?.bend_contents,
        },()=>{this.setState({loading: false})
      })
      }
    }
  }

  handleContinueWatchApiResponse = (apiResponse:any) => {
    if (apiResponse?.errors) {
      this.setState({loading:false});
    } else {
      if (apiResponse) {
        const filteredDat = apiResponse?.data ? apiResponse?.data : []

        this.setState({
          getContinueWatchData: filteredDat
        }, () => {
          this.setState({ loading: false })
        })
      }
    }
  }
  // Customizable Area End

}
