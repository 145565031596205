import React from "react";
import { Grid, Modal, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

export const themeCreatStyle = (theme: any) => ({
  deleteDiv: {
    fontFamily: "Open sans",
    fontSize: "24px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.main,
    marginBottom: "14px",
    textAlign: "center" as const
  },
  deleteMes1: {
    fontFamily: "Open sans",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.secondary.main,
    margin: "31px 2px 30px 3px",
    textAlign: "center" as const
  },
  cancelText: {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px"
  },
  deleteText: {
    fontFamily: "Open sans",
    fontSize: "14px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.1px"
  },
  cancelButton: {
    margin: theme.direction === "ltr" ? "0 20px 0 0" : "0 0 0 20px",
    padding: "9px 20px 12px 22px",
    borderRadius: "3px",
    background: "none",
    color: theme.palette.primary.main
  },
  deleteButton: {
    margin: theme.direction === "ltr" ? "0 0px 0 20px" : "0 20px 0 0",
    textTransform: "initial" as const,
    padding: "9px 20px 12px 22px",
    borderRadius: "3px",
    backgroundColor: "#EA435D",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#EA435D"
    }
  },
  backgroundImage_div: {
    outline: "none",    
    borderRadius: 8,
    background: "linear-gradient(180deg, rgba(7, 7, 13, 0.9) 0%, #07070D 49.5%, rgba(7, 7, 14, 1) 100%)",
    justifyContent: "center",
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "32px 18px",
    width: "500px",
    maxWidth: "90%"
  },
  SessionExpModal: {
    borderRadius: 8,
    backdropFilter: "blur(6px)",
    direction: theme.direction === "rtl" ? ("rtl" as const) : ("ltr" as const)
  },
  deleteInnerDiv: {
    margin: "14px 0 31px 0",
    height: '1px',
    width: '100%',
    background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)"

  },
  BtnGrid: {
    display: "flex",
    justifyContent: "center"
  }
});

interface Props {
  deleteModal: boolean;
  onCloseModal: any;
  classes: any;
  t: any;
}

export const SessionExp = ({
  deleteModal,
  onCloseModal,
  classes,
  t
}: Props) => {
  return (
    <>
      <Modal
        open={deleteModal}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.SessionExpModal}
      >
        <Grid className={classes.backgroundImage_div}>
          <div className={classes.deleteDiv}>
            {t("sessionDialog.sessionExp")}
          </div>
          <div className={classes.deleteInnerDiv} />
          <div className={classes.deleteMes1}>{t("sessionDialog.PLIA")}</div>
          <Grid className={classes.BtnGrid}>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={onCloseModal}
            >
              <span className={classes.deleteText}>
                {t("sessionDialog.login")}
              </span>
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default withStyles(themeCreatStyle)(withTranslation()(SessionExp));
