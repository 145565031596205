import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// import { toast } from 'react-toastify';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DeviceDetector from "device-detector-js";
import { t } from "i18next";
import { removeStorageData, setStorageData } from "framework/src/Utilities";
import moment from "moment";
const deviceDetector = new DeviceDetector();
const userAgent = navigator.userAgent;
const device = deviceDetector.parse(userAgent);
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  fullName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  selectedCountry: any;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showLoader: boolean;
  isInvalidPhone: boolean;
  isInvalidPassword: boolean;
  isInvalidRepeat: boolean;
  isInvalidEmail: boolean;
  isInvalidName: boolean;
  countryArr: any[];
  selectionCode: any;
  openModal: any;
  anchorE1: any;
  showPassword: boolean;
  showConfrimPassword: boolean;
  fnameerror: boolean;
  fnameerrorText: string;
  lnameerror: boolean;
  lnameerrorText: string;
  phoneerror: boolean;
  phoneerrorText: string;
  emailerror: boolean;
  emailerrorText: string;
  pswderror: boolean;
  pswderrorText: string;
  confrimPassword: string;
  confpswderror: boolean;
  confpswderrorText: string;
  isTermAllowed: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  SignUpFormApiIntegrationCallId: any;
  countryDataCallId: any;
  createAccountApiCallId: any;
  validationApiCallId: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelfullName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  numberReg: RegExp;
  currentCountryCode: any;
  phoneReg: RegExp;
  pswdspaceReg: RegExp;
  nameReg: RegExp;
  emailValidateReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      fullName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      countryArr: [],
      showLoader: false,
      anchorE1: false,
      selectionCode: "+966",
      selectedCountry: "SA",
      openModal: false,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      isInvalidPhone: false,
      isInvalidPassword: true,
      isInvalidRepeat: true,
      isInvalidEmail: true,
      isInvalidName: false,
      showPassword: false,
      showConfrimPassword: false,

      fnameerror: false,
      fnameerrorText: "",
      lnameerror: false,
      lnameerrorText: "",
      phoneerror: false,
      phoneerrorText: "",
      emailerror: false,
      emailerrorText: "",
      pswderror: false,
      pswderrorText: "",
      confrimPassword: "",
  confpswderror: false,
  confpswderrorText: "",
  isTermAllowed: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = /\w+/;
    this.emailReg = /\w+/;

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelfullName = configJSON.labelfullName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.phoneReg = /^(?:\d●?){7,15}\d$/;
    this.pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    this.emailValidateReg = /^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i;
    this.passwordReg = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    this.nameReg = /^[a-zA-Z ]*$/;
    this.numberReg = /^\d*$/;

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.SignUpFormApiIntegrationCallId) {
      const errors = responseJson?.errors;

      if (!errors) {
        this.setState({ showLoader: false });
        const token = responseJson?.meta?.token;
        if (token) {
          let userData = responseJson.account?.data
          toast.success(this.props.t("registerScreen.CSUS"));
          setStorageData("Token", token);
          removeStorageData("isGuestUser")
          setStorageData(
            "refresh_token",
            responseJson?.meta?.refresh_token
          );
          setStorageData("device_unique_id", responseJson.account_device?.id)
          setStorageData("User_Id", userData?.id);
          let exp_time: any = moment()
            .add(23, "hours")
            .format("DD/MM/YYYY hh:mm a");
          setStorageData("exp_time", exp_time);
          setStorageData(
            "user_data",
            JSON.stringify(userData?.attributes)
          );
          const registerNavigationMes: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          registerNavigationMes.addData(
            getName(MessageEnum.NavigationTargetMessage), "ViewSubscription")
          registerNavigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(registerNavigationMes);
        }
      } else {
        this.setState({ showLoader: false });
        const error = this.getErrorMessage(errors);

        if (error) {
          toast.error(error);
        }
      }
    } else if (apiRequestCallId === this.countryDataCallId) {
      this.setState({
        showLoader: false,
        countryArr: responseJson?.country_codes || [],
      });
    }
  }

  getErrorMessage(errors: any): string {
    if (
      errors.full_phone_number &&
      errors.full_phone_number[0].includes("has already been taken")
    ) {
      return t("registerScreen.PNHABT");
    } else if (
      errors.email &&
      errors.email[0].includes("has already been taken")
    ) {
      return t("registerScreen.emailAlTaken");
    } else {
      const key = Object.keys(errors)[0];
      const errorMessage = errors[key][0];
      return errorMessage ? errorMessage + "." : "";
    }
  }

  // Customizable Area Start
  selectCountryCode = (item: any) => {
    this.setState({
      selectionCode: "+" + item,
      openModal: false,
      anchorE1: null,
    });
  };

  navToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlockWeb");
  };

  handleClick = (e: any) => {
    this.setState({ anchorE1: e.currentTarget });
  };

  handleAnchorClose = () => {
    this.setState({ anchorE1: null });
  };

  showPasswordFn = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  showConfrimPasswordFn = () => {
    this.setState({
      showConfrimPassword: !this.state.showConfrimPassword,
    });
  };

  async componentDidMount() {
    this.getCountrylist();
    this.createDeviceUniqueId();
  }

  handleChangefullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || this.nameReg.test(event.target.value)) {
      this.setState({
        fullName: event.target.value,
        fnameerror:false,
        fnameerrorText:""
      });
    }    
  };  

  handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
      phone: e.target.value,
      phoneerror:false,
      phoneerrorText:""
  });
  }; 

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value.trim(),
      emailerror:false,
      emailerrorText:""
    });
  };
 
  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
   this.setState({ 
    password: event.target.value ,
    pswderror:false,
    pswderrorText:""
  });
  };

  handleChangeConfrimPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
     confrimPassword: event.target.value ,
     confpswderror:false,
     confpswderrorText:""
   });
   };

  navToHome = (path : string) => {
    this.props.navigation.navigate(path);
  };

  navigateToLogin=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  }

  onTermClicked = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({isTermAllowed : event.target.checked})
  }
 
  SignUpFormValidation = (e: any) => {
    e.preventDefault();
    let fullNameValidation = this.validatefullName();
    let confrimPasswordValidation = this.validateConfrimPassword();
    let emailValidation = this.validateEmail();
    let passwordValidation = this.validatePassword();
    let phoneValidation = this.validatePhone() ;

    const isValid =
    fullNameValidation &&
    confrimPasswordValidation &&
    emailValidation &&
    phoneValidation &&
      passwordValidation;

    isValid && this.SignUpFormApiIntegration();
  };

  splitFullName =(fullName: string) => {
    const nameParts = fullName.trim().split(/\s+/);
 if (nameParts.length === 1) {
        return {
            firstName: nameParts[0],
            lastName: ""
        };
    }const lastName = nameParts.pop();
    const firstName = nameParts.join(" ");
    return {
        firstName: firstName,
        lastName: lastName
    };
}

  validatefullName = () => {
    const { t } = this.props;
    const { fullName} = this.state;
    const { lastName} = this.splitFullName(fullName);
    if (fullName === null || fullName.length === 0) {
      this.setState({
        fnameerror: true,
        fnameerrorText: t("registerScreen.firstNameReq"),
      });
      return false;
    }
    else if (lastName === null || lastName?.length === 0) {
      this.setState({
        fnameerror: true,
        fnameerrorText: t("registerScreen.PEVFN"),
      });
      return false;
    }
    return true;
  };

  validateConfrimPassword = () => {
    const { t } = this.props;
    const {password, confrimPassword } = this.state;
    if (confrimPassword === null || confrimPassword.length === 0) {
      this.setState({
        confpswderror: true,
        confpswderrorText: t("loginScreen.CR"),
      });
      return false;
    }
    else if (confrimPassword !== password){
      this.setState({
        confpswderror: true,
        confpswderrorText: t("loginScreen.PCPM"),
      });
      return false
    }
    return true;
  };

  validateEmail = () => {
    const { t } = this.props;
    const { email} = this.state;
    if (email === null || email.length === 0) {
      this.setState({
        emailerror: true,
        emailerrorText: t("registerScreen.emailReq"),
      });
      return false;
    } else if (!this.emailValidateReg.test(email)) {
      this.setState({
        emailerror: true,
        emailerrorText: t("loginScreen.PECEA"),
      });
      return false;
    }
    return true;
  };

  validatePhone = () => {
    const { t } = this.props;
    const { phone} = this.state;
    if (phone === null || phone.length === 0) {
      this.setState({
        phoneerror: true,
        phoneerrorText: t("registerScreen.phoneReq"),
      });
      return false;
    } else if (
      !this.numberReg.test(phone) ||
      phone.length < 7 ||
      phone.length > 15
    ) {
      this.setState({
        phoneerror: true,
        phoneerrorText: t("registerScreen.validPhone"),
      });
      return false;
    }
    return true;
  };

  validatePassword = () => {
    const { t } = this.props;
    const {password} = this.state;
    if (password === null || password.length === 0) {
      this.setState({
        pswderror: true,
        pswderrorText: t("registerScreen.passwordReq"),
      });
      return false;
    } else if (!this.pswdspaceReg.test(password)) {
      this.setState({
        pswderror: true,
        pswderrorText: t("loginScreen.NSWS"),
      });
      return false;
    } else if (!this.passwordReg.test(password)) {
      this.setState({
        pswderror: true,
        pswderrorText: t("loginScreen.MINP"),
      });
      return false;
    }
    return true;
  };

  SignUpFormApiIntegration = async () => {
    let mobileNumber = this.state.phone.trim();
    const {firstName , lastName} = this.splitFullName(this.state.fullName);
    this.setState({ showLoader: true });
    const header = {
      "Content-Type": "application/json",
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    };
    let deviceToken = localStorage.getItem("deviceId")
    const attrs = {
      first_name: firstName,
      last_name: lastName,
      full_phone_number: this.state.selectionCode + mobileNumber,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password,
      guest_email: deviceToken + configJSON.almanshaEmail,
      is_guest: false
    };

    const data = {
      type: "email_account",
      attributes: attrs,
      device_name: device?.os?.name,
      device_unique_id: deviceToken,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignUpFormApiIntegrationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountrylist = () => {
    this.setState({ showLoader: true });
    const getCountryCodeData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryDataCallId = getCountryCodeData.messageId;

    getCountryCodeData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_country_codes"
    );
    getCountryCodeData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getCountryCodeData.id, getCountryCodeData);
  };

  createDeviceUniqueId() {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      localStorage.setItem("deviceId", deviceId);
    } else {
      localStorage.setItem("deviceId", uuidv4());
    }
  }
  // Customizable Area End
}
