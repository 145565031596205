export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImagePng = require("../assets/Group_2.png");
export const apply_coupons_img = require("../assets/Discount.png");
export const line_img = require("../assets/Line.png");
export const user_img = require("../assets/portrait.png");
export const cross_img = require("../assets/Path.png");
export const discount_img = require("../assets/Shape2.png");
export const done_img = require("../assets/Done.png");
export const download_img = require("../assets/download.png");
export const mobile_img = require("../assets/Shape.png");
export const success_img = require("../assets/Group_30.png");
export const TextFieldImg = require("../assets/TextField_Img.png");
export const RegBackgroundImg = require("../assets/RegBackground_Img.png");
export const RegMainImg = require("../assets/RegMain_Img.png");
export const RegisterTitle = require("../assets/Register_Title.png");
export const RegisterTitlear = require("../assets/Register_Titlear.png");
export const RegButtonImg = require("../assets/RegButton_Img.png");
export const GoogleRegImg = require("../assets/Google_Img.png");
export const FacebookRegImg = require("../assets/Facebook_Img.png");
export const AppleRegImg = require("../assets/Apple_Img.png");
export const LineLeft = require("../assets/LineLeft_Img.png");
export const LineRight = require("../assets/LineRight_Img.png");

export const RegBgImg = require("../assets/RegBgImg_Img.png");
export const BackButton = require("../assets/BackButton_Img.png");
export const resolutionIcon = require("../assets/resolutionIcon.png");
export const appLogo = require("../assets/newLogo.png");
export const backarrow = require("../assets/backarrow.png");
export const rightbackarrow = require("../assets/arrow_right.jpeg");
export const success = require("../assets/Line.png");
export const error = require("../assets/Line.png");
export const tickIcon = require("../assets/image-tick-Icon.png")
export const selectedIcon = require("../assets/selectedIcon.png");
export const edit_icon_dark = require("../assets/Line.png");
export const line_horizontalcolor = require("../assets/line_horizontalcolor.png");
export const premiumIcon = require("../assets/premium.png");
