import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CONTENT_TYPES from "../../../components/src/Enums";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    contentDetails?: any;
    onPlayVideo?: any;
    isPodcast?: any;
    updateMovieDetailsState?: any,
    isSubscribed?: any
    seasonsData?: any
    getEpisodesByRelease?: any
    playingSeason?: any
    updatePodcastParts?: boolean,
    contentId :any
    t?:any
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    imgIndex: any;
    loading: boolean;
    isSharedModal: boolean;

    // Customizable Area End
}
interface SS {
    id: any;
}

export default class BannerController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            errorMsg: "",
            token: "",
            loading: false,
            imgIndex: null,
            isSharedModal: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && !responseJson.errors && responseJson.data) {
                this.handleAPIResponse(responseJson)
            } else {
                this.handleAPIErrorResponse(errorReponse)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    navToPlayer = (data: any) => {
        const { onPlayVideo, contentDetails, seasonsData, contentId } = this.props;
        this.checkForShared(contentId ,data?.content_type);
        let assetId = '';
        let playertitle = '';
        let isGuestVideo= false;
        const continueWatch = data?.watch_history && !data?.watch_history?.is_completed ? data?.watch_history : null;
        const { content_type, watch_history, asset_id, title } = data;
        const isTVShows = (content_type == CONTENT_TYPES?.WEBSERIES || content_type == CONTENT_TYPES?.TVSHOWS);
        if (continueWatch) {
            if (isTVShows) {
                assetId = watch_history?.asset_id;
                playertitle = watch_history?.title;
            }
            else
                assetId = asset_id;
        } else {
            if (isTVShows && seasonsData && seasonsData?.data?.length > 0) {
                assetId = seasonsData?.data[0]?.attributes?.asset_id;
                playertitle = seasonsData?.data[0]?.attributes?.title;
                isGuestVideo = seasonsData?.data[0]?.attributes?.is_guest_content
            } else {
                assetId = asset_id;
                playertitle = title;
            }
        }
        onPlayVideo({ assetId, title: playertitle, contentType: contentDetails.content_type , isGuestSeries: isGuestVideo });
    }

    handleShareDialog(){
        this.setState({isSharedModal : !this.state.isSharedModal})
    }

    checkForShared = (contentID: any, content_type: any) => {
        let urlSearch = window.location.search;
        if (urlSearch?.toLowerCase().includes("share")) {
            if (this.isMobile()) {
                try {
                    window.location.href = `almanassah://6SuK=/${content_type}/${contentID}`;
                }
                catch(error){
                    
                }
            }           
            this.replaceWebURL();
        }
    }

    isMobile = () => {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }

    replaceWebURL = () =>{
        try{
        history.replaceState(null, document.title, window.location.pathname);
        }
        catch(error){
           
        }
    }

    removeZeroFromFront = (time: any) => {
        let spit_time = time.split("");
        if (spit_time[0] === "0") {
            return spit_time[1];
        }
        else {
            return spit_time.join("");
        }
    }

    handleTimeFormat = (data: any) => {
        const {t} = this.props
        if (data.includes(":")) {
            let split_time = data.split(":");
            let hours = split_time[0] !== "00" ? (this.removeZeroFromFront(split_time[0])) : "00";
            let miniutes = split_time[1] !== "00" ? (this.removeZeroFromFront(split_time[1])) : "00";
            if (hours === "00" && miniutes === "00") {
                return `00 ${t("dashboard.min")}`
            } else if (hours !== "00" && miniutes === "00") {
                return hours + t("dashboard.hours")
            } else if (hours === "00" && miniutes !== "00") {
                return miniutes + t("dashboard.min")
            } else {
                return hours + t("dashboard.hours") + " " + miniutes + t("dashboard.minutes")
            }
        } else {
            return data;
        }
    }

    handlePlayButtonText = (contentDetails:any) => {
        const { t }: any = this.props;        
        if (contentDetails?.watch_history && !contentDetails?.watch_history?.is_completed) {
            if (contentDetails?.content_type == CONTENT_TYPES?.WEBSERIES ||
                contentDetails?.content_type == CONTENT_TYPES?.TVSHOWS || 
                contentDetails?.content_type == CONTENT_TYPES?.MOVIES) {
                // return `${t("movieDetails.resume")} ${contentDetails?.watch_history?.title}`
                return `${t("movieDetails.resume")}`
            }
            else
                if (!contentDetails?.watch_history?.is_completed && contentDetails?.content_type == CONTENT_TYPES?.AUDIOPODCAST) {
                    return t("movieDetails.continueListen")
                }
                else if (!contentDetails?.watch_history?.is_completed) {
                    return t("movieDetails.continueWatching")
                }
                else {
                    return t("movieDetails.play")
                }
            
        }else {
            return t("movieDetails.play")
        }
    }

    // API response
    handleAPIResponse = (json: any) => {
        if (json.data.length === 0) {
            this.setState({
                errorMsg: "Data Not Found",
                loading: false
            });
        } else {
            this.setState({
                errorMsg: "",
                loading: false
            });
        }
    }

    handleAPIErrorResponse = (json: any) => {
        if (json === undefined) {
            this.setState({
                errorMsg: "Something went wrong",
                loading: false
            });
        } else {
            this.setState({
                errorMsg: json,
                loading: false
            });
        }
    }
    // Customizable Area End
}
