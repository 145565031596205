import React from "react";
import { Box, Typography, Grid, withStyles , Avatar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// @ts-ignore
import cssClasses from "./StarCastMovies.module.css";
import MovieCard from "./MovieCard.web";
// @ts-ignore
import StarCastMoviesController from "./StarCastMoviesController";
import { leftArrow } from "./assets";

export class StarCastMovies extends StarCastMoviesController {
  render() {
    const { castData } = this.state;
    const {classes}: any = this.props
    return (
      <>
      <Grid item xs={12} className={classes.underLineCSS}></Grid>
      <Box className={`${cssClasses.boxMain}`}>
        <Box className={cssClasses.starLabel}>
          <IconButton
            data-test-id="backHistory"
            onClick={() => this.historyBack()}
            className={`${cssClasses.iconButton}`}
            aria-label="navigate back"
          >
            <img
            src={leftArrow}
              data-test-id="test"
              className={`${cssClasses.iconSize} ${classes.navigateStyle}`}
            />
          </IconButton>          
        <Grid container justifyContent="center" alignItems="center">
        <Avatar src={this.state.castImage} className={classes.avatarImage} />
          <Typography className={`${cssClasses.starName}`} color="primary">
            {this.state.castName}
          </Typography>
        </Grid>
        </Box>
        <Box>
          <Grid container spacing={2} className={`${cssClasses.gridContainer}`}>
            {(castData || []).map((content: any) => {
              return (
                <MovieCard {...this.props} cardData={content} />
                );
            })}
          </Grid>
        </Box>
      </Box>
      </>
    );
  }
}

export const swiperStyle: any = ((theme: any) =>({
  navigateStyle:{
    transform: theme.direction === "rtl" ? "rotate(180deg)" : "none"
  },
  underLineCSS:{
    height:2,
    background: "#141426",
    marginBottom: "15px"
  },
  avatarImage: {
    width: 80,
    height: 80,
    [theme.breakpoints.only("xs")]: {
      width: 60,
      height: 60,
      
    }
  }


}))

export default withStyles(swiperStyle)(StarCastMovies);
