import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
// Customizable Area End

export const themeCreatStyle: any = (theme: any) => {
    return {
        root: {
            "label + &": {
                marginTop: theme.spacing(1),
            },
        },
        backgroundImage_div: {
            padding: "30px",
            [theme.breakpoints.only("xs")]: {
                padding: "15px 20px",
            },
            [theme.breakpoints.only("sm")]: {
                padding: "20px 25px",
            }
        },
        description_text: {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontStyle: "normal",
            letterSpacing: 0,
            color: '#F6F6F6'
        },
        description_Box: {
            marginTop: 50,
            [theme.breakpoints.only("xs")]: {
                marginTop: 27,
            },
            [theme.breakpoints.only("md")]: {
                marginTop: 45,
            },
            [theme.breakpoints.only("sm")]: {
                marginTop: 40,
            }
        },
        details_description_text: {
            color: "#F6F6F6",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontStyle: "normal",
            letterSpacing: 0,
            fontSize: '20px',
            lineHeight: '22px',
            margin: '7px 0'
        },
        font_size_18: {
            fontSize: 18,
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 17,
            }
        },
        currentPlan:{
            fontFamily: "Roboto",
            fontWeight: 500,
            color: '#F6F6F6',
            fontSize: 20,
            marginBottom: 9,
            lineHeight: "20px"
        },
        heading: {
            fontFamily: "Roboto",
            fontWeight: 500,
            color: '#F6F6F6',
            fontSize: 24,
            marginBottom: 9,
            marginTop: 30,
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
                marginBottom: 6,
                marginTop: 20,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 20,
                marginBottom: 8,
                marginTop: 25,
            }
        },
        font_Size_28: {
            fontWeight: 700,
            fontSize: 20,
            fontFamily: "Roboto",
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 19,
            }
        },
        description_text1: {
            fontFamily: "Roboto",
             fontWeight: 400,
             fontStyle: "normal",
             letterSpacing: 0,
             fontSize: '16px',
             marginBottom: "5px",
             color: "#DCDCDC"
           },
        subscription_details_outer_div1: {
            background:
            "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
          padding: 5,
          borderRadius: 8,
        },
        subscription_details_div1: {
            background:"linear-gradient(90deg, rgba(20, 21, 35, 1) 0%, rgba(26, 27, 34, 1) 100%)",
          borderRadius: 8,
          padding: 15,
          flexDirection: "column" as const
        },
        price_div: {
            background: "linear-gradient(91.24deg, rgba(234, 67, 93, 0.5) 1.44%, rgba(104, 105, 172, 0.5) 52.28%, rgba(36, 140, 161, 0.5) 98.95%)",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            padding: '10px',
            maxWidth: '150px',
            marginTop: '7px'
        },
       divUnderLine: {
            height: "1px",
            background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)"

        },
        planIcons:{
            width: 42,
            height: 42,      
          },
    }
}
// Customizable Area End

import ChangePlanController, {
    Props,
} from "./ChangePlanController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import SuccessDialog from "../../user-profile-basic/src/shared/SuccessDialog.web";
import PriceDetails from "./PriceDetails.web";
import moment from "moment";
import { premiumIcon } from "./assets";

export class ChangePlan extends ChangePlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes, t }: any = this.props;

        // Customizable Area Start
        return (
            <Grid container>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Grid
                    item
                    xs={12}
                    className={classes.backgroundImage_div}
                >

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                    className={classes.font_Size_28}
                                    color="primary"
                                >
                                    {t("view_subscription.planDetail")}
                                </Typography>
                        </Grid>
                        </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.divUnderLine}
                        />
                        
                         <Grid
                    item
                    xs={12}
                    className={classes.backgroundImage_div}
                >
                        <Grid item xs={12}>
                            <Typography className={classes.currentPlan}> {t("choose_profile.CP")}</Typography>
                            {this.state.currentPlanDetails?.attributes && <div
                                className={classes.subscription_details_outer_div1}
                            >
                                <Grid
                                    container
                                    className={classes.subscription_details_div1}
                                    spacing={1}
                                >
                                <div>
                                    <img src={this.state.currentPlanDetails?.attributes?.icon ? this.state.currentPlanDetails?.attributes?.icon : premiumIcon} alt="preminumLogo" className={classes.planIcons} />
                                </div>
                                <Typography
                                    className={classes.details_description_text}>
                                    {this.state.currentPlanDetails?.attributes?.name}
                                </Typography>                               
                                <Typography
                                    className={classes.description_text1}>
                                    {this.state.currentPlanDetails?.attributes?.user_limit_desc}
                                </Typography>
                                <Typography
                                    className={classes.description_text1}>
                                    {t("view_subscription.expires")} {moment(this.state.currentPlanDetails?.attributes?.end_date).format("DD/MM/YYYY")}
                                </Typography>
                                <div className={classes.price_div}>
                                    <Typography
                                        color="secondary"
                                        className={classes.description_text}>
                                        {
                                        this.state.currentPlanDetails?.attributes?.price === 0 
                                            ? `${t("plan_subscription.Free")}`
                                            : `$${this.state.currentPlanDetails?.attributes?.price} `
                                        }
                                        / {this.state.currentPlanDetails?.attributes?.duration}

                                    </Typography>
                                </div>
                                </Grid>
                            </div>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.heading} color="primary">
                                {t("view_subscription.changeplan_heading")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item sm={11} xs={12}>
                                    <Typography
                                        className={`${classes.description_text} ${classes.font_size_18}`}                                        
                                    >
                                        {t("view_subscription.subheading")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.description_Box}>
                            <Grid container spacing={3}>
                                {this.state.plansDetails?.filter((_planData: any)=>{
                                   return (_planData?.id !== this.state.currentPlanDetails?.id)
                                }).map((_data: any) => {
                                    return (
                                        <React.Fragment key={_data.id}>
                                            <PriceDetails
                                                priceDetails={_data}
                                                onButtonClick={(data: any) => this.goToDowngradePerk(data)}
                                                isDisabled={false}
                                                buttonText={this.buttonName(_data)}
                                                selectedPlanId={this.state.selectedPlanId}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                            </Grid>
                        </Grid>

                    </Grid>
                

                {this.state.confirmDialog && <SuccessDialog data-test-id="close_modal"
                    title={t("view_subscription.dialogCPHC")}
                    deleteModal={this.state.confirmDialog}
                    onCloseModal={() => {
                        this.setState({ confirmDialog: false });
                    }}
                    disableButton={false}
                    confirmMes={this.deleteButtonName()}
                    okButtonText={t("view_subscription.GFIT")}
                    deleteClick={this.navigateToUpgradePlan}
                    />
                }
                {this.state.successDialog && <SuccessDialog data-test-id="close_modal1"
                    title={t("view_subscription.successfully")}
                    deleteModal={this.state.successDialog}
                    onCloseModal={() => {
                        this.setState({ successDialog: false });
                    }}
                    disableButton={false}
                    confirmMes={this.successConfirmMes()}
                    okButtonText={t("view_subscription.done")}
                    deleteClick={() => this.deleteClick()} />
                }
            </Grid>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}



// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(ChangePlan));
// Customizable Area End
