import React from 'react';
import { string } from 'yup';

export const UserContext = React.createContext<UserContenxtType | null>(null);
export const UserContextProvider = (props: any) => {
  const [user, setUser] = React.useState<UserContenxtType | null>(null);

  return (
    <UserContext.Provider
      value={{
        //@ts-ignore
        user,
        //@ts-ignore
        setUser
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export interface UserContenxtType {
  user: UserType | null;
  setUser: (user: UserType) => void;
}
interface UserPlanType {
  id: number;
  name: string;
  duration: string;
  price: number;
  details: string;
  created_at: Date;
  updated_at: Date;
  userlimit: string;
  platform: string;
  resolution: string;
  download: string;
}
interface GenreAttrType {
  id: number;
  name: string;
}
interface GenreType extends BaseType<GenreAttrType> {}
interface UserAttrType {
  id: number;
  country: null;
  address: null;
  city: null;
  postal_code: null;
  account_id: number;
  profile_role: null;
  name: string;
  kids_profile: null;
  photo: null | string;
  plan_count: number;
  profile_count: number;
  account_owner: boolean;
  plan: UserPlanType;
  genres: {
    data: GenreType[];
  };
}

export interface UserType extends BaseType<UserAttrType> {}

interface BaseType<Attr> {
  id: string;
  type: string;
  attributes: Attr;
}
