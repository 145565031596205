import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
    Button,
    Typography,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End
import {
    download_img,
    line_img,
    mobile_img,
    resolutionIcon,
    selectedIcon,
    tickIcon,
    user_img,
    premiumIcon,
} from "./assets";
import { BlockComponent } from "framework/src/BlockComponent";
// Customizable Area Start
interface Props {
    // Customizable Area Start
    classes: any;
    t: any;
    priceDetails: any;
    onButtonClick: any;
    isDisabled: boolean;
    buttonText: string;
    selectedPlanId: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export class PriceDetails extends BlockComponent<Props, S, SS> {
    render() {
        const {
            priceDetails,
            classes,
            t,
            onButtonClick,
            isDisabled,
            buttonText,
            selectedPlanId
        } = this.props;

        return (
            <>
                <Grid item sm={6} xs={12}>
                    <div
                        className={
                            parseInt(selectedPlanId) ===
                                parseInt(priceDetails.id)
                                ? classes.selected_subscription_details_outer_div
                                : classes.subscription_details_outer_div
                        }
                    >
                        <Grid container className={classes.subscription_details_div}>
                            <Grid
                                xs={12}
                                className={classes.innerPlanContainer}
                            >
                                <Grid container justifyContent="space-between">
                    <img src={priceDetails?.attributes?.icon ? priceDetails?.attributes?.icon : premiumIcon} alt="preminumLogo" className={classes.planIcons} />
                   { parseInt(selectedPlanId) ===
                                parseInt(priceDetails.id) && <img src={selectedIcon} alt="selected" className={classes.selectedIcons} />}
                  </Grid>
                                <Typography
                                    className={`${classes.details_description_text} ${classes.font_size_24}`}
                                >
                                    {priceDetails?.attributes?.name}
                                </Typography>
                            </Grid>
                            <Grid container className={classes.dividerStyleLine} />                            
                            <Grid xs={12}  className={classes.innerPlanContainer}>
                               <Grid xs={12} className={classes.plans_details}>
                                <img
                                    src={tickIcon}
                                    alt="user"
                                    className={classes.image_css}
                                />
                                <span>{priceDetails?.attributes?.user_limit_desc}</span>
                            </Grid>
                            <Grid xs={12} className={classes.plans_details}>
                                <img
                                    className={classes.image_css}
                                    src={tickIcon}
                                    alt="user"
                                />
                                <span> {priceDetails?.attributes?.platform}</span>
                            </Grid>
                            <Grid xs={12} className={classes.plans_details}>
                                <img
                                    src={tickIcon}
                                    alt="user"
                                    className={classes.image_css}
                                />
                                <span> {priceDetails?.attributes?.resolution?.toLowerCase()}</span>
                            </Grid>
                            <Grid
                                xs={12}
                               className={classes.background_color}
                            >
                                <Typography
                                    color="secondary"
                                    className={`${classes.description_text} ${classes.font_size_26}`}
                                >{
                                    priceDetails?.attributes?.price === 0 
                                      ? `${t("plan_subscription.Free")}` 
                                      : `$${priceDetails?.attributes?.price} `
                                  }
                                  / {priceDetails?.attributes?.duration}
                                </Typography>
                            </Grid>
                            </Grid>
                            <Grid container className={classes.dividerStyleLine} />                           
                            <Grid xs={12} className={classes.innerPlanContainer}>
                                <Button
                                    variant="contained"
                                    disabled={isDisabled}
                                    data-test-id="select_plan_button"
                                    fullWidth
                                    className={classes.choose_button_css}
                                    style={{ textTransform: "none" }}
                                    onClick={() => onButtonClick(priceDetails)}
                                >
                                    {buttonText}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </>

        )
    }
}


export const themeCreatStyle = (theme: any) =>({
        subscription_details_outer_div: {
            height: "100%",
            padding: 1,
            boxShadow: "0px 1px 10px rgba(0,0,0, 0.1)",
            background:
                "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
                borderRadius: 8,
            
            "&:hover": {
                transform: "translateY(-0.25em)",
                boxShadow: "0 0.5em 0.5em -0.4em RGB(104, 105, 172)",
            },
        },
        subscription_details_div: {
            height: "100%",
            borderRadius: 8,
            background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
            
        },
        dividerStyleLine:{
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
            height:'1px',
            padding: "0px"
          },
        innerPlanContainer : {
            [theme.breakpoints.only("xs")]: {
                padding: "20px",               
            },
            [theme.breakpoints.only("sm")]: {
                padding: "23px"
            },
            [theme.breakpoints.up("lg")]: {
                padding: "30px"               
            },
            [theme.breakpoints.only("md")]: {
                padding: "27px"                
            },
            [theme.breakpoints.only("lg")]: {
                padding: "30px"               
            }, 
        },
        selectedIcons:{
            width: 20,
            height: 20,
            marginTop: '14px'
        },
        planIcons:{
            width: 42,
            height: 42,
            marginTop: '10px'      
          },
        choose_button_css: {
            color: theme.palette.primary.main,
            boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
            fontWeight: 700,
            borderRadius: 10,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 17
            },
            fontSize: 18,
           [theme.breakpoints.only("sm")]: {
                fontSize: 16
            }
        },
        selected_subscription_details_outer_div: {
            height: "100%",
            transform: "translateY(-0.25em)",
            boxShadow: "0 0.5em 0.5em -0.4em #ab133d",
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
            padding: 3,
            borderRadius: 8,
            
        },
        details_description_text: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 600,
            color: theme.palette.primary.main,
            letterSpacing: 0,
        },
        font_size_24: {
            fontSize: 24,
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 21,
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 22,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 24,
            },
        },
        background_color: {
            background: "linear-gradient(91.24deg, rgba(234, 67, 93, 0.5) 1.44%, rgba(104, 105, 172, 0.5) 52.28%, rgba(36, 140, 161, 0.5) 98.95%)",
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      width: '100%',
      padding: '10px',
      maxWidth: '210px',
      marginTop: '20px'
        },
        font_size_16: {
            fontSize: 16,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14.5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 14.5,
            }
        },
        plans_price: {
            background: "linear-gradient(90deg, #0097A8 0%, #6869AC 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: 700,
        },
        image_css: {
            width: 15,
            height: 15,
            marginRight: theme.direction == "rtl" ? 0 : 15,
            marginLeft: theme.direction == "rtl" ? 15 : 0,
        },
        description_text: {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontStyle: "normal",
            letterSpacing: 0,
        },
        plans_details: {
            color: theme.palette.text.primary,
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            letterSpacing: 0,
            fontSize: 16,
            margin: "15px 0",
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
                margin: "10px 0",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 14.5,
                margin: "10px 0",
            }
        },
    }
);
export default withStyles(themeCreatStyle)(withTranslation()(PriceDetails));
// Customizable Area End