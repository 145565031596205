import React from "react";

// Customizable Area Start
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
// Customizable Area End

// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  player: any;
  controls: any;
  parent: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class CaptionsButton<Props, S, SS> extends shaka.ui
  .TextSelection<Props, S, SS> {
  // Customizable Area Start

  constructor(parent: any, controls: any) {
    super(parent, controls);

    this.eventManager.listen(this.player, "texttrackvisibility", () => {
      this.onCaptionStateChange_();
      this.updateTextLanguages_();
    });
  }

  onCaptionStateChange_() {
    if (this.player.isTextTrackVisible()) {
      this.icon.textContent = "speaker_notes";
      this.button.ariaPressed = "true";
    } else {
      this.icon.textContent = "speaker_notes_off";
      this.button.ariaPressed = "false";
    }

    this.controls.dispatchEvent(
      new shaka.util.FakeEvent("captionselectionupdated")
    );
  }
  // Customizable Area End
}

// Customizable Area Start
CaptionsButton.Factory = class {
  create(rootElement: any, controls: any) {
    return new CaptionsButton(rootElement, controls);
  }
};
// Customizable Area End
