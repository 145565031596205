import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import i18next from "i18next";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  languageList: any;
  selectedLanguage: string;
  languageMenu: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      languageList: [
        {
          id: '1',
          code: 'en',
          name: 'English',
        },
        {
          id: '2',
          code: 'ar',
          name: 'العربية',
        },
      ],
      languageMenu: null,
      selectedLanguage: localStorage.getItem("i18nextLng") || ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  navToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  };

  getLanguageName= () =>{
    const selectedLanguage = this.state.languageList.find((language : {id:string , code: string , name:string}) => language.code === this.state.selectedLanguage );
    return selectedLanguage?.name
  }

  setlanguageMenu(languageMenu: any) {
    this.setState({ languageMenu });
  }

  changeLanguages = (value: string) => {
    this.setlanguageMenu(null)
    if(this.state.selectedLanguage === value) return false
    i18next.changeLanguage(value)
    this.setState({
      selectedLanguage: value
    })
    window.location.reload();
  }
  // Customizable Area End
}
