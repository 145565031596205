import React from "react";
// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    Box,
    InputAdornment,
    InputBase
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withTranslation } from "react-i18next";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";

// Customizable Area End
import UpgradePlanController from "./UpgradePlanController.web";

export class UpgradePlan extends UpgradePlanController {
    render() {
        const { classes, t } = this.props;
        return (
            <Box className={classes.subscription_div}>
                <Grid container justifyContent="center">
                    {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
                    <Grid item lg={4} sm={6} xs={12}>
                        <Typography variant="body1" component={"div"} className={classes.subscription_details_outer_div}>
                            <Grid container className={classes.subscription_detail_div}>
                                <Grid className={classes.back_arrow_button_css}>
                                    <ArrowBackIosIcon
                                        data-test-id="back_btn"
                                        className={classes.arrow_icon}
                                        style={{ cursor: "pointer" }}
                                        onClick={(event: any) => this.onBackClick(event)} />
                                    <Typography component={"span"} className={classes.back_link}>
                                        {t("Upgrade_plan.Back")}
                                    </Typography>
                                </Grid>
                                <Grid className={classes.subscription_contents_div}>
                                    {this.state.currentPlansDetails?.attributes?.price !== 0 &&
                                        <Grid className={classes.coupounTextField}
                                        >
                                            <InputBase
                                                data-test-id="applied_coupon"
                                                className={classes.inputbase}
                                                value={this.state.coupon}
                                                placeholder={t("Upgrade_plan.CC")}
                                                onChange={this.handleChangeCoupon}
                                                disabled={this.state.appliedCoupon}
                                                endAdornment={this.state.appliedCoupon ?
                                                    <InputAdornment position="end">
                                                        <Button
                                                            className={classes.remove_btn_css}
                                                            onClick={this.handleRemove}
                                                        >
                                                            {t("Upgrade_plan.Remove_btn")}
                                                        </Button>
                                                    </InputAdornment> :
                                                    <InputAdornment position="end">
                                                        <Button
                                                            className={classes.apply_btn_css}
                                                            onClick={this.applySubscriptionCoupon}
                                                        >
                                                            {t("Upgrade_plan.Apply")}
                                                        </Button>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                    }
                                    <Grid className={classes.plan_details}>
                                        <span>
                                            {t("Upgrade_plan.Upgrade_request_text")}
                                        </span>
                                    </Grid>
                                    <Grid className={classes.current_plan_amount}>
                                        <span>
                                            {t("Upgrade_plan.Current_plan")}
                                            {this.state.currentPlan}
                                        </span>
                                    </Grid>
                                    <Grid className={classes.current_plan_amount}>
                                        <span>
                                            {t("Upgrade_plan.New_plan")}
                                            {this.state.newPlan}
                                        </span>
                                    </Grid>
                                    <Grid className={classes.amount_section}>
                                        <span>{t("Upgrade_plan.Amt")}</span>
                                    </Grid>
                                    <Grid container className={classes.current_plan_amount} justifyContent="space-between">
                                      {this.props.i18n.language === 'ar' ? <span>{this.state.currentPlansDetails.attributes?.ar_name_with_dura}</span> : <span>
                                           {this.state.newPlan}
                                            {t("Upgrade_plan.Plan_for")}
                                            <span style={{textTransform:"lowercase"}}>{this.state.newPlanDuration}</span>
                                        </span>}
                                        <span>${this.state.newPlanPrice} </span>
                                    </Grid>
                                   <Grid container className={classes.current_plan_amount} justifyContent="space-between">
                                            <span className="switchDirection">
                                                <span>{this.state.currentPlan}</span>
                                                <span>{t("Upgrade_plan.Previous_plan_balance")}</span>
                                            </span>
                                            <span className={classes.current_plan_amount_span}>
                                                <span>-</span>
                                                <span>${this.state.prevBalance}</span>
                                            </span>
                                        </Grid>
                                    {this.state.discount > 0 &&
                                        <Grid container className={classes.amount_section} justifyContent="space-between">
                                            <span>{t("Upgrade_plan.CD")}</span>
                                            <span className={classes.current_plan_amount_span}>
                                                <span>-</span> 
                                                <span>${this.state.discount}</span>
                                            </span>
                                           </Grid>
                                    }
                                    <Grid className={classes.total_divider_line} />
                                    <Grid container className={classes.amount_section} justifyContent="space-between">
                                        <span>{t("Upgrade_plan.TP")}</span>
                                        <span>${this.state.totalPayAmount}</span>
                                    </Grid>
                                    <Grid >
                                        <Button
                                            variant="contained"
                                            data-test-id="proceed_btn"
                                            fullWidth
                                            className={classes.choose_button_css}
                                            style={{ textTransform: "uppercase" }}
                                            onClick={(event: any) => this.applySelectedSubsPlan(event)}
                                        >
                                            {t("Upgrade_plan.Proceed_btn")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid >
            </Box>
        )
    }
}
export const themeCreatStyle = (theme: any) => {
    return {
        subscription_div: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px 10px 20px"            
        },
        subscription_details_outer_div: {
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
            height: "100%",
            padding: 1,
            borderRadius: 8,
        },
        choose_button_css: {
            fontWeight: 900,
            borderRadius: 10,
            color: theme.palette.primary.main,
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
                marginTop: 5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
                marginTop: 5,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 17,
                marginTop: 10,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 18,
                marginTop: 15,
            },
        },
        subscription_contents_div: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                margin: "0px 5px",
            },
            [theme.breakpoints.only("sm")]: {
                margin: "0px 5px",
            },
            [theme.breakpoints.up("md")]: {
                margin: "0px 15px",
            },
        },
        subscription_detail_div: {
            borderRadius: 8,
            height: "100%",
            width: "100%",
            background: "#222222",

            [theme.breakpoints.only("sm")]: {
                padding: "23px",
            },
            [theme.breakpoints.only("xs")]: {
                padding: "20px",
            },
                padding: "30px"
        },
        back_link: {
            fontWeight: 600,
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            lineHeight: 1,
            fontFamily: "Roboto",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        arrow_icon: {
            transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        coupounTextField: {
            display: "flex",
            justifyContent: "center",
            padding: 1,
            borderRadius: 8,
            background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        },
        plan_details: {
            fontWeight: 700,
            margin: "30px 0px 20px 0px",
            fontFamily: "Roboto",
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
        },
        discount_amount: {
            color: "#138457"
        },
        current_plan_amount: {
            margin: "10px 0px",
            color: theme.palette.type === "dark" ? "#BAB9BF" : "#000000",
            fontWeight: 300,
            fontFamily: "Roboto",
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        current_plan_amount_span: {
            display: "flex",
            flexDirection: (theme.direction === "rtl" ? 'row-reverse' : 'row') as 'row' | 'row-reverse'
        },
        amount_section: {
            fontWeight: 700,
            margin: "10px 0px",
            fontFamily: "Roboto",
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        },
        remove_btn_css: {
            color: "#C8536B",
            fontWeight: 600,
            fontFamily: "Roboto",
            fontSize: 17,
            [theme.breakpoints.only("xs")]: {
                fontSize: 13,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 15,
            }
        },
        apply_btn_css: {
            color: "rgba(234,67,93, 1)",
            fontWeight:600,
            fontFamily: "Roboto",
            fontSize: 17,
            [theme.breakpoints.only("xs")]: {
                fontSize: 13,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 15,
            }
        },
        back_arrow_button_css: {
            display: "flex",
            justifyContent: "left",
            marginBottom: "24px",
            color: theme.palette.type === "dark" ? "#F6F6F6" : "#000000",
            alignItems: "center",
            [theme.breakpoints.only("xs")]: {
                fontSize: 12,
                marginBottom: "16px",
            },
            fontFamily: "Roboto",
            [theme.breakpoints.only("sm")]: {
                fontSize: 16,
                marginBottom: "20px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
                marginBottom: "24px",
            },
        },
        total_divider_line: {
            height: "1px",
            background: "#34334F",
            margin: "20px 0px",
        },
        inputbase: {
            backgroundColor: "#07070E",
            borderRadius: 8,
            width: "100%",
            padding: "15px",
            fontFamily: "Roboto",
            [theme.breakpoints.only("xs")]: {
                fontSize: 16,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18,
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20,
            },
        }
    }
};
export default withStyles(themeCreatStyle)(withTranslation()(UpgradePlan));
// Customizable Area End
