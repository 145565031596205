export enum StorageKeys {
  Token = 'Token',
  RefreshToken = 'RefreshToken',
  User_Id = 'User_Id',
  isSubscribe = 'isSubscribe',
  SKIP_ONBOARDING = 'SKIP_ONBOARDING',
  coupon_Id = 'coupon_Id',
  Otp_Type = 'Otp_Type',
  USER_LANG = 'USER_LANG',
  full_name = 'full_name',
  profile_picture = 'profile_picture',
  ProfileId = 'ProfileId',
  userProfileId = 'userProfileId',
  DateOfBirth = 'DateOfBirth',
  value = 'value', // otp Value
  Mid = 'Mid',
  ProfileSelectedID = 'ProfileSelectedID',
  Theme = 'Theme',
  FCM_TOKEN = 'FCM_TOKEN',
  current_plan = 'current_plan',
  isGuest="false",
}
