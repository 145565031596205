//@ts-nocheck
import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    Dialog
} from "@material-ui/core";
import "./MovieDetails.css";
import { shareicon, copy, sendIcon, like ,LikeIconWeb , LikedIconWeb} from "./assets";
import Avatar from "@material-ui/core/Avatar";
import LikeCommentShareController from './LikeCommentShareController';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    FacebookMessengerShareButton,
    PinterestShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    TelegramIcon,
    FacebookMessengerIcon,
    PinterestIcon,
    EmailIcon
} from "react-share"
import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
// @ts-ignore
import cssClasses from './LikeCommentShare.module.css';
import { withTranslation } from 'react-i18next';
import MesDialogWeb from '../../user-profile-basic/src/shared/MesDialog.web';
import moment from 'moment';
require("moment/min/locales.min");
let lang = localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
moment.locale(lang);
export const configJSON = require("./config.js");

export const StyledTextField = withStyles({
    root: {

        fontFamily: "Araboto-Normal",
        fontSize: "14",
        fontWeight: 400,
        fontStyle: 'normal',
        "& .MuiInputBase-input": {
            color: "#fff",
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: "Araboto-Normal",
            fontSize: "14",
            color: localStorage.getItem("darkMode") == "true" ? "#FFFFFF" : '#000000',
            fontWeight: 400,
        },
    },
})((props: any) => <TextField {...props} />);

export class LikeCommentShare extends LikeCommentShareController {

    // Customizable Area Start
    // Customizable Area End


    render() {
        const { classes, t , isShareDialog, handleCloseShare ,isComment , isLikeModal }: any = this.props;
        const { isLike, comments } = this.state;
        return (
            <>
            {
                isLikeModal && <img src={isLike ? LikedIconWeb : LikeIconWeb} className={classes.likeShareIcons} onClick={this.likeUnlikeHandler} />
            }
            {
                                isShareDialog &&
                                <Dialog
                                data-test-id="close_"
                                PaperProps={{
                                  className : classes.outer_div
                                }}
                                aria-labelledby="customized-dialog-title"
                                open={isShareDialog}
                                onClose={handleCloseShare}
                                closeAfterTransition
                                fullWidth={true}
                                disableAutoFocus={true}
                                style={{
                                  borderRadius: 8, backdropFilter: "blur(1px)",
                      
                                }}
                              >
                                <Box
                                    className={`${classes.drawerBox} ${cssClasses.contentSpaceBetween}`}
                                >
                                  <Typography color="primary" className={classes.DrawerLinks}>{t("movieDetails.shareWithFriends")}</Typography>

                                  <Grid container className={classes.dividerStyleLine}/>
                                    <Box
                                        className={classes.flexSpaceAround}
                                    >
                                        <FacebookShareButton url={this.state.movieUrl} translate="">
                                            <FacebookIcon size={36} round={true} path={undefined} crossOrigin={undefined} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={this.state.movieUrl} translate="">
                                            <TwitterIcon size={36} round={true} path={undefined} crossOrigin={undefined} />
                                        </TwitterShareButton>
                                        <WhatsappShareButton url={this.state.movieUrl} translate="">
                                            <WhatsappIcon size={36} round={true} path={undefined} crossOrigin={undefined}/>
                                        </WhatsappShareButton>
                                        <LinkedinShareButton url={this.state.movieUrl} translate="">
                                            <LinkedinIcon size={36} round={true} path={undefined} crossOrigin={undefined}/>
                                        </LinkedinShareButton>
                                    </Box>
                                    {this.state.shareShow && 
                                    <Box
                                        className={classes.flexSpaceAround}
                                    >
                                        <TelegramShareButton url={this.state.movieUrl} translate="">
                                            <TelegramIcon size={36} round={true} path={undefined} crossOrigin={undefined} />
                                        </TelegramShareButton>
                                        <PinterestShareButton media='' url={this.state.movieUrl} translate="">
                                            <PinterestIcon size={36} round={true} path={undefined} crossOrigin={undefined}/>
                                        </PinterestShareButton>
                                        <FacebookMessengerShareButton appId='' url={this.state.movieUrl} translate="">
                                            <FacebookMessengerIcon size={36} round={true} path={undefined} crossOrigin={undefined}/>
                                        </FacebookMessengerShareButton>
                                        <EmailShareButton url={this.state.movieUrl} translate="">
                                            <EmailIcon size={36} round={true} path={undefined} crossOrigin={undefined}/>
                                        </EmailShareButton>
                                    </Box>}
                                    <Grid container className={classes.dividerStyleLine}/>
                                    <Box
                                        className={`${cssClasses.ptb10} ${cssClasses.mlr10}`}
                                    >
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Typography color="primary" className={classes.link}>{t("movieDetails.copyTheLink")}</Typography>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={this.handleTooltipClose}
                                                placement={localStorage.getItem("i18nextLng") == 'ar' ? "right" : "left"}
                                                arrow
                                                open={this.state.tootipOpen}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title={t("movieDetails.CopiedSuccessfully")}
                                            >
                                                <img className={classes.copyImage} src={copy} onClick={this.handleCopyEvent} />
                                            </Tooltip>
                                        </Box>
                                        <Typography color="primary" className={`${classes.sublink} two_lines`}>{this.state.movieUrl}</Typography>
                                    </Box>
                                    <Grid container className={classes.dividerStyleLine}/>
                                    <Box
                                        className={classes.flexSpaceAround}
                                    >
                                        <Button color="primary" data-test-id="cancelButton" className={classes.cancelButton} onClick={()=>handleCloseShare()}>
                                            {t("movieDetails.cancel")}
                                        </Button>

                                        <Button data-test-id="shareButton" variant='contained' color="primary" className={classes.shareButton} onClick={() => this.setState({ shareShow: true })}>
                                            <img className={classes.shareIcon} src={shareicon} />
                                            {t("movieDetails.Share")}
                                        </Button>

                                    </Box>

                                </Box>
                                </Dialog>

                            }
                            {
                               isComment &&
                                <Grid container className={classes.commentContainerOuter}>
                                    <Grid container>
                                        <Grid item className={classes.sendInputOuter}>
                                        <StyledTextField
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: { input: classes["placeholder"] },
                                            }}
                                            data-test-id="one"
                                            placeholder={t("movieDetails.startTyping")}
                                            className={classes.textfeild}
                                            id="commentInput"
                                            onChange={(e: any) => { this.setState({ commentText: e.target.value }) }}
                                            onKeyDown={(e: any) => { e.key === "Enter" && this.createCommentsApi() }}
                                            value={this.state.commentText}
                                        />
                                        </Grid>

                                        <Grid item><img  className={classes.sendIcon} onClick={this.createCommentsApi} style={{ transform: localStorage.getItem("i18nextLng") === 'ar' ? "rotate(180deg)" : "none" }} src={sendIcon} /></Grid>

                                    </Grid>
                                  <Grid container ref={this.commentEnd} className={`${cssClasses.commentContainer}`}>
                                            {
                                                comments?.map((ele: any, i: any) => {
                                                    return (
                                                        <Grid item xs={12} key={ele?.attributes?.profile_name} className={classes.commentCard}>
                                                            <Grid container spacing={1}>
                                                                <Grid item >
                                                                    <Avatar alt="comment_img" src={ele?.attributes?.photo} className={classes.commentAvatar}></Avatar>
                                                                </Grid>
                                                                <Grid item className={classes.commentInnerBox}>
                                                                    <Typography className={`${classes.userText} single_line`} >{ele?.attributes?.profile_name}  <span className={classes.timeShow}>{moment(ele?.attributes?.updated_at ? ele?.attributes?.updated_at : new Date()).fromNow().replace(/a few/g, "a")}</span></Typography>
                                                                     <Typography color='primary' className={`${classes.commentText} ${localStorage.getItem("i18nextLng") === 'ar' ? cssClasses.textRight : ''}`}>{ele?.attributes?.comment}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>)
                                                })
                                            }
                                        </Grid>                                  
                                </Grid>
                            }               
            </>
        )
    }
}

/*Styles*/
const drawerWidth = 350;
export const styles: any = (theme: any) => ({
    flexSpaceAround: {
        display: "flex",
        justifyContent: "space-around",
        padding: '15px 0'
    },
    sendIcon: {
        width: "54px",
        height: 54,
        padding: '0 20px'
    },
    outer_div:{
        background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        padding: '1px',
    width: 400,   
    borderRadius: 8 
    },
    commentAvatar: {
        width: '34px',
        height: "34px",
        borderRadius: '50%'
    },
    commentText:{
        fontWeight: 600,
        color: "#EDDBDB",
        size: "16",
        marginTop: "10px",
        fontFamily: "Roboto"
    },
    userText:{
        fontWeight: 400,
        color: "#F6F6F6",
        size: "16",
        fontFamily: "Roboto"
    },
   drawerBox: {
        borderRadius: 8,
        opacity: 1,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        background:"linear-gradient(180deg, rgba(7, 7, 13, 0.9) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.9) 100%)",
    },
    dividerStyleLine:{
        background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        height:'1px',
        padding: 0,
        margin: "10px 0"
      },
      commentInnerBox:{
        flexGrow: 1,
        maxWidth: 'calc(100% - 46px)'
      },
    timeShow: {
        fontSize: "14px", 
        color: "#DCDCDC",
        lineHeight: 1,
        fontFamily: "Roboto",
        opacity: 0.5,
        padding: '0px 15px'

    },
    shareIcon: {
        width: 23,
        height: 23,
        paddingRight: "10px", 
        paddingLeft: '10px', 
        filter: 'invert(1)' 
    },
    copyImage: {
        width: "30px", 
        height: "30px", 
        cursor: "pointer", 
        filter: theme.palette.type == "dark" ? 'none' : 'invert(1)'
    },
    emojiImg: {
        filter: theme.palette.type == "dark" ? "none" : 'invert(1)'
    },
    commentCard: {
        margin: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        borderBottom: '1px solid #141426'
    },   
    link: {
        size: "18px",
    },
    sublink: {
        fontSize: "14px",
        fontFamily: "Araboto-Normal"
    },
    avatar: {
        backgroundColor: "white",
    },
    sendInputOuter:{
        width: 'calc(100% - 100px)'
    },

    textfeild: {
        border: "none",
        background: "#141426",
        borderRadius: 30,
        padding: '10px 30px',
        width: "calc(100% - 60px)"

    },
    placeholder: {
        "&::placeholder": {
            color: "#fff",
        },
    },
    username: {
        fontSize: "12px",
        fontFamily: "Araboto-Normal"
    },
    BannerTitle: {
        color: "white",
    },
    DrawerLinks: {
        aligntext: "center",
        padding: "15px 0",
        fontWeight: 700,
        fontFamily: "Araboto-Bold",
        size: "18px",
        textAlign : "center" as const
    },
    commentContainerOuter:{
        padding: theme.direction === 'ltr' ? '15px 30px 15px 0' : '15px 0px 15px 30px'
    },
   cancelButton: {
        borderRadius: "12px",
        border: `${theme.direction === 'rtl' ? '1px solid rgb(24 23 23)' : '1px solid rgba(151,151,151, 1)'}`,
        opacity: 1,
        backgroundClor: " rgba(26,27,34, 1)",
        fontWeight: 700,
        fontFamily: "Araboto-Normal",
        width: "155px",
        marginRight: "4px",
        marginLeft: `${theme.direction === 'rtl' && '4px'}`,
        textTransform: "capitalize",
    },
    shareButton: {
        borderRadius: "12px",
        opacity: 1,
        fontFamily: "Araboto-Normal",
        fontWeight: 700,
        textTransform: "capitalize",
        width: "155px",
        color:"#ffffff"
       
    },
    commentType: {
        borderRadius: " 23px",
        boxShadow: "4px 6px 20px rgba(0,0,0, 0.2)",
        opacity: "1",
        backgroundColor: '#D3D3D3',
        width: "auto",
        height: "34px",
        display: "flex",
        justifyContent: "space-between",
        color: "white",
        alignItems: "center",
    },
});
export default withStyles(styles)(withTranslation()(LikeCommentShare));
