import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  Popover
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import SessionExp from "../../dashboard/src/SessionExp.web";
import {
  dummy_icon,
  logOut,
  generalSetting,
  editIcon,
} from "./assets";
import { withTranslation } from 'react-i18next';
// Customizable Area End


export const themeCreatStyle: any = (theme: any) => {
  return {
    font_size_18: {
      fontFamily: "Roboto",
                        fontWeight: 600,
                        wordBreak: 'break-all',
                        maxWidth: '80%',
                        width: '200px',
                        textTransform: 'capitalize' as const,
                        color: "#F6F6F6",
      fontSize: 18,
      [theme.breakpoints.only("xs")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16.5,
      }
    },
    font_size_16: {
      fontFamily: "Roboto",
      fontWeight: 500,
      color: "#9A9A9A",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 14.5,
      },
      fontSize: 16,
      
    },
    settingUnderLine:{
      height: 2,
      background: "#141426"
    },
    setting_div: {
      cursor: 'pointer',
      padding: "15px 2px 15px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.only("xs")]: {
        padding: "13px 2px 13px 0",
      },
      [theme.breakpoints.only("md")]: {
        padding: "14.5px 2px 14.5px 0",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "14px 2px 14px 0",
      },
      [theme.breakpoints.only("lg")]: {
        padding: "15px 2px 15px 0",
      },
    },
    setting_icons: {
      width: 22,
      height: 22,
      margin: theme.direction === "rtl" ? "0 0 0 15px" : "0 15px 0 0",
      justifyContent: "space-between",
      transform: theme.direction === "rtl"?  "rotate(180deg)" : "none",
      [theme.breakpoints.only("xs")]: {
        width: 20,
        height: 20,
      }
    },
    settingPopup: {
      background: 'none',      
      maxHeight: 300,
      height: 260,
      boxShadow: 'none',      
    },
    dialogBackground: {
      background: "#07070E",
      borderRadius: 8    
    },
    outer_div: {
      background: "transparent",
      maxWidth: 450
    },
    user_name_outer_div: {
      padding: 20,

      [theme.breakpoints.only("xs")]: {
        padding: 15,
      },
      [theme.breakpoints.only("sm")]: {
        padding: 17,
      },
      [theme.breakpoints.only("lg")]: {
        padding: 20,
      },
      [theme.breakpoints.only("md")]: {
        padding: 19,
      },
    },
    setting_bottom_div: {
      padding: '0px 30px 30px 45px',

      [theme.breakpoints.only("xs")]: {
        padding: '0px 25px 25px 25px',
      },
      [theme.breakpoints.only("sm")]: {
        padding: '0px 27px 27px 27px',
      },
      [theme.breakpoints.only("lg")]: {
        padding: '0px 30px 30px 30px',
      },
      [theme.breakpoints.only("md")]: {
        padding: '0px 29px 29px 29px',
      },
    },
    editIcon:{
      width: 20,
      height: 20,
      cursor: "pointer"
    },
    details_outer_div: {
      boxShadow: "0px 1px 10px rgba(0,0,0, 0.1)",
      padding: "1px",
      [theme.breakpoints.only("xs")]: {
        borderRadius: 15,
      },
      [theme.breakpoints.only("sm")]: {
        borderRadius: 17,
      },
      [theme.breakpoints.only("lg")]: {
        borderRadius: 20,
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: 20,
      },
      [theme.breakpoints.only("md")]: {
        borderRadius: 18,
      },
    },
    profileAvatar: {
      cursor: "pointer",
      height: '46px',
      width: '46px',
      padding: '1px',
      borderRadius: "50%",
      background : "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
      [theme.breakpoints.only("xs")]: {
        width: '36px',
        height: '36px',
      
      },
    },
    DialogHeaderContent: {
      display: "flex",
      flexDirection: "column",
      margin: theme.direction === "rtl" ? "0 20px 0 0" : "0 0 0 20px",
    }
  }
};


// Customizable Area End

import SettingDialogController, { Props } from "./SettingDialogController.web";
import Spinner from "./shared/Spinner.web";
import MesDialog from "./shared/MesDialog.web";

export class SettingDialog extends SettingDialogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  menuItems = [
    { label: this.props.t("settingDialogWeb.setting"), route: "Setting" },
    // Add more menu items here
  ];

  render() {
    const { classes } = this.props;
    const { t }: any = this.props;
    const selectedUserId = localStorage.getItem("selectedUser");
    // Customizable Area Start
    const { isGuestMember } = this.state
    return (
      <>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        {this.state.logout && <SessionExp deleteModal={this.state.logout} onCloseModal={() => this.logOut()} />}
        <Popover
                        data-test-id="close"
                        anchorEl={this.props.settingAnchor}
                        open={Boolean(this.props.settingAnchor)}
                        onClose={() => { this.props.setSettingAnchor() }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                            className: classes.settingPopup                            
                        }}
                    >
          <div
            className={classes.dialogBackground}
            style={{
              overflowY: "auto",
              direction: localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
            }}
          >
            <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"                
                className={classes.user_name_outer_div}
              >
                <div
                  data-test-id="memberDetails"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    }}
                >
                  <img
                    src={this.state.userDetails.length && this.state.userDetails[0]?.attributes?.photo ? this.state.userDetails[0]?.attributes?.photo : dummy_icon}
                    className={classes.profileAvatar}
                  />
                  <div
                    className={classes.DialogHeaderContent}
                  >
                    <Typography
                      color="primary"
                      className={`${classes.font_size_18} single_line`}
                    >
                      {this.state.userDetails.length !==0 && this.state.userDetails[0].attributes.name}
                    </Typography>                  
                  </div>
                </div>
               {selectedUserId && !isGuestMember && <div>
                  <img src={editIcon} className={classes.editIcon} data-test-id="closeDialog" onClick={() => this.generalSettingClick()} />
                </div>}

              </Grid> 
            <Grid
              item
              xs={12}
             className={classes.settingUnderLine}
            />

            <Grid item xs={12}>
              <Grid container className={classes.setting_bottom_div}>
                <Grid item xs={12} className={classes.details_outer_div}>

                  </Grid>
                 
                  <Grid item xs={12} className={classes.setting_div}
                    data-test-id="member"
                    onClick={() => this.handleSettingClick("GeneralSetting")}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={generalSetting} className={classes.setting_icons} />
                      <Typography
                        className={classes.font_size_16}
                      >
                        {t("settingDialogWeb.generalSettings")}
                      </Typography>
                    </div>
                   
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.settingUnderLine}
                  />
                

              <Grid item xs={12} className={classes.setting_div}
                  data-test-id="logOut"
                  onClick={() => {
                    this.setState({ logOut: true })
                  }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={logOut} className={classes.setting_icons} />
                    <Typography
                      className={classes.font_size_16}
                    >
                        {this.state.isGuestMember ? t("settingDialogWeb.signInsignUp") : t("settingDialogWeb.logout") }
                    </Typography>
                  </div>                 
                </Grid>
              </Grid>
            </Grid>
          </div>
          </Popover>
          {this.state.logOut && <MesDialog
            isLogout={true}
            deleteModal={this.state.logOut}
            isCancelFunction ={true} 
            onCancelClick={()=> this.handleCloseDialog()}
            onCloseModal={() => this.closeDialog()}
            confirmMes={this.getTitle(
              t("settingDialogWeb.aAM"), t("settingWeb.log_out_mes"))}
            cancelButtonText={this.getTitle(
              t("settingDialogWeb.registerNow"), t("settingWeb.dont"))}
            okButtonText={this.getTitle(
              t("sessionDialog.login"), t("settingWeb.log_out"))}
            deleteClick={() => this.handleLogoutClick()} />
          }            
        
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(SettingDialog));
// Customizable Area End
