import React from "react";
// Customizable Area Start
//@ts-ignore
import { Button, Box, Container, Grid, withStyles, Typography, Tabs, Tab } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import "./Podcast.css"
import { volumeIcon } from "./assets";

// Customizable Area End
import PodcastsController from "./PodcastController";
import MoviesSlider from "./MoviesSlider.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import MovieSwiperSkelton from "./Swiperskelton.web";
import MovieSwiper from "./Swiper.web";

export class PodcastsWeb extends PodcastsController {
  constructor(props: any) {
    super(props)
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  removeChildBullets = () => {
    let bulletd: any = document.getElementsByClassName('swiper-pagination')[0];
    bulletd.childNodes[0].style.display = 'none';
  }
  navigate = async (id: any) => {
    sessionStorage.setItem("moviesData", id)
    this.props.navigation.navigate('Dashboard');
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, t }: any = this.props;
   
    return (<>
        <Grid item xs={12}>
         {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
             
         <Container maxWidth={false} className={`${classes.dashboardContainer} podcast_slider`} disableGutters>
          {
                  this.state?.moviesLoading ? <MovieSwiperSkelton /> :
                    <>
                      {this.state?.trendingPodcast?.length !== 0 && this.state?.trendingPodcast?.length !== null &&
                         <MovieSwiper data-test-id="movieswiper" moviesData={this.state?.trendingPodcast} navHandler={this.navHandler} imgIndex={this.state?.imgIndex} />
                      }
                    </>
                }
               </Container>           
          </Grid>
     <Box>
          <Tabs aria-label="wrapped label tabs example"
            variant="scrollable"
            scrollButtons="auto"
            centered
            className={classes.tabsView}
          >
            <Tab
              data-test-id="category_change"
              className={classes.chips}
              label={t("podcast.all")}
              onClick={() => this.onCategoryChange('')}
              style={{
                background: this.state.categoryId == '' ? "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)" : 'transparent'
              }}

            ><Typography>
              {t("podcast.all")}
            </Typography>
            </Tab>
            {
              this.state.podcastCategory?.map((category: any) =>
                <Tab key={category.id}
                  data-test-id="category-change"
                  className={classes.chips}
                  label={category?.attributes?.name}
                  wrapped={true}
                  onClick={() => this.onCategoryChange(category?.attributes?.id)}
                  style={{
                    background: category?.attributes?.id === this.state.categoryId ? "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)" : 'transparent'
                  }}
                >
                </Tab>)
            }
          </Tabs>
        </Box>

        {this.state.getContinuePodcast?.length > 0 && <Box className={classes.SliderWrapper}>
          <MoviesSlider
            navHandler={this.navHandler}
            slidesToShow={10}
            slidesToScroll={5}
            sliderTitle={t("movieDetails.continueListen")}
            isContinueWatching={true}
            similarMovies={this.state.getContinuePodcast}
          />
        </Box>}
        {this.state.bandData?.length > 0 &&
          this.state.bandData?.map((item) => {
            return (
              <Box key={item.id} className={classes.SliderWrapper}>
                <MoviesSlider
                  navHandler={this.navHandler}
                  slidesToShow={10}
                  slidesToScroll={5}
                  sliderTitle={item?.name}
                  similarMovies={item?.bend_contents?.data}
                  previousHandler={this.previewHandler}
                  nextHandler={this.nextHandler} />
              </Box>
            )
          })
        }
        {
          this.state.bandData?.length == 0 && this.state.getContinuePodcast?.length == 0 &&
          <Box style={{ height: 20, margin: '2% 5%' }}>
            <Typography className={classes.fontStyle1}>{t("podcast.nocontent")}</Typography>
          </Box>
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  movieNames: {
    color: '#fff',
    position: 'absolute',
    top: 170,
    textAlign: 'center',
    fontSize: 20,
  },
  dashboardContainer: {
    margin: '0px',
    padding: '0px',
    overflowX: 'hidden',
    boxSizing: 'border-box',
  },
  SliderWrapper: {
    margin: theme.direction === "rtl" ? '0 5% 0 0' : '0 0 0 5%'
  },
  arabicButtons: {
    position: "absolute",
    bottom: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
    },
  },
  tabsView:{
    margin: theme.direction === "rtl" ? '6% 5% 0 0' : '6% 0 0 5%',
    "& .MuiTab-wrapper": {
      background: "green"
    },
    "MuiTab-wrapper": {
      background: "green"
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    margin: '10px 5vw',
    background: 'transparent',
    boxShadow: 'none'
  },
  leftHeader: {
    display: 'flex',
  },
  rightHeader: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  play: {
    width: '70px',
    height: '70px',
    position: 'relative',
    top: -120,
    right: -80,
    [theme.breakpoints.only("xs")]: {
      width: '50px',
      height: '50px',
      top: -105,
      right: -90,
    },
  },
  backgroundImage_div3: {
    maxWidth: "100%",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
    [theme.breakpoints.only("xs")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "27px 10px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "32px 10px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "32px 10px",
    },
  },

  typo: {
    color: '#fff',
    [theme.breakpoints.only("xs")]: {
      fontSize: '17px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '27px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },

  arrowLeft: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrowRight: {
    border: '2px solid #fff',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrows: {
    [theme.breakpoints.only("xs")]: {
      fontSize: '15px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: '22px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '25px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: '30px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '30px',
    },
  },
  arrowsGrid: {
    width: '5%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    right: 50,

    [theme.breakpoints.only("xs")]: {
      position: 'relative',
      right: 0,
      width: '15%',
    },
    [theme.breakpoints.only("sm")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("md")]: {
      position: 'relative',
      right: 0,
      width: '10%',
    },
    [theme.breakpoints.only("lg")]: {

    },
    [theme.breakpoints.up("lg")]: {

    },
  },

  user_header: {
    padding: "0 0px 20px",
    [theme.breakpoints.only("xs")]: {
      padding: "0 0px 15px",
    },
  },
  image_div: {
    width: '50px',
    height: '50px',
    marginRight: theme.direction == "rtl" ? 0 : 15,
    marginLeft: theme.direction == "rtl" ? 15 : 0,

    [theme.breakpoints.only("xs")]: {
      width: '38px',
      height: '38px',
      marginRight: theme.direction == "rtl" ? 0 : 10,
      marginLeft: theme.direction == "rtl" ? 10 : 0,
    },

    [theme.breakpoints.only("sm")]: {
      width: '42px',
      height: '42px',
      marginRight: theme.direction == "rtl" ? 0 : 10,
      marginLeft: theme.direction == "rtl" ? 10 : 0,
    },

  },
  font_size_20: {
    fontSize: 20,
    [theme.breakpoints.only("xs")]: {
      fontSize: 17.5,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 19,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 18.5,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 20,
    },
  },
  textField: {
    paddingBottom: '1.3rem',
    width: '100%'
  },
  topPodHead: {
    marginTop: '1.3rem',
    marginBottom: '0.8rem',
  },
  listenBtn: {
    background: '#EA435D',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    "&:hover": {
      background: '#EA435D',
    },
  },
  musicImg: {
    maxWidth: "100%",
    width: "100vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    minHeight: "100vh",

  },
  lockicon: {
    float: 'right',
    color: '#fff'
  },
  playTitle: {
    color: '#fff',
    marginTop: '-30px'
  },
  playSubTitle: {
    color: '#7A7B7F'
  },
  trendingPodHead: {
    fontSize: '28px',
    paddingTop: '0.9rem',
    textAlign: theme.direction == "rtl" ? "right" : "left",
  },
  musicimglog: {
    display: 'flex',
    justifyContent: 'center',
  },
  player: {
    border: '1px solid gray',
    borderRadius: '1rem',
    backgroundImage: `url(${volumeIcon})`,
    maxWidth: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: '0rem 1rem'
  },
  playerbox: {
    display: 'none',
    [theme.breakpoints.up("md")]: {
      paddingTop: '2rem',
      display: "block"
    }
  },
  mobilePlayerBox: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: 'none',
    }
  },
  mobilePlayBox: {
    height: "50px",
    width: "50px",
    background: "rgba(234, 67, 93, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    position: "fixed",
    zIndex: 200,
    bottom: 30,
    right: 30
  },
  mobilePlayIcon: {
    color: "white"
  },
  quicksearch: {
    margin: "24px 0 0 0"
  },
  searchPodcast: {
    background: `${theme.palette.type == "dark" ? "linear-gradient(270deg, rgba(18,18,26,1) 0%, rgba(18,18,27,1) 40%, rgba(23,23,39,1) 100%)" : ''}`,
    height: '97%'
  },
  playsection: {
  },
  playIcons: {
    backgroundColor: '#6869AC',
    borderRadius: "0px 0px 20px 20px",
    padding: '1.4rem',
    marginLeft: '-16px',
    marginRight: '-16px',
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "space-between"
  },
  timeRemainder: {
    fontSize: "10px",
    color: '#fff',
    marginTop: '6px',
  },
  sliderColor: {

    "&>.MuiSlider-thumb": {
      color: "#1976d2 !important"
    },
    "&>.MuiSlider-rail": {
      color: "#1976d2 !important",
      opacity: 0.38,
    },
    "&>.MuiSlider-track": {
      color: "#1976d2 !important"
    }
  },
  DownloadText: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "16px",
    },
  },
  playButton: {
    width: "173px",
    height: "46px",
    backgroundColor: "rgba(234, 67, 93, 1)",
    opacity: 1,
    marginRight: '8px',
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "rgba(234, 67, 93, 1)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
  DownloadImage: {
    width: "20px",
    height: "20px !important",
    margin: theme.direction == "rtl" ? "0 0 0 10px" : "0 10px 0 0",
    filter: localStorage.getItem("darkMode") == "false" ? 'invert(1)' : "none"
  },
  DownloadButton: {
    width: "216px",
    height: "45px",
    margin: theme.direction == "rtl" ? "0 0 0 10px" : "0 10px 0 0",
    backgroundColor: theme.palette.background.blackDark,
    border: "1px solid #979797",
    "&:hover": {
      backgroundColor: theme.palette.background.blackDark,
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
  DownloadButtonActive: {
    width: "240px",
    height: "45px",
    margin: theme.direction == "rtl" ? "0 0 0 10px" : "0 10px 0 0",
    backgroundColor: "rgba(234, 67, 93, 1)",
    border: "1px solid #979797",
    "&:hover": {
      backgroundColor: "rgba(234, 67, 93, 1)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
  containerMain: {
    maxWidth: "100%",
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "8rem",
      paddingRight: "8rem"
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: "4rem",
      paddingRight: "4rem"
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: "3.2rem",
      paddingRight: "3.2rem"
    },
  },
  toppodcastergroup: {
    height: 'auto',
    maxHeight: '18.5rem',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  chips: {
    fontSize: '14px',
    fontWeight: 700,
    margin: "5px 5px 5px 5px",
    color: `${theme.palette.type == "dark" ? 'white' : 'black'}`,
    borderWidth: '0.09rem',
   borderStyle: 'solid',
    borderImage: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
    borderImageSlice: 1, 
    borderRadius: 4, 
  },

  fontStyle1: {
    color: `${theme.palette.type == "dark" ? 'white' : 'black'}`,
    fontSize: '1.2rem',
  }
})
export default withStyles(styles)(withTranslation()(PodcastsWeb))
// Customizable Area End
