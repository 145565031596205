import React from "react";
// Customizable Area Start
import { Grid, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import "./Payment.css";
// Create formatter (English).
// Customizable Area End

import PaymentController, { Props } from "./PaymentController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";

export class PaymentWeb extends PaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    return (
      <>
        {this.state.checkout_id !== "" && (
          <Grid className={classes.MainWrapper}>
            <form
              action="Confirmation"
              className="paymentWidgets"
              data-brands="VISA MASTER AMEX"
            />
          </Grid>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = () => ({
  MainWrapper: {
    direction: "ltr",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    background: "rgb(0,0,0)",
    backgroundColor: "rgba(0,0,0,0.6)",
    "& .wpwl-container": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      padding: "0 16px"
    },
    "& .wpwl-form": {
      padding: "14px",
      backgroundColor: "white",
      maxWidth: "36em !important"
    },
    "& .wpwl-wrapper>.wpwl-icon": {
      right: ".5625em",
      left: "auto"
    }
  }
});
export default withStyles(styles)(withTranslation()(PaymentWeb));
// Customizable Area End
