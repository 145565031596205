//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, PermissionsAndroid, Platform } from "react-native";
import ImagePicker from "react-native-image-crop-picker";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;

  // Customizable Area Start
  route: {
    params: {
      editBanner: boolean;
      bannerId: string;
    };
  };
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  imagebannerUrl: string;
  bannerName: string;
  bannerUrl: string;
  bannnerDescription: string;
  bannerImage: File | string;
  showClearModal: boolean;
  loadingData: boolean;
  bannerDialog: boolean;
  isEdit: boolean;
  editBannerId: string;
  fileId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAdvertisementsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addCustomAdvertismentApiCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loadingData: false,
      imagebannerUrl: "",
      bannerName: "",
      bannerUrl: "",
      showClearModal: false,
      bannerDialog: true,
      bannnerDescription: "",
      bannerImage: "",
      isEdit: false,
      editBannerId: "",
      fileId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const bannerData = message.getData(
        getName(MessageEnum.AddAdvertisementMessageData)
      );
      if (bannerData) {
        let editBannerData = JSON.parse(bannerData);
        editBannerData.isEdit
          ? this.editBannerData(editBannerData)
          : this.setState({ isEdit: false });
      }

      const uploadResponse = message.getData(
        getName(MessageEnum.UploadResponseMessageData)
      );
      if (uploadResponse) {
        this.setState({ fileId: uploadResponse });
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.addCustomAdvertismentApiCall) {
        this.getResponseAddEdit(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  //For web
  getResponseAddEdit = async (responeJson: []) => {
    if (responeJson != null) {
      this.handlebannerDialogClose();
      this.handleAddAdsSuccessMessage();
    } else {
      this.handleAddAdsErrorMessage();
    }
  };

  getUploadFileResponse = (responseJson: { data: { id: string } }) => {
    if (responseJson.data) {
      this.setState({ fileId: responseJson.data.id });
    }
  };
  //For Mobile
  getDeviceOS = (valueIOS: string, valueAndroid: string) => {
    return Platform.OS === "ios" ? valueIOS : valueAndroid;
  };
  //For Mobile
  requestStoragePermission = async () => {
    let grantedPermission = "";

    grantedPermission = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
      {
        title: "App Storage Permission",
        message: "App needs access to your Storage",
        buttonNegative: "Cancel",
        buttonPositive: "OK",
      }
    );
    const permissionPlatform = this.getDeviceOS(
      grantedPermission,
      PermissionsAndroid.RESULTS.GRANTED
    );
    if (permissionPlatform === PermissionsAndroid.RESULTS.GRANTED) {
      await this.chooseProfileImage();
    } else {
      Alert.alert(
        configJSON.alertTitle,
        configJSON.noAccess,
        [
          {
            text: configJSON.okTitle,
          },
        ],
        { cancelable: false }
      );
    }
  };
  //For Mobile
  chooseProfileImage = async () => {
    this.setState({ showClearModal: true });
    // ImagePicker.openPicker({
    //   width: 200,
    //   height: 200,
    //   cropping: true,
    //   freeStyleCropEnabled: true,
    //   compressImageQuality: 0.4,
    // })
    //   .then((imageResult) => {
    //     let selectedImage = imageResult;
    //     let sendImageData = {
    //       uri: this.getDeviceOS(
    //         `file:///${selectedImage?.path}`,
    //         selectedImage.path
    //       ),

    //       type: "image/jpeg",
    //       name: "image.jpg",
    //     };
    //     this.uploadAdapterMessage(sendImageData);
    //     this.setState({
    //       bannerImage: sendImageData,
    //       imagebannerUrl: imageResult.path,
    //     });
    //   })
    //   .catch((exceptionImage) => {});
  };
  //For web
  handleAddAdsSuccessMessage = () => {
    let successMes = this.state.isEdit
      ? configJSON.editSuccessMessage
      : configJSON.addSuccessMessage;
    toast.success(successMes);
  };
  //For web
  handleAddAdsErrorMessage = () => {
    toast.error(configJSON.addEditErrorMessage);
  };

  addBanner = async () => {
    let formData = new FormData();
    if (this.state.fileId !== "") {
      formData.append("advertisement[attachment_id]", this.state.fileId);
    }
    formData.append("advertisement[name]", this.state.bannerName);
    formData.append("advertisement[url]", this.state.bannerUrl);
    formData.append(
      "advertisement[description]",
      this.state.bannnerDescription
    );

    const headers = {
      token: await getStorageData(configJSON.tokenKey),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCustomAdvertismentApiCall = getValidationsMsg.messageId;
    let backendUrl = this.state.isEdit
      ? configJSON.getCustomAdvertisement + "/" + this.state.editBannerId
      : configJSON.getCustomAdvertisement;
    let apiMethodType = this.state.isEdit
      ? configJSON.validationApiMethodTypePUT
      : configJSON.validationApiMethodTypePOST;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      backendUrl
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return true;
  };
  //For web
  onBannnerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ bannerName: event.target.value });
  };
  //For web
  onChangeBannerDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ bannnerDescription: event.target.value });
  };
  //For web
  onChangeBannerUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ bannerUrl: event.target.value });
  };
  //For web
  onChangeBannerImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileImage = event.target.files?.[0];
    if (fileImage) {
      this.setState({ bannerImage: fileImage });
      this.uploadAdapterMessage(fileImage);
    }
  };
  //For Mobile
  setName = (setName: string) => {
    this.setState({ bannerName: setName });
  };
  //For Mobile
  setURL = (setUrl: string) => {
    this.setState({ bannerUrl: setUrl });
  };
  //For Mobile
  setDiscription = (setDiscription: string) => {
    this.setState({ bannnerDescription: setDiscription });
  };
  uploadAdapterMessage = (imageFile: File | string) => {
    const messageBanner: Message = new Message(
      getName(MessageEnum.BulkUploadMessage)
    );
    const uploadFileData = {
      file: imageFile,
    };
    messageBanner.addData(
      getName(MessageEnum.BulkUploadMessageData),
      uploadFileData
    );
    this.send(messageBanner);
  };
  //For web
  handlebannerDialogClose = () => {
    const customAdsAdpaterMes: Message = new Message(
      getName(MessageEnum.CustomAdvertisementMessage)
    );
    customAdsAdpaterMes.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(customAdsAdpaterMes);
  };

  editBannerData = (data: {
    id: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      url: string;
      banner: {
        image: string;
      };
    };
  }) => {
    let imageUrl = data.attributes.attachment.data.attributes?.attachment_path;
    this.setState({
      bannerName: data.attributes.name,
      bannnerDescription: data.attributes.description,
      bannerUrl: data.attributes.url,
      bannerImage: imageUrl,
      imagebannerUrl: imageUrl,
      bannerDialog: true,
      isEdit: true,
      editBannerId: data.id,
    });
  };
  // Customizable Area End
}
