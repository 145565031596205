import React from "react";
import {
    Grid,
    Typography,
    withStyles
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End
import { FaqItemBg, downloadIcon } from "./assets";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import TransactionsController, { Props } from "./TransactionsController.web";
import { withTranslation } from 'react-i18next';
import  TransactionInvoice  from "./TransactionInvoice.web";
import moment from "moment";

export class TransactionsWeb extends TransactionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        const { t }: any = this.props;
        const {transactionData} : any = this.state

        return (
            // Customizable Area Start
            <Grid container>
                {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
                <Grid
                    item
                    xs={12}
                    className={classes.DeviceWrapper}
                >
                    <Typography
                        className={classes.font_Size_20}
                        color="primary"
                    >
                        {t("settings.transactions")}
                    </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.historyUnderLine}
                        />
                         <Grid
                    item
                    xs={12}
                    className={classes.DeviceWrapper}
                >

                    <Grid container spacing={2}>
                    {transactionData?.plan_histories?.map((_data: any, index: number) => {
                                return (
                                    <Grid item key={_data?.id} xs={12} sm={6} >
                                        <Grid container className={classes.transactionContainer}>
                                         <Grid item className={classes.DetailsList}>
                                            <Grid item xs={12}>
                                            <Typography className={classes.transationDate}>{moment(_data?.created_at, "YYYY-MM-DD").format("DD/MM/YYYY")}</Typography>

                                            </Grid>
                                            <Grid container>
                                                    <Grid item className={classes.DetailsListItem}>
                                                        <Typography className={classes.ItemTagName}>
                                                            {t("settingWeb.transactions.plan")}:
                                                        </Typography>
                                                        <Typography className={classes.ItemTagData}>
                                                            {this.checkForLang(_data?.plan_name, _data?.plan_name_in_arabic)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className={classes.DetailsListItem}>
                                                        <Typography className={classes.ItemTagName}>{t("settingWeb.transactions.mode")}:</Typography>
                                                        <Typography className={classes.ItemTagData}>{this.checkForLang(_data?.payment_mode, transactionData.payment_mode_in_arabic)}</Typography>
                                                    </Grid>
                                                    <Grid item className={classes.DetailsListItem}>
                                                        <Typography className={classes.ItemTagName}>{t("settingWeb.transactions.method")}:</Typography>
                                                        <Typography className={classes.ItemTagData}>{this.checkForLang(_data?.payment_method, transactionData.payment_method_in_arabic)}</Typography>
                                                    </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.DetailsListItem}>
                                                        <Typography className={classes.ItemTagName}>{t("settingWeb.transactions.valid")}:</Typography>
                                                        <Typography className={classes.ItemTagData}>{this.dateFormat(_data?.start_date)} - {this.dateFormat(_data?.end_date)}</Typography>
                                                    </Grid>
                                                    <Grid item className={classes.DetailsListItem}>
                                                        <Typography className={classes.ItemTagName}>{t("settingWeb.transactions.transactionId")}:</Typography>
                                                        <Typography className={classes.ItemTagData}>{_data?.transaction_id}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography data-test-id={`downloandLink${index}`} className={classes.downloadText} onClick={()=> this.setSelectedHistory(_data)}><img src={downloadIcon} className={classes.downloadIcon} alt="download" /> {t("settingWeb.transactions.downloadInvoice")}</Typography>
                                                    </Grid>
                                                </Grid>    
                                                </Grid>                                        
                                    </Grid>
                                )
                            })}
                            </Grid>
                        </Grid>
                        {this.state.download && <div style={{visibility:"hidden"}}>
                            <div id="invoicePdf" >
                            <TransactionInvoice navigation={this.props.navigation} id="" data={this.state.selectedHistory}/>
                            </div>
                            </div>}
                        
                    </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    DeviceWrapper: {
        padding: "30px 30px 10px",
        [theme.breakpoints.only("xs")]: {
            padding: "15px 20px 5px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "20px 25px 7px",
        }
    },
    CurrentPlan: {
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
    },
    font_Size_20: {
        fontWeight: 700,
            fontSize: 20,
            fontFamily: "Roboto",
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 19,
            }
    },
    ItemTagName: {
        fontSize: "16px",
        lineHeight: '22px',
        color: "#A0A0A0",
        fontWeight: '400',
        fontFamily: 'Roboto',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
            lineHeight: '20px'
        },

    },
    downloadText:  {
        fontSize: "16px",
        lineHeight: '22px',
        marginTop: '10px',
        display: "flex",
        alignItems: "center" as const,
        cursor: "pointer",
        color: "#248CA1",
        fontWeight: '400',
        fontFamily: 'Roboto',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
            lineHeight: '20px'
        },
    },
    downloadIcon: {
        height: 18,
        width: 20,
        margin : theme.direction === "ltr" ? "0 10px 0 0" : "0 0 0 10px",
    },
    ItemTagData: {
        fontSize: "16px",
        lineHeight: '22px',
        marginTop: 8,
        color: "#F6F6F6",
        fontWeight: '400',
        fontFamily: 'Roboto',
        wordWrap: 'anywhere',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
            lineHeight: '20px'
        },
    },
    transationDate:{
        fontSize: "20px",
        lineHeight: '22px',
        margin: '10px 0',
        color: "#F6F6F6",
        fontWeight: '700',
        fontFamily: 'Roboto',
        [theme.breakpoints.down("xs")]: {
            fontSize: '18px',
            lineHeight: '20px'
        },
    },
    historyUnderLine:{
        height: "2px",
        background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
        marginTop: 16
    },
    DeviceWrap: {
        padding: "30px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    transactionContainer:{
        padding: 1,
        background: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
        borderRadius: 8,
        height: '100%'
    },
    DetailsList: {
        borderRadius: 8,
        background: '#222222',
        padding: 15,
        width: '100%'
    },
    DetailsListItem: {
        padding: "10px 0",
        flexGrow: 1
    }
});
export default withStyles(styles)(withTranslation()(TransactionsWeb))
// Customizable Area End
