import React from "react";

import {
  Grid,
  Typography,
  withStyles,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withTranslation } from "react-i18next";
// Customizable Area End

import ConditionController, { Props } from "./ConditionController.web";
import { backArrow } from "./assets";

export class Condition extends ConditionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    const { t }: any = this.props;
    return (
      // Customizable Area Start
      <Grid container className={this.props.isSmallScreen !== true && classes.termFullScreenPage}>
      <Grid
          className={classes.term_us_main_container}
          container
          alignItems="center"
      >
        {!this.props.isSmallScreen &&  <img
                            src={backArrow}
                            alt="back_arrow"
                            data-test-id= "condition_back_button"
                            onClick={this.conditionBackClick}
                            className={classes.conditionBackImage}
                        />}
          <Typography
              className={classes.font_Size_24}                        
          >
              {t("footer.terms")}
          </Typography>
      </Grid>
      <Grid
          item
          xs={12}
          className={classes.termUnderLineCSS}
      />
      <Grid
          item
          xs={12}
          className={classes.term_us_main_container}
      >
           <Typography className={classes.term_font_20} style={{marginTop: 0}}>{t("privacyPolicy.headerTitle3")}</Typography>
          <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph1")} </Typography>
          <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph2")} </Typography>
   
          <Typography className={classes.term_font_20} >{t("privacyPolicy.headerTitle5")}</Typography>
          <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph3")} </Typography>
           <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph4")} </Typography>
          <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph5")} </Typography>
          <Typography className={classes.term_text_14} >{t("privacyPolicy.paraGraph6")} </Typography>
          
      </Grid>
  </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ConditionStyle = (theme: any) => ({
   
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  
term_us_main_container: {
padding: "30px 30px 10px",
[theme.breakpoints.only("xs")]: {
    padding: "15px 20px 5px",
},
[theme.breakpoints.only("sm")]: {
    padding: "20px 25px 7px",
},},
termUnderLineCSS:{
    height: "2px",
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
    marginTop: 10
    
},
term_font_20: {
    fontWeight: 700,
        fontSize: 20,
        fontFamily: "Roboto",
        margin: '22px 0px 10px',
        color: "#F6F6F6",
        [theme.breakpoints.only("sm")]: {
          fontSize: 19,
      },
        [theme.breakpoints.only("xs")]: {
            fontSize: 18,
        }
        
},
term_text_14 : {
    fontWeight: 400,
        fontSize: 14,
        marginBottom: '10px',
        color: "#F6F6F6",
        fontFamily: "Roboto",
        lineHeight: '18px',
        [theme.breakpoints.only("sm")]: {
            fontSize: "13.5px",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: 13,
        },
},
font_Size_24: {
    fontWeight: 700,
        fontSize: 24,
        color: "#F6F6F6",
        fontFamily: "Roboto",
        [theme.breakpoints.only("xs")]: {
            fontSize: 20,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 22,
        }
},
conditionBackImage: {
  width: 11,
  height: 20,
  cursor: 'pointer',
  transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
  margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
},
termFullScreenPage: {
    padding: '0px 60px',
    [theme.breakpoints.only("md")]: {
      padding: '0 45px',
    },
    [theme.breakpoints.only("xs")]: {
      padding: '0 20px',
    },
[theme.breakpoints.only("sm")]: {
  padding: '0 30px',
}
}

});

export default withStyles(ConditionStyle)(withTranslation()(Condition));
// Customizable Area End
