// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import "./App.css"
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { HISTORY } from '../../components/src/History';


ReactDOM.render(
  <Router history={HISTORY}>
    <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
