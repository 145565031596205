import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/Utility";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: any;
  tabView: any;
  faqList: any;
  langaugeCode: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpAndFaqController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiFaqId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      tabView: "Faq",
      faqList: [],
      langaugeCode: localStorage.getItem("i18nextLng") === "ar",
    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.handelTab("faq");
    this.handelFaq();
    // this.getUserProfile()

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("Message Recived", message);

      if (apiRequestCallId === this.apiFaqId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            faqList: responseJson?.data
          })
        }
      }
    }
    // Customizable Area End
  }

  handelFaq = () => {
    this.apiFaqId = sendAPIRequest(
      configJSON.faqListEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? configJSON.arabicfaq : configJSON.englishfaq
        }
      }
    );
  }

  handelTab(key: any) {
    this.setState({ tabView: key })
  }

  // Customizable Area Start
  historyBack() {
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
