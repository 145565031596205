import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { CometChat } from "@cometchat-pro/chat";
import React from "react";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    ChatUserId: any;
    t: any;
}

export interface S {
    // Customizable Area Start

    loading: boolean;
    message: any;
    messageList: any;
    userId: any;
    hasMore: boolean;
    latestId: any;
    typing: boolean;
    friendDeatils: any;
    showEmojiPicker: boolean;
    status: string;
    sending: boolean;
    userImage: string

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}

export default class ChatScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserProfileApiCallId: string = "";
    applyPlansApiCallId: string = '';
    addUserApiCallId: string = '';
    getProfileApiCallId: string = '';
    inputRef: any;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        this.inputRef = React.createRef()


        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start

            loading: false,
            message: '',
            messageList: [],
            userId: '',
            hasMore: true,
            latestId: '',
            typing: false,
            friendDeatils: [],
            showEmojiPicker: false,
            status: 'Offline',
            sending: false,
            userImage: ''
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {

        super.componentDidMount();
        let listenerID = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';
        this.setState({ userId: listenerID })
        this.getPorfile()
        await this.getUserImage()
        // await this.fetchMoreMessage()
        await this.fetchLastMessage()
        await this.addMessageListenr()
    }

    async componentDidUpdate(prevProps: any) {
        if (this.props.ChatUserId !== prevProps.ChatUserId) {
            let listenerID = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';
            CometChat.removeMessageListener(listenerID);
            CometChat.removeUserListener(listenerID)
            this.setState({ messageList: [], status: "Offline" }, async () => {
                this.getPorfile()
                await this.getUserImage()
                // await this.fetchMoreMessage()
                await this.fetchLastMessage()
                await this.addMessageListenr()
            })

        }

    }

    async componentWillUnmount() {
        let listenerID = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';
        CometChat.removeMessageListener(listenerID);
        CometChat.removeUserListener(listenerID)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getProfileApiCallId) {
                if (responseJson != null) {
                    
                    if (!responseJson.errors) {
                        this.setState({ friendDeatils: responseJson.data, loading: false })
                    }
                    else {
                        this.setState({ loading: false })
                        let key = Object.keys(responseJson?.errors[0])[0]
                        toast.error(responseJson?.errors[0][key])
                    }
                }

            }

        }
    }
    // Customizable Area End

    // Customizable Area Start

    postMessage = (event: any) => {
        this.setState({ sending: true })
        this.typingEnded()
        let receiverID = this.props.ChatUserId;
        let messageText = this.state.message;
        let receiverType = CometChat?.RECEIVER_TYPE?.USER;

        let textMessage = new CometChat.TextMessage(
            receiverID,
            messageText,
            receiverType
        );

        CometChat.sendMessage(textMessage)?.then(
            (message) => {
                this.setState({ message: "" })
                this.setState({ messageList: [...this.state.messageList, message], sending: false })
            },
            (error) => {
               
                this.setState({ sending: false })
            }
        );


    }

    getUserImage = async() =>{
        let userData = JSON.parse(await getStorageData("selected_user_data"))
        userData && this.setState({userImage : userData.photo})
    }

    fetchMoreMessage = async () => {
        let UID = this.props.ChatUserId;
        let limit = 30;
        let latestId = await CometChat.getLastDeliveredMessageId();
        let id = this.state.latestId === "" ? latestId : this.state.latestId
        


        let messagesRequest = new CometChat.MessagesRequestBuilder()
            .setUID(UID)
            .setMessageId(id)
            .setLimit(limit)
            .build();

        messagesRequest.fetchPrevious().then(
            messages => {
                if (messages.length > 0) {
                    this.setState({
                        messageList: [...messages, ...this.state.messageList],
                        hasMore: messages.length < 30 ? false : true,
                        latestId: messages[0].getId()
                    })
                }
                else {
                    this.setState({ hasMore: false })
                }
            }, error => {
                console.log("Message fetching failed with error:", error);
            }
        );

    }

    addMessageListenr = async () => {
        let listenerID = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';
        CometChat.addMessageListener(
            listenerID,
            new CometChat.MessageListener({
                onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
                    this.setState({ messageList: [...this.state.messageList, textMessage] })
                    CometChat.markAsDelivered(textMessage);
                    CometChat.markAsRead(textMessage);
                },
                onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
                    console.log("Media message received successfully", mediaMessage);
                },
                onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
                    console.log("Custom message received successfully", customMessage);
                },
                onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
                    this.setState({ typing: true })
                },
                onMessagesDelivered: (messageReceipt: CometChat.MessageReceipt) => {
                    let chatMes = this.state.messageList
                    let findIndex = this.state.messageList.findIndex((_data: any) =>
                        _data.id === messageReceipt.getMessageId()
                    )
                    if (findIndex !== -1) {
                        chatMes[findIndex].deliveredAt = messageReceipt.getDeliveredAt()
                        this.setState({ messageList: chatMes })
                    }
                },
                onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
                    this.setState({ typing: false })
                },
                onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
                    let chatMes = this.state.messageList
                    let findIndex = this.state.messageList.findIndex((_data: any) =>
                        _data.id === messageReceipt.getMessageId()
                    )
                    if (findIndex !== -1) {
                        chatMes[findIndex].readAt = messageReceipt.getReadAt()
                        this.setState({ messageList: chatMes })
                    }
                }

            })
        );
        let receiverId = listenerID;
        let receiverType = CometChat.RECEIVER_TYPE.USER;
        this.inputRef.current.focus();
        let typingNotification = new CometChat.TypingIndicator(receiverId, receiverType);
        CometChat.startTyping(typingNotification);

        CometChat.addUserListener(
            listenerID,
            new CometChat.UserListener({
                onUserOnline: (onlineUser: CometChat.User) => {
                    this.setState({ status: 'Online' })
                },
                onUserOffline: (offlineUser: CometChat.User) => {
                    this.setState({ status: 'Offline' })
                }
            })
        );
    }

    typingStarted = async () => {

        let receiverId = this.props.ChatUserId;
        let receiverType = CometChat?.RECEIVER_TYPE?.USER;

        let typingNotification = new CometChat.TypingIndicator(receiverId, receiverType);
        CometChat.startTyping(typingNotification);
        console.log(typingNotification)
    }

    typingEnded = async () => {

        let receiverId = this.props.ChatUserId;
        let receiverType = CometChat?.RECEIVER_TYPE?.USER;

        let typingNotification = new CometChat.TypingIndicator(receiverId, receiverType);
        CometChat.endTyping(typingNotification);
        console.log(typingNotification)
    }

    timeStringToDate = (strTime: string) => {
        let today = moment().format("DD MMM YYYY")
        let timestamp = Number(strTime) * 1000;
        let mesdate = moment(timestamp).format('DD MMM YYYY')
        return moment(mesdate, 'DD MMM YYYY').isSame(today) ? moment(timestamp).format('HH:mm') : moment(timestamp).format('DD MMM YYYY')
    }

    fetchLastMessage = async () => {
        let UID = this.props.ChatUserId;
        await CometChat.getConversation(UID, 'user').then(
            conversation => {
                let lastMessage = conversation.getLastMessage()
                this.setState({ messageList: [...this.state.messageList, lastMessage] })
                CometChat.markAsDelivered(lastMessage);
                CometChat.markAsRead(lastMessage);
                if (lastMessage?.id) {
                    this.setState({ latestId: lastMessage.id }, () => {
                        this.fetchMoreMessage()
                    })
                } else {
                    this.fetchMoreMessage()
                }



            }, error => {
                console.log('error while fetching a conversation', error);
            }
        );
    }


    getPorfile() {

        this.setState({ loading: true , message: "" })


        const headers = {

            token: localStorage.getItem("Token")
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addUserEndPoint + `/${this.props.ChatUserId}`
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    userAvatar = () => {
        const friendDetails = this.state.friendDeatils;
        if (friendDetails && Object.keys(friendDetails).length > 0 && friendDetails.attributes && friendDetails.attributes.photo) {
            return friendDetails.attributes.photo;
        } else {
            return null; 
        }
    }

    checkStatus = () => {
        return this.state.status === "Online" ? this.props.t("chat.online") : this.props.t("chat.offline")
    }

    checkVariant = () => {
        return this.state.status === "Offline" ? "standard" : "dot"
    }

    onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        if (value.length !== 0 || value !== "") {
            this.typingStarted()
        } else {
            this.typingEnded()
        }
        this.setState({ message: event.target.value });
    }

    onSendIcon = (event: any) => {
        event.preventDefault()
        if (this.state.message !== '' && !this.state.sending) this.postMessage(event)
    }

    onPickerChange = (emojiObject: any) => {
        this.setState({ message: this.state.message + emojiObject.emoji }, () => {
            if (this.inputRef?.current) this.inputRef.current.focus();
            this.setState({ showEmojiPicker: false })
        })
    }


    // Customizable Area End
}

