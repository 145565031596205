/* App/Lib/GeneralHelpers.js */
//@ts-nocheck
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StackActions } from '@react-navigation/native';
import { StorageKeys } from '../../framework/src/MobileStorageKeys'
import React from 'react';
import { Platform } from 'react-native';
import { moderateScale } from 'react-native-size-matters';



export const navigationRef = React.createRef();
export const isIOS = Platform.OS==='ios'
export function getOS(): string {
  return Platform.OS;
}
export const Logout = async () => {
   if (navigationRef.current.getCurrentRoute().name !== "EmailAccountLoginBlock") {
    const expectRemove = [StorageKeys.SKIP_ONBOARDING, StorageKeys.USER_LANG, StorageKeys.FCM_TOKEN, StorageKeys.Theme]
    AsyncStorage.getAllKeys()
      .then(keys => {

        const removeKeys = [...keys]
        expectRemove.forEach(item => {

          const index = removeKeys.indexOf(item);
          if (index !== -1) {
            removeKeys.splice(index, 1);
          }
        })

        AsyncStorage.multiRemove(removeKeys)
      })

    resetTo('EmailAccountLoginBlock')
  }
};

export function navigate(name:any, params:any) {
  //@ts-ignore
  navigationRef.current?.navigate(name, params);
}

export function push(...args:any) {
    //@ts-ignore

  navigationRef.current?.dispatch(StackActions.push(...args));
}
export const resetTo = (name: string,params?:object = {}) => {
    //@ts-ignore
  navigationRef.current?.reset({
    index: 0,
    routes: [{ name: name, params }],
  });
}
//@ts-ignore
export const  normalize=(number: number, factor = 0.25)=>  moderateScale(number, factor)
