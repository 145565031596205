import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  t?: any;
  isLanding? :any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FooterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
     };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleRoutes = async (path: string , component: string) =>{
    let isLogin = await getStorageData("Token")
    const navigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     navigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
        isLogin ? "GeneralSetting" : component)
      navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const propsMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
  propsMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: path });
  navigationMes.addData(getName(MessageEnum.NavigationRaiseMessage), propsMessage);
      this.send(navigationMes);
  }
  // Customizable Area End
}
