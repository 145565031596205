import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    withWidth
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

import { withTranslation } from 'react-i18next';
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
    settingInnerContainer: {
        background: "#222222",
        borderRadius: 8,
        padding: '10px 0',
        position: 'relative' as const,
        height: "100%"
    },
    closeIcon: {
        width: 20,
        height: 20,
        position: 'absolute' as const,
        right: theme.direction === "ltr" ? 25 : "auto",
        left: theme.direction === "rtl" ? 25 : "auto",
        top: 24,
        filter: 'brightness(100)',
        cursor: "pointer"
    },
    settingContainer: {
        padding: '0 20px '
    },
    selectedListName: {
        fontWeight: 700,
        filter: 'brightness(250%)'
    },
    settingTab: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center" as const
    },
    moreSettingIcon: {
        width: 18,
        height: 18,
        transform: theme.direction === "rtl" ? "rotate(180deg)" : "rotate(0)"
    },
    expandSettingIcon: {
        width: 18,
        filter: 'brightness(250%)',
        height: 18,
        transform: "rotate(90deg)"
    },
    multiListInnerContainer: {
        background: "#1B1B1B",
        borderRadius: 8
    },
    multiListContainer: {
        padding: '0 15px ',
        marginBottom: '10px'
    },
    settingOuterContainer: {
        padding: '10px 50px',
        [theme.breakpoints.only("xs")]: {
            padding: "5px 20px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "10px 25px",
        }
    },
    profileUnderLine: {
        height: "2px",
        background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
    },
    settingList: {
        padding: '0px 15px',
        cursor: "pointer"
    },
    settingListName: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 16,
        color: "#9A9A9A",
        lineHeight: '22px',
        padding: '19px 5px',
        display: 'flex',
        alignItems: "center" as const
    },
    multiListName: {
        fontFamily: "Roboto",
        color: "#9A9A9A",
        fontWeight: 400,
        padding: '17px 5px',
        fontSize: 14,
        lineHeight: '22px',
        display: 'flex',
        alignItems: "center" as const
    },
    settingIcons: {
        width: 20,
        height: 20,
        margin: theme.direction === "ltr" ? "0 12px 0 0" : "0 0 0 12px"
    }
});

// Customizable Area End

import GeneralSettingController, {
  MainItem,
  NestedItem,
  Props,
} from "./GeneralSettingController.web";
import ChangePlan from "../../email-account-registration/src/ChangePlan.web";
import NewsWeb from "../../reviews/src/NewsWeb.web";
import SwitchProfile from "../../user-profile-basic/src/SwitchProfile.web";
import ManageYourDeviceWeb from "./ManageYourDeviceWeb.web";
import TransactionsWeb from "./TransactionsWeb.web";
import WatchHistory from "./WatchHistory.web";
import PrivacyPolicy from "../../TermsAndConditions/src/PrivacyPolicy.web";
import Condition from "../../TermsAndConditions/src/Condition.web";
import AboutUS from "../../TermsAndConditions/src/AboutUS.web";
import HelpAndFaqWeb from "./HelpAndFaqWeb.web";
import { closeIcon, moreSetting } from "./assets";
import  EmailVerificationWeb  from "../../email-account-login/src/EmailVerificationWeb.web";
import AcDeleteDialogWeb from "../../user-profile-basic/src/AcDeleteDialog.web";
import MemberProfile from "../../user-profile-basic/src/MemberProfile.web";

export class GeneralSetting extends GeneralSettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;

        // Customizable Area Start
        return (
            <Grid container className={classes.settingOuterContainer}>
                <Grid container spacing={3}>
                {this.checkIsMobileScreen() && <Grid item xs={12} md={4} lg={3} className={classes.settingContainer}>
                    <Grid container className={classes.settingInnerContainer}>
                        <Grid item xs={12}>
                            {this.handleGeneralList()?.map((_data: MainItem) => {
                                return (
                                    <Grid item xs={12} className={classes.settingList}>
                                        <div data-test-id={`settingList${_data.value}`} onClick={() => this.handleListClick(_data.value)} className={classes.settingTab}>
                                            <Typography className={`${classes.settingListName} ${this.getSelectedTab(_data.value) && classes.selectedListName}`}><img src={_data.icon} className={classes.settingIcons} />{_data.name}</Typography>
                                            {_data?.isNested && <img src={moreSetting} className={this.state.isExpand ? classes.expandSettingIcon : classes.moreSettingIcon}/>}
                                        </div>
                                        {_data?.isNested && this.state.isExpand && <Grid item xs={12} className={classes.multiListContainer}>
                                            <Grid container className={classes.multiListInnerContainer}>
                                                {_data.nestedList?.map((_multiData: NestedItem) => {
                                                    return (
                                                        <Grid item xs={12} data-test-id={`settingList${_multiData.value}`} className={classes.settingList}  onClick={() => this.handleListClick(_multiData.value)}>
                                                            <Typography className={`${classes.multiListName} ${this.state.selectedTab === _multiData.value && classes.selectedListName}`}><img src={_multiData.icon} className={classes.settingIcons} />{_multiData.name}</Typography>
                                                            {_multiData.value !== "watch_history" && <Grid item xs={12} className={classes.profileUnderLine} />}
                                                        </Grid>)
                                                })}
                        </Grid>
                        </Grid>
                                    }
                                   {this.isLast(_data.value) && <Grid item xs={12} className={classes.profileUnderLine} />}
                                </Grid>)
                            })}
                        </Grid>
                    </Grid>

                </Grid>}
               {this.checkIsMobileScreenForSetting() && <Grid item xs={12} md={8} lg={9} className={classes.settingContainer}>
                <Grid container className={classes.settingInnerContainer}>
                <Grid item xs={12}>
                   {this.checkIsMobile() && <img src={closeIcon} className={classes.closeIcon} onClick={this.handleCloseClick}/>}
                            {this.state.selectedTab === "news" && <NewsWeb {...this.props} />}
                            {this.state.selectedTab === "switch_profile" && <SwitchProfile {...this.props} />}
                            {this.state.selectedTab === "plan_details" && <ChangePlan {...this.props} />}
                            {this.state.selectedTab === "transactions" && <TransactionsWeb {...this.props} />}
                            {this.state.selectedTab === "manage_devices" && <ManageYourDeviceWeb {...this.props} />}
                            {this.state.selectedTab === "watch_history" && <WatchHistory {...this.props} />}
                            {this.state.selectedTab === "privacy_policy" && <PrivacyPolicy {...this.props} isSmallScreen={true}/>}
                            {this.state.selectedTab === "terms_of_use" && <Condition {...this.props} isSmallScreen={true}/>}
                            {this.state.selectedTab === "about_us" && <AboutUS {...this.props} isSmallScreen={true}/>}
                            {this.state.selectedTab === "help_faq" && <HelpAndFaqWeb {...this.props}/>}
                            {this.state.selectedTab === "my_profile" && <EmailVerificationWeb profileId={localStorage.getItem("User_Id")} profileType={"Owner"} onChangeTab={""} {...this.props}/>}
                            {this.state.selectedTab === "delete_account" && <AcDeleteDialogWeb {...this.props}/>}
                            {this.state.selectedTab === "members" && <MemberProfile {...this.props}/>} 
                        </Grid>
                        </Grid>
            </Grid> }
                </Grid>
            </Grid>
            //Merge Engine End DefaultContainer
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(
  withWidth()(withTranslation()(GeneralSetting))
);
// Customizable Area End
