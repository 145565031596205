import AsyncStorage from '@react-native-async-storage/async-storage';

interface HeaderType {
  'Content-Type'?: string;
  token?: string;
  language: string;
}

export const getHeaders = async (
  contentType = 'application/json',
  props?: HeaderType
): Promise<string> => {
  const lang = await AsyncStorage.getItem('USER_LANG');
  const headers: HeaderType = {
    'Content-Type': contentType,
    ...props,
    language: lang || 'en'
  };
  const token = await AsyncStorage.getItem('Token');
  if (token) {
    headers.token = token;
  }
  return JSON.stringify(headers);
};
