import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { CometChat } from '@cometchat-pro/chat';
import moment from "moment";
import { sendAPIRequest } from "../../../components/src/Utility";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  t: any;
}

export interface S {
  // Customizable Area Start
  plansDetails: any;
  userProfileDetails: any;
  coupon: any;
  applyCouponModal: any;
  couponApplied: boolean;
  loading: boolean;
  selectedUserID: any;
  selectedUserName: any;
  coupounError: boolean;
  coupounErrorMessage: string;
  seletedCoupounID: any;
  selectedPlanDetail: any;
  appliedCouponSuccessMes: string;
  userName: any;
  userNameError: boolean;
  userNameErrorMes: any;
  userEdit: boolean;
  userModal: boolean;
  userPhoto: any;
  kidsPorfile: boolean;
  logout: boolean;
  prefernceId: any;
  isMaxPlan: boolean;
  settingAnchor: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  chooseProfileApiCallId: string = '';
  addUserApiCallId: string = '';
  addUserDeviceApiCallId: string = "";
  getRefreshTokenApiCallId: string = "";
  postChoosePreferenceApiCallId: string = "";
  getChoosePreferenceApiCallId: string = "";
  logOutApiCallId: string = "";
  deleteFirebaseTokenApiCallId: string = "";
  getPlansApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      plansDetails: [],
      userProfileDetails: [],
      selectedUserID: '',
      selectedUserName: {},
      coupon: '',
      coupounError: false,
      coupounErrorMessage: '',
      seletedCoupounID: '',
      applyCouponModal: null,
      couponApplied: false,
      loading: false,
      selectedPlanDetail: {},
      appliedCouponSuccessMes: '',
      userName: '',
      userNameError: false,
      userNameErrorMes: '',
      userEdit: false,
      userModal: false,
      userPhoto: {},
      kidsPorfile: false,
      logout: false,
      prefernceId: [],
      isMaxPlan: true,
      settingAnchor: null
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    if (window.location.href.toLowerCase().includes('userprofile')) {
      localStorage.removeItem("selectedUser")
      localStorage.removeItem("selected_user_data")
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', this.disableForwardButton);
    }
    this.getUserProfile()
    // this.getChoosePreference()
    this.getPlan()
    let exp_time = localStorage.getItem("exp_time")
    if (exp_time) {
      moment().isAfter(moment(exp_time, "DD/MM/YYYY hh:mm a")) && this.getRefreshToken()
    }
  }

  disableForwardButton = () => {
    window.history.pushState(null, document.title, window.location.href);
  }

  async componentWillUnmount() {
    window.removeEventListener("popstate", this.disableForwardButton)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getUserProfileApiCallId:
          this.handlegetUserProfileResponse(responseJson)
          break;
        case this.addUserApiCallId:
          this.handlegetaddUserResponse(responseJson)
          break;
        case this.chooseProfileApiCallId:
          this.handlegetchooseProfileResponse(responseJson)
          break;
        case this.getRefreshTokenApiCallId:
          this.handlegetRefreshTokenResponse(responseJson)
          break;
        case this.postChoosePreferenceApiCallId:
          this.handlepostChoosePreferenceResponse(responseJson)
          break;
        case this.getChoosePreferenceApiCallId:
          this.handlegetChoosePreferenceResponse(responseJson)
          break;
        case this.logOutApiCallId:
          this.handleLogOutResponse(responseJson)
          break;
        case this.getPlansApiCallID: 
          this.handleMAxPlan(responseJson)
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleMAxPlan = (responseJson: any) =>{
    if (!responseJson.errors) {
      let planData = responseJson?.packages?.data
      let isMaxPlan = planData.some((_data : any) => _data.attributes?.is_disable === false)
      this.setState({isMaxPlan: !isMaxPlan})
    }
  }
  getPlan = () => {
    this.setState({ loading: true });

    let header=  {
      token: localStorage.getItem("Token"),
      "Content-Type": configJSON.validationApiContentType,
    }
    const getPlanRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlansApiCallID = getPlanRequestMessage.messageId;

    getPlanRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlansApiEndPoint
    );
    getPlanRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );
    getPlanRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getPlanRequestMessage.id, getPlanRequestMessage);
  };

  handlegetUserProfileResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        if (responseJson?.error == "Not Authorized, Token Expired") {
          this.setState({ loading: false })
          this.getRefreshToken()
        }
        this.setState({
          selectedPlanDetail: responseJson?.data[0]?.attributes?.plan,
          userProfileDetails: responseJson?.data,
          loading: false
        }, () => {
          this.setDefaultProfile();
        });
        if (!responseJson?.data[0]?.attributes?.plan) {
          this.props.navigation.navigate("ViewSubscription")
        }
      } else {
        this.handleErrorResponse(responseJson)
      }
    }
  }
  

  handleErrorResponse=(responseJson:any)=>{
   if(responseJson?.errors[0]?.message === "Please login again." || responseJson?.errors === "Please login again."){
      this.setState({ logout: true })
    }
     else {
      this.setState({ loading: false })
      responseJson?.errors == "Not Authorized, Token Expired" && toast.error(responseJson?.errors);
      (responseJson?.errors[0]?.token || responseJson?.errors == "Not Authorized, Token Expired") && this.getRefreshToken()
    }
  }

  handleAddMoreUser = () => {
    let profileAllow = this.state.selectedPlanDetail?.data?.attributes?.profile_enabled
    if (profileAllow && parseInt(this.state.selectedPlanDetail?.data?.attributes?.userlimit) > this.state.userProfileDetails?.length) {
      this.setState({ userModal: true })
    }else
      {
        this.upgradeClick()
    }    
  }

  checkForAddUser = () => {
    let isProfileRemaning = parseInt(this.state.selectedPlanDetail?.data?.attributes?.userlimit) > this.state.userProfileDetails?.length
    return isProfileRemaning || !this.state.isMaxPlan
  }

  setSettingAnchor=()=>{
    this.setState({settingAnchor: null})
  }


  upgradeClick = () => {
    let isUserProfilePage = window.location.href.toLowerCase().includes('userprofile')
    sessionStorage.setItem("upgradeFromUser", isUserProfilePage ? "true" : "false")
    const chatNavigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    chatNavigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "Perks");
    chatNavigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(chatNavigationMessage);
  }
  

  handlegetaddUserResponse = (responseJson: any) => {
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        this.getUserProfile()
        this.setState({ userModal: false, userName: '', userPhoto: {}, kidsPorfile: false })
        toast.success(this.props.t("choose_profile.PCS"))

      } else {
        this.setState({ loading: false })
      }
    }
  }

  handlegetchooseProfileResponse = (responseJson: any) => {
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        this.setState({ loading: false });
        if (responseJson?.data?.attributes?.genres?.data?.length !== 0) {
          this.props.navigation.navigate("DashboardWeb")
        } else {
          this.props.navigation.navigate("ChoosePreference")
        }
        localStorage.setItem('selected_user_data', JSON.stringify(responseJson?.data?.attributes));
        const eventNew = new StorageEvent('storage')
        window.dispatchEvent(eventNew)

      }
      else {
        this.setState({ loading: false })        
      }
    }

  }

  handlegetRefreshTokenResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson?.errors) {
        if (responseJson?.error == "Not Authorized, Refresh Token Expired") {
          this.setState({ logout: true })
        }
        console.log("new token", responseJson?.data?.token)
        if (responseJson?.data?.token) {
          let exp_time: any = moment().add(23, "hours").format("DD/MM/YYYY hh:mm a")
          localStorage.setItem("exp_time", exp_time)
          localStorage.setItem("Token", responseJson?.data?.token)
          window.location.reload()
        }
      }
      else {
        this.setState({ logout: true })
      }
    }


  }

  handlepostChoosePreferenceResponse = (responseJson: any) => {
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        this.setState({ loading: false })
        this.props.navigation.navigate("DashboardWeb")

      }
      else {
        this.setState({ loading: false })
      }
    }

  }

  handlegetChoosePreferenceResponse = (responseJson: any) => {
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {

        this.setState({ prefernceId: responseJson?.data.map((_data: any) => parseInt(_data.id)), loading: false })
      }
      else {
        this.setState({ loading: false })
        responseJson?.errors == "Not Authorized, Token Expired" && toast.error(responseJson?.errors)
      }
    }

  }

  handleLogOutResponse = async (responseJson: any) => {
    if (responseJson) {
      let deviceId = await getStorageData("deviceId")
      let darkMode = localStorage.getItem("darkMode") || "true"
      let lang = localStorage.getItem("i18nextLng") || "en"
      localStorage.clear()
      toast.success(responseJson.message)
      localStorage.setItem("darkMode", darkMode)
      await setStorageData("deviceId", deviceId)
      localStorage.setItem("i18nextLng", lang)
      this.props.navigation.navigate("EmailAccountLoginBlockWeb")

    }
  }

  handleChangeUserName = (event: any) => {
    const value = event.target.value.trim();
    if (value.length === 0) {
      this.setState({
        userNameError: true,
        userNameErrorMes: this.props.t("choose_profile.NIR"),
      });
    } else {
      this.setState({
        userNameError: false,
        userNameErrorMes: "",
      });
    } this.setState({ userName: event.target.value });
  }

  handleBlurUserName = () => {
    if (
      this.state.userName === null ||
      this.state.userName.length === 0
    ) {
      this.setState({
        userNameError: true,
        userNameErrorMes: this.props.t("choose_profile.NIR"),
      });
    } else {
      this.setState({
        userNameError: false,
        userNameErrorMes: "",
      });
    }
  }

  getUserProfile = () => {
    this.setState({ loading: true })
    this.getUserProfileApiCallId = sendAPIRequest(
      configJSON.getUserProfileEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
          "Content-Type": configJSON.validationApiContentType,
        }
      }
    );
  }

  addUser = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true })
    let formData = new FormData();
    if (this.state.userPhoto?.name && this.state.userPhoto?.size) {
      formData.append("profile[photo]", this.state.userPhoto)
    }
    formData.append("profile[name]", this.state.userName)
    if (this.state.kidsPorfile) {
      formData.append("profile[kids_profile]", 'true')
    }
    this.addUserApiCallId = sendAPIRequest(
      configJSON.addUserEndPoint,
      {
        method: configJSON.callTypeApiValidateMobileNo, body: formData,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
    return true
  }

  getRefreshToken = () => {
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');

    this.getRefreshTokenApiCallId = sendAPIRequest(
      configJSON.getRefershTokenEndPoint,
      {
        method: configJSON.callTypeApiValidateMobileNo, body: formData,
        headers: {
          token: localStorage.getItem("refresh_token"),
        }
      }
    );
  }

  choosePorfile = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true })
    CometChat.logout()
    localStorage.setItem("selectedUser", this.state.selectedUserID)
    this.chooseProfileApiCallId = sendAPIRequest(
      configJSON.addUserEndPoint + `/${this.state.selectedUserID}`,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  getChoosePreference = () => {
    this.setState({ loading: true })
    this.getChoosePreferenceApiCallId = sendAPIRequest(
      configJSON.getPerferenceEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
          "Content-Type": configJSON.validationApiContentType,
        }
      }
    );
  }

  applySelectedPreference = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true })
    CometChat.logout()

    let userData: any = this.state.userProfileDetails.filter((_data: any) => _data.id == this.state.selectedUserID)
    localStorage.setItem("selectedUser", this.state.selectedUserID)
    localStorage.setItem('selected_user_data', JSON.stringify(userData[0]?.attributes));
    const eventNew = new StorageEvent('storage')
    window.dispatchEvent(eventNew)

    const data = {
      profile_id: this.state.selectedUserID,
      preferences: this.state.prefernceId
    }
    
    this.props.navigation.navigate("DashboardWeb")
  }

  onEditClick = (event: any) => {
    this.setState({
      settingAnchor: event.currentTarget
    })
  }

  navToLogin = () => {
    this.props.navigation.goBack()
  };

  onSelectProfile = (profileId: any) => {

    this.setState({ selectedUserID: parseInt(profileId.id), selectedUserName: profileId.attributes.name });
    let userData: any = this.state.userProfileDetails.filter((_data: any) => _data.id == profileId)
    localStorage.setItem("selectedUser", profileId)
    localStorage.setItem('selected_user_data', JSON.stringify(userData[0]?.attributes));
    const eventNew = new StorageEvent('storage')
    window.dispatchEvent(eventNew)
    localStorage.setItem("Profile_Id", profileId.id);
  }
  handleLogout = async () => {
    let deviceUniqueId = await getStorageData("deviceId")
    localStorage.clear()
    this.setState({ logout: false })
    setStorageData("deviceId", deviceUniqueId)
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  }

  deleteFirebaseToken = async(token: any, firebaseToken: any) => {
    let formData = new FormData();
    formData.append('token', firebaseToken);

    this.deleteFirebaseTokenApiCallId = sendAPIRequest(
      configJSON.deleteFirebaseTokenEndPoint,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token,
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'        }
      }
    );
  }

  logOut = async () => {
    this.setState({ loading: true, logout: false })
    await this.deleteFirebaseToken(localStorage.getItem("Token"), localStorage.getItem("firebasetoken"))
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');

    this.logOutApiCallId = sendAPIRequest(
      configJSON.logoutEndPoint,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'     }
      }
    );
  }

  setDefaultProfile() {
    if (this.state.userProfileDetails.length > 0 && !localStorage.getItem("selectedUser")) {
      const firstProfile = this.state.userProfileDetails[0];
      this.setState({
        selectedUserID: parseInt(firstProfile.id),
        selectedUserName: firstProfile.attributes.name,
      });
      localStorage.setItem("selectedUser", firstProfile.id);
      localStorage.setItem("selected_user_data", JSON.stringify(firstProfile.attributes));
      const eventNew = new StorageEvent('storage');
      window.dispatchEvent(eventNew);
    }
  }

  // Customizable Area End
}
