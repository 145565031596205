export const BackgroundImage = require("../assets/LandingBg2_Img.png")
export const comment = require("../assets/updated-comment1.png")
export const send = require("../assets/send.png")
export const download = require("../assets/download (2).png")
export const search = require("../assets/search.png")
export const like = require("../assets/like.png")
export const likeIcon = require("../assets/like (2).png")
export const play = require("../assets/play.png")
export const share = require("../assets/updated-share1.png")
export const shareIcon = require("../assets/updated-share1.png")
export const logo_vod = require("../assets/logo_vod (1).png")
export const twitter = require("../assets/twitter (1).png")
export const instagram = require("../assets/insta (1).png")
export const facebook = require("../assets/facebook (1).png")
export const whats_up = require("../assets/whats_up (1).png")
export const copy = require("../assets/copy.png")
export const emoji = require("../assets/emoji.png")
export const shareicon = require("../assets/updated-share1.png")
export const ArrowRight = require("../assets/rightArrow.png")
export const ArrowLeft = require("../assets/leftArrow.png")
export const backbtn=require("../assets/backButton.png")
export const DropBox=require("../assets/image_upload.png")
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const HeaderIconImg = require("../assets/HeaderIcon_Img.png")
export const downloadIcon = require("../assets/downloadnew.png");
export const pauseIcon = require("../assets/pause.png");
export const deleteIcon = require("../assets/delete.png");
export const playButton = require("../assets/play.png")
export const profileIcon = require("../assets/profile.png")
export const newLogo = require("../assets/headerImage1.png")
export const dummy_icon = require("../assets/dummy.png");
export const volumeIcon = require("../assets/Group91.jpg");
export const topmusic = require("../assets/topmusic.png");
export const loaderImg = require("../assets/loader-image.gif");
export const DownloadWhite = require("../assets/DownloadWhite.png")
export const crossIcon = require("../assets/crossIcon.png")
export const heart = require("../assets/Heart.png")
export const backgroundNew = require("../assets/backgroundImage.jpg")
export const userDummyProfile = require("../assets/userDummyProfile.png")
export const logo_png = require("../assets/HeaderIcon_Img.png");
export const background1 = require("../assets/FinalBg_Img.png");
export const background2 = require("../assets/LandingBg3_Img.png");
export const background3 = require("../assets/LandingBg_Img.png");
export const background4 = require("../assets/LandingFooter_Img.png");
export const Image1 = require("../assets/LandingMain2_Img.png");
export const Image2 = require("../assets/LandingMain3_Img.png");
export const Image3 = require("../assets/LandingMain4_Img.png");
export const Star = require("../assets/star.png");
export const LikeIconWeb = require("../assets/LikeIcon.png");
export const LikedIconWeb = require("../assets/likedIcon.png");
export const ShareIconWeb = require("../assets/ShareIcon.png");
export const arrow = require("../assets/ArrowRight_Img.png");
export const appIcon = require("../assets/app_icon.png")
export const languageIcon = require("../assets/language_icon.png")
export const notificationIcon = require("../assets/button_notification.png")
export const thumbIcon = require("../assets/thumbIcon.png")
export const downloadIconNew = require("../assets/image_download.png");
export const commentIconNew = require("../assets/updated-comment1.png");
export const shareIconNew = require("../assets/updated-share1.png");
export const downloadicon = require("../assets/button_download.png");
export const backArrow = require("../assets/back.png");
export const dotIcon = require("../assets/doticon.png");
export const sendIcon = require("../assets/sendIcon.png");
export const FavourateImage = require("../assets/FavourateImage.png");
export const notificationImage = require("../assets/notificationImage.png");
export const searchImage = require("../assets/searchImage.png");
export const messageImage = require("../assets/messageIcon.png");
export const podcastImage = require("../assets/Bottom2.png");
export const play_white = require("../assets/play_white.png");
export const commentIcon = require("../assets/commentIcon.png");
export const crossButton = require("../assets/cross.png");
export const gradient_horizontal_line = require("../assets/gradient_horizontal_line.png");
export const leftArrow = require("../assets/left-Arrow.png");
export const starbutton = require("../assets/upgrade-plan.png");
export const tvshows = require("../assets/tvshows.png");
export const movies = require("../assets/movies.png");