import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  appLogo,
  homeIcon,
  imgPasswordInVisibleWeb,
  imgPasswordVisibleWeb,
  languageChange,
  LoginTitle,
  LoginTitleLight
} from "./assets";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);


const StyledTextField = withStyles({
  root: {
    fontFamily: "Araboto",
    fontSize: "14",
    "& .MuiInputBase-input": {},
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent"
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent"
    },
    "& input::placeholder": {
      fontFamily: "Araboto",
      fontSize: "14",

      fontWeight: 400
    }
  }
})((props: any) => <TextField {...props} />);

import EmailAccountLoginController from "./EmailAccountLoginController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { withTranslation } from "react-i18next";
import CustomCountryCodePicker from "./customCountryCodePicker";
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";

export class EmailAccountLoginBlockWeb extends EmailAccountLoginController {
  render() {
    const { email, password } = this.state.formData;
    const { classes, t }: any = this.props;
    return (
      // Required for all blocks
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid item xs={12}  className={classes.LoginContainer} >
          <img data-test-id="homeIcon" className={classes.homeButton} src={homeIcon} onClick={()=>this.navToLanding()}/>
          <Grid container className={classes.mainGrid}>
              <Grid item xs={12} className={classes.grid2}>
                <Grid container justifyContent="center">
            <img onClick={()=>this.navToLanding()} className={classes.LoginImg} src={appLogo} />
            </Grid>
            <Grid container className={classes.formContainer}>
              <form
                onSubmit={this.handleFormSubmit}
                className={classes.loginForm}
              >
               <Typography
                  className={classes.header2}
                  color="secondary"
                >
                  {t("loginScreen.subheading")}
                </Typography>                
                  <Grid item xs={12}>
                    <StyledTextField
                      InputProps={{
                      //  disableUnderline: true,
                        classes: { input: classes.placeHolderTypeEmail },
                        endAdornment: (
                          <InputAdornment position="end">
                            {email.length > 0 && !isNaN(email) && (
                              <CustomCountryCodePicker
                                {...this.props}
                                countryList={this.state.countryArr}
                                selectedCountryCode={this.state.selectionCode}
                                selectCountryCode={this.selectCountryCode}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      data-testid={"email-test"}
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={this.handleChangeForm}
                      placeholder={t("loginScreen.user")}
                      color="primary"
                      className={classes.emailTextField}
                      onBlur={this.checkForEmailValidation}
                      // error={this.state.emailErr}
                      helperText={<StyledFormHelperText>{this.state.emailErrMsg}</StyledFormHelperText>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      data-testid={"password-txtInput"}
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      placeholder={t("loginScreen.password")}
                      name="password"
                      value={password}
                      onChange={this.handleChangeForm}
                      className={classes.passwordTextField}
                      onBlur={this.checkForPasswordValidation}
                      color="primary"
                      InputProps={{
                        classes: { input: classes.placeHolderTypePassword },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            // eslint-disable-next-line react-native/no-inline-styles
                            style={{ marginRight: "0" }}
                          >
                            <img
                              onClick={this.handleClickShowPassword}
                              src={
                                !this.state.showPassword
                                  ? imgPasswordInVisibleWeb
                                  : imgPasswordVisibleWeb
                              }
                              className={classes.inputBgImg}
                            />
                          </InputAdornment>
                        )
                      }}
                      // error={this.state.passErr}
                      helperText={<StyledFormHelperText>{this.state.passErrMsg}</StyledFormHelperText>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.rembbermeButton}>
                      <Typography
                        className={classes.rembbermeButtonTxt}
                        data-testid={"navtoforgot"}
                        onClick={this.navigateToForgotPassword}
                      >
                        {t("loginScreen.FP")}?
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.loginButton}>
                      <Button
                        variant="contained"
                        className={classes.loginButton}
                        data-testid="btnLogin"
                        type="submit"
                        fullWidth
                      >
                        {t("loginScreen.login")}
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box className={classes.register}>
                      <Typography>
                        {t("loginScreen.dha")}
                        <span                         
                          data-testid={"navtoregister"}
                          onClick={this.navigateToRegistration}
                          className={classes.rembbermeButtonTxt}
                        >
                          {t("loginScreen.register")}
                        </span>
                      </Typography>
                    </Box>
                    <Grid item xs={12}>
                      <Typography data-test-id="loginAsGuest" onClick={()=> this.loginAsGuest()} className={classes.loginGuest}>
                      {t("loginScreen.lAAG")}
                      </Typography>
                    </Grid>
                    <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}                    
                    />
                  </Grid>
               
              </form>
              </Grid>              
            </Grid>            
          </Grid>
          <Grid container className={classes.detailsFooter}>
              <Typography className={classes.tAndC} color="secondary">{t("registerScreen.loginT&C")}<span className={classes.footerHeading} onClick={()=>this.goToUserProfile("PrivacyPolicy")}>{t("registerScreen.privacyPolicy")}</span> {t("registerScreen.and")}<span className={classes.footerHeading} onClick={()=>this.goToUserProfile("TermsAndConditions")}>{t("registerScreen.tOU")}</span></Typography>
              </Grid>
          
        </Grid>
      </Grid>
    );
  }
}

export const styles: any = (theme: any) => {
let themeDirection = (arrValue: any, enValue: any) => theme.direction == "rtl" ? arrValue : enValue
return {  
  formContainer:{
    [theme.breakpoints.only("xs")]: {
      padding: "25px 20px",
    },
    background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
    padding: "40px 45px",
    borderRadius: "8px"

  },
 LoginContainer : {
      display: "flex" , 
      justifyContent: "space-around" , 
      flexDirection : "column" , 
      minHeight: '100vh',
      padding: '10px 30px'
    },
    footerHeading:{
fontWeight: 700,
color: "#248CA1",
cursor: "pointer"
    },
    tAndC : {
      fontFamily: "Araboto",
fontSize: "16px",
fontWeight: "400",
lineHeight: "18px",
textAlign: "center"

    },
    loginGuest:{
      fontFamily: "Araboto",
      marginTop: "15px",
fontSize: "16px",
fontWeight: "500",
cursor: "pointer",
lineHeight: "18px",
textAlign: "center",
color: "#EA435D",
    },
  inputBgImg: {
    width: "22px",
    marginRight: "0px",
    cursor: "pointer",
    filter: `${theme.palette.type === "dark" ? "none" : "invert(1)"}`
  },
  mainGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    margin: "40px 0",
    [theme.breakpoints.down("md")]: {
      margin : '30px 0'
    },
    [theme.breakpoints.down("sm")]: {
      margin : '20px 0'
    }
  },
  detailsFooter:{
    margin: "25px 0",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin : '20px 0'
    },
    [theme.breakpoints.down("sm")]: {
      margin : '15px 0'
    }
  },
  LoginImg: {
    maxWidth: "120px",
    width: "auto",
    aspectRation : "1/1",
     marginBottom: 20,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
 header2: {
    fontWeight: 700,
    fontFamily: "Roboto",
    textAlign: "center",
    width: "100%",
    marginBottom: 25,
    lineHeight: '27px',
    fontSize: 20,
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
      marginBottom: 15     
    }
  },
  placeHolderTypeEmail: {
    color: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main
    }
  },
  placeHolderTypePassword: {
    color: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main
    }
  },
  emailTextField: {
    width: "100%",
    borderRadius: 10,
    alignSelf: "center",
    fontFamily: "Araboto",
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
passwordTextField: {
    width: "100%",
    borderRadius: 10,
    alignSelf: "center",
    color: theme.palette.primary.main,
    fontFamily: "Araboto",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },    
  },
  loginForm: {
    width: "100%",
    marginBottom: "0px"
  },
 grid2: {
    margin: "auto",
    maxWidth: '600px'
  
  },
  loginButton: {
    width: "100%",
    height: "48px",
    alignSelf: "center",
    backgroundColor: "rgba(234, 67, 93, 1)",
    justifyContent: "center",
    marginBottom: 20,
    borderRadius: 10,
    fontWeight: 600,
    opacity: 0.9,
    color: theme.palette.primary.main,
    
    [theme.breakpoints.only("xs")]: {
      fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 18
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 17
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 18
    },
    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(234, 67, 93, 1)"
    }
  },
  rembbermeButton: {
    display: "flex",
    margin: '15px 0 30px',
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  rembbermeButtonTxt: {
    fontSize: 16,
    cursor: "pointer",
    color: "#EA435D",
    fontWeight: 700,
    padding: '0px 6px',
  },
iconButton: {
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  register: {
    width: "100%",
    textAlign: "center",    
    color: "#A1A1A1"
  },
  navigatorBtn: {
    color: "red",
    textDecoration: "underline"
  },
 homeButton: {
      cursor: 'pointer',
      width: '32px',
      position: "absolute",
      color:"rgba(234, 67, 93, 1)",
      top: 25,
      left: themeDirection("auto", 30),
      right: themeDirection(30, "auto"),        
  }
}};
export default withStyles(styles)(withTranslation()(EmailAccountLoginBlockWeb));
// Customizable Area End
