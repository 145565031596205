import React from "react";
import { withStyles , Box } from "@material-ui/core";
import MovieCardController from "./MovieCardController";

export class MovieCard extends MovieCardController {
    render() {
        const { classes, navigation, cardData }: any = this.props;
        return (
            <Box className={`${classes.movieCard}`}
            data-test-id="movieCard"
                onClick={() => {
                    navigation.navigate(`MovieDetails`, { id: cardData?.data?.id });
                }}
            >
              <div className="movie-card">
                      <img
                        src={cardData?.data?.attributes?.image}                        
                        className={classes.Image}
                        alt="poster"
                      />
               </div>
               </Box>
        )
    }
}

export const styles: any = (theme: any) => (
    {
        movieCard: {
            width: "150px",
            cursor: "pointer",
            maxWidth: "168px",
            // margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
            minWidth: "168px",
            marginBottom: "20px",
            // [theme.breakpoints.up("xl")]: {
            //   width: "266x",
            //   minWidth: "266px",
            //   maxWidth: "266px",
            // },
            // [theme.breakpoints.down("xs")]: {
            //     maxWidth: "142px",
            //   width: "142px",
            //   minWidth: "142px",
            // },
        },
        Image: {
            width: "150px",
            height: "184px",
            borderRadius: "6px",
            border: "1px solid #F6F6F6",
            cursor: "pointer",
            // [theme.breakpoints.up("xl")]: {
            //   height: "320px",
            // },
            // [theme.breakpoints.down("xs")]: {
            //   height: "170px",
            // },
          },
    }
)
export default withStyles(styles)(MovieCard)