import React from "react";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import Banner from "./Banner.web";
import MovieDetailsController, { Props } from "./MovieDetailsController.web";
import Row from "./Row.web";
import MesDialog from "../../user-profile-basic/src/shared/MesDialog.web";
import { withTranslation } from "react-i18next";
import VideoShakaPlayer from "../../videos/src/VideoShakaPlayer.web";
import MovieSwiperSkelton from "./Swiperskelton.web";

export class MovieDetails extends MovieDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const {
      playVideo,
      streamingURL,
      contentDetails,
      seasonsFetched,
      movieLoading
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <>
          {playVideo && streamingURL ? (
            <VideoShakaPlayer
              {...this.state}
              data-test-id="video-js-test-id"
              {...this.props}
              createWatchHistory={this.createWatchHistory}
              onClosePlayer={this.onClosePlayer}
              onClickNext={this.onClickNext}
              getEpisodesByRelease={this.getEpisodesByRelease}
              onClickNextPart={this.onClickNextPart}
            />
          ) : (
            movieLoading ? <MovieSwiperSkelton />  : 
            (contentDetails &&
            seasonsFetched && (
              <Banner
                {...this.props}
                {...this.state}
                onPlayVideo={this.onPlayVideo}
                updateMovieDetailsState={this.updateState}
                getEpisodesByRelease={this.getEpisodesByRelease}
              />
            ))
          )}
          {this.state.loading && (
            <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />
          )}
          <Row {...this.props} {...this.state} />
        </>
        {this.state.userCountError && (
          <MesDialog
            title={t("settingWeb.userLimit")}
            deleteModal={this.state.userCountError}
            onCloseModal={() => {
              this.setState({ userCountError: false });
            }}
            confirmMes={t("settingWeb.UIAA")}
            okButtonText={t("settingWeb.manageDevice")}
            cancelButtonText={t("settingWeb.cancel")}
            deleteClick={() => {
              this.props.navigation.navigate("Setting");
              this.setState({ userCountError: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(MovieDetails);
