Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleAPiMethodDelete = "DELETE";

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.raiseTicketEndPoint = "bx_block_ticket/tickets";
exports.faqListEndPoint = "bx_block_static_content/faqs";
exports.deviceManageEndPoint = "bx_block_account_device/account_devices";
exports.deviceLogoutEndPoint = "bx_block_account_device/account_devices/delete_account_device";

exports.englishfaq = "en";
exports.arabicfaq = "ar";

exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getProfileFavouritesEndPoint="/bx_block_favourites/favourites"
exports.getWatchHistoryEndPoint = "/bx_block_content_management/contents/get_watch_histoty"
exports.deleteWatchHistoryEndPoint="bx_block_content_management/contents/delete_history"
exports.getTransactionHistoryEndPoint= "bx_block_plan/plans/get_transaction_detail";
// Customizable Area End