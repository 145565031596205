import React,{lazy , Suspense} from "react";
import { Box, Container , Typography , Grid } from "@material-ui/core";
import { BackgroundImage } from "./assets";
import { withStyles, } from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RowController from "./RowController";
import { withTranslation } from 'react-i18next';
import { LazyMoviesSlider, LazyStar } from "./LazyImporter.web";
import CONTENT_TYPES from '../../../components/src/Enums';
import LikeCommentShare from "./LikeCommentShare.web";
const Star = lazy(LazyStar)
const MoviesSlider = lazy(LazyMoviesSlider)

export class Row extends RowController {

  render() {
    const { classes, t }: any = this.props;
    let isComment = this.state.selectedTab === "comment";
    return (
      <>
        <Box>
          <Container maxWidth={false} className={classes.MovieContainer}>
          {this.props.contentDetails && <>
            <Grid container className={classes.typoContainer}>
              <Typography className={`${classes.tabTypo} ${!isComment && classes.selectedValue }`} onClick={()=>this.onChangeTab('starCast')}>
              {t("movieDetails.StarCast")}
              <br/>
              <span className={!isComment && classes.selectedTabCss}></span>
              </Typography>
             {!this.props.isGuest && <Typography className={`${classes.tabTypo} ${!isComment && classes.selectedValue }`} onClick={()=>this.onChangeTab('comment')}>
              {t("movieDetails.Comments")}
              <span className={isComment && classes.selectedTabCss}></span>
              </Typography>}
            </Grid>
          <Suspense fallback>
           {isComment && <LikeCommentShare {...this.props} id={"0"} isComment={true} /> }
           {this.state.selectedTab === "starCast" && <Star data-test-id="star"
              {...this.props}
              classes={classes.slickSlider}
              headerText={t("movieDetails.StarCast")}
            />}
            </Suspense>
            </>}
            <Suspense fallback>
            {this.props.similarMovies?.length >0 && <MoviesSlider data-test-id="slider"
              navHandler={this.navHandler}
              sliderTitle={this.props.contentDetails?.content_type === CONTENT_TYPES.WEBSERIES ? t("movieDetails.similarSeries") : t("movieDetails.similarMovies")}
              {...this.props}
            />}
            </Suspense>
          </Container>
        </Box>

      </>
    );
  }
}
/*Styles*/
export const styles: any = (theme: any) => ({
  BannerImg: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "relative",
    objectFit: "contain",
    fontWeight: 700,
  },
  selectedTabCss: {
    width: 8,
height: 4,
borderRadius: '5px',
background: "#F6F6F6",
marginTop: "10px"
  },
  selectedValue:{
    fontWeight: 600
  },
  tabTypo:{
    color: "#F6F6F6",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 300,
    display: 'flex',
    flexDirection: "column",
    alignItems :"center",
    margin: theme.direction === "rtl" ? '0 0 0 15px':'0px 15px 0px 0px',
    cursor: "pointer"
  },
  typoContainer: {
    padding: '20px 10px 10px '
  },
  SliderWrapper: {
    margin: theme.direction === "rtl" ? '60px 5% 60px 0' : '60px 0 60px 5%'
  },
  Title: {
    color: "#B1B6CD",
    fontSize: "14px"
  },
  MovieContainer: {
    paddingLeft: theme.direction === "rtl" ? '0' : '5%',
    paddingRight: theme.direction === "rtl" ? '5%' : '0'
  },
});
export default withStyles(styles)(withTranslation()(Row));
