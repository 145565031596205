import React from "react";

import {
    Grid,
    Typography,
    withStyles
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withTranslation } from 'react-i18next';
// Customizable Area End

import AboutUSController, {
    Props,
} from "./AboutUSController.web";
import { backArrow } from "./assets";
export class AboutUS extends AboutUSController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        const { t }: any = this.props;
        return (
            // Customizable Area Start
            <Grid container className={this.props.isSmallScreen !== true && classes.fullScreenPage}>
                <Grid
                container
                alignItems="center"
                    className={classes.about_us_main_container}
                >
                   {!this.props.isSmallScreen &&  <img
                            src={backArrow}
                            alt="back_arrow"
                            data-test-id= "about_back_button"
                            onClick={this.onBackClick}
                            className={classes.backImage}
                        />}
                    <Typography
                        className={classes.font_Size_24}                        
                    >
                        {t("aboutUs.aboutUs")}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.underLineCSS}
                />
                <Grid
                    item
                    xs={12}
                    className={classes.about_us_main_container}
                >
                     <Typography className={classes.font_Size_20} style={{marginTop: 0}}>{t("aboutUs.aboutUs")}</Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.welcomeAbout")} </Typography>

                    <Typography className={classes.font_Size_20} >{t("aboutUs.ourMissin")}</Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.welcomeAbout")} </Typography>
             
                    <Typography className={classes.font_Size_20} >{t("aboutUs.whatsApart")}</Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.whatsDes")} </Typography>

                    <Typography className={classes.font_Size_20} >{t("aboutUs.ecoSystem")}</Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.ecoSystemDes")} </Typography>

                    <Typography className={classes.typography_text_14} >{t("aboutUs.movies")} {t("aboutUs.moviesDes")} </Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.series")} {t("aboutUs.seriesDes")} </Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.originals")} {t("aboutUs.originalsDes")} </Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.documentaries")} {t("aboutUs.documentariesDes")} </Typography>

                    <Typography className={classes.font_Size_20} >{t("aboutUs.joinUS")}</Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.joinUSDes1")} </Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.joinUSDes2")} </Typography>
                    <Typography className={classes.typography_text_14} >{t("aboutUs.joinUSDes3")} </Typography>


                </Grid>
            </Grid>
          
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const AboutUSStyle = (theme: any) => ({ 
    root: {
        "label + &": {
          marginTop: theme.spacing(1),
        },
      },
      
   about_us_main_container: {
    padding: "30px 30px 10px",
    [theme.breakpoints.only("xs")]: {
        padding: "15px 20px 5px",
    },
    [theme.breakpoints.only("sm")]: {
        padding: "20px 25px 7px",
    },},
    underLineCSS:{
        height: "2px",
        background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
        marginTop: 10
        
    },
    font_Size_20: {
        fontWeight: 700,
            fontSize: 20,
            margin: '22px 0px 10px',
            fontFamily: "Roboto",
            color: "#F6F6F6",
            [theme.breakpoints.only("xs")]: {
                fontSize: 18,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 19,
            }
    },
    backImage: {
        width: 11,
        height: 20,
        margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
        cursor: 'pointer',
        transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
    },
    typography_text_14 : {
        fontWeight: 400,
            fontSize: 14,
            marginBottom: '10px',
            fontFamily: "Roboto",
            lineHeight: '18px',
            color: "#F6F6F6",
            [theme.breakpoints.only("xs")]: {
                fontSize: 13,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "13.5px",
            }
    },
    font_Size_24: {
        fontWeight: 700,
            fontSize: 24,
            fontFamily: "Roboto",
            color: "#F6F6F6",
            [theme.breakpoints.only("xs")]: {
                fontSize: 20,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 22,
            }
    },
    fullScreenPage: {
        padding: '0px 60px',
    [theme.breakpoints.only("xs")]: {
      padding: '0 20px',
    },
    [theme.breakpoints.only("md")]: {
      padding: '0 45px',
    },
    [theme.breakpoints.only("sm")]: {
      padding: '0 30px',
    }
    }
});

export default withStyles(AboutUSStyle)(withTranslation()(AboutUS));
// Customizable Area End
