import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

// Customizable Area End

export const themeCreatStyle: any = (theme: any) => {
  let themePalleteDirection = (arrValue: any, enValue: any) => theme.direction === "rtl" ? arrValue : enValue
  let themePalletStyle = (start: string, end: string) => theme.palette.type === "dark" ? start : end;
  return {
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    backgroundImage_div: {
      maxWidth: "100%",
      backgroundSize: "100% 100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      alignSelf: "center",
      minHeight: "100vh",
      [theme.breakpoints.only("lg")]: {
        padding: "80px 80px 50px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "80px 80px 50px",
      },
      [theme.breakpoints.only("xs")]: {
        padding: "40px 25px 25px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "50px 40px 35px",
      },
      [theme.breakpoints.only("md")]: {
        padding: "80px 80px 50px",
      },
    },
    search_icon: {
      marginRight: themePalleteDirection(0, 15),
      marginLeft: themePalleteDirection(15, 0),
      filter: themePalletStyle('none', 'invert(1)'),
      [theme.breakpoints.only("lg")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.only("xs")]: {
        width: 16,
        height: 16,
      },
      [theme.breakpoints.up("lg")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.only("md")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.only("sm")]: {
        width: 18,
        height: 18,
      },
    },
    description_text: {
      fontStyle: "normal",
      fontFamily: "Araboto",
      fontWeight: 400,
      letterSpacing: 0,
    },
    font_size_26: {
      fontSize: 26,
      [theme.breakpoints.only("lg")]: {
        fontSize: 26,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 22,
      },
    },
    font_size_22: {
      [theme.breakpoints.only("lg")]: {
        fontSize: 22,
      },
      fontSize: 22,
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 18.5,
      },
    },

    description_Box: {
      marginTop: 60,
      [theme.breakpoints.only("lg")]: {
        marginTop: 60,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: 35,
      },
      [theme.breakpoints.only("md")]: {
        marginTop: 55,
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 45,
      },
    },
    details_description_text: {
      fontFamily: "Araboto",
      fontWeight: 400,
      fontStyle: "normal",
      color: theme.palette.primary.main,
      letterSpacing: 0,
    },
    font_size_24: {
      [theme.breakpoints.only("lg")]: {
        fontSize: 24,
      },
      fontSize: 24,
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 22,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 21,
      },
    },
    button_div: {
      display: "flex",
      [theme.breakpoints.only("md")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      [theme.breakpoints.only("sm")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: 10,
        justifyContent: "flex-start",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
      [theme.breakpoints.only("lg")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
    },
    button_css: {
      color: theme.palette.primary.main,
      borderRadius: 8,
      opacity: 0.9,
      backgroundColor: themePalletStyle('rgba(36,40,48, 1)', "#85868b"),
      fontSize: 14,

      [theme.breakpoints.only("xs")]: {
        fontSize: 11.5,
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 13.5,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 12.5,
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: themePalletStyle('rgba(36,40,48, 1)', "#85868b"),
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 14,
      },
    },
    description_text_css: {
      letterSpacing: "0px",
      color: theme.palette.secondary.main,
      fontFamily: "Araboto",
      fontWeight: 400,
      fontStyle: `normal`,
      lineHeight: 1,
      marginTop: 10,
      fontSize: 14,
      [theme.breakpoints.only("xs")]: {
        fontSize: 11.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 13.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 12.5,
      },
    },
    redeem_button_css: {
      boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
      color: theme.palette.primary.main,
      backgroundColor: `rgba(234,67,93, 1)`,
      borderRadius: 10,
      fontWeight: 700,
      opacity: 0.9,
      marginTop: 20,
      fontSize: 18,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      "&:disabled": {
        opacity: 0.5,
        color: theme.palette.primary.main,
      },
      "&:hover": {
        opacity: 0.9,
        backgroundColor: `rgba(234,67,93, 1)`,
      },
    },
    disbaled_redeem_button_css: {
      backgroundColor: `#242830`,
      borderRadius: 10,
      color: "#7A7B7F",
      fontWeight: 700,
      marginTop: 20,
      fontSize: 18,
      "&:disabled": {
        backgroundColor: `#242830`,
        color: "#7A7B7F",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
    },
    font_size_16: {
      fontSize: 16,
      [theme.breakpoints.only("lg")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 15.5,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 14.5,
      },
    },
    font_size_12: {
      fontSize: 12,
      [theme.breakpoints.only("xs")]: {
        fontSize: 10.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 12,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 11.5,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 11,
      },
    },
    may_be_button_css: {
      backgroundColor: themePalletStyle('#1A1B22', '#85868b'),
      border: "1px solid #979797",
      borderRadius: 12,
      color: theme.palette.primary.main,
      fontWeight: 700,
      opacity: 0.9,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
        marginTop: 35,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
        marginTop: 42,
        maxWidth: 280,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
        marginTop: 37,
        maxWidth: 230,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
        marginTop: 45,
        maxWidth: 280,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginTop: 45,
        maxWidth: 280,
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: themePalletStyle('#1A1B22', '#85868b'),
      },
      "&:disabled": {
        opacity: 0.5,
        color: theme.palette.primary.main,
      },
    },
    background_color: {
      background: themePalletStyle('#242830', '#b2bfd9'),
    },
    background_color_1: {
      background: themePalletStyle('linear-gradient(270deg, #12121A 0%,#12121B 40% , #171727 100%)', 'white'),
    },
    background_color_2: {
      background: themePalletStyle("linear-gradient(270deg, #12121A 0%,#12121B 40% , #171727 100%)", '#ebebeb'),
    },
    PPTypo: {
      textAlign: "center" as const,
      margin: "15px 0 20px",
      fontWeight: 400,
      fontSize: 16,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      }
    },
    headingTypo: {
      textAlign: "center" as const,
      fontSize: 20,
      fontWeight: 700,
     [theme.breakpoints.only("xs")]: {
        fontSize: 18
      }
    },
    textFiledCss: {
      fontFamily: "Araboto",
      color: theme.palette.primary.main,
      lineHeight: 1,

      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
        padding: "18px 20px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
        padding: "14.5px 16px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
        padding: "17px 18.5px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
        padding: "16px 17px",
      },
    },
    forrmInput_textfield: {
      background: themePalletStyle("#212330", "none"),
      width: "100%",
      borderRadius: "10px",
      color: theme.palette.primary.main,
      fontSize: 20,
      fontFamily: "Open sans",
      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
      },
    },
    plans_price: {
      background: "linear-gradient(90deg, #0097A8 0%, #6869AC 100%)",
      WebkitBackgroundClip: "text",
      fontWeight: 700,
      WebkitTextFillColor: "transparent",
    },
    heading: {
      fontFamily: "Araboto",
      fontWeight: 700,
      fontStyle: "normal",
      letterSpacing: 0,
      lineHeight: 1,
      breakWord: "break-all",

      [theme.breakpoints.only("xs")]: {
        fontSize: 42,
        marginBottom: 22,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 52,
        marginBottom: 27,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 48,
        marginBottom: 25,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 56,
        marginBottom: 30,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 56,
        marginBottom: 30,
      },
    },
    scrollBar: {
      scrollbarColor: "#B7B7B7 transparent",
      "&::-webkit-scrollbar": {
        width: 6,
        height: 6,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
        minHeight: 24,
        minWidth: 24,
      },
      "&::-webkit-scrollbar-thumb:focus": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },
      "&::-webkit-scrollbar-thumb:active": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent",
      },
    },
    first_letter: {
      color: "transparent",
      WebkitTextStroke: `1px ${theme.palette.primary.main}`,
      background: `linear-gradient(142deg, transparent 0%,transparent 44%, ${theme.palette.primary.main} 45%, ${theme.palette.primary.main} 100%)`,

      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    last_letter: {
      color: "transparent",
      WebkitTextStroke: `1px ${theme.palette.primary.main}`,
      background: `linear-gradient(322deg, transparent 0%,transparent 49%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,

      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    question_mark: {
      color: "transparent",
      WebkitTextStroke: `1px ${theme.palette.primary.main}`,
    },
    done_icon: {
      width: 14,
      height: 14,
      marginRight: themePalleteDirection(0, 15),
      marginLeft: themePalleteDirection(15, 0),
    },
    planContainer: {
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      padding: "40px 45px",
      borderRadius: "8px",
      [theme.breakpoints.only("xs")]: {
        padding: "30px 35px",
      },
      maxWidth: "1000px"
    }
  }
}
// Customizable Area End

import ViewSubscriptionController, {
  Props,
} from "./ViewSubscriptionController.web";
import PriceDetails from "./PriceDetails.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";


export class ViewSubscription extends ViewSubscriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t }: any = this.props;
    // Customizable Area Start
    return (
      <Grid container>
         {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
        <Grid
          item
          xs={12}
          className={classes.backgroundImage_div}
        >
          <Grid container className={classes.planContainer}>
            <Grid item xs={12}>
              <Typography className={classes.headingTypo} color="primary">
                    {t("settings.planDetail")}
                  </Typography>
                  <Typography className={classes.PPTypo} color="secondary">
                  {t("view_subscription.subheading")}
                  </Typography>
            </Grid>
           <Grid item xs={12} className={classes.description_Box}>
              <Grid container spacing={3}>

                {this.state.plansDetails.map((_data: any, index: any) => {
                  return (
                    <React.Fragment key={_data.id}>
                      <PriceDetails
                        priceDetails={_data}
                        onButtonClick={(data: any) => this.buttonClickView(data)}
                        isDisabled={false}
                        buttonText={t("view_subscription.CP")}
                        selectedPlanId={this.state.selectedPlanId}
                      />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                data-test-id="disable"
                fullWidth
                variant="contained"
                className={classes.may_be_button_css}
                disabled={
                  this.state.selectedPlanId === "" ||
                  this.state.selectedPlanId.length === 0
                }
                onClick={() => this.handleAddPlan()}
                style={{
                  border: "none",
                  backgroundColor: "rgba(234,67,93, 1)",
                }}
              >
                {t("view_subscription.continue")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(ViewSubscription));
// Customizable Area End
