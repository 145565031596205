import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { CometChat } from "@cometchat-pro/chat";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    width?: any;
    t?: any;
}

export interface S {
    // Customizable Area Start

    userProfileDetails: any;
    chatUser: any;
    loading: any;
    selectedTab: any;
    userId: number;
    userType: string;
    userList: any;
    isPremiumUser: boolean;
    chatServiceError: boolean;
    isGuest: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}

export default class ChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileApiCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);


        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start

            userProfileDetails: [],
            loading: false,
            selectedTab: null,
            userId: 0,
            userType: 'Owner',
            chatUser: [],
            userList: [],
            isPremiumUser: true,
            chatServiceError:false,
            isGuest: false

            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        let isGuestUser = await getStorageData("isGuestUser");
    (await isGuestUser) !== "true"
      ? this.getPorfile()
      : this.toGoPerk()
        // this.getUserProfile()

    }

    async componentWillUnmount() {
        let listenerID = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';
        CometChat.removeMessageListener(listenerID);
        CometChat.removeUserListener(listenerID)
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getProfileApiCallId) {

                if (responseJson != null) {
                    console.log("JSON NOt NUll")
                    if (!responseJson.errors) {
                        this.authCometChat(responseJson)
                    }
                    else {
                        this.setState({ loading: false })
                        let key = Object.keys(responseJson?.errors[0])[0]
                        console.log(key)
                        toast.error(responseJson?.errors[0][key])
                    }
                }

            }


        }

        // Customizable Area End
    }

    // Customizable Area Start

    authCometChat = (responseJson: any) => {
        let planType = responseJson.data.attributes.plan.data.attributes.chat_enabled
        !planType && this.toGoPerk()
        this.setState({isPremiumUser : planType, loading: false})
        if (responseJson?.meta?.chat_service?.error) {
            this.setState({chatServiceError:true})
        }
        
        let authToken = responseJson?.meta?.chat_service?.data?.authToken;
        if (authToken) {
            CometChat.getLoggedinUser().then(
                user => {
                    if (!user) {
                        this.loginCometChat(responseJson, authToken)
                    } else {
                        CometChat.logout().then(
                            () => {
                                CometChat.getLoggedinUser().then(
                                    user => {
                                        if (!user) {
                                            this.loginCometChat(responseJson, authToken)
                                        }
                                    })
                            }
                        );
                    }
                }, error => {
                    toast.error(this.props.t("loginScreen.SEO"));
                }
            );
        }

    }

    loginCometChat = (responseJson: any, authToken: string) => {
        CometChat.login(authToken).then(
            user => {
                this.setState({ chatUser: responseJson?.meta?.chat_friends?.data, loading: false, chatServiceError:false },
                    () => this.getLastConversation())

            }, error => {
                toast.error(error?.message)
            }
        )
    }

    onUserIdChange = (id: number, type: any) => {
        this.setState({
            userId: id,
            userType: type
        })
        this.getLastConversation()
    }
    onBackClick = (e: any) => {
        if ((this.props.width === "sm" || this.props.width === "xs") && this.state.selectedTab !== null) {
            this.setState({ selectedTab: null })
            this.getLastConversation()
        } else {
            this.props.navigation.goBack()
        }
    }

    onUserSelect = (id: number, type: any) => {
        this.setState({
            userId: id,
            userType: type
        }, () => this.setState({ selectedTab: 2 }))
    }
    getPorfile() {

        this.setState({ loading: true })
        const headers = {
            token: localStorage.getItem("Token")
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addUserEndPoint + `/${localStorage.getItem("selectedUser")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    convertStringToDate = (strTime: string) => {
        let today = moment().format("DD MMM YYYY")
        let timestamp = Number(strTime) * 1000;
        let mesdate = moment(timestamp).format('DD MMM YYYY')
        return moment(mesdate, 'DD MMM YYYY').isSame(today) ? moment(timestamp).format('hh:mm a') : moment(timestamp).format('DD MMM YYYY')
    }

    toGoPerk = () =>{     
        setStorageData("lastScreen", "Chat")   
         this.navigateToSP("Perks")
    }

    getLastConversation = () => {
        let chatUser: [] = []
        chatUser = this.state.chatUser
        let limit = 30;
        let conversationsRequest = new CometChat.ConversationsRequestBuilder()
            .setLimit(limit)
            .build();
        conversationsRequest.fetchNext().then(
            conversationList => {
                chatUser.map((_data: any) => {
                    let index = conversationList.findIndex((_chat: any) => _chat.conversationWith.uid === _data.id)
                    if (index !== -1) {
                        _data.lastMessage = conversationList[index].getLastMessage()
                        _data.unReadCount = conversationList[index].getUnreadMessageCount()
                    }
                })
                this.setState({
                    chatUser
                }, () => this.setState({ loading: false }))

            }, error => {
                this.setState({
                    chatUser
                }, () => this.setState({ loading: false }))
                console.log("Conversations list fetching failed with error:", error);
            }

        );
        this.addBackgroundUserListListener()

    }

    addBackgroundUserListListener = () => {
        let listenerID: string = localStorage.getItem("selectedUser") || 'UNIQUE_LISTENER_ID';

        CometChat.addMessageListener(
            listenerID,
            new CometChat.MessageListener({
                onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
                    let chatMes = [...this.state.chatUser]
                    let findIndex = this.state.chatUser.findIndex((_data: any) =>
                        _data.id === textMessage.getSender().getUid()
                    )

                    if (findIndex !== -1) {
                        chatMes[findIndex].lastMessage = textMessage
                        chatMes[findIndex].unReadCount = parseInt(chatMes[findIndex].unReadCount) + 1
                        this.setState({ chatUser: chatMes })
                    }
                    CometChat.markAsDelivered(textMessage);

                },
            }))

        CometChat.addUserListener(
            listenerID,
            new CometChat.UserListener({
                onUserOnline: (onlineUser: CometChat.User) => {

                    let chatMes = [...this.state.chatUser]
                    let findIndex = this.state.chatUser.findIndex((_data: any) =>
                        _data.id === onlineUser.getUid()
                    )

                    if (findIndex !== -1) {
                        chatMes[findIndex].status = onlineUser.getStatus()
                        this.setState({ chatUser: chatMes })
                    }
                },
                onUserOffline: (offlineUser: CometChat.User) => {
                    let chatMes = [...this.state.chatUser]
                    let findIndex = this.state.chatUser.findIndex((_data: any) =>
                        _data.id === offlineUser.getUid()
                    )

                    if (findIndex !== -1) {
                        chatMes[findIndex].status = offlineUser.getStatus()
                        this.setState({ chatUser: chatMes })
                    }
                }
            })
        );
    }

    checkIsMobileScreenForChat = () => {
        return (((this.props.width === "xs" || this.props.width === "sm") &&
            this.state.selectedTab !== null) ||
            (this.props.width !== "xs" && this.props.width !== "sm"))
    }

    checkIsMobileScreen = () => {
        return (((this.props.width === "xs" || this.props.width === "sm") &&
            this.state.selectedTab === null) ||
            (this.props.width !== "xs" && this.props.width !== "sm"))
    }
    
    getTitle(guestMessage: string , upgradeMessage: string){
    return this.state.isGuest ? guestMessage : upgradeMessage;
    }

   handleNaigation = (path: string) =>{
    const changePlanMessage = new Message(getName(MessageEnum.NavigationMessage));
    changePlanMessage.addData(getName(MessageEnum.NavigationTargetMessage), "GeneralSetting");
    changePlanMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: path });
    changePlanMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(changePlanMessage);
}
    onChatClick = (item: any, index: number) => {
        let chat = [...this.state.chatUser];
        chat[index].unReadCount = 0;
        this.setState({
            selectedTab: item.id,
            chatUser: chat,
        });
    }

    navigateToSP = (urlPath: string) =>{
        this.props.navigation.navigate(urlPath)
    }

    // Customizable Area End
}
