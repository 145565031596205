import React from "react";
import { Typography, Grid, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import FooterController, { Props } from "./FooterController.web";

export class FooterComponent extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    let {classes} = this.props
    let {t} = this.props
  return (
    <>
      <Grid item xs={12} className={classes.linearLine} style={{marginTop : this.props.isLanding && '0'}}>

      </Grid>
    <Grid container className={classes.footerContainer}>
      <Grid item xs={12} sm={7}>

      <Typography className={classes.font_size_16} color="secondary">      
        {t("footer.allRightsReserved")}
      </Typography>
      </Grid>
      <Grid item xs={12} sm={5}>

<Typography className={`${classes.font_size_16} ${classes.footerText}`}>
<span className={classes.SpanLink}
    data-test-id="privacyPolicy"
    onClick={() => {
      this.handleRoutes("privacy_policy" , "PrivacyPolicy");
    }}> {t("footer.privacyPolicy")}</span>
 |
  <span className={classes.SpanLink}
    data-test-id="terms"
    onClick={() => {
      this.handleRoutes("terms_of_use" , "TermsAndConditions");
    }}> {t("footer.terms")} </span> |
    <span className={classes.SpanLink}
    data-test-id="aboutUS"
    onClick={() => {
      this.handleRoutes("about_us", "AboutUS");
    }}> {t("footer.aboutUs")} </span>
  
</Typography>
</Grid>
    </Grid>
    </>
  );
}
}

export const themeCreatStyle = (theme: any) => ({
    linearLine: {
      marginTop: '20px',
      height: '2px',
      background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)"
    },

    footerContainer: {
      background: "transparent",
     justifyContent: "space-between",
      [theme.breakpoints.only("xs")]: {
        padding: "12px 20px 25px"
      },
      [theme.breakpoints.only("sm")]: {
        padding: "15px 30px 25px"
      },
      padding: "20px 40px 30px",
      [theme.breakpoints.only("md")]: {
        padding: "17px 35px 25px" 
      }
    },
    footerText:{
      color: "#A1A1A1",
      textAlign: "end" as const,
      [theme.breakpoints.only("xs")]: {
        textAlign:"center"        
      },
    },

    font_size_16: {
      fontFamily: "Araboto",
      fontWeight: 400,
      marginBottom: '5px',
      [theme.breakpoints.only("xs")]: {
        textAlign:"center" as const,
        fontSize: 14
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 15.5
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 14.5
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 16
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16
      }
    },
    SpanLink: {
      color: "#248CA1",
      cursor: "pointer",
      padding: '0px 5px'
    }
  })


export default withStyles(themeCreatStyle)(withTranslation()(FooterComponent));
