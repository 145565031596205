/* eslint-disable no-undef */
import React from "react";
// Customizable Area Start
import {
  FormHelperText,
  Button,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Customizable Area End
import ForgotPasswordController from "./ForgotPasswordController.web";
import {
 VODLogo
} from "./assets";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { withTranslation } from "react-i18next";
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";

export class OtpVerification extends ForgotPasswordController {
  render() {
    //  Customizable Area Start
    const { classes, t }: any = this.props;
    //  Customizable Area End
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid item xs={12} className={classes.PPContainer}>
          {/* Customizable Area Start */}
          <Grid container className={classes.PPGridContainer}>
             <Grid container className={classes.PPRightGrid}>
             <Grid container justifyContent="center">
            <img className={classes.logoImage} src={VODLogo} />
            </Grid>
               <Grid
                  item
                  xs={12}
                  className={classes.PPSubgrid2}
                >
                    <Typography className={classes.headingTypo} color="primary">
                    {t("loginScreen.FP")}
                  </Typography>
                  <Typography className={classes.PPTypo} color="secondary">
                    {localStorage.getItem("otp_type") === "email"
                      ? `${t("loginScreen.CE")} ${localStorage.getItem(
                          "forgetpasswordemail"
                        )}.`
                      : `${t("loginScreen.CP")} ${localStorage.getItem(
                          "forgetpasswordemail"
                        )}.`}
                  </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.PPSubgrid3}
                  >
                    <div className={classes.OptBox}>
                      <div className={classes.FlexBox}>
                        <TextField
                          id="standard-basic"
                          data-test-id="otp1"
                          className={classes.forrmInput_textfield}
                          variant="outlined"
                          autoFocus={true}
                          type="number"
                          name="otp_code"
                          value={this.state.otp1}
                          onPaste={e => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3]
                            });
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeOtp1(event)}
                          inputProps={{
                            className: classes.login_otp_input_props
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              // eslint-disable-next-line radix
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px"
                            }
                          }}
                        />
                        <TextField
                          data-test-id="otp2"
                          id="standard-basic"
                          className={classes.forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp2"
                          value={this.state.otp2}
                          onPaste={e => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3]
                            });
                          }}
                          onKeyDown={(event: any) =>
                            this.onKeyDown(
                              event,
                              this.state.otp2,
                              "input[name=otp_code"
                            )
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeOtp2(event)}
                          inputProps={{
                            className: classes.login_otp_input_props
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              // eslint-disable-next-line radix
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px"
                            }
                          }}
                        />
                        <TextField
                          data-test-id="otp3"
                          id="standard-basic"
                          className={classes.forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp3"
                          value={this.state.otp3}
                          onPaste={e => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3]
                            });
                          }}
                          onKeyDown={(event: any) =>
                            this.onKeyDown(
                              event,
                              this.state.otp3,
                              "input[name=otp2"
                            )
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onChangeOtp3(event)}
                          inputProps={{
                            className: classes.login_otp_input_props
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              // eslint-disable-next-line radix
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px"
                            }
                          }}
                        />
                        <TextField
                          data-test-id="otp4"
                          id="standard-basic"
                          className={classes.forrmInput_textfield}
                          variant="outlined"
                          type="number"
                          name="otp4"
                          value={this.state.otp4}
                          onPaste={e => {
                            const otp = e.clipboardData.getData("Text");
                            this.setState({
                              otp1: otp[0],
                              otp2: otp[1],
                              otp3: otp[2],
                              otp4: otp[3]
                            });
                          }}
                          onKeyDown={(event: any) =>
                            this.onKeyDown(
                              event,
                              this.state.otp4,
                              "input[name=otp3"
                            )
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            this.setState({ otp4: event.target.value });
                          }}
                          inputProps={{
                            className:classes.login_otp_input_props
                          }}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = Math.max(
                              0,
                              // eslint-disable-next-line radix
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 1);
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8.8px"
                            }
                          }}
                        />
                      </div>
                      {this.state.otperror && (
                        <FormHelperText className={classes.HelperTax}>
                          {this.state.otperrorText}
                        </FormHelperText>
                      )}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.PPResend}
                  >
                    {this.state.oneMinTimer === 0 ? (
                      <Typography
                        className={classes.ResendText}
                        data-test-id="onResendOtp"
                        onClick={(event: any) => this.ResendOtp(event)}
                      >
                        {t("loginScreen.RC")}
                      </Typography>
                    ) : (
                      <Typography color="primary" className={classes.TimerTax}>
                        {this.state.oneMinTimer < 10
                          ? `00:0${this.state.oneMinTimer}`
                          : `00:${this.state.oneMinTimer}`}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className={classes.PPSubgrid4}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.PPButton}
                      data-test-id="onSubmitOtp"
                      onClick={(event: any) => this.emailOTPhandleSubmit(event)}
                    >
                      {t("forgotpassword.submit")}
                    </Button>
                    <Typography
                  data-testid="navtoregister"
                  onClick={this.navToLogin}
                  className={classes.backToLoginButton}
                >
                  {t("loginScreen.backTL")}

                </Typography>
                  </Grid>
                  <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}                    
                    />
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          
          {/* Customizable Area End */}
        </Grid>
      </Grid>
    );
  }
}

export const styles: any = (theme: any) => {
  let themeDirection = (arrValue: any, enValue: any) =>
    theme.direction === "rtl" ? arrValue : enValue;
  return {
    //  Customizable Area Start
    PPContainer: {
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
       [theme.breakpoints.only("xs")]: {
       padding: "10px 10px"
      },
      [theme.breakpoints.only("sm")]: {
        width: "100%",
        height: "100%",
        padding: "40px 20px"
      }},
    PPGridContainer: {
      display: "flex",
      justifyContent: "space-around" as const,
      alignItems: "center" as const,
      alignSelf: "center" as const
    },
    PPSideGridImg: {
      width: "100%",
      maxWidth: "100%",

      height: "550px",

      backgroundColor: "transparent",
      paddingTop: 50,
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        position: "relative" as const,
        top: 70,
        paddingTop: 0
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },
    PPText: {
      maxWidth: "100%",
      width: "auto",
      filter:theme.palette.type === "dark" ? "none" : "invert(1)",
      [theme.breakpoints.only("xs")]: {
        width: "80%",
        margin: "auto 10%"
      }
    },
    changeLanguage: {
      width: "34.27px",
      height:"26px",
      
    },
    FlexBox: {
      display: "flex",
      justifyContent: "space-between" as const
    },
    HelperTax: {
      color: "white",
      fontStyle: 'italic',
      margin: "auto",
      fontSize: "14px",
      textAlign: "start" as const
    },
    ResendText: {
      fontSize: 12,
      cursor: "pointer",
      color: "#EA435D",
      textDecoration: "underline" as const
    },
   PPButton: {
      maxWidth: "100%",
      width: "100%",
      height: "54px",
      borderRadius: 10,
      alignSelf: "center" as const,
      backgroundColor: "rgba(234, 67, 93, 1)",
      fontFamily: "Araboto",
      fontWeight: 600,
      color: theme.palette.primary.main,
      opacity: 0.9,
      [theme.breakpoints.only("xs")]: {
        width: "80%",
        margin: "auto 10%"
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: "rgba(234, 67, 93, 1)"
      }
    },
    TimerTax: {
      fontSize: 12,
      cursor: "pointer"
    },
    OptBox: {
      maxWidth: "100%",
      width: "100%",
    },
    PPTypo:  {
      fontSize: 16,
      textAlign: "center" as const,
      fontWeight: 400,
      margin: "15px 0 20px",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      }
    },
    headingTypo: {
      fontWeight: 700,
      fontSize: 20,
      textAlign: "center" as const,
     [theme.breakpoints.only("xs")]: {
        fontSize: 18
      }
    },

    PPSubgrid2: {
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      padding: "40px 55px",
      borderRadius: "8px",
      [theme.breakpoints.only("xs")]: {
        padding: "30px",
      }

    },
     backToLoginButton:{
      fontSize: 16,
      fontFamily: "Roboto",
    cursor: "pointer" as const,
    marginTop:"15px",
    color: "#EA435D",
    fontWeight: 700,
    textAlign: "center" as const
    },
    logoImage: {
      width: "auto",
      maxWidth: "120px",
      marginBottom: 20,
      aspectRation : "1/1",
      backgroundColor: "transparent"
    },
    
    PPRightGrid: {
      margin: "auto",
      maxWidth: '550px'
    },
    PPSubgrid1: {
      marginBottom: 30,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 25
      }
    },
     PPSubgrid3: {
      marginBottom: 20,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 15
      }
    },
    PPResend: {
      marginBottom: 30,
      width: "100%",
      display: "flex",
      justifyContent: "flex-end" as const,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 25,        
      }
    },
    login_otp_input_props: {
      fontFamily: "Araboto",
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.primary.main,
      textAlign: "center" as const,
      padding: "0",
      height: "65px",

      [theme.breakpoints.only("xs")]: {
        height: '50px'
      },
      [theme.breakpoints.only("sm")]: {
        height: '55px'
      }

    },
    image_direction: {
      cursor: "pointer",
      transform: themeDirection("scaleX(-1)", "none"),
      margin: themeDirection("0 0 0 20px", "0 20px 0 0")
    },
    forrmInput_textfield: {
      width: '65px',
      borderRadius: "8.8px",
      backgroundColor: theme.palette.type === "dark" ? "#212330" : "none",
      border: theme.palette.type === "dark" ? "none" : "1px solid #212330",
      fontSize: '16px',
      marginTop: "20px",
      [theme.breakpoints.only("xs")]: {
       width: '50px'
      },
      [theme.breakpoints.only("sm")]: {
        width: '55px'
      }
    }
    //  Customizable Area End
  };
};

export default withStyles(styles)(withTranslation()(OtpVerification));
