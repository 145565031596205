Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.searchApiContentType = 'application/json'
exports.getSearchApiEndPoint = 'search/users'
exports.errorTitle = 'Error'
exports.movieListAPIEndPoints = "/bx_block_content_management/contents",
// exports.filterAPIEndPoints = "/bx_block_content_management/contents?search="
exports.genreListEndPoints = "/bx_block_categories/genre"
// Customizable Area End
