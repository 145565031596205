import React, { useRef } from 'react';
import {
    Box,
    Typography,
    Grid,
    withStyles
} from "@material-ui/core"
import { withTranslation } from 'react-i18next';
import "./SearchSlider.css"
export const configJSON = require("./config.js");

export const useStyles: any = (theme: any) => ({

    SliderBtnBlock: {
        display: "flex",
        justifyContent: "flex-end",
        color: "white",
        padding: theme.direction === "rtl" ? '1% 0 0 10%' : '1% 10% 0 0',
    },
    barColorPrimary: {
        background: 'linear-gradient(90deg, rgba(234,67,93,1) 0%, rgba(104,105,172,1) 100%);',
    },
    colorPrimary: {
        backgroundColor: '#242830',
        height: "7px",
        borderRadius: "4px"
    },
    RowTitle: {
        fontSize: "28px",
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: "Araboto",
        letterSpacing: '0px',
        [theme.breakpoints.only("xs")]: {
            fontSize: 22,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 26,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 24,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 28,
        },
    },

    displayNon: {
        display: `${theme.direction === 'rtl' ? 'none' : 'flex'}`,
        width: 40,
        height: 40,
        cursor: "pointer",
        [theme.breakpoints.only("md")]: {
            width: 35,
            height: 35,
        },
        [theme.breakpoints.only("xs")]: {
            width: 30,
            height: 30,
        },
        [theme.breakpoints.only("sm")]: {
            width: 32,
            height: 32,
        },
    },

    displayFlex: {
        display: `${theme.direction === 'rtl' ? 'flex' : 'none'}`,
        width: 40,
        height: 40,
        cursor: "pointer",
        [theme.breakpoints.only("xs")]: {
            width: 30,
            height: 30,
        },
        [theme.breakpoints.only("sm")]: {
            width: 32,
            height: 32,
        },
        [theme.breakpoints.only("md")]: {
            width: 35,
            height: 35,
        },
    },

    Slider: {
        '& .slick-list': {
            height: '100%',
            '& .slick-slide': {
                width: '150px !important' as any,
                float: theme.direction === "rtl" ? 'right !important' : 'left !important',
                '& div': {
                    '& div': {
                        minHeight: '168px',
                    }
                }
            },
            '& .slick-track': {
                height: '100%',
            }
        }
    },

    MovieSubTitle: {
        opacity: 1,
        fontFamily: "Araboto-Normal",
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0px',
        textAlign: theme.direction === 'rtl' ? 'right' : 'left',
        lineHeight: 'inherit',
        marginTop: '8px',
        color: '#7A7B7F',
        fontStyle: 'normal',
        padding: theme.direction === 'rtl' ? '0 12% 0 0' : '0 0 0 12%',
    },
    ButtonWithIcon: {
        position: 'absolute',
        transform: "translate(-50%, -50%)",
        top: '50%',
        left: '50%',
        '& svg': {
            width: '2.2em',
            height: '2.2em',
            fill: '#FFFFFF',
            opacity: '80%'
        }
    },
    slideCard: {
        width: "184px",
        minWidth: "184px",
        margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
        maxWidth: "184px",
        [theme.breakpoints.down("xs")]: {
          width: "142px",
          maxWidth: "142px",
          minWidth: "142px",
        },
        [theme.breakpoints.up("xl")]: {
          width: "266x",
          maxWidth: "266px",
          minWidth: "266px",
        },
    },
    Image: {
        width: "100%",
        cursor: "pointer",
        height: "220px",
    border: "1px solid #F6F6F6",
    borderRadius: "6px",
    margin: "12px 0",
    [theme.breakpoints.up("xl")]: {
      height: "320px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "170px",
    },
    }
})

export const SearchSlider = (props: any) => {
    let SliderRef = useRef(null);
    const { sliderTitle, similarMovies, navigateHandler, classes }: any = props;

    return (
        <Grid container style={{ padding: "30px 0" }}>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography color="primary" className={classes.RowTitle}>{sliderTitle}</Typography>
            </Grid>
            {(similarMovies)?.length > 0 &&
                <div className="movie-carousels">
                    <div className="slider-containers" ref={SliderRef}>
                        {(similarMovies).map((ele: any, i: number) => {
                            return (
                                <Box data-test-id={`navigatemovie${i}`}
                                    onClick={() => navigateHandler(ele?.id)}
                                    key={ele.id} className={classes.slideCard}>
                                    {console.log(ele, "similarmovies")}
                                    <div className='movie-card'>
                                        <img src={ele?.attributes?.thumbnail_image} className={classes.Image} />
                                    </div>                                    
                                </Box>
                            );
                        })}
                    </div>
                </div>}
        </Grid>
    )
}

export default withStyles(useStyles)(withTranslation()(SearchSlider));

