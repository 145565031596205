import React from "react";
// Customizable Area Start
import { List, ListItem, Grid, withStyles, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
require("moment/min/locales.min");
let lang = localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
moment.locale(lang);
// Customizable Area End

import NewsController, { Props } from "./NewsController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";

export class NewsWeb extends NewsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { t }: any = this.props;
    const isArabic: boolean = localStorage.getItem("i18nextLng") === "ar";

    return (
      <>
        <Grid container>
          {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
          <Grid className={classes.MainWrapper}>
            <Grid className={classes.NewsContainer}>
              <Grid container className={classes.NewsHeader}>
                <Grid data-test-id="backHistory" className={classes.BackBlock} onClick={() => this.historyBack()}>
                  {this.state.showData?.title && <ArrowBackIosIcon className={classes.ArrowIcon} /> }
                  <Typography className={classes.Heading}>{t("newsweb.headerTitle")}</Typography>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  style={{
                    height: '1.5px',
                    background: "linear-gradient( 90deg, #E14663 , #5772A9 , #288AA2 )",
                    marginBottom: '15px'
                  }}
                /> */}
              </Grid>
              <Grid container className={classes.NewsBlock}>
               {!this.state.showData?.title ? <Grid >
                  <List key="newsList" className={`${classes.NewsList} ${classes.ListscrollBar}`}>
                    {this.state.allNewesData.map((item: any,index:number,arrayData:any) => {
                      return (
                        <Grid container>
                          <ListItem key={`newsListItem-${item.id}`} data-test-id="newsListItem" onClick={() => this.handleData(item)} className={classes.ActiveNews}>
                            <Grid id="newsImage" className={classes.ActiveNewsImage}>
                              {isArabic ? <img src={item?.content?.url} alt="NewsWrapImage" /> : <img src={item?.group?.content[0]?.url} alt="NewsWrapImage" />}
                            </Grid>
                            <Grid className={classes.NewsContent}>
                              <Grid className={classes.LeftContent}>
                                <Typography className={classes.Title}>{item?.title}</Typography>
                                <Typography className={classes.Description}>{item?.description}</Typography>
                              </Grid>
                              <Grid className={classes.ContentTop}>
                                <Typography className={classes.WhiteFont}>{moment(item?.pubDate).fromNow()}</Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                         {index !== (arrayData.length -1) && <Grid
                            item
                            xs={12}
                            style={{
                              height: '1.5px',
                              background: "linear-gradient( 90deg, #E14663 , #5772A9 , #288AA2 )",
                              marginBottom: '15px'
                            }}
                          />}
                        </Grid>
                      )
                    })}
                  </List>

                </Grid>
                 :
                <Grid className={`${classes.NewsRight} ${classes.NewsScrollBar}`}>
                  <Grid className={classes.WrapImage}>
                    {isArabic ? <img src={this.state.showData?.content?.url} alt="NewsWrapImage" /> : <img src={this.state.showData?.group?.content[0]?.url} alt="NewsWrapImage" />}
                  </Grid>
                  <Grid className={classes.BottomDescription}>
                  <Typography className={classes.Hours}>{moment(this.state.showData?.pubDate).fromNow()}</Typography>
                    <Typography className={classes.DesHeading}>{this.state.showData?.title}</Typography>
                    <Grid className={classes.NewsData} dangerouslySetInnerHTML={{ __html: this.state.showData?.description }}>
                    </Grid>
                  </Grid>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End 
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  ListscrollBar: {
    scrollbarWidth: 'thin',
    scrollbarColor: "transparent transparent",
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "#242830",
      minHeight: 24,
      minWidth: 24,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },
  NewsScrollBar: {
    scrollbarWidth: 'thin',
    scrollbarColor: "#242830 transparent",
    "&::-webkit-scrollbar": {
      width: 10,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      background: "#242830",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#242830",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },
  MainWrapper: {
    width:"100%"
  },
  ArrowIcon: {
    fontSize: "22px",
    transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
    color: theme.palette.primary.main,
  },
  NewsContainer: {
    // padding: "0 5vw 0 5vw"
  },
  NewsHeader: {
    padding: "30px 0 20px 0",
  width: "100%",
  borderBottom: "2px solid",
  borderImage: "linear-gradient( 90deg, #E14663 , #5772A9 , #288AA2 ) 1",
  },
  BackBlock: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    padding: '0px 30px'
  },
  Heading: {
    fontSize: "28px",
    lineHeight: "33px",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  NewsData: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    color: theme.palette.secondary.main,
    "& li": {
      margin: "0 0 16px 0"
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "13px",
    },

  },
  DesHeading: {
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
    margin: "0 0 24px 0",
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "20px",
    },

  },
  NewsBlock: {
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  NewsLeft: {
    maxWidth: "30%",
    width: "100%",
    margin: theme.direction === "rtl" ? "0 0 0 80px" : "0 80px 0 0",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      margin: "0 0 40px 0",
    },
  },
  NewsRight: {
    // maxWidth: "100%",
    width: "100%",
    maxHeight: "800px",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    },
  },
  NewsList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0",
    maxHeight: "800px",
    overflowY: "scroll",
    cursor : "pointer"
  },
  NewsListItem: {
    width: "100%",
    padding: "10px 0px",
    marginBottom: "16px",
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: "row",
    // background: theme.palette.background.gunmeta,
    // borderRadius: "10px",
    // border: `1px solid #212330`,
    cursor: "pointer",
    "&:last-child": {
      marginBottom: 0,
    }
  },
  ActiveNews: {
    width: "100%",
    padding: "10px 0px",
    // marginBottom: "16px",
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: "row",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    // background: theme.palette.type === "dark" ? "linear-gradient(90deg, #0097A8 0%, #6869AC 100%)" : "white",
    border: `1px solid ${theme.palette.type === "dark" ? "transparent" : "#212330"}`,
    boxShadow: theme.palette.type === "dark" ? "none" : "6px 6px 12px RGBA(0, 0, 0, 0.2)",
    position: "relative",
    borderRadius: 10,
    "&:last-child": {
      marginBottom: 0,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      backgroundImage: "linear-gradient(270deg, #121339 0%, #6869AC 100%)",
      borderRadius: 10,
      zIndex: -1
    }
  },
  NewsImage: {
    minWidth: "92px",
    minHeight: "110px",
    borderRadius: "6px",
    border: "0.5px solid #242830",
    boxShadow: "2px 2px 8px RGBA(0, 0, 0, 0.5)",
    overflow: "hidden",
    "& img": {
      width: "92px",
      height: "110px",
      display: "block",
      objectFit: "cover",
    }
  },
  ActiveNewsImage: {
    minWidth: "92px",
    minHeight: "110px",
    borderRadius: "6px",
    border: "0.5px solid #FFFFFF",
    boxShadow: "none",
    overflow: "hidden",
    "& img": {
      width: "92px",
      height: "110px",
      display: "block",
      objectFit: "cover",
    }
  },
  NewsContent: {
    width: "100%",
    margin: theme.direction === "rtl" ? "0 16px 0 0" : "0 0 0 16px",
    display: "flex",
    flexDirection: "column"
  },
  LeftContent: {
    flex: "1 1 0",
    width: "100%",
  },
  ContentTop: {
  },
  StatusTag: {
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: 2.4,
    textTransform: "uppercase",
    color: "#EA435D",
  },
  Hours: {
    fontSize: "12px",
    lineHeight: "12px",
    color: "#7A7B7F",
    textAlign: theme.direction === "rtl" ? "right" : "left",
    marginBottom: "10px"
  },
  WhiteFont: {
    fontSize: "12px",
    lineHeight: "12px",
    color: theme.palette.primary.main,
    textAlign: theme.direction === "rtl" ? "right" : "left",
    [theme.breakpoints.down("lg")]: {
      marginTop: "8px"
    },
  },
  Title: {
    fontSize: "18px",
    lineHeight: "25px",
    fontWeight: 700,
    color: theme.palette.primary.main,
    margin: "0 0 6px 0",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    wordBreak: "break-all",
    fontFamily: "Roboto"
  },
  Description: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.palette.secondary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    wordBreak: "break-all",
    fontFamily: "Roboto"
  },
  WrapImage: {
    width: "100%",
    height: "330px",
    borderRadius: "10px",
    overflow: "hidden",
    border: "0.5px solid RGB(36, 40, 48)",
    boxShadow: "2px 2px 8px RGBA(0, 0, 0, 0.5)",
    "& img": {
      display: "block",
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "270px",
    },
  },
  BottomDescription: {
    marginTop: "20px",
  },
  Readmore: {
    fontSize: '14px',
    color: '#288AA2',
    fontWeight: 700,
    lineHeight: "18px",
    cursor: "pointer",
  }
})
export default withStyles(styles)(withTranslation()(NewsWeb))
// Customizable Area End
