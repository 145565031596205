import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  history?:any
}

export interface S {
  // Customizable Area Start
  plansDetails:any;
  choosePreferenceDetails:any;
  coupon:any;
  applyCouponModal:any;
  couponApplied:boolean;
  loading:boolean;
  selectedUserID:any;
  coupounError:boolean;
  coupounErrorMessage:string;
  seletedCoupounID:any;
  selectedPlanDetail:any;
  appliedCouponSuccessMes:string;
  userName:any;
  userNameError:boolean;
  userNameErrorMes:any;
  userEdit:boolean;
  userModal:boolean;
  userPhoto:any;
  click:number;
  logout:boolean;
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  
  // Customizable Area End
}

export default class ChoosePreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChoosePreferenceApiCallId: string = "";
  postChoosePreferenceApiCallId : string='';
  applyPlansApiCallId:string='';
  getRefreshTokenApiCallId:string='';
  

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      plansDetails:[],
      choosePreferenceDetails:[],
      selectedUserID:[],
  coupon:'',
  coupounError:false,
  coupounErrorMessage:'',
  seletedCoupounID:'',
      applyCouponModal:null,
      couponApplied:false,
      loading:false,
      selectedPlanDetail:{},
      appliedCouponSuccessMes:'',
      userName:'',
  userNameError:false,
  userNameErrorMes:'',
  userEdit:false,
  userModal:false,
  userPhoto:{}, 
  click:1,
  logout:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getChoosePreference()
  }

  componentDidUpdate(){   
    let array:any = document.getElementsByClassName("slick-active")
    try{
    let array1:any = document.getElementsByClassName("slick-prev")
    let array2:any = document.getElementsByClassName("slick-next")
    console.log(array1)
    if(localStorage.getItem("darkMode")== "false"){
      array1[0].className +=" light_mode_button"
      array2[0].className +=" light_mode_button"
    }
    else{
      array[0].className =array[0].className.replaceAll("light_mode_button","")
    }
  }catch{

  } 
    if(array.length>0){
     if(array.length>=7){
        array[0].className =array[0].className.replaceAll("slick-center","")  + " slick-custom-first"    
        array[1].className = array[1].className.replaceAll("slick-custom-first","") + " slick-center"
        array[2].className = array[2].className.replaceAll("slick-custom-first","") + " slick-center"
        array[3].className = array[3].className.replaceAll("slick-custom-first","") + " slick-center"
        array[4].className = array[4].className.replaceAll("slick-custom-first","") + " slick-center"
        array[array.length-2].className = array[array.length-2].className.replaceAll("slick-center","")  + " slick-custom-first"       
        array[array.length-1].className = array[array.length-1].className.replaceAll("slick-center","") + " slick-custom-first"
      }
     if(array.length ==5){
      array[0].className =array[0].className.replaceAll("slick-center","")  + " slick-custom-first"  
      array[1].className = array[1].className.replaceAll("slick-custom-first","") + " slick-center"
      array[2].className = array[2].className.replaceAll("slick-custom-first","") + " slick-center"
      array[3].className = array[3].className.replaceAll("slick-custom-first","") + " slick-center"
      array[4].className = array[4].className.replaceAll("slick-center","")  + " slick-custom-first"
     }
     if(array.length ==4){
      array[0].className = array[0].className.replaceAll("slick-custom-first","") + " slick-center"
      array[1].className = array[1].className.replaceAll("slick-custom-first","") + " slick-center"
      array[2].className = array[2].className.replaceAll("slick-custom-first","") + " slick-center"
      array[3].className = array[3].className.replaceAll("slick-custom-first","") + " slick-center"
     }
     if(array.length <=3){
      array[0].className = array[0].className.replaceAll("slick-custom-first","") + " slick-center"
      array[1].className = array[1].className.replaceAll("slick-custom-first","") + " slick-center"
      array[2].className = array[2].className.replaceAll("slick-custom-first","") + " slick-center"      
     }    
     
    }
  
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );     

      if (apiRequestCallId === this.getChoosePreferenceApiCallId) {
        this.handleGetChoosePreferenceResponse(responseJson)
    }
    else if (apiRequestCallId === this.postChoosePreferenceApiCallId) {
      this.handlePostChoosePreferenceResponse(responseJson)    
  }
  else if (apiRequestCallId === this.getRefreshTokenApiCallId) {
   this.handleRefreshTokenResponse(responseJson)
  }    
    }   
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleGetChoosePreferenceResponse=(responseJson:any)=>{
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        if(responseJson?.error == "Not Authorized, Token Expired"){
          this.setState({loading:false})
         this.getRefreshToken()
        }
         this.setState({choosePreferenceDetails:[...responseJson?.data,...responseJson?.data,...responseJson?.data ], loading:false})
      } 
      else{
        this.setState({loading:false})
        let key = Object.keys(responseJson?.errors[0])[0]
        toast.error(responseJson?.errors[0][key]);     
         (responseJson?.errors[0]?.token || responseJson?.errors == "Not Authorized, Token Expired") && this.getRefreshToken()
            }
  }
  }

  handlePostChoosePreferenceResponse=(responseJson:any)=>{
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
         this.setState({ loading:false})
         this.props.navigation.navigate("DashboardWeb")

      } 
      else{
        this.setState({loading:false})
        let key = Object.keys(responseJson?.errors[0])[0]
        toast.error(responseJson?.errors[0][key])
      }
  }
  }

  handleRefreshTokenResponse=(responseJson:any)=>{
    if (responseJson != null) {
      if (!responseJson?.errors) {
        if(responseJson?.error == "Not Authorized, Refresh Token Expired"){
          this.setState({logout:true})
        }
        console.log("new token" , responseJson?.data?.token)
        if(responseJson?.data?.token){
        let exp_time:any = moment().add(23 , "hours").format("DD/MM/YYYY hh:mm a")
        localStorage.setItem("exp_time", exp_time)
        localStorage.setItem("Token",responseJson?.data?.token)
         window.location.reload()
      }
       }
      else { 
        this.setState({logout:true})
                  }
    }
  }
  
  handleToggle = (value: any) => () => {
    let valu = parseInt(value)
    const currentIndex = this.state.selectedUserID.indexOf(valu);
    const newChecked = [...this.state.selectedUserID];

    if (currentIndex === -1) {
        newChecked.push(valu);
    } else {
        newChecked.splice(currentIndex, 1);
    }
    this.setState({selectedUserID:newChecked});
};

  getChoosePreference() {
    this.setState({loading:true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
       token:localStorage.getItem("Token"),
       language:localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
      // token:'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTQwLCJleHAiOjE2NTYxNDU5NzgsInRva2VuX3R5cGUiOiJsb2dpbiJ9.hh_70GWosX6BSAJpLGAE2HB0kZGSDoP1hz1TjQaQ4_wshaKd-UUQOjLNdnr5YAOQliyRtxGSzaKaJTLHfd8ihQ'
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChoosePreferenceApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPerferenceEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  
  applySelectedPlan =(event:any)=>{
    this.setState({loading:true})

    const data ={
      profile_id: localStorage.getItem("selectedUser"),
    preferences:this.state.selectedUserID
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
       token:localStorage.getItem("Token")
          };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postChoosePreferenceApiCallId = getValidationsMsg.messageId;

    const httpBody = data;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPereferenceEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  handleLogout=()=>{
    localStorage.clear()
    this.setState({logout:false})
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  }

  getRefreshToken() {
    console.log("refresh token")
    const headers = {
      token: localStorage.getItem("refresh_token"),
    };

    let formData = new FormData();

    formData.append('device_unique_id', localStorage.getItem("deviceId") || ''); 

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRefreshTokenApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRefershTokenEndPoint
    );
   
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area End
}
