import React from "react";
// Customizable Area Start
import {
    Avatar,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Badge
} from "@material-ui/core";
import {
    withStyles,
    createStyles
} from "@material-ui/core/styles";

import {
    delivered,
    seen,
    sendIcon,
    sent,
} from "./assets";
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import InfiniteScroll from "react-infinite-scroll-component";
import LockRoundedIcon from '@material-ui/icons/LockRounded';

// Customizable Area End

export const themeCreatStyle = (theme: any) => {
    let themePalletStyle = (start: string, end: string) => theme.palette.type === "dark" ? start : end;
    let themeDirection = (arrValue: any, enValue: any) => theme.direction === "rtl" ? arrValue : enValue
    return {
        root: {
            "label + &": {
                marginTop: theme.spacing(1),
            },
        },
        user_tab_gird: {
            padding: "39px 39px 0",
            [theme.breakpoints.only("xs")]: {
                padding: "19px 19px 0px",
            },
            [theme.breakpoints.only("md")]: {
                padding: "30px 29px 0px ",
            },
            [theme.breakpoints.only("sm")]: {
                padding: "25px 25px 0px",
            },


        },
        typingFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        typingStyle: {
            fontFamily: "Roboto",
            color: "green",
            fontWeight: 400,
            wordBreak: 'break-word' as 'break-word',
            textAlign: 'left' as 'left',
            paddingLeft: 8
        },
        font_size_16: {
            [theme.breakpoints.only("xs")]: {
                fontSize: 14,
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 15.5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 14.5,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 16,
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: 16,
            },
        },
        font_size_12: {
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: 400,
            color: "#DCDCDC",
            [theme.breakpoints.only("xs")]: {
                fontSize: 10.5,
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 11.5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 11,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 12,
            },
        },
        font_size_20: {
            fontFamily: "Roboto",
            fontWeight: 700,
            wordBreak: 'break-all' as "break-all",
            fontSize: 20,
            [theme.breakpoints.only("xs")]: {
                fontSize: 17.5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 18.5,
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 19,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 20,
            },
        },
        textFiledCss: {
            fontFamily: "Robot",
            color: "#DCDCDC",
            lineHeight: 1,
            fontSize: 17,
            padding: "16px 20px",
            [theme.breakpoints.only("xs")]: {
                fontSize: 15.5,
                padding: "12.5px 16px",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 16.5,
                padding: "14px 17px",
            },
        },
        sendICons:{
        maxWidth: 18,
        maxHeight: 18
        },
        image_div: {
            width: '50px',
            height: '50px',
            marginRight: themeDirection(0, 15),
            marginLeft: themeDirection(15, 0),

            [theme.breakpoints.only("xs")]: {
                width: '38px',
                height: '38px',
                marginRight: themeDirection(0, 10),
                marginLeft: themeDirection(10, 0),
            },

            [theme.breakpoints.only("sm")]: {
                width: '42px',
                height: '42px',
                marginRight: themeDirection(0, 10),
                marginLeft: themeDirection(10, 0),
            },

        },
        image_div2: {
            width: '40px',
            height: '40px',
            [theme.breakpoints.only("xs")]: {
                width: '34px',
                height: '34px',
            },
            [theme.breakpoints.only("sm")]: {
                width: '36px',
                height: '36px',

            },

        },

        font_size_14: {
            fontFamily: "Roboto",
            fontWeight: 400,
            wordBreak: 'break-all' as 'break-all',
            fontSize: 14,
            [theme.breakpoints.only("xs")]: {
                fontSize: 12.5,
            },
            [theme.breakpoints.only("md")]: {
                fontSize: 13.5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: 13,
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: 14,
            },
        },
        PPTextField: {
            backgroundColor: '#141426', 
    borderRadius: '30px',
    padding: '0 10px',
        },
        user_header: {
            padding: "0 0px 20px",
            borderBottomWidth: '1px',
  borderBottomStyle: 'solid' as const,
  borderImage: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
  borderImageSlice: 1,
            [theme.breakpoints.only("xs")]: {
                padding: "0 0px 15px",
            },
        },
        infintyScrollStyle: {
            display: 'flex',
            flexDirection: 'column-reverse' as 'column-reverse',
            width: '100%',
            height: '100%',
            overflow: 'hidden' as 'hidden'
        },
        flexDiv: {
            display: "flex",
            flexDirection: "column" as const
        },
        scrollBar: {
            height: 'calc(100vh - 429px)',
            marginTop: '5px',
            width: '100%',
            overflowY: 'auto' as 'auto',
            display: 'flex',
            flexDirection: 'column-reverse' as 'column-reverse',
            maxHeight: 'calc(100vh - 430px)',
            [theme.breakpoints.only("xs")]: {
                height: 'calc(100vh - 329px)',
                maxHeight: 'calc(100vh - 330px)',
            },
            [theme.breakpoints.only("md")]: {
                height: 'calc(100vh - 380px)',
                maxHeight: 'calc(100vh - 390px)',
            },
            [theme.breakpoints.only("sm")]: {
                height: 'calc(100vh - 380px)',
                maxHeight: 'calc(100vh - 390px)',
            },
            [theme.breakpoints.only("lg")]: {
                height: 'calc(100vh - 429px)',
                maxHeight: 'calc(100vh - 430px)',
            },
            scrollbarColor: "#242830 transparent",
            "&::-webkit-scrollbar-thumb": {
                borderRadius: 6,
                background: "#242830",
                minHeight: 24,
                minWidth: 24,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar": {
                width: 10,
                height: 6,
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb:focus": {
                background: "#242830",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                background: "#242830",
            },
            "&::-webkit-scrollbar-thumb:active": {
                background: "#242830",
            },
            "&::-webkit-scrollbar-corner": {
                backgroundColor: "transparent",
            },
        },
        image_css: {
            position: 'relative' as 'relative',
            marginRight: themeDirection(7, 0),
            marginLeft: themeDirection(0, 7),
        },
        receiverMes: {
            fontFamily: "Roboto",
            fontWeight: 400,
            wordBreak: 'break-word' as 'break-word',
            padding: '8px 16px 9px 16px ',
            background: "#141E26",
            color: "#EDDBDB",
            borderRadius: themeDirection('0 8px 8px', "8px 0px 8px 8px"),
            [theme.breakpoints.only("xs")]: {
                padding: '7px 12px 8px 12px '               
            },
            [theme.breakpoints.only("sm")]: {
                padding: '7.5px 14px 8.5px 14px '
            },
            margin: '6px 0'
        },
        senderMes: {
            margin: '6px 0',
            fontFamily: "Roboto",
            fontWeight: 400,
            wordBreak: 'break-word' as 'break-word',
            padding: '8px 16px 9px 16px ',
            background: "#141426",
            borderRadius: themeDirection('8px 0 8px 8px', "0px 8px 8px"),
            [theme.breakpoints.only("sm")]: {
                padding: '7.5px 14px 8.5px 14px '
            },
            [theme.breakpoints.only("xs")]: {
                padding: '7px 12px 8px 12px '
            },
        },
        flexStyle: {
            display: 'flex'
        },
        paddingStyle: {
            padding: 8
        },
        receiverFlex: {
            display: 'flex',
            justifyContent: 'flex-end' as const,
            alignItems: 'flex-end' as const,
            textAlign: "end" as const
        },
        receiverDiv: {
            position: 'relative' as 'relative',
            marginRight: '3px'
        },
        recieverImageDiv:{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column" as const,
            padding: themeDirection("0 10px 0 0", "0 0 0 10px"),
            height: '100%'
        },
        SendIcon: {
            cursor: 'pointer',
            height: 51,
            width:51,
            marginRight: themeDirection(10, 0),
            marginLeft: themeDirection(0, 10),
            transform: themeDirection("rotate(270deg)", "none"),
            [theme.breakpoints.only("xs")]: {
                height: 44,
            width:44,
            },
        },
        emoji_picker: {
            paddingRight: themeDirection(14, 0),
            paddingLeft: themeDirection(0, 'auto'),
            marginRight: 0
        },
        formStyles:{
            width: '100%',
            marginBottom: 0
        },
        formDiv:{
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            margin: '10px 0'
        },
        inputStyles:{
                color: "#757A8C",
                fontFamily: "Roboto",
                opacity: 1,
                borderRadius: 10,
        }

    }
}

const StyledBadge = withStyles((theme: any) =>
    createStyles({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }),
)(Badge);

// Customizable Area End

import ChatScreenController, {
    Props,
} from "./ChatScreenController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import Picker from 'emoji-picker-react';
import { withTranslation } from "react-i18next";

 export class ChatScreen extends ChatScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes, t }: any = this.props;
        const deliverImage = (_data: any) => { return _data.deliveredAt ? <img src={delivered} className={classes.sendICons}/> : <img src={sent} className={classes.sendICons}/> }
        const imageToShow = (_data: any) => { return _data.readAt ? <img src={seen} className={classes.sendICons} /> : deliverImage(_data) };
        // Customizable Area Start
        return (
            <Grid container className={classes.user_tab_gird}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Grid item xs={12} className={classes.user_header}>
                    <Grid container justifyContent="space-between">
                        <Grid item style={{display:"flex"}}>
                            <Avatar
                                src={this.userAvatar()}
                                alt="moive_poster"
                                className={classes.image_div}
                            />
                            <Grid item className={classes.flexDiv}>
                                <Typography
                                    color="primary"
                                    className={classes.font_size_20}
                                >
                                    {this.state.friendDeatils?.attributes?.name}
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    className={classes.font_size_14}
                                >
                                    {this.checkStatus()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} className={classes.scrollBar}
                    id="scrollableGrid"
                >
                    <InfiniteScroll
                        className={classes.infintyScrollStyle}
                        inverse={true}
                        dataLength={this.state.messageList.length}
                        next={this.fetchMoreMessage}
                        hasMore={this.state.hasMore}
                        loader={this.state.hasMore}
                        scrollThreshold="200px"
                        scrollableTarget="scrollableGrid"                        
                    >
                        <Grid container >
                            {
                                this.state.messageList.map((_data: any) => {
                                    return (
                                        <Grid key={_data.id} item xs={12} className={classes.paddingStyle}>
                                            <Grid container spacing={2} style={{
                                                justifyContent: _data.receiverId === this.state.userId ? 'flex-start' : 'flex-end'
                                            }}>
                                                {_data.receiverId === this.state.userId ? <Grid item xs={8} className={classes.flexStyle}>
                                                    <StyledBadge
                                                        overlap="circular"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        variant={this.checkVariant()}
                                                    >
                                                        <Avatar
                                                            src={this.userAvatar()}
                                                            className={classes.image_div2}
                                                        />
                                                    </StyledBadge>
                                                    <div
                                                        className={classes.image_css}>
                                                        <Typography
                                                            color="primary"
                                                            className={classes.font_size_14}
                                                        >
                                                            {this.state.friendDeatils?.attributes?.name}
                                                        </Typography>
                                                        <Typography
                                                            color="primary"
                                                            className={`${classes.font_size_16} ${classes.senderMes}`}
                                                        >
                                                            {_data?.data?.text ? _data?.data?.text : t("chat.ST")}
                                                        </Typography>
                                                        <Typography
                                                            className={classes.font_size_12}
                                                        >
                                                            {this.timeStringToDate(_data.sentAt)}
                                                        </Typography>

                                                    </div>
                                                </Grid> :
                                                    <Grid item xs={8} className={classes.receiverFlex}>
                                                        <div className={classes.receiverDiv}>
                                                        <Typography
                                                            color="primary"
                                                            className={classes.font_size_14}
                                                        >
                                                            {t("chat.you")}
                                                        </Typography>
                                                            <Typography
                                                                className={`${classes.font_size_16} ${classes.receiverMes}`}
                                                            >
                                                                {_data?.data?.text ? _data?.data?.text : t("chat.DM")}
                                                            </Typography>
                                                            <Typography
                                                            className={classes.font_size_12}
                                                        >
                                                            {this.timeStringToDate(_data.sentAt)}
                                                        </Typography>
                                                        </div>
                                                        <div className={classes.recieverImageDiv}>
                                                        <Avatar
                                                            src={this.state.userImage}
                                                            className={classes.image_div2}
                                                        />
                                                        {imageToShow(_data)}
                                                        </div>

                                                    </Grid>}
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                            {
                                this.state.typing && <Grid item xs={8} className={classes.typingFlex} >
                                    <Typography
                                        className={`${classes.font_size_16} ${classes.typingStyle}`}
                                    >
                                        {t("chat.typing")}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </InfiniteScroll>
                </Grid>
                <form data-test-id="submit" onSubmit={(event: any) => this.onSendIcon(event)} className={classes.formStyles}>
                    <Grid item xs={12} className={classes.formDiv} >
                        <TextField
                        data-test-id="change"
                            fullWidth
                            placeholder={t("chat.ST")}
                            className={classes.PPTextField}
                            inputRef={this.inputRef}
                            variant="standard"
                            value={this.state.message}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChangeText(event)}
                            inputProps={{ className: classes.textFiledCss }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                    data-test-id="click"
                                        position="start"
                                        className={classes.emoji_picker}>
                                        <InsertEmoticonOutlinedIcon style={{
                                            cursor: 'pointer',
                                            color: "#DCDCDC"
                                        }}                                            
                                            onClick={() => this.setState({ showEmojiPicker: true })} />

                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                               className:classes.inputStyles
                                
                                
                            }}
                        />
                        <img 
                        src={sendIcon}
                        data-test-id="send"
                            className={classes.SendIcon}
                            onClick={(event: any) => this.onSendIcon(event)} />
                    </Grid>
                    {this.state.showEmojiPicker &&
                        <Picker data-test-id="emoji" onEmojiClick={(emojiObject:any , event:any)=>{this.onPickerChange(event)}}
                            pickerStyle={{
                                position: 'absolute',
                                bottom: '50',
                                boxShadow: 'none'
                            }}
                        />
                    }
                </form>
            </Grid>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(ChatScreen));
// Customizable Area End
