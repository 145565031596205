import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/Utility";

// Customizable Area Start
// import { URLSearchParams } from "url";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    searchText: string;
    searchTxt: string,
    searchList: any;
    activeId: number;
    activeFirstName: string;
    activeLastName: string;
    activeUserName: string;
    activeEmail: string;
    activePhoneNumber: string;
    activeCountryCode: string;
    activeType: string;
    activeDeviceId: string;
    activeCreatedAt: string;
    isVisible: boolean;
    drawerOpen: boolean;
    selectedFilter: any[];
    searchMovie: string;
    profileId: any;
    movieImage: any;
    getMovieData: any[],
    getSeriesData: any[],
    getPodcastData: any[],
    category: any[];
    langauage: any;
    showFilterData: boolean;
    showSearchData: boolean;
    ratings: number[];
    genreList: any[];
    filterMovieData: any[];
    applyFilter: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SearchControllerWeb extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    searchApiCallId: any;
    getMovieListAPICallId: string = '';
    getFilterListAPICallId: string = '';
    getGenreAPICallId: string = '';
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            searchText: "",
            searchTxt: "",
            searchList: [],
            activeId: 0,
            activeFirstName: "",
            activeLastName: "",
            activeUserName: "",
            activeEmail: "",
            activePhoneNumber: "",
            activeCountryCode: "",
            activeType: "",
            activeDeviceId: "",
            activeCreatedAt: "",
            isVisible: false,
            drawerOpen: false,
            selectedFilter: [],
            searchMovie: '',
            movieImage: [],
            getMovieData: [],
            getSeriesData: [],
            getPodcastData: [],
            category: [],
            langauage: "en",
            showFilterData: false,
            showSearchData: false,
            ratings: [1, 5],
            genreList: [],
            filterMovieData: [],
            applyFilter: false,
            profileId: localStorage.getItem("selectedUser")
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        this.getGenreList();
        // Customizable Area End
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            runEngine.debugLog("API Message Recived", message);
            // Genre List API Response
            if (this.getGenreAPICallId === apiRequestCallId) {
                this.handleGetGenre(apiResponse)
            }
            else // Movie List API Response
                if (this.getMovieListAPICallId === apiRequestCallId) {
                    this.handleGetMoviesList(apiResponse)
                }
        }

        // Customizable Area End
    }
    // Customizable Area Start

    handleGetGenre = (apiResponse: any) => {
        if (apiResponse?.errors) {
            console.log(apiResponse?.errors)
        } else {
            if (apiResponse) {
                this.setState({
                    genreList: apiResponse?.data
                })
            }
        }
    }

    handleGetMoviesList = (apiResponse: any) => {
        if (apiResponse?.errors) {
            console.log(apiResponse?.errors)
        } else {
            if (apiResponse) {
                if (!apiResponse?.data) {
                    if (this.state.applyFilter || this.state.searchMovie.length !== 0) {
                        this.updateSearchData(apiResponse)
                    }
                    else {
                        this.clearSearchData()
                    }
                }
            }
        }
    }

    assestLength = (searchData: any) => {
        return searchData?.length !== 0;
    }

    updateSearchData = (apiResponse: any) => {
        this.setState({
            getMovieData: apiResponse?.movies?.data && this.assestLength(apiResponse.movies.data) ? apiResponse.movies.data : [],
            getSeriesData: apiResponse?.series?.data && this.assestLength(apiResponse.series.data) ? apiResponse.series.data : [],
            getPodcastData: apiResponse?.podcasts?.data && this.assestLength(apiResponse.podcasts.data) ? apiResponse.podcasts.data : []
        }, () => {
            this.setState({ showSearchData: true })
        })
    }

    clearSearchData = () => {
        this.setState({ getMovieData: [], getSeriesData: [], getPodcastData: [] })
    }

    handleChangeRatings = (event: any, newValue: number | number[]) => {
        this.setState({
            ratings: newValue as number[]
        })
    }
    handleSearchMovie = (e: any) => {
        this.setState({
            showSearchData: false,
            showFilterData: false,
            searchMovie: e.target.value
        }, () => {
            if (!this.state.applyFilter && this.state.searchMovie.length == 0) {
                this.setState({ getMovieData: [], getSeriesData: [], getPodcastData: [] })
            } else {
                this.state.searchMovie.length >= 3 && this.getMovieList()
            }
        })
    }

    movieNavigateHandler = (id: any, type: any) => {
        this.props.navigation.navigate("MovieDetails", { id })
    }

    podcastNavigateHandler = (id: any, type: any) => {
        this.props.navigation.navigate("PodcastDetails", { id })
    }

    handleDrawerOpen = () => {
        this.setState({
            searchMovie: ""
        })
        this.clearSearchData()
    };

    handleDrawerClose = () => {
        this.setState({
            drawerOpen: false
        })
    };

    handleFilterChips = (d: any) => {
        const temp = this.state.selectedFilter
        const include = temp.includes(d)

        if (include) {
            const removefilters = temp.filter((i) => i !== d)
            this.setState({
                selectedFilter: removefilters,
                category: removefilters
            })
        } else {
            this.setState({
                selectedFilter: [...temp, d],
                category: [...this.state.category, d]
            })
        }
    };

    onClickCancelButton = () => {
        this.setState({ drawerOpen: false, applyFilter: false, category: [], selectedFilter: [] },
            () => this.getMovieList())
    }

    onClickContinueButton = () => {
        this.setState({ applyFilter: true, drawerOpen: false }, () => this.getMovieList())
    }

    // Get Movie list API
    getMovieList = (search?: any) => {

        let endpoint = `${configJSON.movieListAPIEndPoints}?profile_id=${this.state.profileId}&`
        if (this.state.searchMovie !== '' && this.state.searchMovie.length !== 0) {
            endpoint += `search=${this.state.searchMovie}&`
        }
        if (this.state.category.length !== 0) {
            for (const category of this.state.category) {
                endpoint += `genre[]=${category}&`;
            }
        }
        if (this.state.applyFilter) {
            endpoint += `rating[min]=${this.state.ratings[0]}&rating[max]=${this.state.ratings[1]}&`
        }

        if (this.state.applyFilter || this.state.searchMovie.trim() !== "") {
            this.getMovieListAPICallId = sendAPIRequest(
                endpoint,
                {
                    headers: {
                        token: localStorage.getItem('Token'),
                        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
                    }
                }
            )
        } else {
            this.setState({ getMovieData: [], getSeriesData: [], getPodcastData: [] })
        }
    }
    // Genre List API
    getGenreList = () => {
        this.getGenreAPICallId = sendAPIRequest(
            configJSON.genreListEndPoints,
            {
                headers: {
                    token: localStorage.getItem('Token'),
                    language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
                }
            }
        );
    }
    // Customizable Area End
}
