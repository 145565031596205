import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { sendAPIRequest } from "components/src/Utility";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  showData: any;
  token: string;
  allNewesData: any;
  showPassword: boolean,
  handleHideShow: boolean,
  deleteAccount: boolean,
  logOut: boolean,
  password: any,
  passErrMsg: string;
  passError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AcDeleteDialogController extends BlockComponent<Props, S, SS> {

  apiNewsId: string = "";
  deleteAccountApiCallId: string = "";
  logOutApiCallId: string = '';
  deleteFirebaseTokenApiCallId: string = "";
  getUserPasswordCallId: string = "";
  deleteUserProfileCallId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      loading: false,
      showData: {},
      allNewesData: [],
      token: "",
      showPassword: false,
      handleHideShow: true,
      deleteAccount: false,
      logOut: false,
      password: '',
      passErrMsg: "",
      passError: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.logOutApiCallId) {
        this.handlelogOutResponse(responseJson)
      }

      if (apiRequestCallId === this.deleteAccountApiCallId) {
        responseJson && this.logOut()
        // responseJson && this.logOut()
      }

      if (apiRequestCallId === this.getUserPasswordCallId) {
        this.handleGetUserPasswordResponse(responseJson)
      }
      // else if (apiRequestCallId === this.deleteUserProfileCallId) {
      //   this.handleDeleteUserProfileResponse(responseJson)
      // }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  // Customizable Area Start

  handleGetUserPasswordResponse = (responseJson: any) => {
    const incorrectPassErrorMsg = this.props.t("settingDialogWeb.incorrectPassErrorText");
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        this.setState({ loading: false });
        this.deleteAccount()
      }
      else {
        this.setState({ loading: false })
        let key = Object.keys(responseJson?.errors[0])[0]
        console.log(key)
        this.setState({
          passError: true,
          // passwordError: true,
          // passwordErrorMes: this.props.t("loginScreen.PR"),
          passErrMsg: incorrectPassErrorMsg,
        });
        // toast.error(responseJson?.errors)
      }
    }
  }

  handleDeleteUserProfileResponse = (responseJson: any) => {
    if (responseJson != null) {
      console.log("JSON NOt NUll")
      if (!responseJson.errors) {
        this.setState({ loading: false })
        // toast.success(this.props.t("favourite.DS"))
        toast.success("delete successfully")
        // this.props.onSuccessFullyDeleted()

      }
      else {
        this.setState({ loading: false })
        let key = Object.keys(responseJson?.errors[0])[0]
        console.log(key)
        toast.error(responseJson?.errors[0][key])

      }
    }
  }

  deleteAccount1 = () => {
    this.setState({ handleHideShow: false });
  }

  deleteAccount = async () => {

    this.setState({ loading: true, deleteAccount: false });
    let accountId = localStorage.getItem("User_Id")
    this.deleteAccountApiCallId = sendAPIRequest(
      `${configJSON.deleteAccountApiEndPoint}/${accountId}`,
      {
        method: configJSON.methodTypeApiDelete,
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      },
    );
  }

  handlelogOutResponse = async (responseJson: any) => {
    if (responseJson) {
      let darkMode = localStorage.getItem("darkMode") || "true"
      let deviceUniqueId = await getStorageData("deviceId")
      let lang = localStorage.getItem("i18nextLng") || "en"
      localStorage.clear()
      toast.success(responseJson.message)
      localStorage.setItem("darkMode", darkMode)
      localStorage.setItem("i18nextLng", lang)
      await setStorageData("deviceId", deviceUniqueId)
      this.setState({ loading: false, handleHideShow: false });
      this.props.navigation.navigate("EmailAccountLoginBlockWeb")

    }
  }

  deleteFirebaseToken = async (token: any, firebaseToken: any) => {
    let formData = new FormData();
    formData.append('token', firebaseToken);
    this.deleteFirebaseTokenApiCallId = sendAPIRequest(
      `${configJSON.deleteFirebaseTokenEndPoint}`,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token,
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      },
    );
    console.log("deleteApi")
  }

  logOut = async () => {
    this.setState({ loading: true, logOut: false });
    await this.deleteFirebaseToken(localStorage.getItem("Token"), localStorage.getItem("firebasetoken"));
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');
    this.logOutApiCallId = sendAPIRequest(
      `${configJSON.logoutEndPoint}`,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      },
    );
  }

  handleClickShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });


  getUserPassword(e: any) {
    e.preventDefault()
    const passwordErrorMsg = this.props.t("settingDialogWeb.errorText");
    let isValid: boolean = true

    if (this.state.password === null || this.state.password.length === 0) {
      
      this.setState({
        passError: true,
        passErrMsg: passwordErrorMsg,
      });
      isValid = false
    }
    if (isValid) {
      console.log('test password is valid and api working');
      this.setState({ loading: true })
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("Token"),
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUserPasswordCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserPasswordEndPoint + `?password=${this.state.password}`
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value, 'check onchange console');

    if (event.target.name == "password") {
      this.setState({  passError: false });
    }

    this.setState({
      password: event.target.value,
    });
  }
  // Customizable Area End
}
