import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  movieDataProps?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  swiper: any;
  open: any;
  isMatch: any;
  isMatch1: any;
  profileDialog: boolean;
  showNotification: boolean;
  navOnScroll: boolean;
  moviesData: any;
  movieID: any;
  assetID: any;
  moviesLoading: boolean;
  bandData: any[];
  categoryId: any;
  continueWatchData: any[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getMoviesCallId: string = "";
  getCategoryBandAPICallId: string = "";
  getContinueWatchDataAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      swiper: null,
      imgIndex: null,
      open: false,
      isMatch: '',
      isMatch1: '',
      profileDialog: false,
      showNotification: false,
      navOnScroll: false,
      moviesData: [],
      movieID: '',
      assetID: '',
      moviesLoading: false,
      bandData: [],
      categoryId: '',
      continueWatchData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    
    const categoryId = this.props?.navigation?.getParam('id');
    categoryId == 'UserSetting' ? this.setState({ profileDialog: true }) : this.setState({ categoryId }, () => {
      this.onInitialized();
    })

    // Customizable Area End
  }

  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );  
     
      // Band API response
      if (this.getCategoryBandAPICallId === apiRequestCallId) {        
       this.handleGetCategoryBandResponse(responseJson)
      }
      if (this.getContinueWatchDataAPICallId === apiRequestCallId) {       
        this.handleGetContinueResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // handers

  handleGetCategoryBandResponse=(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({ loading: false })
    } else {
      if (responseJson) {
        this.setState({
          moviesData: responseJson?.thumbnail_contents?.data,
          moviesLoading: false,
          bandData: responseJson?.bend_contents,
        }, () => {
          this.setState({ loading: false })
        })
      }
    }
  }

  handleGetContinueResponse =(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({ loading: false })
    } else {
      if (responseJson) {         
        const filteredDat = responseJson?.data ? responseJson?.data : []
        this.setState({
          continueWatchData: filteredDat
        }, () => {
          this.setState({ loading: false })
        })
      }
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { categoryId } = prevState;
    const currentCategoryId = this.props?.navigation?.getParam('id');
    if (currentCategoryId !== "UserSetting") {
      (categoryId !== currentCategoryId) && this.setState({ categoryId: currentCategoryId }, () => {
        this.onInitialized();
      });
    }
  }

  onInitialized = () => {
    this.getCategoryBands();
    this.getContinueWatchData();
  }
  handleClose = () => {
    this.setState({ profileDialog: false }, () => { })
    if (window.location.href.toLowerCase().includes('dashboardweb/setting_menu')) {
      this.props.navigation.navigate("DashboardWeb")
    }
  }

  handleNotification = () => {
    this.setState({
      showNotification: true
    })
  }

  closeDialog = () => {
    this.setState({
      showNotification: !this.state.showNotification
    })
  }

  previewHandler = (ref: any) => {
    ref.slickPrev();
  };

  nextHandler = (ref: any) => {
    ref.slickNext();
  };

  navHandler = (contentId: any, type: any) => {
    const { navigation } = this.props;
    type == "AudioPodcast" ? navigation.navigate(`PodcastDetails`, { id: contentId }) : navigation.navigate(`MovieDetails`, { id: contentId });
  }

  // Band API

  getCategoryBands = () => {
    this.setState({ moviesLoading: true})
    const { categoryId } = this.state;
    const categoryParam = categoryId !== undefined && categoryId !== "" ? `&category_id=${categoryId}` : '';
    this.getCategoryBandAPICallId = sendAPIRequest(
      `${configJSON.bandDataAPIEndPoints}?profile_id=${localStorage.getItem("selectedUser")}${categoryParam}`,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      }
    );
  }

  getContinueWatchData = () => {
    this.setState({ loading: true })
    this.getContinueWatchDataAPICallId = sendAPIRequest(
      `${configJSON.getContinueWatchDataAPIEndPoints}?profile_id=${localStorage.getItem("selectedUser")}`,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      }
    );
  }
  // Customizable Area End

}
