import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface MemberProfileInter {
  id: string,
  attributes: {
    name: string, 
    id: number,
    account_id: number,
    photo: string
  }
}

export interface Props {
  navigation: any;
  id: string;
  classes:any;
  t: any;
}

export interface S {
  // Customizable Area Start
  
  memberProfileDetails:MemberProfileInter[];
  loading:any;
  memberId: number;
  memberType: string;
  isEdit: boolean;
  
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  
  // Customizable Area End
}

export default class MemberProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMemberDetailsApiCallId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      memberProfileDetails:[],      
      loading:false,
      memberId: 0,
      memberType: '',
      isEdit: false
      
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
 
  async componentDidMount() {
    super.componentDidMount();
    this.getMemberProfile()
    
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
       let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );     
  
        if (apiRequestCallId === this.getMemberDetailsApiCallId) {
            if (!responseJson.errors) {
                this.setState({ memberProfileDetails: responseJson?.data, loading: false })                
            }
        }
      }
   
    // Customizable Area End
  }

  // Customizable Area Start
  
  
  onUserSelect = (_data: MemberProfileInter, index: number) =>{
    let isOwner =  index ===0
    this.setState({
      memberId: isOwner ? _data.attributes.account_id : _data.attributes.id,
      memberType: isOwner ? "Owner" : "member",
      isEdit: true
    })
  }
  changeEditToMember = () =>{
    this.setState({
      memberId: 0,
      memberType: "",
      isEdit: false
    })
  }
  changeEdit=()=>{
   this.changeEditToMember()
    this.getMemberProfile()
  }

 async getMemberProfile() {
    this.setState({ loading: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("Token")
    };
  
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMemberDetailsApiCallId = getValidationsMsg.messageId;
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileEndPoint
    );
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
   // Customizable Area End
}
