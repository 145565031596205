import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  Button,
  FormHelperText
} from "@material-ui/core";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  modalBackground,
} from "./assets";
import CloseIcon from '@material-ui/icons/Close';

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({

  redeem_button_css: {
    boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,
    backgroundColor: `rgba(234,67,93, 1)`,
    color: `${theme.palette.type == "dark" ? '#FFF' : "#000000"}`,
    borderRadius: 10,
    fontWeight: 700,
    opacity: 0.9,
    fontSize: 18,

    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 18,
    },
    "&:hover": {
      opacity: 0.9,
      backgroundColor: `rgba(234,67,93, 1)`,
    },
    "&:disabled": {
      opacity: 0.5,
      color: `white`,
    },
  },
  forrmInput_textfield: {
    width: "100%",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16.5,
    },
  },

  textStyle: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 21,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  textFiledCss: {
    fontFamily: "Araboto",
    color: theme.palette.primary.main,
    lineHeight: 1,
    fontSize: 20,
    padding: "18px 20px",

    [theme.breakpoints.only("lg")]: {
      fontSize: 20,
      padding: "18px 20px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 16.5,
      padding: "14.5px 16px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 19,
      padding: "17px 18.5px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 17.5,
      padding: "16px 17px",
    },
  },
  textFiledCss1: {
    lineHeight: 1,
    fontSize: 20,
    color: "#757A8C",
    fontFamily: "Roboto",
    opacity: 1,
  },
  font_size_16: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 14.5,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },

  dialogBackground: {
    background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
    borderRadius: 8,
    padding: "42px 40px 41px",
    [theme.breakpoints.only("xs")]: {
      padding: "38px 30px 37px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "40px 35px 39px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "42px 40px 41px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "42px 40px 41px",
    },
    scrollbarColor: "#B7B7B7 transparent",
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
      minHeight: 24,
      minWidth: 24,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },
  outer_div: {
    background: "transparent",
    maxWidth: 600

  },

  dialogBlur: {
    borderRadius: 8, 
    backdropFilter: "blur(7px)",
    direction: theme.direction
  },


  text_field_div: {
    margin: '36px 0px 40px',
    [theme.breakpoints.only("xs")]: {
      margin: '20px 0px 25px',
    },
    [theme.breakpoints.only("md")]: {
      margin: '36px 0px 40px',
    },
    [theme.breakpoints.only("sm")]: {
      margin: '26px 0px 30px',
    },

  },
});
// Customizable Area End

import DeleteProfileController, { Props } from "./DeleteProfileController.web";
import Spinner from "./shared/Spinner.web";
export class DeleteProfile extends DeleteProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    const { t }: any = this.props
    // Customizable Area Start
    return (
      <>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Dialog
          PaperProps={{
            className: `${classes.outer_div}`,
          }}
          classes={{ scrollPaper: classes.topScrollPaper }}
          aria-labelledby="customized-dialog-title"
          open={this.props.deleteDialog}
          onClose={() => this.props.onCloseDeleteDialog()}
          closeAfterTransition
          fullWidth={true}
          disableAutoFocus={true}
          className={classes.dilaogBlur}          
        >
          <div
            className={classes.dialogBackground}
            style={{
              overflowY: "auto",
            }}
          >
            <Grid container>
              <CloseIcon color="primary"
                style={{
                  cursor: "pointer",
                  fontSize: 30,
                  position: "absolute",
                  top: 10,
                  right: localStorage.getItem("i18nextLng") === "ar" ? "auto" : "10",
                  left: localStorage.getItem("i18nextLng") === "ar" ? "10" : "auto",
                }}
                data-test-id="closeButton"
                onClick={() => this.props.onCloseDeleteDialog()}
              />
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: 'center'

                }}
              >

                <Typography
                  style={{
                    fontWeight: 700,
                    fontFamily: 'Araboto',
                    textAlign: 'center'
                  }}
                  className={classes.textStyle}
                  color="primary"
                >
                  {t("deleteprofile.confirmpass")}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontFamily: 'Araboto',
                    marginTop: 10,
                    textAlign: 'center'
                  }}
                  className={classes.font_size_16}
                  color="primary"
                >
                  {t("deleteprofile.entercurrpass")}
                </Typography>

              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} className={classes.text_field_div}>
                <TextField
                  data-test-id="password"
                  id="standard-basic"
                  placeholder={t("loginScreen.password")}
                  type={this.state.showPassword ? "text" : "password"}
                  className={classes.forrmInput_textfield}
                  style={{ textAlign: "start" }}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.onChangePassword(event)
                  }
                  value={this.state.password}
                  // error={this.state.passwordError}
                  helperText={<StyledFormHelperText>{this.state.passwordErrorMes}</StyledFormHelperText>}
                  inputProps={{
                    className: classes.textFiledCss, 
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ marginRight: "0" }}
                      >
                        <img
                          src={!this.state.showPassword ? imgPasswordInVisible : imgPasswordVisible}
                          onClick={this.handleClickShowPassword}
                         style={{
                            width: "22px",
                            marginRight: "0px",
                            cursor: 'pointer',
                            filter: localStorage.getItem("darkMode") == "false" ? 'invert(1)' : "none"
                          }}
                        />
                      </InputAdornment>
                    ),
                    className: `${classes.textFiledCss1}`,
                    style: {
                      color: "#757A8C",
                      fontFamily: "Araboto",
                      opacity: 1,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                fullWidth
                variant="contained"
                className={classes.redeem_button_css}
                data-test-id="checkPassword"
                onClick={(e: any) => {
                  this.getUserPassword(e);
                }}
                style={{ textTransform: "none", maxWidth: '80%' }}
              >
                <Typography
                  style={{
                    textTransform: "none",
                    fontSize: "1.125rem",
                    fontWeight: 600,
                  }}
                >
                  {t("deleteprofile.deleteAccount")}
                </Typography>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(DeleteProfile);
// Customizable Area End
