import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

// Customizable Area Start
const html2pdf = require('html2pdf.js/dist/html2pdf');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: any;
    expanded: any;
    transactionData: any;
    selectedHistory: any;
    download: boolean;
    currentLang: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class TransactionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getTransactionHistoryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            loading: false,
            expanded: false,
            transactionData: {},
            selectedHistory: {},
            download: false,
            currentLang: localStorage.getItem('i18nextLng')
        }
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTransactionHistory()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getTransactionHistoryApiCallId) {
                if (responseJson) {
                    this.setState({ transactionData: responseJson })
                }
                this.setState({ loading: false })
            }
        }
        // Customizable Area End
        runEngine.debugLog("Message Recived", message);
    }

    // Customizable Area Start

    getTransactionHistory() {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("Token"),
            language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTransactionHistoryApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTransactionHistoryEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    setSelectedHistory = async (_data: any) => {
        const {transactionData} = this.state
        const userData = JSON.parse(localStorage.getItem('user_data') || '{}');
        this.setState({ selectedHistory: { ..._data, userData: userData , 
            method_in_arabic : transactionData.payment_method_in_arabic,
            mode_in_arabic: transactionData.payment_mode_in_arabic }, download: true }, () => {
            this.generatePdf()
        })
    }

    checkForLang = (inEnglish: string , inArabic: string) =>{
        return this.state.currentLang === 'en' ? inEnglish : inArabic
    }

    generatePdf = () => {
        let checkPdf = document.getElementById("invoicePdf")
        if (checkPdf !== null) {
            const opt = {
                margin: [0, 0, 0, 0],
                filename: "invoice.pdf",
                html2canvas: { scale: 1 },
                jsPDF: { unit: "pt", format: "a4", orientation: "p" }
            }
            //save the pdf
            html2pdf().from(checkPdf).set(opt).save()
        }
        this.setState({download: false})
    }

    dateFormat = (_date: string) => {
        return moment(_date, "YYYY-MM-DD").format("DD MMM, YYYY")
    }
    // Customizable Area End
}
