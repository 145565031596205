import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  showData: any;
  token: string;
  allNewesData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NewsController extends BlockComponent<Props, S, SS> {
  apiNewsId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      loading: false,
      showData: {},
      allNewesData: [],
      token: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiNewsId) {
        this.handleNewsApiData(responseJson);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getRegionalNewsData()
  }

  // Customizable Area Start

  async getRegionalNewsData() {
    // Customizable Area Start

    this.setState({ loading: true })

    const header = {
      token: localStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiNewsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiNews + `?language=${localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  historyBack = () => {
    this.setState({showData :{}})
  }

  handleData = (title: any) => {
    this.setState({
      showData: title
    });
  }

  handleNewsApiData = (responseJson:any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        allNewesData: responseJson.result.sort((a:any ,b:any)=> a?.pubDate - b?.pubDate)  
            });
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      let key = Object.keys(responseJson.errors[0])[0];
      toast.error(responseJson.errors[0][key]);
    }
  }
  // Customizable Area End
}
