import React, { Component } from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Dialog,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { profile_icon, profile_icon_light } from "./assets";

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);
// Customizable Area End

// Customizable Area Start
interface Props {
  addEditUserDialog: boolean;
  userPhoto: any;
  handleAddEditUserDialogClose: any;
  isEditUser: boolean;
  userName: string;
  userNameError: any;
  userNameErrorMsg: string;
  onChangeUserName: any;
  onBlurUserName: any;
  addEditUserClick: any;
  classes: any;
  handleImageClick: any;
  kidsProfile: boolean;
  onChecked: any;
  t: any
}

export class AddEditUser extends Component<Props> {
  render() {
    const {
      addEditUserDialog,
      handleAddEditUserDialogClose,
      userName,
      userNameError,
      userNameErrorMsg,
      onChangeUserName,
      onBlurUserName,
      addEditUserClick,
      classes,
      userPhoto,
      handleImageClick,
      kidsProfile,
      onChecked,
      t
    } = this.props;

  let localStorageValue = (arrValue: any, enValue: any) => localStorage.getItem("darkMode") == "true" ? arrValue : enValue


    return (
      <Dialog
        data-test-id="close_"
        PaperProps={{
          className: `${classes.outer_div}`,
        }}
        aria-labelledby="customized-dialog-title"
        open={addEditUserDialog}
        onClose={() => handleAddEditUserDialogClose()}
        closeAfterTransition
        fullWidth={true}
        disableAutoFocus={true}
        style={{
          borderRadius: 8, backdropFilter: "blur(4px)",
          direction: localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
        }}
      >
        <div
          className={classes.backgroundImage_div}
          style={{
            overflowY: "auto",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{

                  fontWeight: 500,

                  fontFamily: 'Araboto'
                }}
                color="primary"
                className={classes.font_Size_28}
              >
                {t("choose_profile.ANP")}
              </Typography>

            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
              className={classes.image_div}
            >

              <input
                id="contained-button-file"
                style={{ display: "none" }}
                type="file"
                accept="image"
                onChange={handleImageClick}
              />
              <label htmlFor="contained-button-file">
                <img src={userPhoto?.name && userPhoto?.size ? URL.createObjectURL(userPhoto) : localStorageValue(profile_icon, profile_icon_light)} className={classes.image_css} alt="profile" />
              </label>

            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontFamily: 'Araboto'
                }}
                color="primary"
                className={classes.font_size_22}>
                {t("choose_profile.UPP")}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.text_field_div}>
              <TextField
                id="standard-basic"
                placeholder={t("choose_profile.name")}
                className={classes.forrmInput_textfield}
                style={{ textAlign: "start" }}
                variant="outlined"
                value={userName}
                onChange={onChangeUserName}
                onBlur={onBlurUserName}
                // error={userNameError}
                helperText={<StyledFormHelperText>{userNameErrorMsg}</StyledFormHelperText>}

                inputProps={{ className: classes.textFiledCss }}
                InputProps={{
                  style: {
                    color: "#757A8C",
                    fontFamily: "Araboto",
                    opacity: 1,
                  },
                  className: `${classes.inputBackground}`
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
              <FormControlLabel
                color="secondary"
                control={
                  <Checkbox
                    checked={kidsProfile}
                    onChange={onChecked}
                    name="includingNagativeMark"
                    style={{
                      color: "#E9445E",
                    }}
                  />
                }
                label={t("choose_profile.KP")}
              />
            </Grid>
          </Grid>

          <div>
            <Button
              fullWidth
              variant="contained"
              data-test-id="edit_user"
              className={classes.redeem_button_css}
              onClick={(e: any) => {
                addEditUserClick(e);
              }}
              disabled={userName == '' || userName.length == 0}
              style={{ textTransform: "none" }}
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: "1.125rem",
                  fontWeight: 600,
                }}
              >
                {t("choose_profile.submit")}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}


export const themeCreatStyle = (theme: any) => {

  let themeDirection = (arrValue: any, enValue: any) => theme.palette.type == "dark" ? arrValue : enValue
  return {
    redeem_button_css: {
      boxShadow: `-10px 24px 80px rgba(26,27,34, 1)`,

      backgroundColor: `rgba(234,67,93, 1)`,
      color: theme.palette.primary.main,
      borderRadius: 10,
      fontWeight: 700,
      opacity: 0.9,
      marginTop: 20,
      fontSize: 18,

      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      "&:hover": {
        opacity: 0.9,
        backgroundColor: `rgba(234,67,93, 1)`,
      },
      "&:disabled": {
        opacity: 0.5,
        color: theme.palette.primary.main,
      },
    },
    inputBackground: {
      backgroundColor: themeDirection('rgba(33, 35, 48, 1)', '#D3D3D3'),
    },
    forrmInput_textfield: {
      width: "100%",
      borderRadius: "10px",
      color: theme.palette.primary.main,
      fontFamily: "Araboto",
      fontSize: 20,
      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
      },
      "& p": {
        textAlign: theme.direction === "rtl" ? "right" : "left"
      },
    },
    textFiledCss: {
      fontFamily: "Araboto",
      color: theme.palette.primary.main,
      lineHeight: 1,
      fontSize: 20,
      padding: "18px 20px",

      [theme.breakpoints.only("lg")]: {
        fontSize: 20,
        padding: "18px 20px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
        padding: "14.5px 16px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 19,
        padding: "17px 18.5px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 17.5,
        padding: "16px 17px",
      },
    },
    backgroundImage_div: {
      borderRadius: 12,
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.9) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.9) 100%)",
      height: "100%",
      padding: "42px 40px 41px",
      [theme.breakpoints.only("xs")]: {
        padding: "38px 30px 37px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "40px 35px 39px",
      },
      [theme.breakpoints.only("lg")]: {
        padding: "42px 40px 41px",
      },
      [theme.breakpoints.only("md")]: {
        padding: "42px 40px 41px",
      },
      scrollbarColor: '#B7B7B7 transparent',
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 6,
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 90%)',
        minHeight: 24,
        minWidth: 24,
      },
      '&::-webkit-scrollbar-thumb:focus': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent',
      },
    },
    outer_div: {
      background: "transparent",
      width: 440,
      [theme.breakpoints.only("xs")]: {
        width: "90%",
      },
      [theme.breakpoints.only("sm")]: {
        width: 430,
      },
      [theme.breakpoints.only("lg")]: {
        width: 440,
      },
      [theme.breakpoints.only("md")]: {
        width: 440,
      },
    },
    font_Size_28: {
      fontSize: 28,
      [theme.breakpoints.only("xs")]: {
        fontSize: 22,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 26,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 24,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 28,
      },
    },
    font_size_22: {
      fontSize: 22,
      [theme.breakpoints.only("xs")]: {
        fontSize: 16.5,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 18.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 22,
      },
    },
    image_div: {
      margin: '53px 0 30px',
      [theme.breakpoints.only("xs")]: {
        margin: '25px 0 15px',
      },
      [theme.breakpoints.only("md")]: {
        margin: '50px 0 17px',
      },
      [theme.breakpoints.only("sm")]: {
        margin: '45px 0 23px',
      },
      [theme.breakpoints.only("lg")]: {
        margin: '53px 0 30px',
      },
    },
    text_field_div: {
      marginTop: '64px',
      [theme.breakpoints.only("xs")]: {
        marginTop: '30px'
      },
      [theme.breakpoints.only("md")]: {
        marginTop: '56px'
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: '45px'
      },
      [theme.breakpoints.only("lg")]: {
        marginTop: '64px'
      },
    },
    image_css: {
      borderRadius: '50%',
      border : '1px solid #E9445E',
      [theme.breakpoints.only("xs")]: {
        width: 100, height: '100px',
      },
      [theme.breakpoints.only("md")]: {
        width: 135, height: '135px',
      },
      [theme.breakpoints.only("sm")]: {
        width: 120, height: '120px',
      },
      [theme.breakpoints.only("lg")]: {
        width: 150, height: '150px',
      },
      [theme.breakpoints.up("lg")]: {
        width: 150, height: '150px',
      },
    }
  }
};
export default withStyles(themeCreatStyle)(withTranslation()(AddEditUser));
// Customizable Area End