import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Dialog,
  Typography,
  TextField
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { add_icon } from "./assets";
import AddAdvertisementsController from "./AddAdvertisementsController";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

export class AddAdvertisements extends AddAdvertisementsController {
  render() {
    const {
      bannerDialog,
      bannerName,
      bannerUrl,
      bannnerDescription,
      bannerImage,
      isEdit,
      } = this.state;
       let imageIcon = bannerImage!== "" ? bannerImage : add_icon 
      let imageUrl =typeof bannerImage !== 'string' && Object.prototype.toString.call(bannerImage) === '[object File]'?  URL.createObjectURL(bannerImage) :  imageIcon
    return (
      <Dialog
        data-test-id="close_"
        PaperProps={{
          style:styles.outer_div
        }}
        aria-labelledby="customized-dialog-title"
        open={bannerDialog}
        onClose={this.handlebannerDialogClose}
        closeAfterTransition
        fullWidth={true}
        disableAutoFocus={true}
        style={{
          borderRadius: 8, backdropFilter: "blur(24px)",

        }}
      >
        <div
          style={styles.backgroundImage_div}         
        >
          <Grid container>
            <Grid
              item
              xs={12}
              style={styles.image_div}
            >

              <input
              data-test-id="adImage"
                id="contained-button-file"
                style={{ display: "none" }}
                type="file"
                accept="image"
                onChange={this.onChangeBannerImage}
              />
              <label htmlFor="contained-button-file">
                <img src={imageUrl} style={styles.image_css} alt="profile" />
              </label>

            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
              >
                {configJSON.uploadBannerImageText}
              </Typography>
            </Grid>
            <Grid item xs={12} style={styles.text_field_div}>
              <TextField
              data-test-id="adName"
                id="standard-basic"
                placeholder={configJSON.nameText}
                style={styles.forrmInput_textfield}
               variant="outlined"
                value={bannerName}
                onChange={this.onBannnerNameChange}
                inputProps={{ style:styles.textFiledCss }}
                InputProps={{
                  style:styles.inputBackground
                }}
              />
            </Grid>
            <Grid item xs={12} style={styles.text_field_div}>
              <TextField
              data-test-id="adUrl"
                id="standard-basic"
                placeholder={configJSON.URLText}
                style={styles.forrmInput_textfield}
                variant="outlined"
                value={bannerUrl}
                onChange={this.onChangeBannerUrl}
                inputProps={{ style:styles.textFiledCss }}
                InputProps={{
                  style:styles.inputBackground
                }}
              />
            </Grid>
            <Grid item xs={12} style={styles.text_field_div}>
              <TextField
                id="standard-basic"
                data-test-id="adDes"
                placeholder={configJSON.descriptionText}
                style={styles.forrmInput_textfield}
                variant="outlined"
                minRows={3}
                maxRows={3}
                value={bannnerDescription}
                onChange={this.onChangeBannerDescription}
                inputProps={{ style:styles.textFiledCss }}
                InputProps={{
                  style:styles.inputBackground
                }}
              />
            </Grid>

          </Grid>

          <div>
            <Button
              fullWidth
              data-test-id="add_edit_ads"
              style={styles.redeem_button_css}
              onClick={() => {
                this.addBanner();
              }}
              >
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: "1.125rem",
                  fontWeight: 600,
                }}
              >
                {isEdit ? configJSON.editBannerText : configJSON.addBannerText}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}


const styles = {
  redeem_button_css: {
    boxShadow: `-5px 12px 20px rgba(26,27,34, 1)`,
    backgroundColor: `rgba(234,67,93, 1)`,
    color: "white",
    borderRadius: 10,
    fontWeight: 700,
    opacity: 0.9,
    marginTop: 20,
    fontSize: 18,
    textTransform: "none" as const,
    "&:hover": {
      opacity: 0.9,
      backgroundColor: `rgba(234,67,93, 1)`,
    },
    "&:disabled": {
      opacity: 0.5,

    },
  },
  inputBackground: {
    backgroundColor: '#D3D3D3',
  },
  forrmInput_textfield: {
    width: "100%",
    borderRadius: "10px",
    textAlign: "start" as const
  },
  textFiledCss: {
    fontFamily: "Araboto",
    lineHeight: 1,
    fontSize: 18,
    padding: "18px 20px",
    color: "#382e2e"
  },
  backgroundImage_div: {
    background: "white",
    borderRadius: 12,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "100%",
    padding: "38px 30px 37px",
    overflowY:"auto" as const,
    scrollbarColor: '#B7B7B7 transparent',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 6,
      background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 90%)',
      minHeight: 24,
      minWidth: 24,
    },

  },
  outer_div: {
    background: "transparent",
    width: 440,    
  },

  image_div: {
    margin: '0px 0 10px',
    display: "flex", 
    justifyContent: "center"

  },
  text_field_div: {
    marginTop: 15
  },
  image_css: {
    border: '1px dashed black',    
      width: 135, height: '135px',
  
  }
}

export default withStyles(styles)(AddAdvertisements);
// Customizable Area End