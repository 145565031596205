export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_hprizontal_line = require("../assets/image_hprizontal_line.png");
export const line_horizontal = require("../assets/line_horizontal.png");
export const backgroundImage = require("../assets/background.png");
export const FaqItemBg = require("../assets/faqBg.png");
export const ArrowIcon = require("../assets/arrowIcon.png");
export const DragIcon = require("../assets/file_image_.png");
export const PdfIcon = require("../assets/pdf-icon.png");
export const backarrow = require("../assets/backarrow.png");
export const backgroundImagePng = require("../assets/Group_2.png");
export const moreIcon = require("../assets/more.png");
export const moive1 = require("../assets/moive1.png");
export const moive2 = require("../assets/moive2.png");
export const downloadIcon = require("../assets/downloadIcon.png");
export const moive3 = require("../assets/moive3.png");
export const moive4 = require("../assets/moive4.png");
export const favIcon = require("../assets/favIcon.png");
export const Logo = require("../assets/Logo.png");
export const border = require("../assets/border.png");
export const rightArrow = require("../assets/arrowRight.png");
export const backgroundPDF = require("../assets/backgroundPDF.png");
export const bg = require("../assets/bg.png")
export const removeIcon = require("../assets/removeIcon.png")
export const moreSetting = require("../assets/more-Setting.png")
export const transactions = require("../assets/transactions.png")
export const watchHistory = require("../assets/watch-history.png")
export const switchProfile = require("../assets/switch-profile.png")
export const settings = require("../assets/settings.png")
export const privacyPolicys = require("../assets/privacy-policys.png")
export const planDetails = require("../assets/plan-details.png")
export const myProfile = require("../assets/my-profile.png")
export const manageDevices = require("../assets/manage-devices.png")
export const helpFaq = require("../assets/help-faq.png")
export const deleteAccount = require("../assets/delete-account.png")
export const aboutus = require("../assets/about-us.png")
export const closeIcon = require("../assets/closeIcon.png")
export const myProfileIcon = require("../assets/myProfile.png")
