import React from "react";
import { Box, Grid, withStyles } from "@material-ui/core";

import DrawerWeb from "./DrawerWeb.web";
import FooterComponent from "./Footer.web";
import DashboardLayoutController from "./DashboardLayoutController.web";
import SessionExp from "./SessionExp.web";
import { backgroundNew } from "./assets";


export class DashboardLayout extends DashboardLayoutController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, navigation, children } = this.props;
    return (
      <Box className={this.state.isGeneralSettingPage ? classes.generalBox : classes.box_css}>
        {this.state.logout && (
          <SessionExp
            data-test-id="SessionExp"
            deleteModal={this.state.logout}
            onCloseModal={this.handleLogout}
          />
        )}
        <div className={classes.pageContainer}>
          <div style={{ display: "flex" }}>
            {!this.state.isStatic &&
              <Box style={{ position: this.state.isDetailsPage ? 'absolute' : "relative" }} className={classes.header}>
                <DrawerWeb navigation={navigation} id="" />
              </Box>
            }
          </div>
         <Grid item xs={12} className={classes.mainContainer} >
          {children}
        </Grid>      
          
        </div>
        {!this.state.isStatic &&
          <Box>
            <FooterComponent navigation={navigation} id=""/>
          </Box>
        }
      </Box>
    );
  }
}
export const ThemeCreateStyle: any = (theme: any) => ({
  box_css: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "#07070E"
  },
  generalBox: {
    backgroundImage: `url(${backgroundNew})`,
    maxWidth: "100%",
    width: "100vw",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column"    
  },
  pageContainer: {
    position: "relative",
    flex: 1,
  },
  header: {
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 4,
    backgroundColor: "transparent", /* Make header transparent */
  },
  mainContainer: {
    positon: "relative",
  }


});

export default withStyles(ThemeCreateStyle)(DashboardLayout);
