import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?: any
  // Customizable Area Start
  handleChangeTab: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: any;
  files: any;
  formData: any;
  email: string;
  isGuestTicket: boolean;
  emailError: boolean;
  titleError: boolean;
  descError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RaiseTicketController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiRasiseTicketId: string = "";
  emailValidateReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      files: [],
      formData: {
        ticketTitle: "",
        ticketDescription: "",
        uplodaData: []
      },
      email: '',
      isGuestTicket: false,
      emailError: false,
      titleError: false,
      descError: false
    }

    // Customizable Area Start
    this.emailValidateReg = /^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i;
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    let isGuest = await getStorageData("isGuestUser");
    if ((await isGuest) === "true") {
    this.setState({ isGuestTicket: true });
   }

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("Message Recived", message);

      if (apiRequestCallId === this.apiRasiseTicketId) {
        if (responseJson && responseJson.data) {
          toast.success(this.props.t("ticketraise.successMsg"))
          this.setState({
            loading: false
          });
          this.props.handleChangeTab("faq");
          this.setState({
            formData: {
              ticketTitle: "",
              ticketDescription: "",
              uplodaData: []
            }
          })
          if (Object.keys(responseJson.data).length !== 0) {
            console.log(responseJson?.data)
          }
        }
      }
      // Customizable Area End
    }
  }

  handleTicketForm = (e: any) => {
    e.preventDefault();
    if(this.state.email === ''){
      this.setState({ emailError: true})
    }
    if(this.state.formData.ticketTitle === ''){
      this.setState({ titleError: true})
    }
    if(this.state.formData.ticketDescription === ''){
      this.setState({ descError: true})
    }
    if (this.state.formData.ticketTitle && this.state.formData.ticketDescription) {
      this.postTicketApi();
    }
  }

  handleFormData = (inputName: any, inputValue: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [inputName]: inputValue
      }
    })
    if(inputName=='ticketDescription'){
      this.setState({descError:false})
    }
    if(inputName=='ticketTitle'){
      this.setState({titleError:false})
    }

  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value , emailError: false});
  }

  postTicketApi = () => {
    if(this.state.isGuestTicket && (!this.emailValidateReg.test(this.state.email))){
      this.setState({emailError: true})
      return
    }
    this.setState({
      loading: true
    })
    let header = {};
    if (localStorage.getItem("Token")) {
      header = {
        token: localStorage.getItem("Token")
      };
    }

    let formData = new FormData();
    this.state.isGuestTicket && formData.append('email', this.state.email);
    formData.append('data[attributes][title]', this.state.formData.ticketTitle);
    formData.append('data[attributes][description]', this.state.formData.ticketDescription);
    if (this.state.formData.uplodaData[0] !== undefined) {
      formData.append('data[attributes][attachment]', this.state.formData.uplodaData[0]);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRasiseTicketId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.raiseTicketEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  cancelChanges = (e: any) => {
    e.preventDefault();
    this.setState({
      files: [],
      formData: {
        ticketTitle: "",
        ticketDescription: "",
        uplodaData: []
      }
    })
  }

  onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 1) {
      toast.error("Please select only one file");
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          uplodaData: acceptedFiles
        }
      })
    }
  }

  // Customizable Area Start
  // Customizable Area End
}
