// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  RegisterTitle,
  RegisterTitlear,
} from "./assets";

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);
// Customizable Area End
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { withTranslation } from "react-i18next";
import { newLogo } from "../../dashboard/src/assets";
import CustomCountryCodePicker from "../../email-account-login/src/customCountryCodePicker";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { homeIcon, languageChange } from "../../email-account-login/src/assets";
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";
export class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  render() {
    // Customizable Area Start
    const { email, password, fullName, confrimPassword, phone, isTermAllowed } = this.state;
    const { classes, t }: any = this.props;
    return (
      // Required for all blocks
      <Grid container>
        {this.state.showLoader && (
          <Spinner
            data-test-id="spinner"
            spinnerModal={this.state.showLoader}
          />
        )}
        <Grid item xs={12} className={classes.RegContainer}>
          <img data-test-id="HomeButton" className={classes.RegBack} src={homeIcon} onClick={()=>this.navToHome("LandingPage")}/>
          <Grid container className={classes.mainGrid}>
            <Grid
              item
              className={classes.Reggrid2}
            >
            <Grid container justifyContent="center">
            <img className={classes.registerLogo} src={newLogo} />
            </Grid>
              <Grid container className={classes.form_container}>
              <Typography
                  className={classes.heading}
                  color="secondary"
                >
                  {t("loginScreen.subheading")}
                </Typography> 
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    data-test-id="fullName"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    name="fullname"
                    value={fullName}
                    onChange={this.handleChangefullName}
                    onBlur={this.validatefullName}
                    // error={this.state.fnameerror}
                    helperText={<StyledFormHelperText>{this.state.fnameerrorText}</StyledFormHelperText>}
                    placeholder={t("registerScreen.FN")}
                    className={classes.RegallTextField}
                    InputProps={{
                      classes: { input: classes["placeHolderTypeEmail"] },
                      
                    }}
                  />
                </Grid>               
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    data-test-id="phone"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    name="phone"
                    value={phone}
                    onChange={this.handleChangePhone}
                    onBlur={this.validatePhone}
                    // error={this.state.phoneerror}
                    helperText={<StyledFormHelperText>{this.state.phoneerrorText}</StyledFormHelperText>}
                    placeholder={t("registerScreen.mobile")}
                    className={classes.RegallTextField}
                    InputProps={{
                      
                      classes: { input: classes["placeHolderTypePassword"] },
                      startAdornment: (
                        <InputAdornment position="start" className={classes.phoneAdornment}>
                          {this.state.countryArr.length > 0 && <CustomCountryCodePicker
                            {...this.props}
                            countryList={this.state.countryArr}
                            selectedCountryCode={this.state.selectionCode}
                            selectCountryCode={this.selectCountryCode}
                          />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    data-test-id="email"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    name="email"
                    value={email}
                    onChange={this.handleChangeEmail}
                    onBlur={this.validateEmail}
                    // error={this.state.emailerror}
                    helperText={<StyledFormHelperText>{this.state.emailerrorText}</StyledFormHelperText>}
                    placeholder={t("registerScreen.email")}
                    className={classes.RegallTextField}
                    InputProps={{
                      
                      classes: { input: classes["placeHolderTypeEmail"] },
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    data-test-id="password"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={password}
                    onChange={this.handleChangePassword}
                    onBlur={this.validatePassword}
                    // error={this.state.pswderror}
                    helperText={<StyledFormHelperText>{this.state.pswderrorText}</StyledFormHelperText>}
                    placeholder={t("registerScreen.password")}
                    className={classes.RegallTextField}
                    InputProps={{
                      
                      classes: { input: classes["placeHolderTypeEmail"] },
                      style: {
                        color: "#fff",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: "0" }}
                        >
                          <img
                            data-test-id="show_pass"
                            onClick={this.showPasswordFn}
                            src={
                              !this.state.showPassword
                                ? imgPasswordInVisible
                                : imgPasswordVisible
                            }
                            style={{
                              width: "22px",
                              marginRight: "0px",
                              cursor: "pointer",
                              filter:
                                localStorage.getItem("darkMode") == "false"
                                  ? "invert(1)"
                                  : " none",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    data-test-id="confrimPassword"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    name="confrimPassword"
                    type={this.state.showConfrimPassword ? "text" : "password"}
                    value={confrimPassword}
                    onChange={this.handleChangeConfrimPassword}
                    onBlur={this.validateConfrimPassword}
                    // error={this.state.confpswderror}
                    helperText={<StyledFormHelperText>{this.state.confpswderrorText}</StyledFormHelperText>}
                    placeholder={t("loginScreen.cpassword")}
                    className={classes.RegallTextField}
                    InputProps={{
                      
                      classes: { input: classes["placeHolderTypeEmail"] },
                      style: {
                        color: "#fff",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: "0" }}
                        >
                          <img
                            data-test-id="show_confPass"
                            onClick={this.showConfrimPasswordFn}
                            src={
                              !this.state.showConfrimPassword
                                ? imgPasswordInVisible
                                : imgPasswordVisible
                            }
                            style={{
                              width: "22px",
                              marginRight: "0px",
                              cursor: "pointer",
                              filter:
                                localStorage.getItem("darkMode") == "false"
                                  ? "invert(1)"
                                  : " none",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>                
               <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                 fullWidth
                    variant="contained"
                    className={classes.RegButton}
                    type="submit"
                    onClick={this.SignUpFormValidation}
                  >
                    {t("registerScreen.register")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.login}>
                      <Typography >
                        {t("loginScreen.ahaa")}
                        <span
                          data-test-id="nav_login"
                          onClick={this.navigateToLogin}
                          className={classes.navigatorBtn}
                        >
                          {t("loginScreen.login")}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}                    
                    />
              </Grid>

            </Grid>
            </Grid>
            <Grid container className={classes.Footer}>
              <Typography className={classes.tAndC} color="secondary">{t("registerScreen.t&CBox")}<span className={classes.footerHeading} onClick={()=>this.navToHome("PrivacyPolicy")}>{t("registerScreen.privacyPolicy")}</span> {t("registerScreen.and")}<span className={classes.footerHeading} onClick={()=>this.navToHome("TermsAndConditions")}>{t("registerScreen.tOU")}</span></Typography>
              </Grid>
          </Grid>
        </Grid>
      // </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export const styles: any = (theme: any) => {

  let themeDirection = (arrValue: any, enValue: any) => theme.direction == "rtl" ? arrValue : enValue
  return {
    RegContainer: {
      display: "flex" , 
    justifyContent: "space-around" , 
    flexDirection : "column" , 
    minHeight: '100vh',
    padding: '10px 30px'
  },
  
  tAndC : {
    fontFamily: "Araboto",
fontSize: "16px",
fontWeight: "400",
lineHeight: "18px",
textAlign: "center"

  },
  Footer:{
    margin: "25px 0",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin : '20px 0'
    },
    [theme.breakpoints.down("sm")]: {
      margin : '15px 0'
    }
  },
  footerHeading:{
    fontWeight: 700,
    color: "#248CA1",
    cursor: "pointer"
      },
   registerLogo:{
      maxWidth: "120px",
    width: "auto",
    backgroundColor: "transparent",
    aspectRation : "1/1",
     marginBottom: 20,
    },
    phoneAdornment:{
       margin: themeDirection('auto 20px', 'auto 20px auto 10px' )
    },
    RegBack: {
      width: "32px",
      position: "absolute",
      cursor: 'pointer',
      top: 25,
      color:"rgba(234, 67, 93, 1)",
      left: themeDirection("auto", 30),
      right: themeDirection(30, "auto"), 
    },
    mainGrid: {
      display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    margin: "40px 0",
    [theme.breakpoints.down("md")]: {
      margin : '30px 0'
    },
    [theme.breakpoints.down("sm")]: {
      margin : '20px 0'
    }
    },
    heading: {
      fontWeight: 700,
      marginBottom: 25,
      fontFamily: "Roboto",
      textAlign: "center",
      width: "100%",
      lineHeight: '27px',
      fontSize: 20,
      padding: "0 15px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 15,     
        fontSize: '16px',
      }
    },
    RegImg: {
      maxWidth: "60%",
      width: "auto",
      maxHeight: "423px",
      height: "auto",
      backgroundColor: "transparent",
    },
    Regheader1: {
      width: "auto",
      height: "auto",
      fontStyle: "normal",
      fontWeight: "bold",
      marginBottom: 25,
      maxWidth: "100%",
      maxHeight: "55px",
      filter: `${theme.palette.type == "dark" ? "none" : "invert(1)"}`,

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    checkboxContainer:{
      paddingBottom: "20px"
    },
    Regheader2: {
      // color: "rgba(231, 233, 240, 0.8)",
      width: "90%",
      marginBottom: 25,
      fontWeight: 400,

      [theme.breakpoints.down("md")]: {
        marginBottom: 30,
        fontSize: 20,
        width: "100%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      },
      [theme.breakpoints.between("md", "md")]: {
        width: "90%",
      },
    },
    placeHolderTypeEmail: {
      color: theme.palette.primary.main,
      "&::placeholder": {
        color: theme.palette.primary.main,
      },
    },

    placeHolderTypePassword: {
      borderRight: themeDirection('1px solid #504D4D' , 'none'),
      borderLeft: themeDirection('none','1px solid #504D4D'),
      paddingRight: themeDirection('14px' , '0'),
      paddingLeft: themeDirection('0' , '14px'),
      "&::placeholder": {
        color: theme.palette.primary.main,
      },
    },
    RegallTextField: {
      width: "100%",
      // height: "54px",
      borderRadius: 10,
      alignSelf: "center",
      //  backgroundColor: `${theme.palette.type == "dark" ? "rgba(33, 35, 48, 1)" : '#D3D3D3'}`,
      fontFamily: "Araboto",
      fontSize: 14,
      color: theme.palette.primary.main,
      marginBottom: 20,
      maxWidth: "100%",
      [theme.breakpoints.only("sm")]: {
        // height: "50px",
        marginBottom: 10,
      },
      [theme.breakpoints.between("sm", "sm")]: {
        // width: '500px'
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        marginBottom: 10,
      },
      "& .helperText": {
        color: 'white',
        fontStyle: 'italic'
      }
    },
    RegpasswordTextField: {
      width: "360px",
      height: "54px",
      borderRadius: 10,
      alignSelf: "center",
      backgroundColor: "rgba(33, 35, 48, 1)",
      fontFamily: "Araboto",
      fontSize: 14,
    },
    navigatorBtn: {
      color: "#EA435D",
      fontWeight: 700,
      fontSize: 16,
      padding: '0px 6px',
      cursor: "pointer"
    },
    RegiconsGrid: {
      margin: "auto",
      width: "100%",
      marginBottom: 15,
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "500px",
      },
      [theme.breakpoints.between("md", "md")]: {
        width: "354px",
      },
    },
    Regicons: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "60%",
      height: "7vh",
      border: "0.1px solid  grey",
      borderRadius: 10,
      padding: 3,
    },
    Reggrid2: {
      margin: "auto",
      maxWidth: '600px'
    },
    login: {
      width: "100%",
      textAlign: "center",
      color: "#A1A1A1",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      }
    },
    RegButton: {
      width: "100%",
      height: "48px",
      alignSelf: "center",
      backgroundColor: "rgba(234, 67, 93, 1)",
      justifyContent: "center",
      marginBottom: 20,
      borderRadius: 10,
      maxWidth: "100%",
      opacity: 0.9,
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: "rgba(234, 67, 93, 1)",
      },
      "&:disabled" : {
        opacity: 6,
        backgroundColor: "rgba(234, 67, 93, 1)",
      },
    },
    RegcontinueLines: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "360px",
      marginBottom: 20,
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "500px",
        margin: "auto",
        marginBottom: 30,
      },
    },
    countryCode: {
      top: "50%",
      left: "50%",
      width: "50%",
      height: "70%",
      transform: "transalat(-50%,-50%)",
      [theme.breakpoints.only("xs")]: {
        width: "90%",
        height: "80%",
      },
      [theme.breakpoints.only("sm")]: {
        width: "60%",
        height: "70%",
      },
    },
    RegsocialBoxes: {
      display: "flex",
      justifyContent: "space-between",
      width: "360px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      // lineBreak:'anywhere',
    },
    RegsocialButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: "0.5px solid #979797",
      borderRadius: 8,
      opacity: 1,
      width: 110,
      height: 40,
      fontSize: 14,
      [theme.breakpoints.only("xs")]: {
        fontSize: "12",
        width: 100,
      },
    },
    RegcountryMenu: {
      marginLeft: -300,
      marginTop: 60,
      width: 390,
      height: 400,
      [theme.breakpoints.down("lg")]: {
        marginLeft: -120,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: -200,
        width: "100%",
        height: 300,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        width: "100%",
        height: 300,
      },
    },
    RegcountryButton: {
      marginLeft: -60,
      marginTop: 9,
      background: "transparent",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        // position : 'absolute',
        marginLeft: -70,
        marginTop: 12,
      },
    },
    iconButton: {
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    IconImg: {
      width: 16,
      height: 20,
      [theme.breakpoints.down("sm")]: {
        width: 12,
        height: 14,
      },
    },
    apply_coupon_modal: {
      // height: "80%",
      // padding: "20px 25px 30px 25px",
      width: 320,
      // borderRadius: 10,
      // backgroundImage: `url(${modalBackground})`,
      backgroundColor: "#fff",
      // backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      boxShadow: "-6px 8px 25px rgba(0,0,0, 0.4)",
      padding: 0.5,
      [theme.breakpoints.only("xs")]: {
        width: "80%",
        maxWidth: 320,
      },
    },
    form_container: {
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
    padding: "40px 45px",
    borderRadius: "8px",
    [theme.breakpoints.only("xs")]: {
      padding: "25px 20px",
    },
    },
    image_direction: {
      transform: themeDirection("scaleX(-1)" , "none"), 
      margin: themeDirection("0 0 0 20px" , "0 20px 0 0"),
    },
  };
};
// Customizable Area End
export default withStyles(styles)(
  withTranslation()(EmailAccountRegistrationWeb)
);
