import React, { Component } from "react";
import {
  Box,
  Typography,
  Grid,
  withStyles,
  IconButton,
} from "@material-ui/core";
//@ts-ignore
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeft, ArrowRight } from "./assets";
import LinearProgress from "@material-ui/core/LinearProgress";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { withTranslation, WithTranslation } from "react-i18next";
import "./MoviesSlider.css";
export const configJSON = require("./config.js");

interface Props extends WithTranslation {
  isTitle: boolean;
  sliderTitle: string;
  navHandler: (id: string, type?: any) => void;
  similarMovies: any[];
  isSubTitle: boolean;
  isContinueWatching: boolean;
  classes: any;
  t: any;
}

interface State {
  isControll: boolean;
  windowSize: number;
}

export class MoviesSlider extends Component<any, State> {
  SliderRef: any;

  constructor(props: Props) {
    super(props);
    this.SliderRef = React.createRef();
    this.state = {
      isControll: false,
      windowSize: window.innerWidth,
    };
  }

  myPreviewHandler = () => {
    let box: any = {};
    box = this.SliderRef.current;
    if (!box) return;
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width - 80;
  };

  myNextHandler = () => {
    let box: any = {};
    box = this.SliderRef.current;
    if (!box) return;
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width - 80;
  };

  navigateTo = (ele: any, type?: any) => {
    const { isContinueWatching } = this.props;
    let watchId =
      ele?.content_type === "WebSeries" ? ele?.web_series_id : ele?.content_id;
    const id = isContinueWatching ? watchId : ele?.id;
    if (ele?.content_type === "WebSeries" && isContinueWatching)
      localStorage.setItem("continueWatch", JSON.stringify(ele));
    this.props.navHandler(id, type);
  };

  watchingTime = (duration: any, watching_duration: any) => {
    const time = (watching_duration / duration) * 100;
    return time;
  };

  componentDidMount() {
    try {
      const windowWidth = window.innerWidth;
      let spacing: number = windowWidth * 0.05 + 20;
      let box: any = {};
      box = this.SliderRef.current;
      if (!box) return;
      let width = box.clientWidth;
      console.log(width,"width",windowWidth,spacing)
      this.setState({
        isControll: width >= windowWidth - spacing,
        windowSize: window.innerWidth,
      });
      
    } catch {}
  }

  handleResize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  
  getImageUrl = (_data:any) =>{
  let continueWatchUrl = this.props.isContinueWatching ? _data?.attributes?.image_url : _data?.attributes?.thumbnail_image;
  return continueWatchUrl
  }

  render() {
    const {
      t,
      isTitle,
      sliderTitle,
      similarMovies,
      isSubTitle,
      isContinueWatching,
      classes,
    } = this.props;
    const { isControll } = this.state;
    // console.log(this.state.isControll,'test controll');

    return (
      <Grid container style={{ padding: "20px 0" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color="primary" className={classes.RowTitle}>
            {sliderTitle}
          </Typography>
          {isControll && (
            <Box className={classes.SliderBtnBlock}>
              <img
                data-test-id="prev"
                className={classes.displayNon}
                src={ArrowLeft}
                onClick={this.myPreviewHandler}
              />
              <img
                data-test-id="next"
                className={classes.displayFlex}
                src={ArrowRight}
                onClick={this.myNextHandler}
              />
              <img
                className={classes.displayFlex}
                src={ArrowLeft}
                onClick={this.myPreviewHandler}
              />
              <img
                className={classes.displayNon}
                src={ArrowRight}
                onClick={this.myNextHandler}
              />
              {/* navigateTo(isContinueWatching ? (ele?.attributes?.content_id, ele?.attributes?.content_type) : ele?.attributes?.id) */}
            </Box>
          )}
        </Grid>

        {(similarMovies || []).length > 0 ? (
          <div className="movie-carousel">
            <div className="slider-container" ref={this.SliderRef}>
              {(similarMovies || []).map((ele: any, i: number) => {
                return (
                  <Box
                    key={ele?.id}
                    data-test-id="nav_cont"
                    onClick={() => {
                      this.navigateTo(ele?.attributes);
                    }}
                    className={classes.slideCard}
                  >
                    <div className="movie-card">
                      <img
                        src={this.getImageUrl(ele)}                        
                        className={classes.Image}
                        alt="poster"
                      />
                      {/* <LazyLoadImage
                      src={ele?.attributes?.image_url?.includes("https://") ? ele?.attributes?.image_url : `${configJSON.baseURL}${ele?.attributes?.image_url}`}
                      className={classes.Image}
                      effect="blur"
                      width="100%"
                      placeholderSrc={loaderImg}
                      threshold={50}
                    /> */}
                    {isContinueWatching && (
                      <Box mt={1.5} className={classes.watchHistory}>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.colorPrimary,
                            barColorPrimary: classes.barColorPrimary,
                          }}
                          variant="determinate"
                          value={this.watchingTime(
                            parseInt(ele?.attributes?.duration),
                            parseInt(ele?.attributes?.watching_duration)
                          )}
                        />
                      </Box>
                    )}
                      {/* <IconButton className={classes.ButtonWithIcon}>
                        <PlayCircleFilledIcon />
                      </IconButton> */}
                    </div>
                    {isTitle && (
                      <Typography
                        className={`${classes.MovieTitle} single_line`}
                        color="primary"
                      >
                        {ele?.attributes?.title}
                      </Typography>
                    )}
                    
                    {isSubTitle && (
                      <Typography
                        className={`${classes.MovieSubTitle} single_line`}
                        color="primary"
                      >
                        {ele?.attributes?.subtitle}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </div>
          </div>
        ) : (
          <Typography color="primary">
            {t("movieDetails.nodatafound")}
          </Typography>
        )}
      </Grid>
    );
  }
}

export const useStyles: any = (theme: any) => ({
  RowTitle: {
    fontSize: "18px",
    LineHeight: "20px",
    fontWeight: 600,
    fontFamily: "Roboto",
    fontStyle: "normal",
    letterSpacing: "-0.41px",
    [theme.breakpoints.only("xs")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 23,
    } },
  displayNon: {
    display: `${theme.direction === "rtl" ? "none" : "flex"}`,
    width: 22,
    height: 22,
    cursor: "pointer",
    margin: '0px 8px',
    [theme.breakpoints.only("xs")]: {
      width:20,
      height:20,
    }    
  },
  watchHistory:{
    position: "absolute" as const,
    bottom: 0,
    width: "150px",
  },
  displayFlex: {
    display: `${theme.direction === "rtl" ? "flex" : "none"}`,
    width: 22,
    margin: '0px 8px',
    height: 22,
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      width:20,
      height:20,
    }
  },
  MovieTitle: {
    opacity: 1,
    fontFamily: "Araboto-Normal",
    fontSize: 18,
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    textAlign: theme.direction === "rtl" ? "right" : "left",
    lineHeight: "inherit",
    marginTop: "8px",
    [theme.breakpoints.only("xs")]: {
      fontSize: 17,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 19,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 20,
    },
  },
  Image: {
    width: "150px",   // Fixed width
    height: "184px",  // Fixed height
    borderRadius: "6px",
    cursor: "pointer",
    border: "1px solid #F6F6F6",
    // [theme.breakpoints.up("xl")]: {
    //   height: "320px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: "170px",
    // },
  },
  slideCard: {
    width: "150px",
    maxWidth: "168px",
    minWidth: "168px",
    margin: "0px 0px 0px 0px",
    // margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
    // [theme.breakpoints.up("xl")]: {
    //   width: "266x",
    //   maxWidth: "266px",
    //   minWidth: "266px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "142px",
    //   maxWidth: "142px",
    //   minWidth: "142px",
    // },
  },
  SliderBtnBlock: {
    display: "flex",
    justifyContent: "flex-end",
    color: "white",
    padding: theme.direction === "rtl" ? "1% 0 0 8%" : "1% 8% 0 0",
  },
  ButtonWithIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& svg": {
      width: "2.2em",
      height: "2.2em",
      fill: "#FFFFFF",
      opacity: "80%",
    },
  },
  colorPrimary: {
    backgroundColor: "#242830",
    height: "7px",
    borderRadius: "0 0 6px 6px",
  },
  barColorPrimary: {
    background:
      "linear-gradient(90deg, rgba(234,67,93,1) 0%, rgba(104,105,172,1) 100%);",
  },
  Slider: {
    // margin: :'0px auto 30px' ,
    "& .slick-list": {
      height: "100%",
      "& .slick-track": {
        height: "100%",
        "& .slick-slide": {
          width: "150px !important" as any,
          float:
            theme.direction === "rtl" ? "right !important" : "left !important",
          "& div": {
            "& div": {
              minHeight: "168px",
            },
          },
        },
      },
    },
  },
  MovieSubTitle: {
    opacity: 1,
    fontFamily: "Araboto-Normal",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    letterSpacing: "0px",
    textAlign: theme.direction === "rtl" ? "right" : "left",
    padding: theme.direction === "rtl" ? "0 12% 0 0" : "0 0 0 12%",
    lineHeight: "inherit",
    marginTop: "8px",
    color: "#7A7B7F",
  },
});
export default withStyles(useStyles)(withTranslation()(MoviesSlider));
