import React from "react";

import {
  // Customizable Area Start
  Grid,
  Typography,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import ManageYourDeviceController, { Props } from "./ManageYourDeviceController.web";
import { withTranslation } from 'react-i18next';
// Customizable Area End

export class ManageYourDeviceWeb extends ManageYourDeviceController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes }: any = this.props;
    const { t }: any = this.props;
    return (
      // Customizable Area Start
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid className={classes.DeviceWrapper}>
          <Typography
            className={classes.font_Size_20}
            color="primary"
          >
            {t("settingWeb.manageDevice")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.watchUnderLine}
        />
           <Grid className={classes.DeviceWrapper}>
            {this.state.deviceList.map((item: any , index: number) => {
              return (
                <Grid key={item?.id}>
                  <Grid className={classes.DeviceWrap}>
                    <Grid>
                      <Typography color="secondary" className={classes.DeviceName}>{item.attributes.device_name}</Typography>
                      <Typography color="secondary" className={classes.LoginDay}>{this.changeDateFormat(item.attributes.login_time)}</Typography>
                    </Grid>
                    <button className={classes.LogoutButton} onClick={() => { this.logOutDevice(item.attributes.device_unique_id) }}>{t("settingWeb.manageYourDevice.logout")}</button>
                  </Grid>
                 {!this.isLastDevice(index) && <Grid
                    item
                    xs={12}
                    className={classes.watchUnderLine}
                  />}
                </Grid>
              )
            })}
          </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  DeviceWrapper: {
    padding: "30px",
    width: '100%',
    [theme.breakpoints.only("xs")]: {
        padding: "15px 20px",
    },
    [theme.breakpoints.only("sm")]: {
        padding: "20px 25px",
    }
  },
  watchUnderLine:{
    height: "2px",
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
},
font_Size_20: {
  fontWeight: 700,
      fontSize: 20,
      fontFamily: "Roboto",
      [theme.breakpoints.only("xs")]: {
          fontSize: 18,
      },
      [theme.breakpoints.only("sm")]: {
          fontSize: 19,
      }
},
 
  DeviceWrap: {
    padding: "25px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      padding: "15px 0px",
  },
  [theme.breakpoints.only("sm")]: {
    padding: "17px 0px",
  }
  },
  DeviceName: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    marginBottom: "14px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  LoginDay: {
    fontSize: "20px",
    lineHeight: "24px",
    fontFamily: "Roboto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },
  LogoutButton: {
    padding: "13px 30px",
    border: "1px solid #F6F6F6",
    color: "#F6F6F6",
    borderRadius: "8px",
    background: "transparent",
    fontSize: "18px",
    lineHeight: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px",
      fontSize: "16px",
      lineHeight: "18px",
    },
  }
});
export default withStyles(styles)(withTranslation()(ManageYourDeviceWeb))
// Customizable Area End
