import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
interface SeeMoreProps {
	text?: string;
	limit?: number;
	// don't wrap content in Typography
	noWrap?: boolean;
	classes?: any;
	t?: any;
}

function SeeMore({ text = "", noWrap, limit = 250, classes, t }: SeeMoreProps) {
	const [display, setDisplay] = useState("");
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setDisplay(text.substr(0, limit))
	}, [])

	const toggle = () => {
		if (expanded) {
			setDisplay(text.substr(0, limit))
		} else {
			setDisplay(text)
		}
		setExpanded(e => !e)
	}
	const content = <>
		{display}
		{text?.length > limit && <b onClick={toggle} className={classes.moreContent}>{" "}{ expanded ? t("movieDetails.hide"):t("movieDetails.more")}</b>}			
	</>
	if (noWrap) return content
	return <Typography className={classes.moviePara}>
		{content}
	</Typography>
}
//*********** styles ***************//
export const themeCreatStyle = (theme: any) => ({
	moreContent:{
		cursor: "pointer",
		color: "#EA435D"
	},
	moviePara: {
	fontFamily: "Araboto-Normal",
	fontWeight: 500,
	fontStyle: 'normal',
	lineHeight: '26px',
	[theme.breakpoints.only("xs")]: {
		fontSize: 15,
	},
	[theme.breakpoints.only("md")]: {
		fontSize: 17,
	},
	[theme.breakpoints.only("sm")]: {
		fontSize: 16,
	},
	[theme.breakpoints.only("lg")]: {
		fontSize: 18,
	},
	[theme.breakpoints.up("lg")]: {
		fontSize: 20,
	},
	}
});

export default withStyles(themeCreatStyle)(SeeMore);
