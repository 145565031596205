import React from "react";

import {
  Grid,
  Typography,
  withStyles,
  TextareaAutosize
} from "@material-ui/core";

// Customizable Area Start
import Dropzone from 'react-dropzone'
// Customizable Area End
import RaiseTicketController, { Props } from "./RaiseTicketController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { DragIcon, PdfIcon } from "./assets";
import { withTranslation } from 'react-i18next';

export class RaiseTicketWeb extends RaiseTicketController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes }: any = this.props;
    const { t }: any = this.props

    return (
      // Customizable Area Start
      <Grid container>
        {this.state.loading && <Spinner data-test-id="spinner" spinnerModal={this.state.loading} />}
        <form>
          <div className={classes.backToTitle} >
            <div style={{ marginTop: '20px'}}>
              <Typography className={classes.TicketText}>{t("ticketraise.title1")}</Typography>
              <Typography className={classes.TicketText1}>{t("ticketraiseweb.title")}</Typography>
            </div>
            <div>
              <Typography className={classes.backText} onClick={()=>{this.props.handleChangeTab('faq')}}>{t("ticketraise.backtoHelp")}</Typography>
            </div>
          </div>
          <Grid container className={classes.FormContainer}>
            {this.state.isGuestTicket && <Grid item xs={12}>
              <input
                data-test-id="raise_ticket_email"
                value={this.state.email}
                placeholder={t("registerScreen.email")}
                className={classes.RegallTextField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleEmailChange(event)}
              />
              {this.state.emailError && <div className={classes.emailError}>{t("registerScreen.vaildEmail")}</div>}
            </Grid>
            }
            <Grid item xs={12}>
              <input
                name="ticketTitle"
                data-test-id="raise_ticket_title"
                value={this.state.formData.ticketTitle}
                placeholder={t("ticketraiseweb.inputPlaceholder")}
                className={classes.RegallTextField}
                onChange={(e) => this.handleFormData(e.target.name, e.target.value)}
              />
              {
                this.state.titleError &&
                <span style={{ color: 'white', fontStyle: 'italic'}}>{t("helpandfaq.PETT")}</span>
              }
            </Grid>
            <Grid item xs={12} >
              <textarea
                aria-label="minimum height"
                name="ticketDescription"
                data-test-id="raise_ticket_description"
                value={this.state.formData.ticketDescription}
                rows={6}
                placeholder={t("ticketraiseweb.description")}
                style={{ width: "100%" }}
                className={classes.RegallTextField}
                onChange={(e) => this.handleFormData(e.target.name, e.target.value)}
              />
              {
                this.state.descError &&
                <span style={{ color: 'white', fontStyle: 'italic'}}>{t("helpandfaq.PETD")}</span>
              }
            </Grid>
            <Grid container className={classes.Uploadblock}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }: any) => (
                    <section className={classes.Upoder}>
                      <div {...getRootProps()} className={classes.UpoderInner}>
                        <input {...getInputProps()} />
                        <img src={DragIcon} style={{ margin: "auto" }} />
                        <Typography className={classes.DropText}>{t("ticketraiseweb.dropbox")}</Typography>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid style={{ width: '10%', marginTop: '20px' }} className={classes.ImageWrapper}>
                {this.state.formData.uplodaData.map((filesWrapper: any) => {
                  const extensionName = filesWrapper.name.split('.').pop();
                  if (extensionName === 'pdf') {
                    return (
                      <Grid key={filesWrapper?.id} className={classes.UploadStatus}>
                        <img src={PdfIcon} />
                      </Grid>
                    )
                  }
                  else {
                    return (
                      <Grid key={filesWrapper?.id} className={classes.UploadStatus}>
                        <img src={URL.createObjectURL(filesWrapper)} />
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.ButtonBlock}>
              <button onClick={this.handleTicketForm} type="submit" className={classes.RegButton}>{t("helpandfaq.ticket")}</button>
              {/* <button type="submit" onClick={this.cancelChanges} className={classes.CancelButton}>{t("ticketraiseweb.cancelChanges")}</button> */}
            </Grid>
          </Grid>
        </form>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
  FormContainer: {
    margin: "15px 0 0 0"
  },
  RegallTextField: {
    position: "relative",
    width: "100%",
    padding: "16px",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
    marginTop: 20,
    color: 'white',
    background: "transparent",
    border: "0.5px solid",
    borderImageSlice: 1,
    fontFamily:"Roboto",
    "& ::placeholder": {
      // color: "#fff",
      // fontSize: '80px'
    },

    borderWidth: '1px',
    borderStyle: 'solid',
    borderImage: 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)',

  },
  emailError: {
    // fontSize: 14,
    // fontWeight: 400,
    color: "white",
    fontStyle: "italic",
    // marginTop: 7
  },
  TextArea: {
    margin: "20px 0",
    backgroundColor: `${theme.palette.type == "dark" ? '#212330' : "#D3D3D3"}`,
    border: "1px solid #212330",
    color: theme.palette.primary.main,
    borderRadius: "10px",
    padding: "20px",
    fontSize: "18px",
    lineHeight: "18px",
    resize: "none",
    "&::placeholder": {
      color: "#757A8C"
    },
    "&:focus": {
      border: "1px solid #6869AC"
    }
  },
  TicketText: {
    fontSize: "18px",
    fontWeight: '600',
    lineHeight: "20px",
    marginBottom: '12px',
    color: theme.palette.primary.main
  },
  TicketText1: {
    fontSize: "14px",
    fontWeight: '400',
    lineHeight: "16px",
    color: theme.palette.primary.main
  },
  backText: {
    color: '#EA435D',
    fontSize: "16px",
    fontWeight: '700',
    lineHeight: "18px",
    cursor: 'pointer',
    "@media only screen and (max-width: 600px)": {
      // width: "385px"
      marginTop: '20px',
    }
    // color: theme.palette.primary.main
  },
  Uploadblock: {
    margin: "20 0 40px 0",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column",
      alignItems: "flex-start"
    },
  },
  DropText: {
    color: theme.palette.secondary.main,
    fontSize: "16px",
    textAlign: "center",
    marginTop: "24px",
    fontWeight: '500',
    lineHeight: '22px',
    "& span": {
      // color: "#EA435D",
      // textDecoration: "underline"
    }
  },
  Upoder: {
    // maxWidth: "500px",
    padding: "35px 48px",
    // background: `${theme.palette.type == "dark" ? '#1A1B22' : "#D3D3D3"}`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderImage: 'linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)',
    borderImageSlice: 1,
    borderRadius: "10px",
    border: "1px solid #6869AC",
  },
  UpoderInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer"
  },
  ImageWrapper: {
    margin: "0 0 0 40px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0 0 0",
    },
  },
  UploadStatus: {
    width: "85px",
    height: "85px",
    overflow: "hidden",
    margin: theme.direction === "rtl" ? "0 10px 0 0" : "0 0 0 10px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 10px",
    },
    "& img": {
      width: "100%",
      height: "100%"
    }
  },
  ButtonBlock: {
    display: "flex",
    marginBottom: '20px',
    [theme.breakpoints.up("xs")]: {
      "& button": {
        "&:first-child": {
          margin: theme.direction === "rtl" ? "0 0 0 20px !important" : "0 20px 0 0 !important"
        }
      },
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      "& button": {
        "&:first-child": {
          margin: "0 0 20px 0 !important"
        }
      },
    },
  },
  RegButton: {
    width: "400px",
    padding: '10px',
    color: theme.palette.primary.main,
    height: "54px",
    // backgroundColor: "rgba(234, 67, 93, 1)",
    background: 'linear-gradient(91.24deg, #EA435D 1.44%, #6869AC 32.15%, #248CA1 98.95%)',
    borderRadius: "8px",
    gap: '10px',
    opacity: 0.9,
    outline: "none",
    border: "none",
    fontSize: "20px",
    fontWeight: 700,
    cursor: "pointer",
    lineHeight: '22px',
    '&:hover': {
      opacity: 1,
      backgroundColor: "rgba(234, 67, 93, 1)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  },
  CancelButton: {
    width: "240px",
    height: "48px",
    backgroundColor: "transparent",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.secondary.main,
    outline: "none",
    fontSize: "18px",
    fontWeight: 700,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  },
  backToTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 600px)": {
      // width: "385px"
      display: "block",
    }
  }
});
export default withStyles(styles)(withTranslation()(RaiseTicketWeb));
// Customizable Area End
