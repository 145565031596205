import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);
// Customizable Area End

import ForgotPasswordController from "./ForgotPasswordController.web";
import { imgPasswordVisible, imgPasswordInVisible, VODLogo } from "./assets";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { withTranslation } from "react-i18next";
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";

export class CreatePassword extends ForgotPasswordController {
  render() {
    //  Customizable Area Start
    const { classes, t }: any = this.props;
    // eslint-disable-next-line no-undef
    //  Customizable Area End
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid item xs={12} className={classes.PPContainer}>
          {/* Customizable Area Start */}
          <Grid container className={classes.PPGridContainer}>
            <Grid container item xs={12} className={classes.PPRightGrid}>
              <Grid container justifyContent="center">
                <img className={classes.AppLogo} src={VODLogo} />
              </Grid>

              <Grid container className={classes.form_container}>
                <Typography className={classes.headingTypo} color="primary">
                  {t("loginScreen.resetPassword")}
                </Typography>
                
                <Grid item xs={12} className={classes.PPSubgrid3}>
                  <TextField
                    data-test-id="password"
                    placeholder={t("loginScreen.newpass")}
                    type={this.state.showPassword ? "text" : "password"}
                    className={classes.PPTextField}
                    variant="outlined"
                    value={this.state.password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.onChangeCreatePassword(event)
                    }
                    // error={this.state.pswderror}
                    helperText={<StyledFormHelperText>{this.state.pswderrorText}</StyledFormHelperText>}
                    inputProps={{ className: classes.textFiledCss }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.InputProp}
                        >
                          <img
                            src={
                              !this.state.showPassword
                                ? imgPasswordInVisible
                                : imgPasswordVisible
                            }
                            onClick={this.handleClickShowPassword}
                            className={classes.HideImg}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        fontFamily: "Araboto",
                        opacity: 1,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  lg={12}
                  md={12}
                  xs={12}
                  className={classes.PPSubgrid3}
                >
                  <TextField
                    data-test-id="confirmPassword"
                    id="standard-basic"
                    placeholder={t("loginScreen.cpassword")}
                    className={classes.PPTextField}
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    value={this.state.confirmPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.onChangeConfrimPassword(event)
                    }
                    // error={this.state.cpswderror}
                    helperText={<StyledFormHelperText>{this.state.cpswderrorText}</StyledFormHelperText>}
                    inputProps={{ className: classes.textFiledCss }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.InputProp}
                        >
                          <img
                            onClick={this.handleClickShowConfirmPassword}
                            src={
                              !this.state.showConfirmPassword
                                ? imgPasswordInVisible
                                : imgPasswordVisible
                            }
                            className={classes.HideImg}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        fontFamily: "Araboto",
                        opacity: 1,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.PPSubgrid4}
                >
                  <Button
                    data-test-id="changeButton"
                    variant="contained"
                    color="secondary"
                    className={classes.PPButton}
                    onClick={(event: any) => this.handleSubmit(event)}
                  >
                    {t("forgotpassword.save")}
                  </Button>
                  <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Customizable Area End */}
        </Grid>
      </Grid>
    );
  }
}

export const styles = (theme: any) => {
  let themeDirection = (arrValue: any, enValue: any) =>
    theme.direction === "rtl" ? arrValue : enValue;
  return {
    //  Customizable Area Start
    PPContainer: {
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      [theme.breakpoints.only("lg")]: {
        width: "100%",
        height: "100%",
        padding: "70px 20px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        height: "100%",
        padding: "10px 20px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "40px 20px",
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.only("md")]: {
        width: "100%",
        height: "100%",
        padding: "50px 20px",
      },
    },
    PPGridContainer: {
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "space-around",
    },
    HideImg: {
      width: "22px",
      marginRight: "0px",
      cursor: "pointer",
      filter: theme.palette.type === "dark" ? "none" : "invert(1)",
    },
    PPSideGridImg: {
      width: "100%",
      maxWidth: "100%",
      height: "550px",
      paddingTop: "50px",
      backgroundColor: "transparent",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        position: "relative" as const,
        top: 70,
        paddingTop: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    PPText: {
      maxWidth: "100%",
      width: "auto",
      filter: theme.palette.type === "dark" ? "none" : "invert(1)",
      [theme.breakpoints.only("xs")]: {
        width: "80%",
        margin: "auto 10%",
      },
    },
    PPTextField: {
      maxWidth: "100%",
      width: "100%",
      borderRadius: 10,
      alignSelf: "center",
      fontFamily: "Araboto",
      fontSize: 14,
      backgroundColor: "#222222",
    },
    AppLogo: {
      maxWidth: "120px",
      width: "auto",
      aspectRation: "1/1",
      marginBottom: 20,
    },
    PPButton: {
      maxWidth: "100%",
      width: "100%",
      height: "54px",
      borderRadius: 10,
      alignSelf: "center",
      textTransform: "capitalize" as const,
      backgroundColor: "rgba(234, 67, 93, 1)",
      fontFamily: "Araboto",
      fontWeight: 600,
      color: theme.palette.primary.main,
      opacity: 0.9,
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: "rgba(234, 67, 93, 1)",
      },
    },
    headingTypo: {
      width: "100%",
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 20,
      textAlign: "center" as const,
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
    },
    PPTypo: {
      fontSize: 16,
      textAlign: "center" as const,
      fontWeight: 400,
      margin: "15px 0 20px",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
    },
    InputProp: {
      marginRight: 0,
    },
    PPRightGrid: {
      margin: "auto",
      maxWidth: "600px",
    },
    PPSubgrid1: {
      marginBottom: 30,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 25,
      },
    },
    PPSubgrid2: {
      marginBottom: 70,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 30,
      },
    },
    PPSubgrid3: {
      marginBottom: 30,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 20,
      },
    },
    textFiledCss: {
      fontFamily: "Araboto",
      color: theme.palette.primary.main,
      lineHeight: 1,
      borderRadius: "10px",
      fontSize: 16,
      [theme.breakpoints.only("lg")]: {
        fontSize: 16,
        padding: "18px 20px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14.5,
        padding: "14.5px 16px",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 16,
        padding: "17px 18.5px",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 15.5,
        padding: "16px 17px",
      },
    },
    form_container: {
      [theme.breakpoints.only("xs")]: {
        padding: "30px",
      },
      background:
        "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      padding: "40px 45px",
      borderRadius: "8px",
    },
    image_direction: {
      cursor: "pointer",
      transform: themeDirection("scaleX(-1)", "none"),
      margin: theme.direction === "rtl" ? "0 0 0 20px" : "0 20px 0 0",
    },
    //  Customizable Area End
  };
};

export default withStyles(styles)(withTranslation()(CreatePassword));
