import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  countryList: any;
  selectedCountryCode: any;
  selectCountryCode: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorE1: any;
  filterList: any;
  countrySerch: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomCountryCodePickerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorE1: null,
      filterList: [],
      countrySerch: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setFilterList(this.props.countryList);
  }

  setAnchorE1(anchorE1: any) {
    this.setState({ anchorE1 });
  }

  setFilterList(filterList: any) {
    this.setState({ filterList });
  }

  searchCountry(e: any) {
    this.setState({ countrySerch: e.target.value });
  }

  // Customizable Area End
}
