import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { IBlock } from '../../../framework/src/IBlock';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import { Block } from '../../../framework/src/Block';
// Customizable Area Start
import { getStorageData, logoutUser, removeStorageData, setStorageData } from '../Utilities';
import { Logout } from 'components/src/Helpers';
import LandingPage from '../../../blocks/dashboard/src/LandingPage/Controller';
import { Platform } from 'react-native';
// Customizable Area End

let config = require('../config')

export default class RestApiClientBlock<Entity> extends Block {
  private props: any;

  private static instance: RestApiClientBlock<any>;

  private constructor() {
    super();
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIRequestMessage),
    ]);
  }

  static getInstance(): RestApiClientBlock<any> {
    if (!RestApiClientBlock.instance) {
      RestApiClientBlock.instance = new RestApiClientBlock();
    }
    return RestApiClientBlock.instance;
  }

  async receive(from: string, message: Message) {
    console.log('API Rquest Message' + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIRequestMessage) === message.id) {
      const uniqueApiCallId = message.messageId;
      const {
        RestAPIRequestMethodMessage: method,
        RestAPIResponceEndPointMessage: endpoint,
        RestAPIRequestHeaderMessage: headers,
        RestAPIRequestBodyMessage: body,
        NavigationPropsMessage: props,
      } = message.properties;
      this.props = props;
      this.makeApiCall(uniqueApiCallId, method, endpoint, headers, body);
    }
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body: string
  ) {

    let fullURL = endpoint.indexOf('://') === -1 ? config.baseURL + '/' + endpoint : endpoint
    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      let response:Response = new Response();
      if (headers && body) {
          response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers.length ? JSON.parse(headers) : headers,
          body: body,
        });
      } else if (headers) {
          response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers.length ? JSON.parse(headers) : headers,
        });
      } else {
        response = await fetch(fullURL, {
        method: method.toUpperCase()
      });
    }

      let responseJson = await response.json();
      // Customizable Area Start
      if(Platform.OS !='web'){
      if (responseJson?.data?.token) {
        setStorageData('Token', responseJson.data.token);
        let rapi = await getStorageData('api');
        let { uniqueApiCallId, method, endpoint, headers, body } =
          JSON.parse(rapi);
        this.makeApiCall(
          uniqueApiCallId,
          method,
          endpoint,
          { ...headers, token: responseJson.data.token },
          body
        );
        removeStorageData('api');
        return true;
      }
      const status = response.status;
      if ( status === 401 || status === 422) {
        let rapi = await getStorageData('api');
        let count = parseInt((await getStorageData('api_count')) || '0');
        if (!rapi || count != 0) {
          new LandingPage({
            navigation: null,
            id: 'refreshtoken',
            t: () => {},
          }).getRefreshToken();
          setStorageData(
            'api',
            JSON.stringify({ uniqueApiCallId, method, endpoint, headers, body })
          );
          setStorageData('api_count', count === 0 ? '1' : `${count--}`);
        } else if (count < 1) {
          removeStorageData('api');
          if(status === 422){
          Logout();
        }
      }
      }
    }
      // Customizable Area End

      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );

      console.log('Api Response' + JSON.stringify(responseJson));
    } catch (error) {
      runEngine.debugLog('RestApiClient Error', error);
      //setting Error
      console.log('Api Error' + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        'An error has occuured. Please try again later.'
      );
    }

    if (this.props) {
      apiResponseMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
    }
    this.send(apiResponseMessage);
  }
}
