import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { aboutus, deleteAccount, helpFaq, manageDevices, myProfile, myProfileIcon, planDetails, privacyPolicys, settings, switchProfile, transactions, watchHistory } from "./assets";

export const configJSON = require("./config");
export interface NestedItem {
    id: number;
    name: string;
    value: string;
    icon: string; 
}

export interface MainItem {
    id: number;
    name: string;
    icon: string; 
    isNested?: boolean;
    value: string;
    nestedList?: NestedItem[]; 
}

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t: any;
    width?: any;
}
export interface S {
    // Customizable Area Start
  generalSettingList: MainItem[];
  selectedTab: string;
  isExpand : boolean;
  isGuestMember: boolean;
  selectedUser: string;
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class GeneralSettingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserWatchHistoryApiCallId: string = "";
    deleteUserHistoryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            generalSettingList: [
                { id: 1, name: this.props.t("profile.headerTitle"), icon: myProfileIcon, value: 'my_profile' },
                { id: 2, name: this.props.t("news.headerTitle"), icon: myProfile, value: 'news' },
                { id: 3, name: this.props.t("settingDialogWeb.members"), icon: transactions, value: 'members' },
                { id: 4, name: this.props.t("settingDialogWeb.switchProfile"), icon: switchProfile, value: 'switch_profile' },
                { id: 5, name: this.props.t("settingDialogWeb.setting"), icon: settings, isNested: true, value: 'settings', nestedList :[
                    { id: 11, name: this.props.t("settings.planDetail"), icon: planDetails, value: 'plan_details' },
                    { id: 12, name: this.props.t("settings.transactions"), icon: transactions, value: 'transactions' },
                    { id: 13, name: this.props.t("settings.manageYourDevices"), icon: manageDevices, value: 'manage_devices' },
                    { id: 14, name: this.props.t("settings.watchHistory"), icon: watchHistory, value: 'watch_history' }
                 ] },
                { id: 6, name: this.props.t("settingDialogWeb.aboutUs"), icon: aboutus, value: 'about_us' },
                { id: 16, name: this.props.t("footer.terms"), icon: aboutus, value: 'terms_of_use' },
                { id: 15, name: this.props.t("footer.privacyPolicy"), icon: privacyPolicys, value: 'privacy_policy' },
                { id: 7, name: this.props.t("settingDialogWeb.helpandfaq"), icon: helpFaq, value: 'help_faq' },
                { id: 8, name: this.props.t("settingDialogWeb.deleteAccount"), icon: deleteAccount, value: 'delete_account' }
            ],
            selectedTab: "",
            isExpand : false,
            isGuestMember: false,
            selectedUser: ''
            // Customizable Area End
        };
    }

    async componentDidMount() {
        super.componentDidMount();
        const selectedUser = await getStorageData("selectedUser");
        let isGuest = await getStorageData("isGuestUser");
        if ((await isGuest) === "true") {
            this.setState({ isGuestMember: true });
        }
    (await selectedUser) && this.setState({ selectedUser });
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const propData = message.getData(
              getName(MessageEnum.SessionResponseData)
            );
            if(propData?.selectedTab){

                this.setState({selectedTab : propData.selectedTab})
            }
        }
        // Customizable Area End
      }

   handleListClick = (selectedList: string) =>{
    window.scrollTo(0, 0);
    if(selectedList === "settings"){
        this.setState({isExpand : !this.state.isExpand}) 
        return       
    }
    this.setState({selectedTab: selectedList})
   } 

   isLast = (tabName : string) => {
    let isLast = this.state.isGuestMember ? tabName === "help_faq" : tabName === "delete_account"
    return !isLast
   }

   handleGeneralList = () =>{
    const {isGuestMember , selectedUser} = this.state
    let guestList = ['about_us' , 'terms_of_use' , 'privacy_policy', 'help_faq'];
    let nonSelectedList = ['about_us' , 'terms_of_use' , 'privacy_policy', 'help_faq' ,'delete_account'];
    const result: MainItem[] = [];
    if(isGuestMember || !selectedUser){
    this.state.generalSettingList.forEach((setting: MainItem) => {
      if (
        (this.state.isGuestMember ? guestList : nonSelectedList).includes(
          setting.value
        )){
            result.push(setting);
        }
    });
    return result
   }
   else{
       return this.state.generalSettingList
   }
}

getSelectedTab = (tabName: string) =>{
    let isSelected =  tabName === this.state.selectedTab ||  this.state.isExpand && tabName === "settings"
    return isSelected;
    // Customizable Area End
}

checkIsMobileScreenForSetting = () => {
    return (
      ((this.props.width === "xs" ||
        this.props.width === "sm") ||
        (this.props.width !== "xs" && this.props.width !== "sm")) &&
      this.state.selectedTab !== ""
        )
}

checkIsMobileScreen = () => {
    return (((this.props.width === "xs" || this.props.width === "sm") &&
        this.state.selectedTab === "") ||
        (this.props.width !== "xs" && this.props.width !== "sm"))
}

checkIsMobile = () =>{
    return (this.props.width === "xs" || this.props.width === "sm")
}

handleCloseClick = () =>{
    this.setState({selectedTab: ""})
}
}