import { Message } from './Message';
import MessageEnum, { getName } from './Messages/MessageEnum';
import { getHeaders } from './requestHeader';
import { runEngine } from './RunEngine';
import { RequestType } from './types';

import _ from 'lodash';

export const messageHelper = async (
  url: string,
  requestType: RequestType,
  body: object,
  getMessageId: (id: string) => void
) => {
  const headers = await getHeaders();
  const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
  message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), headers);
  getMessageId(message.messageId);

  message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);
  if (!_.isEmpty(body)) {
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  }

  message.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    requestType || 'GET'
  );
  runEngine.sendMessage(message.id, message);
};
