import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import CryptoJS from 'crypto-js';

// Customizable Area Start
const code = `var wpwlOptions = {
  style: "plain",
  showCVVHint: true,
  brandDetection: true,
  onReady: function () {
    $('.wpwl-label-cardHolder').html('Card Holder Name');
    $('.wpwl-control-cardHolder').attr('placeholder', 'Card holder name');
    $(".wpwl-group-cardNumber").after($(".wpwl-group-brand").detach());
    $(".wpwl-group-cvv").after($(".wpwl-group-cardHolder").detach());

    var visa = $(".wpwl-brand:first")
      .clone()
      .removeAttr("class")
      .attr("class", "wpwl-brand-card wpwl-brand-custom wpwl-brand-VISA");
    var master = $(visa)
      .clone()
      .removeClass("wpwl-brand-VISA")
      .addClass("wpwl-brand-MASTER");
    $(".wpwl-brand:first").after($(master)).after($(visa));
    var amex = $(master)
      .clone()
      .removeClass("wpwl-brand-MASTER")
      .addClass("wpwl-brand-AMEX");
    $(".wpwl-brand:first").after($(amex)).after($(master)).after($(visa));

    // Bind custom validation on payment button click
    $('.wpwl-form-card').find('.wpwl-button-pay').on('click', function(e) {
      validateHolder(e);
    });
  },
  onChangeBrand: function (e) {
    $(".wpwl-brand-custom").css("opacity", "0.3");
    $(".wpwl-brand-" + e).css("opacity", "1");
  },
  onBeforeSubmitCard: function (e) {
    return validateHolder(e); // Validate card holder name before submitting
  }
};

function validateHolder(e) {
  var holder = $('.wpwl-control-cardHolder').val();
  // Remove existing error messages
  $('.wpwl-control-cardHolder').removeClass('wpwl-has-error');
  $('.wpwl-hint-cardHolderError').remove();

  if (!holder || holder.trim().length < 2) {
    // Add error class and message if validation fails
    $('.wpwl-control-cardHolder').addClass('wpwl-has-error').after('<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder name</div>');
    return false;
  }
  return true;
}
`;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  plan_id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  checkout_id: string;
  requests_id: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PaymentController extends BlockComponent<Props, S, SS> {
  apiMobileValidCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      token: "",
      checkout_id: "",
      requests_id: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    let checkout_id = "" ;
    let requests_id = "" ;
    let isMobile = false
    const query = new URLSearchParams(window.location.search);
    let paramsData = query.get('paymentID') || "";
    if(paramsData){
    isMobile = true    
    let jsonString = this.decryptPaymentsDetails(paramsData)
    checkout_id = jsonString.checkout_id;
    let token = jsonString.token;
    requests_id = jsonString.request_id;
    sessionStorage.setItem("mobileToken", token || "")
    }else{    
    checkout_id = sessionStorage.getItem("checkout_id") || "";
    requests_id = sessionStorage.getItem("request_id") || "";
    }
    if (checkout_id !== "" && requests_id !== "") {
      this.setState(
        {
          requests_id,
          checkout_id,
        }, () => {
          isMobile ? this.checkForValid() : this.loadPaymentScript()
        })
    }else {
      this.props.navigation.goBack()
    }
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiMobileValidCallId) {
        if (responseJson.message === "Valid Request!") {
          this.loadPaymentScript()
        } else {
          try{
          sessionStorage.removeItem("mobileToken")
          sessionStorage.removeItem("mobileRequestId")
          window.location.href = `almanassah://thankYou`;          
          }
          catch{}
          finally{
            this.props.navigation.navigate("LandingPage")
          }
        }
      }
    }
    // Customizable Area End
  }
  decryptPaymentsDetails = (_data:string) =>{
    const decodedParams = decodeURIComponent(_data);
    const bytes = CryptoJS.AES.decrypt(decodedParams, configJSON.cryptoKey);
    const jsonString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonString);
  }
  loadPaymentScript = () => {
    let scriptSource = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${this.state.checkout_id}`;
    let request_id = window.btoa(
      encodeURIComponent(this.state.requests_id)
    );
    
    sessionStorage.setItem("payment_requests_id", request_id);
    const script = document.createElement("script");
    script.src = scriptSource;
    
    // Create a script element for the wpwlOptions
    const script2 = document.createElement("script");
    script2.appendChild(document.createTextNode(code));
    
    // Inject scripts into the document
    document.body.appendChild(script);
    document.body.appendChild(script2);
  }
  

  checkForValid = () =>{
    let mobileToken = sessionStorage.getItem("mobileToken")
      const header = {
        token: mobileToken,
      };
  
      const requestPaymentStatusMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiMobileValidCallId = requestPaymentStatusMessage.messageId;
  
      requestPaymentStatusMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.mobileValid}?requests_id=${this.state.requests_id}&checkout_id=${this.state.checkout_id}`
      );
  
      requestPaymentStatusMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestPaymentStatusMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestPaymentStatusMessage.id, requestPaymentStatusMessage);
      // Customizable Area End
      return true;
    
  }
  // Customizable Area End
}
