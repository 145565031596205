import React from "react";
import { Grid } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation
} from "swiper/core";
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export const configJSON = require("./config.js");

export const MovieSwiperSkelton = () => {
  let mySwiperData: any = {
    spaceBetween: 30,
    // speed: 300,
    watchSlidesProgress: true,
    grabCursor: true,
    // slidesPerView: "auto",
    effect: "coverflow",
    centeredSlides: true,
    // loop: true,
    direction: "horizontal",
    observer: true,
    observeParents: true,
    coverflowEffect: {
      rotate: 20,
      stretch: 0,
      depth: 200,
      modifier: 0.7,
      slideShadows: true
    },
    navigation: false
  };

  return (
    <>
      <Grid>
        <>
          <Swiper data-test-id="swiper" {...mySwiperData} style={{height: '400px', overflowY: "hidden"}}>
            {[1].map(item => {
              return (
                <SwiperSlide key={item}>
                  <Skeleton width={"100%"} height={"100%"} baseColor="#07070D"
                   highlightColor="#07070ECC" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      </Grid>
    </>
  );
};

export default MovieSwiperSkelton;
