import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isSmallList?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationID: number;
  isAllDisplayed: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  openDialog: false;
  onClose: false;
  // Customizable Area End
}

export default class NotificationsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  deviceTokenAPICallId: string = "";
  markAsReadNotifApiMsgAPICallId: string = "";
  getNotifcationMessageAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      notificationID: 0,
      isAllDisplayed: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getNotification()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if(apiRequestCallId === this.getNotifcationMessageAPICallId){
      if(responseJson?.data){
        this.setState({data: responseJson.data})
      }
     }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  // PUT Notfifcation API integration
  markAsReadNotification = (id: number) => {
    let headers = {
      'token': localStorage.getItem("Token"),
      "Content-Type": "application/json"
    }
    const httpBody = {
      "data": {
        "attributes": {
          "is_read": true
        }
      }
    }
    const markAsReadNotifApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadNotifApiMsgAPICallId = markAsReadNotifApiMsg.messageId;

    markAsReadNotifApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsReadAPIEndPoints}/${id}`
    );

    markAsReadNotifApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    markAsReadNotifApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    markAsReadNotifApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadNotifApiMsg.id, markAsReadNotifApiMsg);
  }

  getNotification = async () => {
   
    let headers = {
      'token': await getStorageData("Token"),
      "Content-Type": configJSON.apiContentType,
      language: await getStorageData("i18nextLng")
    }

    let backendUrl = this.props.isSmallList ? '?per_page=2' : ''
     const getNotifcationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotifcationMessageAPICallId = getNotifcationMessage.messageId;

    getNotifcationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.markAsReadAPIEndPoints + backendUrl
    );

    getNotifcationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getNotifcationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(getNotifcationMessage.id, getNotifcationMessage);
  }

  seeAllNotification = () => {
    if(this.props.isSmallList){
      const notificationNavigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     notificationNavigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),"Notifications")
      notificationNavigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(notificationNavigationMes);
    } 
    this.setState({isAllDisplayed : true})
  }

  isLastNotification(index: number){
    return this.state.data.length  === index + 1
  }
  // Customizable Area End
}
