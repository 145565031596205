import React from "react";
// Customizable Area Start
import { Grid, withStyles, Typography, TextField, InputAdornment, FormHelperText } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
require("moment/min/locales.min");
let lang = localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
moment.locale(lang);
// Customizable Area End

const StyledFormHelperText = withStyles({
    root: {
        color: 'white',
        margin: 0,
        fontStyle: 'italic'
    },
})(FormHelperText);

import AcDeleteDialogController, { Props } from "./AcDeleteDialogController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import { imgPasswordInVisibleWeb, imgPasswordVisibleWeb } from "../../email-account-login/src/assets";

const StyledTextField = withStyles({
    root: {
        fontFamily: "Araboto",
        fontSize: "14",
        borderRadius: '20px',
        "& .MuiInputBase-input": {},
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent"
        },
        "& input::placeholder": {
            fontFamily: "Araboto",
            fontSize: "14",
            color: "white",
            fontWeight: 400
        }
    }
})((props: any) => <TextField {...props} />);

export class AcDeleteDialog extends AcDeleteDialogController {
    //   props: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const { t }: any = this.props;

        return (
            <>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                <Grid container>
                    <Grid className={classes.MainWrapper}>
                        <Grid className={classes.NewsContainer}>
                            <Grid container className={classes.NewsHeader}>
                                <Grid data-test-id="backHistory" className={classes.BackBlock}>
                                    {/* <ArrowBackIosIcon className={classes.ArrowIcon} /> */}
                                    <Typography className={classes.Heading}>{t("settingDialogWeb.deleteAccount")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.NewsBlock}>
                                <Grid >
                                    {
                                        this.state.handleHideShow ?
                                            <>
                                                <Typography className={classes.Title}>{t("settingWeb.delete_mes")}</Typography>
                                                <Typography className={classes.Title1}>{t("settingDialogWeb.deleteTitle")}</Typography>
                                                <button onClick={this.deleteAccount1} className={classes.ticketButton}>{t("settingDialogWeb.deleteUnderstand")}</button>
                                            </> :
                                            <>
                                                <Typography className={classes.Title}>{t("deleteprofile.confirmpass")}</Typography>
                                                <Typography className={classes.Title1}>{t("deleteprofile.entercurrpass")}</Typography>
                                                <form
                                                    onSubmit={(e: any) => {
                                                        this.getUserPassword(e);
                                                    }}
                                                    className={classes.loginForm}
                                                >
                                                    <Grid item xs={12}>
                                                        <div className={classes.setTextField}>
                                                            <StyledTextField
                                                                fullWidth
                                                                data-testid={"password-txtInput"}
                                                                variant="outlined"
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                placeholder={t("loginScreen.password")}
                                                                name="password"
                                                                value={this.state.password}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                                    this.onChangePassword(event)
                                                                }
                                                                className={classes.passwordTextField}
                                                                color="primary"
                                                                InputProps={{
                                                                    classes: { input: classes.placeHolderTypePassword },
                                                                    endAdornment: (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            // eslint-disable-next-line react-native/no-inline-styles
                                                                            style={{ marginRight: "0" }}
                                                                        >
                                                                            <img
                                                                                onClick={this.handleClickShowPassword}
                                                                                src={
                                                                                    !this.state.showPassword
                                                                                        ? imgPasswordInVisibleWeb
                                                                                        : imgPasswordVisibleWeb
                                                                                }
                                                                                className={classes.inputBgImg}
                                                                            />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            //   error={this.state.passErr}
                                                            // helperText={<StyledFormHelperText>{this.state.passErrMsg}</StyledFormHelperText>}
                                                            />
                                                        </div>
                                                            {
                                                                this.state.passError &&
                                                                <span style={{ color: 'white', fontStyle: 'italic' }}>{this.state.passErrMsg}</span>
                                                            }
                                                    </Grid>
                                                    <button type="submit" className={classes.deleteButton}>{t("settingDialogWeb.deleteAccount")}</button>
                                                </form></>

                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End 
    }
}

// Customizable Area Start
export const styles: any = (theme: any) => ({
    ListscrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: "transparent transparent",
        "&::-webkit-scrollbar": {
            width: 6,
            height: 6,
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 6,
            background: "#242830",
            minHeight: 24,
            minWidth: 24,
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
        },
    },
    setTextField: {
        padding: '2px',
        backgroundImage: 'linear-gradient(black, black), linear-gradient(to right, #E9445E, #5772A9, #288AA2)',
        // borderWidth: '3px',
        // borderStyle: 'solid',
        // marginTop: '220px',
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
        borderRadius: '6px',
        border: 'solid 16px trasparent'
    },
    NewsScrollBar: {
        scrollbarWidth: 'thin',
        scrollbarColor: "#242830 transparent",
        "&::-webkit-scrollbar": {
            width: 10,
            height: 6,
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 5,
            background: "#242830",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#242830",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#242830",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#242830",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
        },
    },
    MainWrapper: {
        width: '100%'
    },
    ArrowIcon: {
        fontSize: "22px",
        transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
        color: theme.palette.primary.main,
    },
    NewsContainer: {
        // padding: "0 5vw 0 5vw"
    },
    NewsHeader: {
        padding: "30px 0 20px 0",
        width: "100%",
        borderBottom: "2px solid",
        borderImage: "linear-gradient( 90deg, #E14663 , #5772A9 , #288AA2 ) 1",
    },
    BackBlock: {
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        padding: '0px 30px'
    },
    Heading: {
        fontSize: "28px",
        lineHeight: "33px",
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    NewsData: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        color: theme.palette.secondary.main,
        "& li": {
            margin: "0 0 16px 0"
        }
    },
    DesHeading: {
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: 700,
        color: theme.palette.primary.main,
        margin: "0 0 24px 0"
    },
    NewsBlock: {
        padding: "30px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    NewsLeft: {
        maxWidth: "30%",
        width: "100%",
        margin: theme.direction === "rtl" ? "0 0 0 80px" : "0 80px 0 0",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            margin: "0 0 40px 0",
        },
    },
    NewsRight: {
        maxWidth: "70%",
        width: "100%",
        maxHeight: "calc(100vh - 24vh)",
        overflowY: "scroll",
        padding: theme.direction === "rtl" ? "0 0 0 30px" : "0 30px 0 0",
        overflowX: "hidden",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            padding: theme.direction === "rtl" ? "0 0 0 30px" : "0 30px 0 0",
        },
    },
    NewsList: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        maxHeight: "calc(100vh - 30vh)",
        overflowY: "scroll",
    },
    NewsListItem: {
        width: "100%",
        padding: "20px",
        marginBottom: "16px",
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: "row",
        // background: theme.palette.background.gunmeta,
        // borderRadius: "10px",
        // border: `1px solid #212330`,
        cursor: "pointer",
        "&:last-child": {
            marginBottom: 0,
        }
    },
    ActiveNews: {
        width: "100%",
        padding: "20px",
        marginBottom: "16px",
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: "row",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        // background: theme.palette.type === "dark" ? "linear-gradient(90deg, #0097A8 0%, #6869AC 100%)" : "white",
        border: `1px solid ${theme.palette.type === "dark" ? "transparent" : "#212330"}`,
        boxShadow: theme.palette.type === "dark" ? "none" : "6px 6px 12px RGBA(0, 0, 0, 0.2)",
        position: "relative",
        borderRadius: 10,
        "&:last-child": {
            marginBottom: 0,
        },
        "&::before": {
            content: '""',
            position: "absolute",
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            backgroundImage: "linear-gradient(270deg, #121339 0%, #6869AC 100%)",
            borderRadius: 10,
            zIndex: -1
        }
    },
    NewsImage: {
        minWidth: "92px",
        minHeight: "110px",
        borderRadius: "6px",
        border: "0.5px solid #242830",
        boxShadow: "2px 2px 8px RGBA(0, 0, 0, 0.5)",
        overflow: "hidden",
        "& img": {
            width: "92px",
            height: "110px",
            display: "block",
            objectFit: "cover",
        }
    },
    ActiveNewsImage: {
        minWidth: "92px",
        minHeight: "110px",
        borderRadius: "6px",
        border: "0.5px solid #FFFFFF",
        boxShadow: "none",
        overflow: "hidden",
        "& img": {
            width: "92px",
            height: "110px",
            display: "block",
            objectFit: "cover",
        }
    },
    NewsContent: {
        width: "100%",
        margin: theme.direction === "rtl" ? "0 16px 0 0" : "0 0 0 16px",
        display: "flex",
        flexDirection: "column"
    },
    LeftContent: {
        flex: "1 1 0",
        width: "100%",
    },
    ContentTop: {
    },
    StatusTag: {
        fontSize: "12px",
        lineHeight: "12px",
        letterSpacing: 2.4,
        textTransform: "uppercase",
        color: "#EA435D",
    },
    Hours: {
        fontSize: "12px",
        lineHeight: "12px",
        color: theme.palette.type === "dark" ? "#7A7B7F" : "#515962",
        textAlign: theme.direction === "rtl" ? "right" : "left",
        marginTop: "8px"
    },
    WhiteFont: {
        fontSize: "12px",
        lineHeight: "12px",
        color: theme.palette.primary.main,
        textAlign: theme.direction === "rtl" ? "right" : "left",
        [theme.breakpoints.down("lg")]: {
            marginTop: "8px"
        },
    },
    Title: {
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: 600,
        color: theme.palette.primary.main,
        margin: "20px 0px",
    },
    Title1: {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 400,
        marginBottom: '20px',
        color: theme.palette.primary.main,
    },
    Description: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 400,
        color: theme.palette.secondary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        wordBreak: "break-all"
    },
    WrapImage: {
        width: "100%",
        height: "330px",
        borderRadius: "10px",
        overflow: "hidden",
        border: "0.5px solid RGB(36, 40, 48)",
        boxShadow: "2px 2px 8px RGBA(0, 0, 0, 0.5)",
        "& img": {
            display: "block",
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            height: "270px",
        },
    },
    BottomDescription: {
        marginTop: "40px",
    },
    ticketButton: {
        width: '400px',
        height: '54px',
        borderRadius: '8px',
        padding: '10px',
        gap: '10px',
        backgroundImage: 'linear-gradient(to right, #EA435D,#6869AC,#248CA1)',
        border: 'none',
        color: 'white',
        fontSize: '20px',
        fontWeight: '700',
        LineHeight: '22px',
        cursor: 'pointer',
        marginTop: '300px',
        // marginBottom: '13px',
        "@media only screen and (max-width: 600px)": {
            // width: "385px"
            width: '250px',
          }
    },
    deleteButton: {
        width: '400px',
        height: '54px',
        borderRadius: '8px',
        padding: '10px',
        gap: '10px',
        border: 'none',
        color: 'white',
        fontSize: '20px',
        fontWeight: '700',
        LineHeight: '22px',
        cursor: 'pointer',
        marginTop: '250px',
        // marginBottom: '23px',
        background: '#D60000',
        "@media only screen and (max-width: 600px)": {
            // width: "385px"
            width: '250px',
          }
        
    },
    inputBgImg: {
        width: "22px",
        marginRight: "0px",
        cursor: "pointer",
        filter: `${theme.palette.type === "dark" ? "none" : "invert(1)"}`
    },
    placeHolderTypePassword: {
        color: theme.palette.primary.main,
        "&::placeholder": {
            color: theme.palette.primary.main
        }
    },
    passwordTextField: {
        width: "100%",
        borderRadius: 10,
        alignSelf: "center",
        color: theme.palette.primary.main,
        fontFamily: "Araboto",
        fontSize: 14,
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
    },
})
export default withStyles(styles)(withTranslation()(AcDeleteDialog))
// Customizable Area End
