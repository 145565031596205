export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const CreatePasswordText = require("../assets/CreatPasswordText_Img.png");
export const CreatePasswordTextar = require("../assets/CreatPasswordText_Imgar.png");
export const ForgotPasswordBg = require("../assets/ForgotPasswordBg_Img.png");
export const ForgotPasswordSideImage = require("../assets/ForgotPasswordSideImage_Img.png");

export const ForgotPasswordText = require("../assets/ForgotPasswordText_Img.png");
export const ForgotPasswordTextar = require("../assets/ForgotPasswordText_Imgar.png");
export const Otp_Text = require("../assets/OTPVerificationText_Img.png");
export const Otp_Textar = require("../assets/OTPVerificationText_Imgar.png");
export const Create_Text = require("../assets/CreatPasswordText_Img.png");
export const BackButton = require("../assets/BackButton_Img.png")
export const modalBackground = require("../assets/modalBackground.png");
export const VODLogo = require("../assets/splashLogo.png")
