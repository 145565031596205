import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
import { sendAPIRequest } from "components/src/Utility";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  settingAnchor: any;
  setSettingAnchor: Function
  t: Function;
}

export interface S {
  // Customizable Area Start
  userDetails: any;
  loading: boolean;
  darkMode: boolean;
  logOut: boolean;
  movieUrl: string;
  tootipOpen: boolean;
  logout: boolean;
  settingDialog: boolean;
  adminDetails: any;
  selectedUserDetails: any;
  anchorEl:any;
  selectedMenuItem:any;
  settingDropdown:boolean;
  settingDropdownChange: string;
  isGuestMember: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class SettingDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  applyPlansApiCallId: string = '';
  addUserApiCallId: string = '';
  logOutApiCallId: string = '';
  getRefreshTokenApiCallId: string = '';
  deleteFirebaseTokenApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      userDetails: [],
      loading: false,
      darkMode: true,
      logOut: false,
      movieUrl: "",
      tootipOpen: false,
      logout: false,
      settingDialog: true,
      adminDetails: {},
      selectedUserDetails: {},
      isGuestMember: false,
      anchorEl: null,
      selectedMenuItem: '' ,
      settingDropdown: false,
      settingDropdownChange: ''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile();
    let adminDetail = localStorage.getItem("user_data")
    let selectedUserDetails = localStorage.getItem("selected_user_data")
    this.setState({ adminDetails: JSON.parse(adminDetail || ''), selectedUserDetails: JSON.parse(selectedUserDetails || '') })
    let isGuest = await getStorageData("isGuestUser");
    if ((await isGuest) === "true") {
    this.setState({ isGuestMember: true });
   }
    const tempUrl = window.location.href
    this.setState({
      movieUrl: tempUrl
    })
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getUserProfileApiCallId) {
        this.handleUserProfileResponse(responseJson)
      }
      if (apiRequestCallId === this.getRefreshTokenApiCallId) {
        this.handleRefreshTokenResponse(responseJson)
      }
      if (apiRequestCallId === this.logOutApiCallId) {
        this.handlelogOutResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleUserProfileResponse = (responseJson: any) => {

    if (!responseJson.errors) {
      if (responseJson?.error == "Not Authorized, Token Expired") {
        this.getRefreshToken()
        this.setState({ loading: false })
      }
      let selectedId = localStorage.getItem("selectedUser")
      let array: [] = responseJson?.data
      let data: any[] = array.filter((_data: any) => _data.id == selectedId)
      this.setState({ userDetails: selectedId !== "" && data.length !== 0 ? data : responseJson?.data, loading: false })
      let user_data1 = localStorage.getItem('selected_user_data');
      if (JSON.stringify(data[0]?.attributes) !== JSON.stringify(user_data1)) {
        localStorage.setItem('selected_user_data', JSON.stringify(data[0]?.attributes));
        const eventNew = new StorageEvent('storage')
        window.dispatchEvent(eventNew)
      }
    }
    else {
      this.setState({ loading: false })
      let key = Object.keys(responseJson?.errors[0])[0]
      toast.error(responseJson?.errors[0][key]);
      (responseJson?.errors[0]?.token || responseJson?.errors == "Not Authorized, Token Expired") && this.getRefreshToken()
    }

  }
  handleRefreshTokenResponse = (responseJson: any) => {
    if (responseJson != null) {
      if (!responseJson?.errors) {
        if (responseJson?.error == "Not Authorized, Refresh Token Expired") {
          this.setState({ logout: true })
        }
        if (responseJson?.data?.token) {
          let exp_time: any = moment().add(23, "hours").format("DD/MM/YYYY hh:mm a")
          localStorage.setItem("exp_time", exp_time)
          localStorage.setItem("Token", responseJson?.data?.token)
          window.location.reload()
        }
      }
      else {
        this.setState({ logout: true })
      }
    }

  }
  handlelogOutResponse = async (responseJson: any) => {
    if (responseJson) {
      let darkMode = localStorage.getItem("darkMode") || "true"
      let deviceUniqueId = await getStorageData("deviceId")
      let lang = localStorage.getItem("i18nextLng") || "en"
      localStorage.clear()
      toast.success(responseJson.message)
      localStorage.setItem("darkMode", darkMode)
      localStorage.setItem("i18nextLng", lang)
      await setStorageData("deviceId", deviceUniqueId)
      this.props.navigation.navigate("EmailAccountLoginBlockWeb")

    }
  }

  deleteFirebaseToken = async (token: any, firebaseToken: any) => {
    let formData = new FormData();
    formData.append('token', firebaseToken);
    this.deleteFirebaseTokenApiCallId = sendAPIRequest(
      `${configJSON.deleteFirebaseTokenEndPoint}`,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token,
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
        }
      },
    );
  }

  getUserProfile() {
    this.getUserProfileApiCallId = sendAPIRequest(
      `${configJSON.getUserProfileEndPoint}`,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token")
        }
      },
    );
  }

  getRefreshToken() {
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');
    this.getRefreshTokenApiCallId = sendAPIRequest(
      `${configJSON.getRefershTokenEndPoint}`,
      {
        method: configJSON.callTypeApiValidateMobileNo, body: formData,
        headers: {
          token: localStorage.getItem("refresh_token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      },
    );
  }

  async logOut() {
    this.setState({ loading: true, logOut: false });
    await this.deleteFirebaseToken(localStorage.getItem("Token"), localStorage.getItem("firebasetoken"));
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');
    this.logOutApiCallId = sendAPIRequest(
      `${configJSON.logoutEndPoint}`,
      {
        method: configJSON.methodTypeApiDelete, body: formData,
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      },
    );
  }


  handleLogout = async () => {
    let deviceUniqueId = await getStorageData("deviceId")
    localStorage.clear()
    this.setState({ logout: false })
    await setStorageData("deviceId", deviceUniqueId)
    this.props.navigation.navigate("EmailAccountLoginBlockWeb")
  }


  handleCloseMenu = () => {
    this.props.setSettingAnchor()
  };

  handleLogoutClick = () =>{
    if(this.state.isGuestMember){
      this.handleSettingClick("EmailAccountLoginBlockWeb")
    }else{
      this.logOut()
    }
  }
  handleCloseDialog = () =>{
    if(this.state.isGuestMember){
      this.handleSettingClick("EmailAccountRegistrationWeb")
    }else{
     this.closeDialog()
    }
  }
  
  closeDialog = () =>{
    this.setState({ logOut: false });
  }

  generalSettingClick = () =>{
    const generalSettingNav: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
   generalSettingNav.addData(
      getName(MessageEnum.NavigationTargetMessage),
       "GeneralSetting")
    generalSettingNav.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const propsMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    propsMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: "members" });
    generalSettingNav.addData(getName(MessageEnum.NavigationRaiseMessage), propsMessage);
    this.send(generalSettingNav);
  }
  getTitle(guestMessage: string , upgradeMessage: string){
    return this.state.isGuestMember ? guestMessage : upgradeMessage;
    }

  handleSettingClick = (setting:string) => {
    this.props.navigation.navigate(setting)
    this.handleCloseMenu();
  };

  // Customizable Area End
}
