import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config.js");
import { sendAPIRequest } from "../../../components/src/Utility";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  headerText: any;
  t: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  movieDetails: any;
  similarMovies: any;
  webSeries: any;
  contentDetails: any;
  contentId: any;
  episodes: any;
  playVideo: boolean;
  streamingURL: string;
  userCountError: boolean;
  assetID: string;
  watchingDuration: any;
  updatePlayer: boolean;
  isSubscribed: boolean;
  updatePodcastParts: boolean;
  isMediaKind: boolean;
  isStreamingApiCalled:boolean;
  isGuest: boolean;
  loadingPodcast: boolean;
  subtitleDetails: any
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PodcastDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  assetId: any;
  getPodcastDetailAPICallId: string = "";
  createWatchHistoryAPICallId: string = "";
  getSimilarPodcastAPICallId: string = "";
  getStreamingURLCallId: string = "";
  getUserCountCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      errorMsg: "",
      token: "",
      loading: false,
      imgIndex: null,
      movieDetails: null,
      similarMovies: [],
      webSeries: null,
      contentDetails: null,
      contentId: null,
      episodes: null,
      playVideo: false,
      streamingURL: "",
      userCountError: false,
      assetID: "",
      watchingDuration: null,
      updatePlayer: false,
      isSubscribed: false,
      updatePodcastParts: false,
      isMediaKind: false,
      isStreamingApiCalled:false,
      isGuest: false,
      loadingPodcast: false,
      subtitleDetails: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const contentId = this.props?.navigation?.getParam("id");
    this.setState({ contentId }, () => {
      this.onInitialized();
    });

    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { contentId } = prevState;
    const currentContentId = this.props?.navigation?.getParam("id");
    contentId &&
      contentId !== currentContentId &&
      this.setState({ contentId: currentContentId }, () => {
        this.onInitialized();
        prevState.playVideo && this.setState({ updatePlayer: true });
        prevState.playVideo &&
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
      });
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson || responseJson.errors) {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const errorMsg =
        errorResponse === undefined ? "Something went wrong" : errorResponse;

      this.setState({
        errorMsg,
        loading: false,
      });
      return;
    }

    if (responseJson.data && responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false,
      });

      return;
    }

    this.setState({
      errorMsg: "",
      loading: false,
    });

    switch (apiRequestCallId) {
      case this.getSimilarPodcastAPICallId:
        this.handleSimilarPodcastResponce(responseJson);
        break;
      case this.getPodcastDetailAPICallId:
        this.handlePodcastDetailsResponce(responseJson);
        break;
      case this.getStreamingURLCallId:
        this.handleGetStreamingResponce(responseJson);
        break;
      case this.getUserCountCallId:
        this.handleGetUserCountResponce(responseJson);
        break;
      case this.createWatchHistoryAPICallId:
        this.handleWatchHistoryResponce(responseJson);
        break;
      default:
        break;
    }
  }

  // Customizable Area Start
  // handers
  onInitialized = async () => {
    let isGuest = await getStorageData("isGuestUser");
        if ((await isGuest) === "true") {
        this.setState({ isGuest: true });
        }
    this.getPodcastDetails();
    this.getSimilarPodcasts();
  };

  navHandler = (contentId: any) => {
    const { navigation } = this.props;
    navigation.navigate(`PodcastDetails`, { id: contentId });
  };

  handleSimilarPodcastResponce = (json: any) => {
    const similarMovies = (json?.similar_contents || []).map(
      (podcast: any) => podcast?.content?.data
    );
    this.setState({ similarMovies });
  };

  handleGetUserCountResponce = (json: any) => {
    this.setState({ loading: false });
    if (parseInt(json.screen_count) > parseInt(json.player_count) || this.state.isGuest) {
      this.setState({ playVideo: true });
      this.getStreamingURLApi(this.state.assetID);
    } else {
      this.setState({ userCountError: true });
    }
  };

  handleGetStreamingResponce = (json: any) => {
    this.setState({ loading: false });
    if(json?.streaming_urls){
    const {streaming_urls} = json
    const { streamingPaths } = streaming_urls;
    let isMediaKind : boolean = !streaming_urls?.hasOwnProperty('hls_asset_id')
    const baseURL = isMediaKind ? configJSON.mediaKindBaseUrl :"https://vodemedia-usea.streaming.media.azure.net";
    const dashPath = (streamingPaths || []).filter(
      (element: any) => element.streamingProtocol == "Dash"
    );
    if (dashPath?.length > 0) {
      this.setState({isMediaKind,  streamingURL: `${baseURL}${dashPath[0].paths[0]}` , isStreamingApiCalled: false});
    } else {
      this.setState({ playVideo: false, streamingURL: "" });
      toast.error(this.props?.t("movieDetails.NoSreamUrlFound"));
    }
  }
  else{
    if(!this.state.isStreamingApiCalled){
      this.setState({isStreamingApiCalled: true},()=>{
          this.getStreamingURLApi(this.state.assetID)
      })
  }    
  }
  };

  onClosePlayer = () => {
    this.setState({ playVideo: false, assetID: "", streamingURL: "" });
  };

  onPlayVideo = (data: any) => {
    const { assetId } = data;
    const { isSubscribed } = this.state;

    if (
      assetId == "" ||
      assetId == undefined ||
      assetId == null ||
      !isSubscribed
    ) {
      this.setState({ playVideo: false });
      this.handlePerkClick()
    } else {
      this.setState({ assetID: assetId }, () => this.getUserCount());
    }
  };

  updateState = (responceData: any) => {
    const { like, updatePodcastParts } = responceData;
    this.setState({ updatePodcastParts });
    if (like) {
      const { contentDetails } = this.state;
      this.setState({ contentDetails: { ...contentDetails, like } });
    }
  };

  getUserCount = () => {
    this.setState({ loading: true });
    this.getUserCountCallId = sendAPIRequest(configJSON.getUserCount, {
      headers: {
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
        token: localStorage.getItem("Token"),
      },
    });
  };

  getStreamingURLApi = (assetId: any) => {
    this.setState({ loading: true });
    let streamingEndPoint = this.state.isStreamingApiCalled ? configJSON.getMKStreamingUrl : configJSON.getStreamingUrlWeb
    this.getStreamingURLCallId = sendAPIRequest(
      streamingEndPoint + assetId,
      {
        headers: {
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
          token: localStorage.getItem("Token"),
        },
      }
    );
  };

  // Get Web Series API

  getPodcastDetails = () => {
    this.setState({ loading: true, loadingPodcast: true });
    this.getPodcastDetailAPICallId = sendAPIRequest(
      configJSON.getPodcastDetails +
        `/${this.state.contentId}?profile_id=${localStorage.getItem(
          "selectedUser"
        )}&web=true`,
      {
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
        },
      }
    );
  };

  getSimilarPodcasts = () => {
    this.setState({ loading: true });
    this.getSimilarPodcastAPICallId = sendAPIRequest(
      `${configJSON.getSimilarPodcast}?id=${
        this.state.contentId
      }&profile_id=${JSON.parse(localStorage.getItem("selectedUser") || "")}`,
      {
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
        },
      }
    );
  };

  createWatchHistory = (
    asset_id: any,
    is_start: any,
    is_completed: any,
    duration: any,
    watching_duration: any,
    subtitleDetails?: any,
  ) => {
    this.setState({ watchingDuration: watching_duration });
    this.createWatchHistoryAPICallId = sendAPIRequest(
      `${configJSON.createWatchHistoryAPIEndPoints}?profile_id=${JSON.parse(
        localStorage.getItem("selectedUser") || ""
      )}&is_start=${is_start}&is_completed=${is_completed}&duration=${duration}&watching_duration=${watching_duration}&asset_id=${asset_id}`,
      {
        headers: {
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
          token: localStorage.getItem("Token"),
        },
        method: "POST",
      }
    );
  };

  handleWatchHistoryResponce = (json: any) => {
    if (json?.data) {
      this.setState((prevState) => ({
        contentDetails: {
          ...prevState.contentDetails,
          watch_history: json?.data?.attributes,
        },
      }));
    }
  };
  handlePerkClick = () => {
    const podcastNavigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     podcastNavigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
            "Perks")
      podcastNavigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(podcastNavigationMes);
}
getDialogMes(guestMessage: string , upgradeMessage: string){
  return this.state.isGuest ? guestMessage : upgradeMessage;
}


  handlePodcastDetailsResponce = (json: any) => {
    this.setState(
      {
        contentDetails: {
          ...json?.data?.attributes,
          ...json?.data?.attributes?.fav_status,
        },
        isSubscribed: json?.meta?.subscription,
      },
      () => {
        if (this.state.updatePlayer) {
          this.onPlayVideo({ assetId: this.state.contentDetails.asset_id });
          this.setState({ updatePlayer: false });
        }
        this.setState({loadingPodcast: false})
      }
    );
  };

  // Customizable Area End
}
