import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utility";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  children: React.ReactNode;
  navigation: any;
  token?: string;
  classes: any;
}
interface S {
  // Customizable Area Start
  logout: boolean;
  isStatic: boolean;
  isDetailsPage: boolean;
  hideshow: boolean;
  isGeneralSettingPage: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getValidTokenApiCallId: string = "";
  getRefreshTokenApiCallId: string = "";
  logOutApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SidebarHideShowMessage)
    ];

    this.state = {
      logout: false,
      isStatic: false,
      isDetailsPage: false,
      hideshow: false,
      isGeneralSettingPage: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {    
    super.componentDidMount();
    let routeUrl = window.location.href.toLowerCase()
    const staticRoutes = ['aboutus', 'termsandconditions', 'helpandfaqweb', 'privacypolicy',"confirmation"];
    const detailsRoutes = ['podcastdetails', 'dashboard' , 'tvshows', 'movies', 'moviedetails', 'podcasts']
    const isDetailsPage = detailsRoutes.some((route : string )=> routeUrl.includes(route));
    isDetailsPage && this.setState({isDetailsPage})
    const isStaticRoute = staticRoutes.some((route : string )=> routeUrl.includes(route));
    let isGeneralSettingPage = routeUrl.includes("generalsetting")
    isGeneralSettingPage && this.setState({isGeneralSettingPage})
    if (isStaticRoute && 
      localStorage.getItem('Token') === null) {
        this.setState({isStatic: true})
    } else {
      this.getToken();
      let exp_time = localStorage.getItem("exp_time")
      if (exp_time) {
        moment().isAfter(moment(exp_time, "DD/MM/YYYY hh:mm a")) && this.getRefreshToken()
      }
    }
    window.scrollTo(0, 0);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getValidTokenApiCallId:
          this.handleValidTokenResponse(responseJson);
          break;
        case this.getRefreshTokenApiCallId:
          this.handleRefreshTokenResponse(responseJson);
          break;
        case this.logOutApiCallId:
          this.handleLogOutResponse(responseJson);
          break;
      }
    }

    if (getName(MessageEnum.SidebarHideShowMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.SidebarHideShowMessageData)
      );
      this.setState({ hideshow : data.hideShowState})
     console.log(data,"data testingg")
    }
  }

  getToken = () => {
    this.getValidTokenApiCallId = sendAPIRequest(
      configJSON.getUserProfileEndPoint,
      {
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en',
          "Content-Type": configJSON.dashboarContentType,
        }
      }
    );
  }

  getRefreshToken = () => {

    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');

    this.getRefreshTokenApiCallId = sendAPIRequest(
      configJSON.getRefershTokenEndPoint,
      {
        method: configJSON.postMethod, body: formData,
        headers: {
          token: localStorage.getItem("refresh_token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  handleLogout = () => {
    this.setState({ logout: false })
    const headers = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
    };
    let formData = new FormData();
    formData.append('device_unique_id', localStorage.getItem("deviceId") || '');
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logOutApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiDelete
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // API Response
  handleValidTokenResponse = (json: any) => {
    if (json != null) {
      if (!json?.errors) {
        let selectedId = localStorage.getItem("selectedUser") || ""
        let data: any[] = json?.data?.filter((_data: any) => _data.id == selectedId)
        let user_data1 = localStorage.getItem('selected_user_data');
        if (JSON.stringify(data[0]?.attributes) !== JSON.stringify(user_data1)) {
          localStorage.setItem('selected_user_data', JSON.stringify(data[0]?.attributes));
          const eventNew = new StorageEvent('storage')
          window.dispatchEvent(eventNew)
        }
      }
      if (json?.errors) {
        json?.errors[0]?.message === "Please login again." && this.setState({ logout: true });
        (json?.errors[0]?.token || json?.errors == "Not Authorized, Token Expired") && this.getRefreshToken()
      }
    }
  }

  handleRefreshTokenResponse = (json: any) => {
    if (json != null) {
      if (!json?.errors) {
        if (json?.error == "Not Authorized, Refresh Token Expired") {
          this.setState({ logout: true })
        }
        if (json?.data?.token) {
          let exp_time: any = moment().add(23, "hours").format("DD/MM/YYYY hh:mm a")
          localStorage.setItem("exp_time", exp_time)
          localStorage.setItem("Token", json?.data?.token)
          window.location.reload()
        }
      }
      else {
        this.setState({ logout: true })
      }
    }
  }

  handleLogOutResponse = async (json: any) => {
    if (json) {
      let deviceUniqueId = await getStorageData("deviceId")
      let darkMode = localStorage.getItem("darkMode") || "true"
      let lang = localStorage.getItem("i18nextLng") || "en"
      localStorage.clear()
      toast.success(json.message)
      localStorage.setItem("darkMode", darkMode)
      localStorage.setItem("i18nextLng", lang)
      await setStorageData("deviceId", deviceUniqueId)
      this.props.navigation.navigate("EmailAccountLoginBlockWeb")
    }
  }
}
