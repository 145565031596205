import React from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
//@ts-ignore
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
import "./Dashboard.css";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
export const configJSON = require("./config.js");




export const MovieSwiper = (props: any) => {
  const { moviesData, navHandler, imgIndex, t, classes }: any = props;
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 600px)');
  

  let mySwiperData: any = {
    spaceBetween: 0,
    speed: 300,
    watchSlidesProgress: true,
    grabCursor: true,
    slidesPerView: 1,
    effect: "coverflow",
    centeredSlides: true,
    loop: true,
    direction: 'horizontal',
    observer: true,
    observeParents: true,
    pagination: {
      clickable: true,
      pagination: '.swiper-pagination',
      dynamicBullets: true,
      dynamicMainBullets: 2,

    },
    navigation: true,
    className: localStorage.getItem("darkMode") == "false" ? 'mySwiper mySwiper_light' : 'mySwiper'
  }

  return (
  <Grid className={`${classes.Slider}`}>
      {moviesData === null || undefined ? (null) :
                <Swiper data-test-id="swiper" {...mySwiperData}>
                  {moviesData.map((item: any, index: any) => {
                    const backgroundImage = isMobile
                    ? `url(${item?.attributes?.mobile_image})`
                    : `url(${item?.attributes?.image})`;
                    return (
                      <SwiperSlide key={item.id} style={{backgroundImage: backgroundImage}} className={item === imgIndex ? "slide activeSlide" : "slide"}>
                        {/* <img src={} alt="" className="slideImage" /> */}
                        <div className={classes.sliderBackground}>
                        </div>
                        <Grid data-test-id={`playButton-${index}`} className={`${classes.arabicButtons} buttons`} onClick={() => navHandler(item.id)}>
                          <Typography color="primary" className={classes.movieTittle}>
                            {item.attributes.title}
                          </Typography>
                          <Box className={classes.genersBox} >
                {item.attributes?.genres?.length && <Box className={classes.divider} />}
                <Typography className={classes.shortDetails}>
                  {item.attributes?.genres && item.attributes?.genres?.length > 0
                    ? (
                        (item.attributes?.genres || []).map(
                          (el: any) => el
                        ) || []
                      ).join("/ ")
                    : t("genre.comdey")}
                </Typography>
                <Box className={classes.divider} />
                <Typography className={classes.shortDetails}>
                  {item.attributes?.publish_date
                    ? moment(item.attributes?.publish_date).format("YYYY")
                    : moment().format("YYYY")}
                </Typography>
                <Box className={classes.divider} />
                {item.attributes?.rating && (
                  <Typography className={classes.shortDetails}>
                    {item.attributes?.rating}
                  </Typography>
                )}               
              </Box>
                        </Grid>
                       
                      </SwiperSlide>
                    )
                  })
                  }
                </Swiper>
              }           
      </Grid>
   
  )
}

export const swiperStyle:any = (theme: any) => ({
    sliderContainer: {
      background: 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)',
      position: 'relative',
      top: 0,
    },
    DownloadImage: {
      width: "20px",
      height: "20px !important",
      margin: theme.direction === "rtl" ? "0 0 0 10px" : "0 10px 0 0",
      filter: localStorage.getItem("darkMode") == "false" ? "invert(1)" : 'none'
    },
    DownloadButton: {
      width: "150px",
      height: "45px",
      margin: theme.direction === "rtl" ? "0 0 0 10px" : "0 10px 0 0",
      backgroundColor: theme.palette.background.blackDark,
      border: "1px solid #979797",
      "&:hover": {
        backgroundColor: theme.palette.background.blackDark,
      },
      [theme.breakpoints.down("sm")]: {
        width: "140px",
      },
    },
    shortDetails: {
      opacity: 1,
      lineHeight: "24px",
      alignItems: "center",
      color: theme.palette.primary.main,
      fontFamily: "Araboto-Normal",
      fontWeight: 500,
      width: "fit-content",
      [theme.breakpoints.only("xs")]: {
        fontSize: 20,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 22,
      },
      fontSize: 26,
     
    },
    genersBox: {
      display: "flex",
      alignItems: "center",     
      justifyContent: "center"  
    },
    divider: {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      background: theme.palette.primary.main,
      margin: "auto 12px",
    },
    playButton: {
      width: "150px",
      height: "46px",
      backgroundColor: "rgba(234, 67, 93, 1)",
      opacity: 1,
      "&:hover": {
        // opacity: 0.9,
        backgroundColor: "rgba(234, 67, 93, 1)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "140px",
      },
    },
    movieTittle: {
     textAlign: "center",
      fontFamily: "Roboto",
      fontWeight: 700,
      marginBottom: "24px",
      [theme.breakpoints.only("md")]: {
        fontSize: 48,
      },
     [theme.breakpoints.only("xs")]: {
        fontSize: 42,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 45,
      },
       fontSize: 50,
      
    },
    font_Size_26: {
      textAlign: "center",
      fontFamily: "Roboto",
      fontWeight: 700,
      marginBottom: "20px",
      color: "#EDDBDB",
      [theme.breakpoints.only("xs")]: {
          fontSize: 22,
      },
      fontSize: 26,
      [theme.breakpoints.only("sm")]: {
          fontSize: 24,
      }      
  },
    SliderWrapper: {
      margin: theme.direction === "rtl" ? '0 5% 0 0' : '0 0 0 5%'
    },
    DownloadText: {
      color: theme.palette.primary.main,
      fontSize: "20px",
      lineHeight: "20px",
      textTransform: "capitalize",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
    },
    Slider: {
      margin: 'auto',
      width: '100%',
      height: '80vh',
      maxHeight: "800px"
      // background: `${theme.palette.type == "dark" ? 'radial-gradient(rgba(22, 22, 29, 1) 0%, rgba(16, 16, 20, 1) 100%)' : 'white'}`
    },
    movieNames: {
      color: '#fff',
      position: 'absolute',
      top: 170,
      textAlign: 'center',
      fontSize: 20,
    },
    dashboardContainer: {
      margin: '0px',
      padding: '0px',
      overflowX: 'hidden',
      boxSizing: 'border-box',
      // background: 'black'
    },
    arabicButtons: {
      width: "85%",
      justifyContent: "center",
      position: "absolute",
      bottom: "30px",
      flexDirection : "column",
      left: "50%",
      transform: "translateX(-50%)",
      [theme.breakpoints.down("sm")]: {
        bottom: "20px",
      },
    },
    sliderBackground:{
      width: "100%",
      height: "100%",
      position: "absolute",
      background: "linear-gradient(2.29deg, #07070E -4.13%, #000000 -4.13%, rgba(5, 5, 11, 0.661538) 15.21%, rgba(4, 4, 8, 0.318163) 80.71%, #07070E 102.98%)"

    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#fff',
      margin: '10px 5vw',
      background: 'transparent',
      boxShadow: 'none'
    },
    leftHeader: {
      display: 'flex',
    },
    rightHeader: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    play: {
      width: '70px',
      height: '70px',
      position: 'relative',
      top: -120,
      right: -80,
      [theme.breakpoints.only("xs")]: {
        width: '50px',
        height: '50px',
        top: -105,
        right: -90,
      },
    },
  })

export default withStyles(swiperStyle)(withTranslation()(MovieSwiper));


