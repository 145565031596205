import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface SubscriptionPerksInter {
  id: string,
  attributes: {
    name: string, 
    id: number,
    account_id: number,
    photo: string
  }
}
interface PlanPerkInterface {
  id: string,
  attributes: {
    priority: number,
    is_disable: boolean,
    name: string
  }

}


export interface Props {
  navigation: any;
  id: string;
  classes:any;
  t: any;
}

export interface S {
  // Customizable Area Start
  
  SubscriptionPerksDetails:string[];
  isRegister: boolean;
  isLoading : boolean;
  buttonName: string;
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  
  // Customizable Area End
}

export default class DowngradePerksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMemberDetailsApiCallId: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      SubscriptionPerksDetails:[],
      isRegister: false,
      isLoading : false,
      buttonName: ""  
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
 
  async componentDidMount() {
    super.componentDidMount();
    this.getSubscriptionPerks()
    let isRegister = await getStorageData("isGuestUser");
    if ((await isRegister) === "true") {
    this.setState({ isRegister: true });
   }
   this.getSubscriptionPerks()  
    
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
       let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );     
  
        if (apiRequestCallId === this.getMemberDetailsApiCallId) {
            if (!responseJson.errors) {
              let perkData = responseJson?.packages?.data
                let buttonName= ""
                !this.state.isRegister && (buttonName = this.getButtonName(perkData))
                let {smallest , largest} = this.findferks(perkData)
                this.setState({ SubscriptionPerksDetails: this.state.isRegister ? smallest?.attributes?.perk_desc : largest?.attributes?.perk_desc, buttonName},()=>this.setState({isLoading: false}))                
            }
        }
      }
   
    // Customizable Area End
  }

  // Customizable Area Start
  
 async getSubscriptionPerks() {
  this.setState({isLoading : true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("Token"),
      language: await getStorageData("i18nextLng")
    };
  
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMemberDetailsApiCallId = getValidationsMsg.messageId;
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPerksEndPoint
    );
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  navigatetoScreen = () =>{
    const navigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     navigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
        this.state.isRegister ? "EmailAccountRegistrationWeb" : "GeneralSetting")
      navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedTab: "plan_details" });
      navigationMes.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(navigationMes);
  }

goToDashboard =() =>{
    removeStorageData("lastScreen")
    const navigationMes: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
     navigationMes.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "DashboardWeb")
      navigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigationMes);
}

  navigateBack = async () =>{
    let screeName = await getStorageData("lastScreen") 
    screeName === "Chat" ? this.goToDashboard() :  this.props.navigation.goBack()
  }

    findferks(packages: {
        attributes: {
            priority: number,
            perk_desc: string[]
        }
    }[]) {

        let smallestPriorityPackage = packages[0];
        let largestPriorityPackage = packages[0];

        for (let i = 1; i < packages.length; i++) {
            const currentPackage = packages[i];
            if (currentPackage.attributes.priority < smallestPriorityPackage.attributes.priority) {
                smallestPriorityPackage = currentPackage;
            }

            if (currentPackage.attributes.priority > largestPriorityPackage.attributes.priority) {
                largestPriorityPackage = currentPackage;
            }
        }

        return {
            smallest: smallestPriorityPackage,
            largest: largestPriorityPackage
        };
    }

    getButtonName = (plans : PlanPerkInterface[]) =>{
      const enabledPlans = plans
     .filter((plan: PlanPerkInterface) => !plan.attributes.is_disable) 
     .sort((_planFirst:PlanPerkInterface, _planSecond:PlanPerkInterface) => _planFirst.attributes.priority - _planSecond.attributes.priority); 
     let planName = enabledPlans.map((plan:PlanPerkInterface) => plan.attributes.name).join(" / ");
     return planName
    }
  
  
   // Customizable Area End
}
