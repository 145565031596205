import React from "react";
// Customizable Area Start

import {
  Button,
  Typography,
  Grid,
  withStyles,
  Popover,
  MenuItem
} from '@material-ui/core'
// Customizable Area End
import LandingPageController, { Props } from "./LandingPageController";
import { background1, background2, background3,  Image1, Image3, Image2, arrow, backgroundNew } from "./assets";
import { ArrowDropDown } from "@material-ui/icons";
import { withTranslation } from 'react-i18next';
import HeaderLogo from "./CustomIcon/HeaderLogo";
import FooterComponent from "./Footer.web";

export const themeCreatStyle = (theme: any) => {
  let themeColor = (arrValue: any, enValue: any) => theme.palette.type == "dark" ? arrValue : enValue
  return {
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    backgroundImage_div: {
      backgroundImage: `url(${backgroundNew})`,
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      [theme.breakpoints.only("xs")]: {
        padding: "150px 30px 60px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "170px 80px 75px",
      },
      padding: "250px 100px 110px",
      [theme.breakpoints.only("md")]: {
        padding: "225px 80px 100px",
      },
    },
    backgroundImage_div2: {
      backgroundImage: `url(${background3})`,
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      [theme.breakpoints.only("xs")]: {
        padding: "50px 20px 49px 30px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "60px 20px 59px 30px",
      },
      [theme.breakpoints.only("lg")]: {
        padding: "100px 40px 99px 80px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "100px 40px 99px 80px",
      },
      [theme.breakpoints.only("md")]: {
        padding: "90px 30px 89px 70px",
      },
    },
    icon: {
      color: themeColor("white","black"),
      fill: themeColor("white","black")
    },
    backgroundImage_div3: {
      backgroundImage: `url(${background1})`,
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      [theme.breakpoints.only("md")]: {
        padding: "90px 30px 89px 70px",
      },      
      [theme.breakpoints.only("sm")]: {
        padding: "60px 20px 59px 30px",
      },
      padding: "100px 40px 99px 80px",
      [theme.breakpoints.only("xs")]: {
        padding: "50px 20px 49px 30px",
      },
     
    },
    backgroundImage_div1: {
      backgroundImage: `url(${background2})`,
      maxWidth: "100%",
      width: "100vw",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      [theme.breakpoints.only("xs")]: {
        padding: "50px 20px 49px 30px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "60px 20px 59px 30px",
      },
      [theme.breakpoints.only("lg")]: {
        padding: "100px 40px 99px 120px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "100px 40px 99px 120px",
      },
      [theme.breakpoints.only("md")]: {
        padding: "90px 30px 89px 90px",
      },
    },
    flex_css: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.only("xs")]: {
        flexDirection: 'column-reverse'
      },
    },
    languageSelect: {
      paddingRight: '0px',
      marginLeft:  theme.direction === "rtl" ?  10 : 'none'
    },
    watch_css: {
      filter: `${theme.palette.type == "dark" ? 'none' : 'invert(1)'}`,
      maxWidth: '100%',
      [theme.breakpoints.only("xs")]: {
        width: '100%',
        marginBottom: '20px'
      },
      [theme.breakpoints.only("sm")]: {
        width: '600px',
        height: '200px',
        marginBottom: '20px'
      },
      [theme.breakpoints.only("lg")]: {
        width: '800px',
        height: '260px',
        marginBottom: '30px'
      },
      [theme.breakpoints.up("lg")]: {
        width: '800px',
        height: '260px',
        marginBottom: '30px'
      },
      [theme.breakpoints.only("md")]: {
        width: '800px',
        height: '260px',
        marginBottom: '30px'
      },
    },
    Image1_css: {
      width: '331x',
      height: '301px',
      [theme.breakpoints.only("xs")]: {
        width: '331x',
        height: '301px',
        marginBottom: '20px'
  
      },
      [theme.breakpoints.only("sm")]: {
        width: '301x',
        height: '271px',
        maxWidth: '100%',
  
      },
      maxWidth: '100%',
    },
    MenuItems: {
      fontSize: 18,
      minHeight: 20,
      color: theme.palette.primary.main,
      padding: 10,
      justifyContent: "center",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
      "&:hover": {
          background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
          color: theme.palette.primary.main,
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      },
  },
    logo_div: {
      width: '100%',
      [theme.breakpoints.only("xs")]: {
        padding: "20px 20px 10px",
      },
      [theme.breakpoints.only("sm")]: {
        padding: "30px 60px 10px",
      },
      [theme.breakpoints.only("lg")]: {
        padding: "40px 80px 10px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "40px 80px 10px",
      },
      [theme.breakpoints.only("md")]: {
        padding: "35px 70px 10px",
      },
    },
    apply_coupon_modal: {
      width:'32%',
      maxWidth: 185,
      background: theme.palette.type === "dark" ? "linear-gradient(270deg, rgba(18, 18, 26, 0.8) 0%, rgba(18, 18, 26, 1) 40%, rgba(23, 23, 39, 1) 100%)" : "#ffffff",
      backgroundSize: "100% 100%",
      boxShadow: "4px 1px 10px 4px rgba(0,0,0, 0.5)",      
      border: '1px solid black'    
  },
    logo_css: {
      [theme.breakpoints.only("xs")]: {
        width: 95,
        height: 22
      },
      [theme.breakpoints.only("sm")]: {
        width: 115,
        height: 26
      },
      [theme.breakpoints.only("lg")]: {
        width: 135,
        height: 32
      },
      [theme.breakpoints.up("lg")]: {
        width: 135,
        height: 32
      },
      [theme.breakpoints.only("md")]: {
        width: 135,
        height: 32
      },
    },
    description_text: {
      color: theme.palette.secondary.main,
      fontFamily: "Roboto",
      fontWeight: 400,
      fontStyle: "normal",
      letterSpacing: 0,
    },
  
    font_size_22: {
      fontFamily: "Araboto",
      fontWeight: 700,
      textAlign: "center" as const,
      [theme.breakpoints.only("xs")]: {
        fontSize: 22,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 24,
      },
      fontSize: 26
    },
  
    font_size_24: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 18,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 22,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 21,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 24,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 24,
      },
    },
  
    button_css: {
      color: "white",
      borderRadius: 8,
      opacity: 0.9,
      backgroundColor: `rgba(36,40,48, 1)`,
  
      [theme.breakpoints.only("xs")]: {
        fontSize: 11.5,
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 13.5,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 12.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 14,
      },
      "&:hover": {
        opacity: 1,
        backgroundColor: `rgba(36,40,48, 1)`,
      },
    },
    language_button_css: {
      display: 'flex',
      justifyContent: "space-between",
      borderWidth: '1.2px',
      color: `${theme.palette.type == "dark" ? "#FFFFFF" : "black"}`,
              borderStyle: 'solid',
              background: "#222222",
              borderImageSlice: 1,
              fontWeight: 700,
              opacity: 1,
              borderImage: "linear-gradient(90deg, #E9445E 0%, #5772A9 48%, #288AA2 100%)",
      fontSize: 18,
      margin:  theme.direction === "rtl" ? '0 0 0 20px' : '0 20px 0 0',
      textTransform: 'capitalize' as const,
      padding : "4px 10px",
      maxWidth: 180,
      border: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.only("xs")]: {
        padding : "4px 6px",
        margin:  theme.direction === "rtl" ? '0 0 0 13px' : '0 12px 0 0',
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      "&:hover" :{
        background : "#222222"
      }       
    },
    topHeadingDiv:{
      display: "flex",
      [theme.breakpoints.only("xs")]: {
        padding: "35px 25px",
      },
      flexDirection: "column" as const,
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      borderRadius: "8px",
      alignItems: "center",
      padding: "55px 50px",
      maxWidth: 600,
      
    },
    redeem_button_css: {
      backgroundColor: `rgba(234,67,93, 1)`,
      color: `${theme.palette.type == "dark" ? "#FFFFFF" : "black"}`,
      borderRadius: 10,
      fontWeight: 700,
      opacity: 1,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      "&:hover": {
        opacity: 0.9,
        backgroundColor: `rgba(234,67,93, 1)`,
      },
      "&:disabled": {
        opacity: 0.5,
        color: `white`,
      },
  
    },
    margin_40: {
      margin: "40px 0 15px",
      [theme.breakpoints.only("xs")]: {
        margin: "25px 0 10px",
      },
  
      [theme.breakpoints.only("sm")]: {
        margin: "30px 0 10px",
      },
  
    },
    font_size_16: {
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 15.5,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 14.5,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
      },
    },
    heading: {
      color: theme.palette.primary.main,
      fontFamily: "Roboto",
      fontWeight: 700,
      fontStyle: "normal",
      letterSpacing: 0,
      lineHeight: 1,
      breakWord: "break-all",
  
      [theme.breakpoints.only("xs")]: {
        fontSize: 36,
        marginBottom: 22,
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 42,
        marginBottom: 27,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 40,
        marginBottom: 25,
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: 44,
        marginBottom: 30,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 44,
        marginBottom: 30,
      },
    },
    scrollBar: {
      scrollbarColor: '#B7B7B7 transparent',
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 6,
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 90%)',
        minHeight: 24,
        minWidth: 24,
      },
      '&::-webkit-scrollbar-thumb:focus': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'linear-gradient(270deg, #474b4c  0%, #6869AC 100%)',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent',
      },
    },
    image_direction: {
      transform: theme.direction == "rtl" ? 'scaleX(-1)' : 'none',
      margin: '0 10px',
      filter: theme.palette.type == "dark" ? 'none' : 'invert(1)'
    },
    selectStyle: {
      color: theme.palette.primary.main,
      backgroundColor: `${themeColor("black", "white")} !important`
    }
  }
};

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { t }: any = this.props
    return (
      //Merge Engine DefaultContainer
      <Grid container>
        <Grid item xs={12}>
          <Grid container >
            <Grid item xs={12} className={classes.logo_div} style={{ position: 'fixed', zIndex: 9999999999 }}>
              <Grid container>
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                  <HeaderLogo width='80' height='80'/>
                </Grid>
                <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' , alignItems:'center' }}>
                  <Button
                  data-test-id="languageButton"
                  className={classes.language_button_css}
                  fullWidth 
                  onClick={(event: any) =>
                    this.setlanguageMenu(event.currentTarget)
                }
                  >
                    <span>{this.getLanguageName()}</span>
                      <ArrowDropDown className={classes.icon} />
                  </Button>
                  <Button className={classes.redeem_button_css}
                    fullWidth
                    variant="contained"
                    data-test-id="loginBtn"
                    style={{
                      textTransform: 'capitalize',
                      maxWidth: '160px',
                      maxHeight:52
                    }}
                    onClick={() => this.navToLogin()}>
                    {t("login.buttonName")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.backgroundImage_div}>
              <Grid container justifyContent="center">
                <Grid item xs={12} className={classes.topHeadingDiv}>
                  <Typography className={classes.font_size_22} color="primary">
                    {t("loginScreen.subheading")}
                  </Typography>
                
                <Button className={`${classes.redeem_button_css} ${classes.margin_40}`}
                  fullWidth
                  variant="contained"                  
                  data-test-id="loginBtn1"
                  onClick={() => this.navToLogin()}
                  style={{
                    maxWidth: '250px',
                    position: 'initial'
                  }}>
                  {t("landing_page_info.screen1.button")} <img src={arrow} className={classes.image_direction} alt="right_arrow" />
                </Button>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} className={classes.backgroundImage_div2}>
              <Grid container style={{
                alignItems: 'center'
              }}>
                <Grid item xs={12} md={5} sm={4} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <img src={Image1} alt='watch' className={classes.Image1_css} />
                </Grid>
                <Grid item md={7} sm={8} xs={12}>
                  <Typography className={`${classes.heading}`}>
                    {t("landing_page_info.screen2.heading")}
                  </Typography>
                  <Typography className={`${classes.description_text} ${classes.font_size_24}`}>
                    {t("landing_page_info.screen2.subHeaing")}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} className={classes.backgroundImage_div1}>
              <Grid container className={classes.flex_css} >

                <Grid item md={7} sm={8} xs={12}>
                  <Typography className={`${classes.heading}`}>
                    {t("landing_page_info.screen3.heading")}
                  </Typography>
                  <Typography className={`${classes.description_text} ${classes.font_size_24}`}>
                    {t("landing_page_info.screen3.subHeaing")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} sm={4} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <img src={Image2} alt='watch' className={classes.Image1_css} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.backgroundImage_div3}>
              <Grid container style={{
                alignItems: 'center'
              }}>
                <Grid item xs={12} md={5} sm={4} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <img src={Image3} alt='watch' className={classes.Image1_css} />
                </Grid>
                <Grid item md={7} sm={8} xs={12}>
                  <Typography className={`${classes.heading}`}>
                    {t("landing_page_info.screen4.heading")}
                  </Typography>
                  <Typography className={`${classes.description_text} ${classes.font_size_24}`}>
                    {t("landing_page_info.screen4.subHeaing")}
                  </Typography>
                </Grid>
              </Grid>


            </Grid>
            <FooterComponent {...this.props} isLanding={true}/>

          </Grid>
        </Grid>
        <Popover
          data-test-id="close"
          anchorEl={this.state.languageMenu}
          open={Boolean(this.state.languageMenu)}
          onClose={() => { this.setlanguageMenu(null) }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          style={{
            backgroundColor: "rgba(16,16,20, 0.60108)",
            opacity: 1,
          }}
          PaperProps={{
            className: classes.apply_coupon_modal,
            style: {
              maxWidth: '185px'
            },
          }}
        >
          <Grid
            container
            style={{
              display: 'block',
              borderRadius: 10,
              height: "100%",
              width: "100%",
            }}
          >
            {this.state.languageList.map((_data: any) => {
              return (
                <MenuItem key={_data.id} className={classes.MenuItems} data-test-id="select_language"
                  onClick={() => { this.changeLanguages(_data.code) }
                  }>{_data.name}</MenuItem>
              )
            })}
          </Grid>
        </Popover>
      </Grid >
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(LandingPage));
// Customizable Area End
