import React from "react";
import {
    Grid,
    Modal,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { modalBackground } from "../assets";


export const themeCreatStyle = (theme: any) => ({
    deleteDiv: {
        fontFamily: "Araboto",
        fontSize: "40px",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",

        color: theme.palette.primary.main,
        [theme.breakpoints.only("xs")]: {
            fontSize: 30,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 37,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 32,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 40,
        },
        
    },
    deleteMes1: {
        fontFamily: "Araboto",
       fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",

        color: theme.palette.secondary.main,
        margin: '31px 2px 68px 3px',

    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
      margin: '21px 0px 48px 0px',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 17.5,
      margin: '28px 0px 58px 0px',
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16.5,
      margin: '25px 0px 53px 0px',
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 18,
      margin: '31px 0px 68px 0px',
    },
  
    },
    deleteMes2: {
        fontFamily: "Open sans",
        fontSize: "14px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",

        color: "#959393",
        margin: '6px 53px 40px 54px',
    },
   
    deleteText: {
        fontFamily: "Araboto",
        fontWeight: 700,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.1px",
         fontSize: 20,
        [theme.breakpoints.only("xs")]: {
            fontSize: 17.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 19,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 18.5,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 20,
        },
    

    },
    
    deleteButton: {
        width:'80%',
        margin: theme.direction == "ltr" ? '0 0px 0 20px' : "0 20px 0 0",
        padding: '9px 20px 12px 22px',
        borderRadius: '10px',
        backgroundColor: '#EA435D',
        color:theme.palette.primary.main,
        '&:hover':{
            backgroundColor: '#EA435D',
        }

    },
    popoverDesign: {
        borderRadius: 8, 
        backdropFilter: "blur(4px)",
        direction: theme.direction
    },
    backgroundImage_div: {
        background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
        borderRadius: 8,
    padding:'50px 60px',
    [theme.breakpoints.only("lg")]: {
        padding:'50px 60px',
    },
    [theme.breakpoints.only("xs")]: {
        padding:'30px 20px',
    },
    [theme.breakpoints.only("md")]: {
        padding:'40px 50px',
    },
    [theme.breakpoints.only("sm")]: {
        padding:'35px 40px',
    },
    // height: "100%",
}
});

interface Props {
    title: string;
    deleteModal: boolean;
    onCloseModal: any;
    deleteClick: any;
    confirmMes: any;
    classes: any;
    okButtonText: any;
    disableButton?:boolean;
}

export const SuccessDialog = ({
    title,
    deleteModal,
    onCloseModal,
    deleteClick,
    confirmMes,
    classes,
    okButtonText,
    disableButton

}: Props) => {
    return (
        <>
            <Modal
                open={deleteModal}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.popoverDesign}
            >
                <Grid
                    style={{
                        justifyContent: "center",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        // backgroundColor: 'white',
                        borderRadius: "12px",
                        
                        width: "500px",
                        maxWidth: '80%'
                    }}
                    className={classes.backgroundImage_div}
                >
                    <div className={classes.deleteDiv} style={{
                        textAlign: "center"
                    }}>{title}</div>
                   
                    <div className={classes.deleteMes1} style={{
                        textAlign: "center"
                    }}>{confirmMes}</div>
                    {/* <div className={classes.deleteMes2}>{description}</div> */}
                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                       
                        <Button variant="contained" className={classes.deleteButton}
                        disabled={disableButton}
                            style={{
                                textTransform: "initial"
                            }} onClick={deleteClick}><span className={classes.deleteText}>{okButtonText}</span></Button>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
}
export default withStyles(themeCreatStyle)(SuccessDialog);
