import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    Button,
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";

import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { backarrow, favIcon, removeIcon } from "./assets";
import { withTranslation } from 'react-i18next';
// Customizable Area End

export const themeCreatStyle = (theme: any) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1),
        },
    },
    backgroundImage_div: {
        [theme.breakpoints.only("xs")]: {
            padding: "10px 15px",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "10px 21px 19px",
        },
        padding: "10px 50px 29px",
       
    },
    may_be_button_css: {
        boxShadow: `1px 3px 4px rgb(26 27 34)`,
        backgroundColor: `rgba(234,67,93, 1)`,
        color: theme.palette.primary.main,
        borderRadius: 10,
        fontWeight: 700,
        opacity: 0.9,

        [theme.breakpoints.only("xs")]: {
            fontSize: 14,
            marginTop: 45,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 17,
            marginTop: 52,
            maxWidth: 280,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 16,
            marginTop: 47,
            maxWidth: 230,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 18,
            marginTop: 55,
            maxWidth: 280,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 18,
            marginTop: 55,
            maxWidth: 280,
        },
        "&:hover": {
            opacity: 1,
            backgroundColor: `rgba(234,67,93, 1)`,
            color: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: `rgba(234,67,93, 1)`,
            color: theme.palette.primary.main,
        },
    },
    font_size_20: {
        fontFamily: "Roboto",
        fontWeight: 500,
        color: "#F6F6F6",
        fontSize: 20,
        [theme.breakpoints.only("xs")]: {
            fontSize: 16.5,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 19,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 18.5,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 20,
        },
    },
    font_size_14: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "22px",
        [theme.breakpoints.only("xs")]: {
            fontSize: 13.5,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 14,
        }
    },
    removeIconTypo:{
        color: "#F95C5C",
        fontSize: "14px",
        fontWeight: 400,
        marginTop: '13px',
        display: "flex",
        alignItems: "center" as const,
        cursor: "pointer",
        justifyContent: "start" as const
    },
    removeIcon: {
        height: 20,
        width: 20,
        margin: theme.direction === "ltr" ? "0 12px 0 0" : "0 0px 0 12px",
    },
    font_Size_28: {
        fontSize: 28,
        [theme.breakpoints.only("xs")]: {
            fontSize: 22,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: 26,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: 24,
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: 28,
        },
    },

    history_div: {
        padding: 24,
        background: '#222222',
        borderRadius: 8,
        [theme.breakpoints.only("xs")]: {
            padding: 10,
        },
        [theme.breakpoints.only("md")]: {
            padding: 14,
        },
        [theme.breakpoints.only("sm")]: {
            padding: 13,
        }        
    },
    image_div: {
        width: '133.33',
        height: '160px',
        borderRadius: 6,
        minWidth: '133.33',
        border: '1px solid #F6F6F6',
        
        [theme.breakpoints.only("xs")]: {
            width: '100.90px',
            minWidth: '100.90px',
            height: '120px'
            
        },
        [theme.breakpoints.only("md")]: {
            width: '120.90px',
            height: '144px',
            minWidth: '120.90px'
        },
        [theme.breakpoints.only("sm")]: {
            minWidth: '110.90px',
            width: '110.90px',
            height: '132px'
        }
    },
    content_div: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "space-between",
        margin: theme.direction === "rtl" ? "0 16px 0 0" : "0 0px 0 16px",
    },
});
// Customizable Area End
import FavouritesController, { Props } from "./FavouritesController.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import MesDialog from "../../user-profile-basic/src/shared/MesDialog.web";

export class Favourites extends FavouritesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { classes } = this.props;
        const { t }: any = this.props;
        // Customizable Area Start
        
        return (
            <Grid container className={classes.backgroundImage_div}>
                {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
                
                    <Grid style={{ width: "100%", maxWidth: 1600 }}>                       
                            <Grid container spacing={2}>
                                {
                                    this.state.userFavourites?.length !== 0 && this.state.userFavourites?.map((_data: any, i: any) => {
                                        return (
                                            <Grid item xs={12} md={6} key={_data?.id} >
                                                <Grid container className={classes.history_div}>
                                                    <div
                                                        data-test-id={`favourites${i}`}
                                                        onClick={() => { this.handleFavouriteClick(_data?.attributes) }}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: `100%`,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img
                                                            src={_data?.attributes?.thumbnail_image}
                                                            alt="moive_poster"
                                                            className={classes.image_div}
                                                        />
                                                        <div className={classes.content_div} >
                                                            <div>
                                                            <Typography
                                                                style={{
                                                                    wordBreak: 'break-all'
                                                                }}
                                                                className={`${classes.font_size_20} single_line`}
                                                                color='primary'
                                                            >
                                                                {_data?.attributes?.title}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                  fontWeight: 400,
                                                                    wordBreak: 'break-all'
                                                                }}
                                                                color="secondary"
                                                                className={`${classes.font_size_14} four_lines`}
                                                            >
                                                                {_data?.attributes?.description}
                                                            </Typography>
                                                            </div>
                                                            <Button data-test-id={`delete${i}`} onClick={(event: React.MouseEvent<HTMLButtonElement>)=> this.removeFavourites(event , _data.id)} className={classes.removeIconTypo}><img src={removeIcon} className={classes.removeIcon} /> {t("settingWeb.removeFav")}</Button>
                                                        </div>
                                                    </div>                                                    
                                                </Grid>

                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    this.state.userFavourites.length == 0 && <Grid item xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: '20px 0'
                                        }}>
                                            <FavoriteIcon fontSize="large" style={{ color: 'grey' }} />
                                        </div>
                                        <Typography style={{
                                            // textAlign: 'center',
                                            maxWidth: '75%'
                                        }}
                                            className={classes.font_size_20}
                                            color='primary'>
                                            {t("settingWeb.NFF")}
                                        </Typography>

                                        <Button fullWidth data-test-id="FSTW" variant="contained" className={classes.may_be_button_css}
                                            onClick={() => this.handleFindFavButton()}
                                        >
                                            {t("settingWeb.FSTW")}
                                        </Button>
                                    </Grid>
                                }

                            </Grid>
                      
                    </Grid>
                    {this.state.removeDialog && <MesDialog
                        title={t("settingWeb.delete_fav")}
                        deleteModal={this.state.removeDialog}
                        onCloseModal={this.handleCloseModal}
                        confirmMes={t("settingWeb.delete_fav_mes")}
                        cancelButtonText={t("settingWeb.cancel")}
                        okButtonText={t("settingWeb.delete")}
                        deleteClick={this.handleDeleteClick} />
                    }
                
            </Grid>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(withTranslation()(Favourites));
// Customizable Area End
