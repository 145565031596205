import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const StyledFormHelperText = withStyles({
  root: {
    color: 'white',
    margin: 0,
    fontStyle: 'italic'
  },
})(FormHelperText);
// Customizable Area End

import ForgotPasswordController from "./ForgotPasswordController.web";
import {
  VODLogo
} from "./assets";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";
import CustomCountryCodePicker from "../../email-account-login/src/customCountryCodePicker";
import LanguageOptions from "../../LanguageOptions/src/LanguageOptions.web";

export class ForgotPasswordWeb extends ForgotPasswordController {
  render() {
    //  Customizable Area Start
    const { classes, t }: any = this.props;
    const {
      loading,
      emailerror,
      emailerrorText,
      forgetType,
      countryList,
      selectedCountryCode
    } = this.state;    
    // eslint-disable-next-line no-undef
    //  Customizable Area End
    return (
      <Grid container>
        {loading && <Spinner spinnerModal={loading} />}
        <Grid item xs={12} className={classes.PPContainer}>
         <Grid container className={classes.PPGridContainer}>
         <Grid container justifyContent="center">
            <img className={classes.AppLogo} src={VODLogo} />
            </Grid>
           <Grid
                  item
                  xs={12}
                  
                  className={classes.PPSubgrid2}
                >
                   <Typography className={classes.headingTypo} color="primary">
                    {t("loginScreen.FP")}
                  </Typography>
                  <Typography className={classes.PPTypo} color="secondary">
                    {t("loginScreen.FSH")}
                  </Typography>
                
               
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    lg={12}
                    className={classes.PPSubgrid3}
                  >
                    <TextField
                      data-test-id="inputField1"
                      placeholder={t("loginScreen.user")}
                      className={classes.PPTextField}
                      variant="outlined"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.onChangeUserName(event)
                      }}
                      // error={emailerror}
                      helperText={<StyledFormHelperText>{emailerrorText}</StyledFormHelperText>}
                      InputProps={{
                        style: {
                          color: "#757A8C",
                          fontFamily: "Araboto",
                          opacity: 1
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            {forgetType === "phone" && (
                              <CustomCountryCodePicker
                                id={""}
                                {...this.props}
                                countryList={countryList}
                                selectedCountryCode={selectedCountryCode}
                                selectCountryCode={this.selectCountryCode}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    >
                    <Button
                      data-test-id="sendOtpButton"
                      variant="contained"
                      className={classes.PPButton}
                      onClick={(event: any) => {
                        this.ForgetPasswordOtp(event);
                      }}
                    >
                      {t("loginScreen.SO")}
                    </Button>
                <Typography
                  data-testid={"navtoregister"}
                  onClick={this.navToLogin}
                  className={classes.backToLoginButton}
                >
                  {t("loginScreen.backTL")}

                </Typography>
                  </Grid>
                  <LanguageOptions
                    id="languageChnage"
                    navigation={this.props.navigation}                    
                    />
                              </Grid>
              </Grid>
           
          
          {/* Customizable Area End */}
        </Grid>
      </Grid>
    );
  }
}

export const styles: any = (theme: any) => {
  let themeDirection = (arrValue: any, enValue: any) =>
    theme.direction === "rtl" ? arrValue : enValue;
  return {
    PPContainer: {
      maxWidth: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      minHeight: "100vh",
      width: "100vw",
      height: "100%",
      padding: "70px 120px",
      alignItems: "center",
      [theme.breakpoints.only("sm")]: {
        width: "100%",
        padding: "40px 20px"
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        padding: "10px 20px"
      },
      [theme.breakpoints.only("lg")]: {
        width: "100%",
        padding: "70px 60px"
      },
      [theme.breakpoints.only("md")]: {
        width: "100%",
        padding: "50px 20px"
      }
    },
    PPGridContainer: {
      margin: "auto",
      maxWidth: '600px'
    },
    changeLanguage: {
      width: "34.27px",
      height:"26px",
      
    },
    PPSideGridImg: {
      height: "550px",
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "transparent",
      paddingTop: "50px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        position: "relative",
        top: 70,
        paddingTop: 0
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      }
    },
    backToLoginButton:{
      fontSize: 16,
      fontFamily: "Roboto",
    cursor: "pointer",
    color: "#EA435D",
    fontWeight: 700,
    marginTop:"15px",
    textAlign: "center" as const
    },
    AppLogo: {
      maxWidth: "120px",
      width: "auto",
      aspectRation : "1/1",
       marginBottom: 20,
      backgroundColor: "transparent"
    },
    PPText: {
      width: "auto",
      maxWidth: "100%",
      filter: `${theme.palette.type === "dark" ? "none" : "invert(1)"}`
    },
    PPTextField: {
      maxWidth: "100%",
      width: "100%",
      borderRadius: 10,
      alignSelf: "center",
      color: theme.palette.primary.main,
      fontFamily: "Araboto",
      fontSize: 14
    },
    PPButton: {
      maxWidth: "100%",
      width: "100%",
      height: "54px",
      borderRadius: 10,
      alignSelf: "center",
      backgroundColor: "rgba(234, 67, 93, 1)",
      fontFamily: "Araboto",
      fontWeight: 600,
      color: theme.palette.primary.main,
      opacity: 0.8,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      },
      [theme.breakpoints.only("md")]: {
        fontSize: 17
      },
       [theme.breakpoints.only("sm")]: {
        fontSize: 16
      },
        fontSize: 18,
      "&:hover": {
        opacity: 1,
        backgroundColor: "rgba(234, 67, 93, 1)"
      }
    },
    PPTypo: {
      textAlign: "center" as const,
      fontWeight: 400,
      fontSize: 16,
      margin: "15px 0 20px",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14
      }
    },
    headingTypo: {
      textAlign: "center" as const,
      fontWeight: 700,
      fontSize: 20,
     [theme.breakpoints.only("xs")]: {
        fontSize: 18
      }
    },

    PPSubgrid2: {
      background: "linear-gradient(180deg, rgba(7, 7, 13, 0.8) 0%, #07070D 49.5%, rgba(7, 7, 14, 0.8) 100%)",
      padding: "40px 45px",
      borderRadius: "8px",
      [theme.breakpoints.only("xs")]: {
        padding: "30px 35px",
      }
    },
    PPSubgrid3: {
      marginBottom: 30,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 20
      }
    },
   PPSubgrid1: {
      marginBottom: 30,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 25
      }
    },
   image_direction: {
      cursor: "pointer",
      transform: themeDirection("scaleX(-1)", "none"),
      margin: themeDirection("0 0 0 20px", "0 20px 0 0")
    }

    //  Customizable Area End
  };
};

export default withStyles(styles)(withTranslation()(ForgotPasswordWeb));
