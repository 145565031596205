import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  deleteDialog: boolean;
  onCloseDeleteDialog: any;
  userId: number;
  onSuccessFullyDeleted: any;
  t?:any;
}

export interface S {
  // Customizable Area Start

  userDetails: any;

  loading: boolean;
  password: any;
  passwordError: boolean;
  passwordErrorMes: string;
  showPassword: boolean;


  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class DeleteProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserPasswordCallId: string = "";
  deleteUserProfileCallId: string = '';

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);


    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      userDetails: [],

      loading: false,
      password: '',
      passwordError: false,
      passwordErrorMes: '',
      showPassword: false,

      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );     

      if (apiRequestCallId === this.getUserPasswordCallId) {
        this.handleGetUserPasswordResponse(responseJson)
      }
      else if (apiRequestCallId === this.deleteUserProfileCallId) {
        this.handleDeleteUserProfileResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
handleGetUserPasswordResponse=(responseJson:any)=>{
  if (responseJson != null) {
    console.log("JSON NOt NUll")
    if (!responseJson.errors) {
      this.setState({ loading: false })
      this.deleteProfile()

    }
    else {
      this.setState({ loading: false })
      let key = Object.keys(responseJson?.errors[0])[0]
      console.log(key)
      toast.error(responseJson?.errors)
      console.log(responseJson?.errors)
      this.setState({
        passwordError: true,
        passwordErrorMes: responseJson?.errors
      })
    }
  }
}
handleDeleteUserProfileResponse=(responseJson:any)=>{
  if (responseJson != null) {
    console.log("JSON NOt NUll")
    if (!responseJson.errors) {
      this.setState({ loading: false })
      toast.success(this.props.t("favourite.DS"))
      this.props.onSuccessFullyDeleted()

    }
    else {
      this.setState({ loading: false })
      let key = Object.keys(responseJson?.errors[0])[0]
      console.log(key)
      toast.error(responseJson?.errors[0][key])

    }
  }
}

  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value, passwordError: false,
      passwordErrorMes: "",
    });
  }

  getUserPassword(e: any) {
    e.preventDefault()
    let regPassword = configJSON.passwordRegx;
    let isValid: boolean = true
    if (!regPassword.test(this.state.password)) {
      debugger
      this.setState({
        passwordError: true,
        passwordErrorMes: this.props.t("loginScreen.MINP"),
      });
      isValid = false
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({
        passwordError: true,
        passwordErrorMes: this.props.t("loginScreen.PR"),
      });
      isValid = false
    }
    if (isValid) {
      this.setState({ loading: true })
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("Token"),
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
         };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUserPasswordCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserPasswordEndPoint + `?password=${this.state.password}`
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }
  deleteProfile() {


    this.setState({ loading: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteUserProfileCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserProfileEndPoint + `/${this.props.userId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiDelete
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }



  // Customizable Area End
}
