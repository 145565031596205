import React from "react";
// Customizable Area Start
import {
  Avatar,
  Typography,
  Grid,
  Tabs,
  withWidth,
  Badge,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { backarrow } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

export const themeCreatStyle = (theme: any) => {
  let themeDirection = (arrValue: any, enValue: any) => theme.direction === "rtl" ? arrValue : enValue
  return{
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  backgroundImage_div: {
    maxWidth: "100%",
    width: "100vw",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    [theme.breakpoints.only("sm")]: {
      padding: "32px 21px 0px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "37px 55px 0px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "25px 15px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "37px 70px 0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "35px 50px 0px",
    },
  },
  tab_selected_button: {
    color: "#6869AC",
    justifyContent: "start",
    borderWidth: 1,
  borderStyle: 'solid',
  borderImage: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
  borderImageSlice: 1,
    opacity: 1,
   boxShadow:"1px 1px 5px rgba(0,0,0, 0.4)",
    padding: "16px 15px",
    cursor: "pointer",
  },
  border_bottom: {
    borderBottomWidth: 1,
  borderBottomStyle: "solid" as const,
  borderImage: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
  borderImageSlice: 1,
  },

  tab_buttons: {
    padding: "18px 16px",
    color: "#7A7B7F",
    justifyContent: "start",
    cursor: "pointer",
  },

  image_div: {
    width: "50px",
    height: "50px",
    [theme.breakpoints.only("sm")]: {
      width: "42px",
      height: "42px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "38px",
      height: "38px",
    },

  },
  sendAtDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column" as "column",
  },
  sendAt: {
    fontFamily: "Araboto",
    fontWeight: 400,
    wordBreak: "break-all" as 'break-all',
    textAlign: "end" as "end",

  },
  lastMessageTime: {
    fontFamily: "Araboto",
    color: "white",
    fontWeight: 400,
    borderRadius: "50%",
    alignSelf: "end",
    verticalAlign: "middle" as "middle",
    lineHeight: "20px",
    width: 20,
    height: 20,
    background: "green",
    textAlign: "center" as "center",
  },
  font_size_12: {
    fontSize: 12,
    [theme.breakpoints.only("lg")]: {
      fontSize: 12,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 10.5,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 11.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 11,
    },
  },
  font_size_17: {
    fontFamily: "Araboto",
    fontWeight: 500,
    wordBreak: "break-all" as "break-all",
    [theme.breakpoints.only("xs")]: {
      fontSize: 15,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 16.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 15.5,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 17,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
    },
  },

  font_size_14: {
    fontSize: 14,
    fontFamily: "Araboto",

    fontWeight: 400,
    wordBreak: "break-all" as "break-all",

    [theme.breakpoints.only("xs")]: {
      fontSize: 12.5,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 13,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 13.5,
    },
  },
  font_Size_28: {
    fontWeight: 700,
    fontFamily: "Roboto",
    fontSize: 20,
    padding: 25,
    [theme.breakpoints.only("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 17,
    },
  },
  linkText:{
    textDecoration: "underline",
    cursor: "pointer",
    color : "#017698"
  },
  tab_gird: {
    padding: "15px 30px 10px",
    borderImage: "linear-gradient(180deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
  borderImageSlice: 1,
    [theme.breakpoints.up("md")]: {
      borderRightStyle: themeDirection("hidden" , "solid"),
      borderRightWidth:
        themeDirection("0" , 1),
        borderLeftStyle: themeDirection("solid" , "hidden"),
      borderLeftWidth:
        themeDirection(1 , "0"),
      minHeight: "calc(100vh - 216px)",
    },
    [theme.breakpoints.only("xs")]: {
      padding:"15px 30px 5px",
    },  
  },
  image_css: {
    display: "flex",
    flexDirection: "column" as "column",
    marginRight: themeDirection(13 , 0),
    marginLeft: themeDirection(0 , 13),
  },
  flex_tabs: {
    display: "flex",
    "&:MuiTabs-vertical": {
      display: "flex",
      flexDirection: "row",
    },
    "&:MuiTabs-flexContainer": {
      display: "flex",
      flexDirection: "row",
    },
    "&:MuiTabs-flexContainerVertical": {
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.only("lg")]: {},
  },
  scrollBar: {
    scrollbarColor: "#B7B7B7 transparent",
    "&::-webkit-scrollbar": {
      height: 6,
      width: 6,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 100%)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: "linear-gradient(270deg, #474b4c  0%, #6869AC 90%)",
      minHeight: 24,
      minWidth: 24,
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "transparent",
    },
  },

  backImage: {
    width: 11,
    height: 20,
    cursor: "pointer",
    marginRight: themeDirection(0 ,20),
    marginLeft: themeDirection(20, 0),
    filter: `${theme.palette.type === "dark" ? "none" : "invert(1)"}`,
    transform: themeDirection("rotate(180deg)" , "none"),
  },
  gridContainer: {
    width: "100%",
    maxWidth: 1500,
    background: "#222222",
    borderRadius: 8,
    paddingBottom: 15
  },
  gridItem: {
    display: "flex",
    alignItems: "center"
  },
  topLine: {
    height: "2px",
    background: "linear-gradient(90deg, #E14663 0%, #5772A9 55.5%, #288AA2 100%)",
  },
  badgeGrid: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
  }


}};

export const StyledBadge = withStyles((theme: any) =>
  createStyles({
    badge: {
      color: "#44b700",
      backgroundColor: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        content: '""',
        border: "1px solid currentColor",
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

// Customizable Area End

import ChatController, { Props } from "./ChatController.web";
import ChatScreen from "./ChatScreen.web";
import Spinner from "../../user-profile-basic/src/shared/Spinner.web";

export class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t }: any = this.props;

    // Customizable Area Start
    return (
      <Grid container>
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Grid
          item
          xs={12}
          className={classes.backgroundImage_div}
        >
          <Grid className={classes.gridContainer}>
            <Grid item xs={12}>
              <div className={classes.gridItem}>
               <Typography
                  className={classes.font_Size_28}
                  color="primary"
                >
                  {t("chat.chat")}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.topLine}
            />

            <Grid item xs={12}>
             {this.state.chatServiceError ?
             <Typography color="secondary" style={{textAlign:"center", marginTop:20}} >{t("chat.serviceError")}</Typography>
            :
              <Grid container>
                {this.checkIsMobileScreen() && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.tab_gird}
                  >
                    {this.state.chatUser.length !== 0 ? (
                      <Tabs
                        className={classes.flex_tabs}
                        orientation={"vertical"}
                        // value={'resources'}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      // variant="scrollable"
                      // scrollButtons="auto"
                      >
                        {this.state.chatUser.map((item: any, i: any) => (
                          <Grid
                            item
                            xs={12}
                            data-test-id={"chatList"+i}
                            className={`${i !== this.state.chatUser.length - 1 &&
                              this.props.classes.border_bottom
                              }
                            ${item.id === this.state.selectedTab
                                ? this.props.classes.tab_selected_button
                                : this.props.classes.tab_buttons
                              }`}
                            key={item.id}
                            onClick={() => this.onChatClick(item, i)}
                          >
                            <Grid
                              container
                              justifyContent="space-between"
                            >
                              <Grid
                                item
                                xs={10}
                                className={classes.badgeGrid}
                              >
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant={
                                    item?.status === "online"
                                      ? "dot"
                                      : "standard"
                                  }
                                >
                                  <Avatar
                                    src={item?.attributes?.photo}
                                    alt="moive_poster"
                                    className={classes.image_div}
                                  />
                                </StyledBadge>
                                <div
                                  className={classes.image_css}
                                >
                                  <Typography
                                    color="primary"
                                    className={classes.font_size_17}
                                  >
                                    {item?.attributes?.name}
                                  </Typography>
                                  <Typography
                                    color="textSecondary"
                                    className={classes.font_size_14}
                                  >
                                    {item?.lastMessage?.text}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={2}

                                className={classes.sendAtDiv}
                              >
                                <Typography

                                  color="textSecondary"
                                  className={`${classes.font_size_12} ${classes.sendAt}`}
                                >
                                  {item?.lastMessage &&
                                    this.convertStringToDate(
                                      item?.lastMessage?.sentAt
                                    )}
                                </Typography>
                                {parseInt(item?.unReadCount) > 0 && (
                                  <Typography

                                    className={`${classes.font_size_12} ${classes.lastMessageTime}`}
                                  >
                                    {item?.unReadCount}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Tabs>
                    ) : (
                      <Typography
                        color="secondary"
                        style={{ textAlign: "center" }}
                      >
                          {t("chat.NFF")} 
                          <span data-test-id="addButton"
                            className={classes.linkText}
                            onClick={() => this.handleNaigation("switch_profile")}> {t("chat.here")} </span> {t("chat.toCreate")}
                      </Typography>
                    )}
                  </Grid>
                )}
                {this.checkIsMobileScreenForChat() && (
                  <Grid item xs={12} sm={12} md={8}>
                    {this.state.selectedTab !== null && (
                      <ChatScreen
                        {...this.props}
                        ChatUserId={this.state.selectedTab}
                      />
                    )}
                  </Grid>
                )}
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(themeCreatStyle)(
  withWidth()(withTranslation()(Chat))
);
// Customizable Area End
