import React from "react";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utility";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // route: any;
  classes: any;
  t: any;
  i18n:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  selectedPlanId: any;
  currentPlansDetails: any;
  coupon: any;
  appliedCoupon: boolean;
  appliedCouponSuccessMes: string;
  seletedCoupounID: any;
  coupounError: boolean;
  coupounErrorMessage: string;
  discount: any;
  prevBalance: any;
  totalPayAmount: any;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class PlanSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  checkoutApiCallId: string = "";
  apiPaymentStatusId: string = "";
  getPlansApiCallId: string = "";
  applyCouponApiCallId: string = "";
  removeCouponApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      loading: true,
      selectedPlanId: "",
      currentPlansDetails: [],
      coupon: "",
      appliedCoupon: false,
      seletedCoupounID: "",
      coupounError: false,
      appliedCouponSuccessMes: "",
      coupounErrorMessage: "",
      discount: 0,
      prevBalance: 0.00,
      totalPayAmount: 0
    }

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSubscriptionPlans();
    this.setState({ selectedPlanId: this.props.navigation.getParam('id') })
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.checkoutApiCallId) {
      this.handleCheckOutApiResponse(responseJson);
    }
    if (apiRequestCallId === this.apiPaymentStatusId) {
    if(responseJson.plan_history.plan_name === "Basic"){
      await this.handleBaiscPlan()
      return true
    }
      this.props.navigation.navigate("UserProfile");
    }
    if (apiRequestCallId === this.getPlansApiCallId) {
      this.handleGetPlansApiResponse(responseJson);
    }
    if (apiRequestCallId === this.applyCouponApiCallId) {
      this.handleApplyCouponApiResponse(responseJson);
    }
    if (apiRequestCallId === this.removeCouponApiCallId) {
      this.handleRemoveCouponApiResponse(responseJson);
    }
  }

  getPaymentStatus = (payment_request_id: string) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("Token"),
    };

    const requestPaymentStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPaymentStatusId = requestPaymentStatusMessage.messageId;

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPaymentStatusEndPoint}?requests_id=${payment_request_id}`
    );

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestPaymentStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestPaymentStatusMessage.id, requestPaymentStatusMessage);
    // Customizable Area End
    return true;
  };

  onBackClick = (event: any) => {
    this.props.navigation.goBack();
  }

  applySelectedSubsPlan = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true })

    let formData = new FormData();
    formData.append("applied", (this.state.appliedCoupon).toString());
    formData.append("prev_bal", parseFloat(this.state.prevBalance).toFixed(2));
    formData.append("hyperpay_amount", parseFloat(this.state.totalPayAmount).toFixed(2));
    formData.append("plan_id", this.state.selectedPlanId);
    formData.append("coupon_code", this.state.coupon);
    
    this.checkoutApiCallId = sendAPIRequest(
      configJSON.getCheckoutIdEndPoint,
      {
        method: configJSON.apiMethodTypeAddDetail, body: formData,
        headers: {
          token: localStorage.getItem('Token'),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  handleCheckOutApiResponse(responseJson: any) {
    if (responseJson != null) {
      if (!responseJson.errors) {
        let requests_id = responseJson?.transaction?.requests_id;
        let checkout_id = responseJson?.transaction?.hyperpay_request_id;
        sessionStorage.setItem("update_plan_page", "false");
        if (checkout_id === null || checkout_id === "") {
          this.getPaymentStatus(requests_id)
        }
        else {
          sessionStorage.setItem("checkout_id", checkout_id)
          sessionStorage.setItem("request_id", requests_id)
          sessionStorage.setItem("selected_plan_id", this.state.selectedPlanId);
          this.props.navigation.navigate("Payment");
        }
      } else {
        this.setState({ loading: false });
        let key = Object.keys(responseJson?.errors[0])[0];
        toast.error(responseJson?.errors[0][key]);
      }
    }
  }

  checkLanguage=()=>{
    return this.props.i18n.language === 'ar'
  }

  handleGetPlansApiResponse(responseJson: any) {
    const currentPlan = responseJson?.packages?.data.filter((value: any) => (value.id === this.state.selectedPlanId))
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({
          currentPlansDetails: currentPlan[0],
          totalPayAmount: currentPlan[0].attributes?.price,
          loading: false
        });
      } else {
        this.setState({ loading: false });
        let key = Object.keys(responseJson?.errors[0])[0];
        toast.error(responseJson?.errors[0][key]);
      }
    }
  }

  handleBaiscPlan = async() => {
  let userData = await getStorageData("user_data")
  let userDataParsed = JSON.parse(userData)
  let mainProfie = userDataParsed.account_profile.data.attributes
  setStorageData("selectedUser", mainProfie.id)
  setStorageData("selected_user_data", JSON.stringify(mainProfie))
    const planNavigationMes: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    planNavigationMes.addData(
      getName(MessageEnum.NavigationTargetMessage), "DashboardWeb")
    planNavigationMes.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(planNavigationMes);
  }

  handleChangeCoupon = (event: any) => {
     this.setState({ coupon: event.target.value });
  }

  getSubscriptionPlans = () => {
    this.setState({ loading: true })
    this.getPlansApiCallId = sendAPIRequest(
      configJSON.getPlansEndPoint,
      {
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        }
      }
    );
  }

  applySubscriptionCoupon = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true });

    this.applyCouponApiCallId = sendAPIRequest(
      `${configJSON.applyCouponEndPoint}?coupon_code=${this.state.coupon}&applied=${(!this.state.appliedCoupon).toString()}&plan_id=${this.state.selectedPlanId}`,
      {
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        },
        method: configJSON.applyCouponApiMethodType
      }
    );
  }

  handleRemove = (event: any) => {
    event.preventDefault();
    this.setState({ loading: true });

    this.removeCouponApiCallId = sendAPIRequest(
      `${configJSON.applyCouponEndPoint}?coupon_code=${this.state.coupon}&applied=${(!this.state.appliedCoupon).toString()}&plan_id=${this.state.selectedPlanId}`,
      {
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
        },
        method: configJSON.applyCouponApiMethodType
      }
    );
  }

  handleRemoveCouponApiResponse(responseJson: any) {
    this.setState({
      coupon: "",
      seletedCoupounID: "",
      appliedCoupon: false,
      discount: 0,
      totalPayAmount: this.state.currentPlansDetails.attributes?.price,
      loading: false
    })
    toast.success(this.props.t("plan_subscription.Remove_coupon_msg"));
  }

  handleApplyCouponApiResponse(responseJson: any) {
    if (responseJson != null) {
      if (!responseJson.errors) {
        if (responseJson?.coupon_id) {
          const discount = parseInt(responseJson.detail.dis_amt) === responseJson.detail.dis_amt ?
            responseJson.detail.dis_amt :
            parseFloat(responseJson.detail.dis_amt).toFixed(2);

          const totalPayAmount = parseInt(responseJson.detail.final_price) === responseJson.detail.final_price ?
            responseJson.detail.final_price :
            parseFloat(responseJson.detail.final_price).toFixed(2);

          this.setState({
            appliedCouponSuccessMes: responseJson.message,
            seletedCoupounID: responseJson.coupon_id,
            appliedCoupon: true,
            coupounError: false,
            discount: discount,
            totalPayAmount: totalPayAmount
          });
          toast.success(this.state.appliedCouponSuccessMes);
        } else {
          toast.error(responseJson?.message);
          this.setState({
            coupounError: true,
            coupounErrorMessage: responseJson?.message,
            appliedCoupon: false,
          });
        }
      }
    }
    this.setState({ loading: false });
  }

}
// Customizable Area End

