export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgPasswordVisibleWeb = require("../assets/ic_password_visible_web.png");
export const imgPasswordInVisibleWeb = require("../assets/ic_password_invisible_web.png");
export const walkThrough1 = require("../assets/walkThrough1.png");
export const walkThrough2 = require("../assets/walkThrough2.png");
export const walkThrough3 = require("../assets/walkThrough3.png");
export const walkThroughNextBtn = require("../assets/group.png");

export const LoginImg = require("../assets/Login_Img.png");
export const LoginTitle = require("../assets/Login_Title.png")
export const LoginTitleLight = require("../assets/Login_Title_light.png")
export const LoginBgImg = require("../assets/LoginBgImg_Img.png")
export const LandingMainImg = require("../assets/LandingMain_Img.png")
export const HeaderIconImg = require("../assets/HeaderIcon_Img.png")
export const LandingTitleImg = require("../assets/LandingTitle_Img.png")
export const LandingMain2Img = require("../assets/LandingMain2_Img.png")
export const LandingMain3Img = require("../assets/LandingMain3_Img.png")
export const LandingMain4Img = require("../assets/LandingMain4_Img.png")
export const languageChange = require("../assets/languageChange.png")
export const FinalBg = require("../assets/FinalBg_Img.png")
export const calender = require("../assets/calendar.png")
export const calenderBlack = require("../assets/calendarBlack.png")
export const ArrowRightImg = require("../assets/ArrowRight_Img.png")
export const LandingBg = require("../assets/LandingBg_Img.png")
export const LandingBg3 = require("../assets/LandingBg3_Img.png")
export const GoogleImg = require("../assets/Google_Img.png")
export const FacebookImg = require("../assets/Facebook_Img.png")
export const AppleImg = require("../assets/Apple_Img.png")
export const avatar = require("../assets/avatar.jpg")
export const successIcon = require("../assets/successIcon.png")
export const dummyIcon = require("../assets/dummy-image.png")
export const appLogo = require("../assets/newLogo.png")
export const eye_close = require("../assets/closedEye.png");
export const eye_open = require("../assets/openEye.png");
export const homeIcon = require("../assets/home-active.png");
export const imageBg = require("../assets/image_bg.jpg")
export const calenderGradient = require("../assets/image_date_picker.png")