import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    classes: any;
    t?: any;
}
export interface S {
    // Customizable Area Start
    userFavourites: any;
    loading: any;
    selectedFav: any;
    removeDialog: any;
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class FavouritesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserFavouritesApiCallId: string = "";
    deleteUserFavouritesApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            userFavourites: [],
            loading: false,
            selectedFav: '',
            removeDialog: false
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getUserFavourites()
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            runEngine.debugLog("API Message Recived", message);

            switch (apiRequestCallId) {
                case this.getUserFavouritesApiCallId:
                    this.handleFavouriteResponse(responseJson);
                    break;
                case this.deleteUserFavouritesApiCallId:
                    this.handledeleteFavResponse(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    onBackClick = () => {
        this.props.navigation.goBack()
    }

    handleFavouriteClick = (_data: any) => {
        this.props.navigation.navigate(_data?.content?.data?.attributes?.content_type == "AudioPodcast" ? `PodcastDetails` : `MovieDetails`, { id: _data?.favouriteable_id })
    }

    getUserFavourites = () => {
        this.setState({ loading: true })
        let id = localStorage.getItem("selectedUser") || '1'
        this.getUserFavouritesApiCallId = sendAPIRequest(
            configJSON.getProfileFavouritesEndPoint + `?profile_id=${id}`,
            {
                headers: {
                    token: localStorage.getItem('Token'),
                    language: localStorage.getItem("i18nextLng") === 'ar' ? 'ar' : 'en'
                }
            }
        );
    }
    removeFavourites =(event:React.MouseEvent<HTMLButtonElement> ,id: string) =>{
        event.stopPropagation()
        this.setState({ selectedFav:id }, () => this.setState({ removeDialog: true }))
    }

    deleteUserFavourites = (id: any) => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("Token")
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteUserFavouritesApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getProfileFavouritesEndPoint + `/${id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethodDelete
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    handleFindFavButton = () => {
        this.props.navigation.navigate("DashboardWeb")
    }

    handleCloseModal = () => {
        this.setState({ removeDialog: false })
    }

    handleDeleteClick = () => {
        this.deleteUserFavourites(this.state.selectedFav)
        this.setState({ removeDialog: false })
    }

    // API Response
    handleFavouriteResponse = (json: any) => {
        if (json != null) {
            if (!json?.errors) {
                this.setState({ userFavourites: json?.data ? json?.data : [], loading: false })
            }
            else {
                this.setState({ loading: false })
                let key = Object.keys(json?.errors[0])[0]
                toast.error(json?.errors[0][key])
            }
        }
    }

    handledeleteFavResponse = (json: any) => {
        if (json != null) {
            if (!json.errors) {
                this.setState({ loading: false })
                toast.success(this.props.t("favourite.DS"))
                this.getUserFavourites()
            }
            else {
                this.setState({ loading: false })
                let key = Object.keys(json?.errors[0])[0]
                toast.error(json?.errors[0][key])
            }
        }
    }



    // Customizable Area End
}
