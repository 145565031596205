import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  movieDataProps?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  imgIndex: any;
  loading: boolean;
  swiper: any;
  open: any;
  isMatch: any;
  isMatch1: any;
  profileDialog: boolean;
  showNotification: boolean;
  navOnScroll: boolean;
  moviesData: any;
  movieID: any;
  assetID: any;
  moviesLoading: boolean;
  bandData: any[];
  topPodcast: any[];
  topPodcasts: any[];
  trendingPodcast: any[];
  getContinuePodcast: any[];
  anchorEl: any;
  podcastCategory: any[];
  categoryId: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PodcastsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getMoviesCallId: string = "";
  trendingPodcastListAPICallId: any;
  addPodcastToFavouritesCallId: any = "";
  PodcastBandsListAPICallId: any = "";
  PodcastWatchHistoryAPICallId: any = "";
  getContinueWatchDataAPICallId: string = "";
  apiLikeUnlikePodastCallId: any = "";
  getCategoryAPICallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      swiper: null,
      imgIndex: null,
      open: false,
      isMatch: "",
      isMatch1: "",
      profileDialog: false,
      showNotification: false,
      navOnScroll: false,
      moviesData: [],
      movieID: "",
      assetID: "",
      moviesLoading: false,
      anchorEl: null,
      bandData: [],
      topPodcast: [],
      topPodcasts: [],
      trendingPodcast: [],
      getContinuePodcast: [],
      podcastCategory: [],
      categoryId: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const queryParams = new URLSearchParams(window.location.search);
    const profile_menu = queryParams.get("profile");
    if (profile_menu !== null && profile_menu) {
      this.setState({ profileDialog: Boolean(profile_menu) });
    }
    this.initializeMethod();
    this.getPodcastList();
    this.getPodcastBands("");
    this.getrecentlyPlaidPodcast();
    this.getContinueWatchData();
    this.getPodcastCategory();

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data?.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false,
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false,
          });
        }
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false,
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false,
          });
        }
      }
    }
    // get movies
    this.handleGetMovies(message);
    // trending podast API response
    this.handletrendingPodcastListAPI(message);
    // add podast to Favourites API response
    this.handleaddPodcastToFavourites(message);
    // get podast bands API response
    this.handlePodcastBandsList(message);
    // get podast watch history API response
    this.handlePodcastWatchHistory(message);
    this.handleContinueWatchData(message);
    this.handleLikeUnlikePodast(message);
    this.handleGetCategory(message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleGetMovies = (message: Message) => {
    if (
      this.getMoviesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        moviesData: apiResponse?.data,
        moviesLoading: false,
      });
    }
  };

  handletrendingPodcastListAPI = (message: Message) => {
    if (
      this.trendingPodcastListAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse) {
          this.setState({
            trendingPodcast: apiResponse.data,
            moviesLoading: false,
            loading: false,
          });
        }
      }
    }
  };

  handleaddPodcastToFavourites = (message: Message) => {
    if (
      this.addPodcastToFavouritesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse) {
          this.getPodcastList();
        }
      }
    }
  };

  handlePodcastBandsList = (message: Message) => {
    if (
      this.PodcastBandsListAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse) {
          this.setState({
            bandData: apiResponse?.bend_contents,
          });
        }
      }
      this.setState({
        loading: false,
      });
    }
  };

  handlePodcastWatchHistory = (message: Message) => {
    if (
      this.PodcastWatchHistoryAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse && apiResponse.length > 0) {
          let list = apiResponse.map((ele: any) => {
            return {
              attributes: ele,
            };
          });
          let data = this.state.bandData;
          data = [
            { name: "Recently Played", bend_contents: { data: list } },
            ...data,
          ];
          this.setState({
            bandData: data,
          });
        }
      }
    }
  };

  handleContinueWatchData = (message: Message) => {
    if (
      this.getContinueWatchDataAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse) {
          this.setState(
            {
              getContinuePodcast: apiResponse?.data ? apiResponse?.data : [],
            },
            () => {
              this.setState({ loading: false });
            }
          );
        }
      }
    }
  };

  handleLikeUnlikePodast = (message: Message) => {
    if (
      this.apiLikeUnlikePodastCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        this.getPodcastList();
      }
    }
  };

  handleGetCategory = (message: Message) => {
    if (
      this.getCategoryAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        return;
      } else {
        if (apiResponse) {
          this.setState({
            podcastCategory: apiResponse?.data,
          });
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ profileDialog: false });
    if (
      window.location.href.toLowerCase().includes("dashboardweb/setting_menu")
    ) {
      this.props.navigation.navigate("DashboardWeb");
    }
  };

  handleNotification = () => {
    this.setState({
      showNotification: true,
    });
  };

  closeDialog = () => {
    this.setState({
      showNotification: !this.state.showNotification,
    });
  };

  navToMovieDetails = (item: any) => {
    this.props.navigation.navigate("Dashboard");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationDashbordControllerMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationDashboardControllerAssetId),
      item
    );
    this.send(msg);
  };

  previewHandler = (ref: any) => {
    ref.slickPrev();
  };
  nextHandler = (ref: any) => {
    ref.slickNext();
  };

  navHandler = (contentId: any) => {
    const { navigation } = this.props;
    navigation.navigate(`PodcastDetails`, { id: contentId });
  };
  goToPodcastDetails = (contentId: any) => {
    const { navigation } = this.props;
    navigation.navigate(`PodcastDetails`, { id: contentId });
  };

  onCategoryChange = (id: any) => {
    this.setState(
      {
        categoryId: id,
      },
      () => this.getPodcastBands(this.state.categoryId)
    );
  };

  // get Podcast API

  getPodcastList = () => {
    this.setState({
      moviesLoading: true,
    });
    let headers = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    };

    const getBandDataApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.trendingPodcastListAPICallId = getBandDataApiMsg.messageId;

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${
        configJSON.trendingPodcastListAPIEndPoints
      }?profile_id=${localStorage.getItem("selectedUser")}`
    );

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(getBandDataApiMsg.id, getBandDataApiMsg);
  };
  // add Podcast to favourite API
  addPodcastToFavouriteList = (podcastId: any) => {
    const localStorage_token: any = localStorage.getItem("Token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage_token,
    };
    const data = {
      favourite: {
        favouriteable_id: podcastId,
        favouriteable_type: "BxBlockContentManagement::Content",
        profile_id: localStorage.getItem("selectedUser"),
      },
    };
    // BxBlockContentManagement::AudioPodcast
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addPodcastToFavouritesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPodcastToFavouritesListAPIEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  initializeMethod = () => {
    this.setState({ topPodcasts: this.state.topPodcast });
  };
  handleChange = (e: any) => {
    let podast = this.state.topPodcast.filter((ele: any) =>
      ele.heading.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ topPodcasts: podast });
  };

  // get Podcast bands API
  getPodcastBands = (categoryid: any) => {
    this.setState({
      loading: true,
    });
    let headers = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    };
    const getBandDataApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PodcastBandsListAPICallId = getBandDataApiMsg.messageId;

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPodcastBands}?profile_id=${localStorage.getItem(
        "selectedUser"
      )}&sub_category_id=${categoryid}`
    );
    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(getBandDataApiMsg.id, getBandDataApiMsg);
  };

  getrecentlyPlaidPodcast = () => {
    let headers = {
      token: localStorage.getItem("Token"),
      language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
    };

    const getBandDataApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PodcastWatchHistoryAPICallId = getBandDataApiMsg.messageId;

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContinueWatchPodcast}?profile_id=${localStorage.getItem(
        "selectedUser"
      )}`
    );

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getBandDataApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(getBandDataApiMsg.id, getBandDataApiMsg);
  };

  openPlayPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  podcastDislike = (id: any) => {
    const reqBody = {};
    const endPoint = `${configJSON.createFavourites}/${id}`;
    const method = "DELETE";
    this.apiLikeUnlikePodastCallId = sendAPIRequest(endPoint, {
      method,
      body: { ...reqBody },
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("Token"),
      },
    });
  };

  getContinueWatchData = () => {
    this.setState({ loading: true });
    this.getContinueWatchDataAPICallId = sendAPIRequest(
      `${
        configJSON.getContinueWatchDataAPIEndPoints
      }?profile_id=${localStorage.getItem(
        "selectedUser"
      )}&content_type=AudioPodcast`,
      {
        headers: {
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
        },
      }
    );
  };

  getPodcastCategory = () => {
    this.getCategoryAPICallId = sendAPIRequest(configJSON.podcastCategoryAPI, {
      headers: {
        token: localStorage.getItem("Token"),
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
      },
    });
  };

  // Customizable Area End
}
