Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveChat2";
exports.labelBodyText = "LiveChat2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.addUserEndPoint = "bx_block_profile/profiles";
exports.preminumPlan = "Premium"
// Customizable Area End
