import React from "react";

// Customizable Area Start
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateRewindButton extends shaka.ui.Element<Props, S, SS> {
  // Customizable Area Start
  constructor(parent: any, controls: any) {
    super(parent, controls);

    /** @private {!HTMLButtonElement} */
    this.button_ = document.createElement("button");
    this.button_.classList.add("material-icons-round");
    this.button_.classList.add(`shaka-rewind-ten-button`);
    this.button_.textContent = `replay_10`;
    this.button_.ariaLabel = `Rewind 10`;
    this.parent.appendChild(this.button_);

    this.eventManager.listen(this.button_, "click", () => {
      this.rewind_();
    });
  }

  rewind_() {
    console.log(this.video.duration);
    if (!this.video.duration || this.video.duration < 10) {
      return;
    }

    if (this.video.currentTime - 10 > 0) {
      this.video.currentTime -= 10;
    } else {
      this.video.currentTime = 0;
    }
  }
  // Customizable Area End
}
// Customizable Area Start
CreateRewindButton.Factory = class {
  create(rootElement: any, controls: any) {
    return new CreateRewindButton(rootElement, controls);
  }
};
// Customizable Area End
