import React, { Fragment } from 'react';
import {
  Container,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid
} from "@material-ui/core"
//@ts-ignore
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowRight, ArrowLeft } from "./assets";
//@ts-ignore
import cssClasses from './EpisodesSlider.module.css';
import EpisodesSliderController from './EpisodesSliderController';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EpisodeComponent from './EpisodeComponent.web';
import CheckIcon from '@material-ui/icons/Check';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import CONTENT_TYPES from '../../../components/src/Enums';

export class EpisodesSlider extends EpisodesSliderController {

  checkForMovie(contentDetails?: any) {
    return contentDetails?.content_type === CONTENT_TYPES?.MOVIES && contentDetails?.parts?.length > 0;
  }

  checkForSeries(contentDetails?: any, slides: any[] = [], releases: any[] = []) {
    return contentDetails?.content_type === CONTENT_TYPES.WEBSERIES && ((slides || []).length > 0 || releases?.length > 0);
  }

  checkForAudio(contentDetails?: any) {
    return contentDetails?.content_type === CONTENT_TYPES.AUDIOPODCAST && contentDetails?.parts?.length > 0;
  }

  checkOnlySeries(contentDetails?: any) {
    return contentDetails?.content_type !== CONTENT_TYPES.MOVIES && contentDetails?.content_type !== CONTENT_TYPES.AUDIOPODCAST;
  }

  checkOnlyMovie(contentDetails?: any) {
    return contentDetails?.content_type === CONTENT_TYPES.MOVIES;
  }

  render() {
    const { classes, t, contentDetails }: any = this.props;
    const { slides, releases, selectedSeason }: any = this.state;
    
    return (
      <>
        {(this.checkForMovie(contentDetails) || this.checkForSeries(contentDetails, slides, releases) || this.checkForAudio(contentDetails)) &&
          < Container className={`${classes.seasonsContainer}`}>
            <Grid container className={`${classes.seasonsListContainer}`}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl className={classes.RowTitle}>
                  {this.checkOnlySeries(contentDetails) ?
                    <Select
                      value={selectedSeason}
                      onChange={this.handleSelectChange}
                      autoWidth={false}
                      displayEmpty
                      className={classes.select}
                      classes={{
                        root: classes.whiteColor,
                        icon: classes.icon,
                      }}
                      MenuProps={{
                        classes: { paper: classes.list },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      disableUnderline
                    >
                      <MenuItem style={{ marginLeft: '10%' }} value="" disabled>
                        {this.checkOnlyMovie(contentDetails) ? t("movieDetails.MovieParts") : t("movieDetails.allSeasons")}
                      </MenuItem>
                      {(releases || []).map((season: any, index: any) => (
                        <MenuItem key={season.id} className={classes.menuItem} value={season.id}>
                          {season?.attributes?.type_name}
                        </MenuItem>
                      ))}
                    </Select>

                    :
                    <Typography className={classes.AllParts}>{t("movieDetails.allparts")}</Typography>
                  }
                </FormControl>

                {
                  this.state.isControll && (
                    <Box className={classes.buttonBox}>
                      <img
                        className={classes.displayNon}
                        src={ArrowLeft}
                        onClick={this.previewHandler}
                        style={{ margin: '0 10px' }}
                      />
                      <img
                        className={classes.displayFlex}
                        src={ArrowRight}
                        onClick={this.nextHandler}
                        style={{ margin: '0 10px' }}
                      />
                      <img
                        className={classes.displayFlex}
                        src={ArrowLeft}
                        onClick={this.previewHandler}
                        style={{ margin: '0 10px' }}
                      />
                      <img
                        className={classes.displayNon}
                        src={ArrowRight}
                        onClick={this.nextHandler}
                        style={{ margin: '0 10px' }}
                      />
                    </Box>
                  )
                }
              </Grid>

              {/* <Box> */}
              {(slides || []).length > 0 ?
                <div className="movie-carousel">
                  <div style={{ paddingBottom: '15px' }} className="slider-container" ref={this.SliderRef}>
                    {(slides || []).map((episode: any, index: number) => {
                      return (
                        <Box
                          // key={ele?.id}
                          data-test-id="nav_cont"
                          // onClick={() => {
                          //   this.navigateTo(ele?.attributes);
                          // }}
                          className={classes.slideCard}
                        >
                          <Fragment key={episode?.attributes?.id}>
                            <EpisodeComponent
                              index={index}
                              episode={episode?.attributes ? episode?.attributes : episode}
                              {...this.props}
                            />
                          </Fragment>
                        </Box>
                      );
                    })}
                  </div>
                </div>
                :
                <Typography color='primary' style={{ textAlign: 'center', margin: '10px' }}>
                  No Episode Found
                </Typography>
              }
              {/* </Box> */}
            </Grid>
          </Container>}
      </>
    )
  }
}
export const styles: any = (theme: any) => ({
  menuItem: {
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
  RowTitle: {
    color: '#ffffff',
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Araboto-Bold",
    fontStyle: 'normal',
    lineHeight: '48px',
    letterSpacing: '0px',
    // paddingLeft: '5%',
    width: '135px',
    marginTop: '20px'
    

  },
  select: {
    '&:before': {
      background: 'rgb(18, 18, 29)'
    },
    '&:after': {
      background: 'rgb(18, 18, 29)'
    },
    '& svg': {
      fill: '#B1B6CD'
    }
  },
  icon: {
    fill: 'white',
  },
  root: {
    background: "transparent",
  },
  EpisodeSlider: {
    padding: "0 0 0 5%",
    display: "flex"
  },
  AllParts: {
    color: theme.palette.type == "dark" ? 'white' : 'black',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  sliderContainer: {
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
    padding: theme.direction === 'rtl' ? "30px 5% 30px 0" : "30px 0 30px 5%",
    transition: "all 300ms",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  whiteColor: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Roboto",
    fontStyle: 'normal',
    lineHeight: '48px',
    letterSpacing: '0px',
    '&svg': {
      display: 'none'
    },
    '&:focus': {
      background: "none"
    }
  },
  list: {
    borderRadius: '10px',
    border: '0.5px solid #6869AC',
    boxShadow: '-6px 8px 25px rgba(0,0,0, 0.4)',
    background: 'linear-gradient(270deg, rgba(18, 18, 26, 1) 0%, rgba(18, 18, 27, 1) 40%, rgba(23, 23, 39, 1) 100%)',
    color: '#B1B6CD',
    width: '15%',
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      margin: '0 10% 0 0%',
      color: '#ffffff',
    },
    "& li": {
      margin: '0 10% 0 10%',
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "white",
    paddingTop: "8px",
    padding: theme.direction === "rtl" ? '0 0 0 5%' : '0 5% 0 0'
  },
  Title: {
    color: "#B1B6CD",
    fontSize: "14px",
    fontFamily: "Plus Jakarta Display"

  },
  Image: {
    marginLeft: "30px",
    "&:hover": {
      opacity: 0.9,
      transform: "scale(1)",
      borderRadius: "009B07",
    },
  },
  seasonsContainer: {
    bottom: '0',
    backgroundColor: `${theme.palette.type == "dark" ? 'rgba(33, 35, 48, 0.6)' : 'rgb(244 244 244 / 60%)'}`,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    padding: '0 0 0 0'
  },
  seasonsContainerPC: {
    bottom: '0',
    backgroundColor: `${theme.palette.type == "dark" ? 'rgba(33, 35, 48, 0.6)' : 'rgb(244 244 244 / 60%)'}`,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: "180px",
    padding: '0 0 0 0'
  },
  seasonsListContainer: {
    padding: theme.direction === 'rtl' ? "0 5% 0 0" : "0 0 0 5%",
  },
  displayNon: {
    display: `${theme.direction === 'rtl' ? 'none' : 'flex'}`,
    height: "30px",
    width: "30px",
    cursor: "pointer"
  },
  displayFlex: {
    display: `${theme.direction === 'rtl' ? 'flex' : 'none'}`,
    height: "30px",
    width: "30px",
    cursor: "pointer"
  },
  slideCard: {
    height: "150px"
  }
});
export default withStyles(styles)(withTranslation()(EpisodesSlider));
