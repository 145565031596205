import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Platform,
} from "react-native";


// Customizable Area End
import {
    Avatar,
    Box,
    Checkbox,
    Button,
    CircularProgress,
    IconButton,
    Link,
    MenuItem,
    Select,
    NativeSelect,
    TextField,
    Typography
} from "@material-ui/core";

import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// import SpinnerController, { Props } from "./SpinnerController";
const avtarIcon = {
    width: "31px",
    height: "31px",
    margin: "20px 23px 20px 19px"
} as const;
// Customizable Area Start
interface Props {
    spinnerModal:boolean;
  }
  
  const Spinner = ({
    spinnerModal
  }: Props) => {
    return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex:1400
                    }}
                    open={spinnerModal}
                    // onClose={this.modalhandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    disableAutoFocus={true}
                >
                    <Fade in={spinnerModal} style={{ border: "none" }}>
                        <div style={{
                            backgroundColor: "transparent",
                            outline: "none",
                            borderRadius: 29,
                            width: "auto",
                            height: "auto",

                        }}>
                            <CircularProgress color="secondary" style={{ height: '100px', width: '100px' }}/>
                             {/* <Player
                                autoplay
                                loop
                                src={spingif}
                                style={{ height: '300px', width: '300px' }}
                            /> */}
                        </div>
                    </Fade>
                </Modal>
            </>
        )
    
}
export default Spinner;
