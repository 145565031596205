Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.apiMethodTypeAddDetail = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";
exports.getCheckoutIdEndPoint = "bx_block_payments/hyperpays/checkout";

exports.btnExampleTitle = "CLICK ME";
exports.checkoutid = "/bx_block_payments/hyperpays/get_status";
exports.mobileValid = "/bx_block_payments/hyperpays/verify_mobile_checkout";
exports.cryptoKey="almansha-payment-token"
// Customizable Area End
