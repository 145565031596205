import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";

// Customizable Area Start
import moment from "moment";
import { sendAPIRequest } from "../../../components/src/Utility";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // classes: any;
  t: any;
}

export interface S {
  // Customizable Area Start
  plansDetails: any;
  loading: boolean;
  selectedPlanId: any;
  seletedCoupounID: any;
  selectedPlanDetail: any;
  currentPlanDetails: any;
  confirmDialog: boolean;
  successDialog: boolean;
  currentPlanPrice: number;
  selectedPlanPrice: number;
  changeDate: any;
  isDisable: boolean;
  downgradStatus: boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class ChangePlanController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPlansApiCallId: string = "";
  getUserProfileApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      plansDetails: [],
      selectedPlanId: "",
      seletedCoupounID: "",
      loading: false,
      selectedPlanDetail: {},
      currentPlanDetails: {},
      confirmDialog: false,
      successDialog: false,
      currentPlanPrice: 0,
      selectedPlanPrice: 0,
      changeDate: "",     
      isDisable: false,
      downgradStatus: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfile();
    this.getPlans();
    let upgradeFromUser =  sessionStorage.getItem("upgradeFromUser")
    sessionStorage.clear()
    upgradeFromUser && sessionStorage.setItem("upgradeFromUser" , upgradeFromUser)
  }

  async receive(from: string, message: Message) {
    const id = getName(MessageEnum.RestAPIResponceMessage);
    if (message.id !== id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getPlansApiCallId:
        this.handleGetPlansResponse(responseJson);
        break;
      case this.getUserProfileApiCallId:
        this.handleGetUserProfileResponse(responseJson);
        break;
    }
  }

  handleGetPlansResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ plansDetails: responseJson?.packages?.data,  loading: false });
    } else if (responseJson && responseJson.errors) {
      this.setState({ loading: false });
      const key = Object.keys(responseJson?.errors[0])[0];
      toast.error(responseJson?.errors[0][key]);
    }
  }

  navigateToUpgradePlan = () => {
    this.props.navigation.navigate("UpgradePlan", { id: this.state.selectedPlanId}); 
  }  

  
  handleGetUserProfileResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ currentPlanDetails: responseJson?.data, changeDate:responseJson?.data?.attriutes?.end_date, loading: false });
    } else if (responseJson && responseJson.errors) {
      this.setState({ loading: false });
      const key = Object.keys(responseJson?.errors[0])[0];
      toast.error(responseJson?.errors[0][key]);
    }
  }

  // Customizable Area Start

  onBackClick = () => {
    this.props.navigation.goBack();
  };
  // API Integration
  getPlans = () => {
    this.setState({ loading: true });
    this.getPlansApiCallId = sendAPIRequest(configJSON.getPlansEndPoint, {
      headers: {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("Token"),
        language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
      },
    });
  };

  getUserProfile = () => {
    this.setState({ loading: true });
    this.getUserProfileApiCallId = sendAPIRequest(
      configJSON.getUserCurrentPlanEndPoint,
      {
        headers: {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem("Token"),
          language: localStorage.getItem("i18nextLng") === "ar" ? "ar" : "en",
        },
      }
    );
  };

  goToDowngradePerk = (_data: any) =>{
    if(_data.attributes.is_disable && !this.state.downgradStatus){
      this.setState({downgradStatus: true})
      this.props.navigation.navigate("DowngradePerks")
    }else if(_data.attributes.is_disable && this.state.downgradStatus){
      this.buttonClick(_data);
    } else{
      this.buttonClick(_data);
    }
  }

  buttonClick = (_data: any) => {
    if (parseInt(this.state.selectedPlanId) == parseInt(_data.id)) {
      this.setState({ confirmDialog: true });
    } else {
      this.setState({
        selectedPlanId: Number(_data.id),
        selectedPlanDetail: _data,
      });
    }
  };
  
  buttonName(_data: any) {
    let name =
      parseInt(this.state.selectedPlanId) == parseInt(_data.id)
        ? this.props.t("view_subscription.select")
        : this.props.t("view_subscription.CP");
    return parseInt(_data.id) ===
      parseInt(this.state.currentPlanDetails?.id)
      ? this.props.t("view_subscription.currentPlan")
      : name;
  }

  deleteButtonName() {
    let message =
      parseInt(this.state.currentPlanDetails?.attributes?.price) >
      parseInt(this.state.selectedPlanDetail?.attributes?.price)
        ? this.props.t("view_subscription.dialogCPSHC")
        : this.props.t("view_subscription.dialogCPSHC1");
    return message;
  }

  deleteClick = () => {
    this.props.navigation.navigate("Setting");
    this.setState({
      successDialog: false,
      selectedPlanDetail: {},
      changeDate: "",
      selectedPlanId: "",
    });
  };

  successConfirmMes() {
    let message =
     `${this.props.t("view_subscription.successCPM")} ${moment(
            this.state.changeDate
          ).format("DD-MMM-YYYY")}`        
    return message;
  }

  // Customizable Area End
}
