import React from "react";

// Customizable Area Start
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsButton extends shaka.ui.OverflowMenu<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  constructor(parent: any, controls: any) {
    super(parent, controls);
    this.parent.lastChild.innerText = "settings";
  }
  // Customizable Area End
}
// Customizable Area Start
SettingsButton.Factory = class {
  create(rootElement: any, controls: any) {
    return new SettingsButton(rootElement, controls);
  }
};
// Customizable Area End
